import { styled, Typography } from '@mui/material'
import { Box } from 'library'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  width: '339px',
  height: '98px',
  borderRadius: '8px',
  backdropFilter: 'blur(28px)',
  opacity: '1',
  padding: '11px',
  border: `1px solid ${colors.background}`,
  boxSizing: 'border-box',
  background:
    'linear-gradient(90deg, rgba(249,250,250,1) 0%, rgba(245,255,255,1) 50%, rgba(249,250,250,1) 100%)',
})

export const StyledTypography = styled(Typography)({
  fontSize: '12px',
})

export const StyledBodyContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
})

export const StyledArrowContainer = styled('label')({
  width: '15px',
  height: '15px',
  position: 'absolute',
  display: 'flex',
  left: '29px',
  bottom: '14%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.orange,
  borderRadius: '50px',

  '& svg': {
    color: colors.commonWhite,
    fontSize: '12px',
    transform: 'scale(-1,1) rotate(180deg)',
  },
})

export const androidStyles = {
  backgroundColor: colors.commonWhite,
  borderRadius: '1px',
  background: 'unset',
}
