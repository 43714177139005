import { styled } from '@mui/material'
import { Box } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const ChartBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '70% 30%',
  columnGap: 0,

  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '100%',
    rowGap: '20px',
  },
})

export const LegendAndNotesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  outline: `1px solid ${colors.border}`,
  padding: '0rem',
  width: '640px',
  height: '220px',
  border: `16px solid ${colors.commonWhite}`,
  borderRadius: '6px',
  backgroundColor: colors.commonWhite,

  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '1rem',
    width: '250px',
    height: '430px',
    backgroundColor: colors.background,
  },
})

export const LayoutContainer = styled(Box)({
  width: '48%',
  backgroundColor: colors.background,
  padding: '30px 20px',
  boxSizing: 'border-box',
  borderRadius: '6px',

  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    width: '100%',
    padding: '0px',
    borderRadius: 'inherit',
  },
})
