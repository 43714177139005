import { useState } from 'react'

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
  onError?: (error: Error) => void
) {
  // Inspired from https://usehooks.com/useLocalStorage/
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  /* eslint-disable no-console */
  const loggingFunc = onError || console.error
  /* eslint-enable no-console */
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      const item = window.sessionStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      loggingFunc!(error as Error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)

      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      loggingFunc!(error as Error)
    }
  }
  return [storedValue, setValue] as const
}

export default useSessionStorage
