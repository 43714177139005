import { PreInventoryColumnField } from 'constants/preinventory'
import { SelectItem } from 'models/select'
import { FieldSelectTextType } from 'models/text/PreInventoryListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: PreInventoryColumnField.ID,
    },
    {
      label: text.slug,
      value: PreInventoryColumnField.SLUG,
    },
    {
      label: text.picture,
      value: PreInventoryColumnField.PICTURE,
    },
    {
      label: text.vehicle,
      value: PreInventoryColumnField.VEHICLE,
    },
    {
      label: text.trim,
      value: PreInventoryColumnField.TRIM,
    },
    {
      label: text.provider,
      value: PreInventoryColumnField.PROVIDER,
    },
    {
      label: text.price,
      value: PreInventoryColumnField.PRICE,
    },
    {
      label: text.mileage,
      value: PreInventoryColumnField.MILEAGE,
    },
    {
      label: text.createdAt,
      value: PreInventoryColumnField.CREATED_AT,
    },
    {
      label: text.certified,
      value: PreInventoryColumnField.CERTIFIED,
    },
    {
      label: text.status,
      value: PreInventoryColumnField.STATUS,
    },
    {
      label: text.actions,
      value: PreInventoryColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    slug: text.slug,
    picture: text.picture,
    vehicle: text.vehicle,
    trim: text.trim,
    provider: text.provider,
    price: text.price,
    mileage: text.mileage,
    certified: text.certified,
    status: text.status,
    actions: text.actions,
  }
}

export const defaultFields = [
  PreInventoryColumnField.PICTURE,
  PreInventoryColumnField.VEHICLE,
  PreInventoryColumnField.TRIM,
  PreInventoryColumnField.PROVIDER,
  PreInventoryColumnField.CERTIFIED,
  PreInventoryColumnField.STATUS,
  PreInventoryColumnField.ACTIONS,
]
