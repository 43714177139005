import { styled } from '@mui/material'
import { Box } from 'library'

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingLeft: '1rem',
  rowGap: '1.2rem',
})
