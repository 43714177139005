import React from 'react'
import { CSSObject } from '@mui/material'
import { Box } from 'library'

import { FONT_WEIGHT } from 'styles/theme'

type StatusTagProps = {
  backgroundColor: string
  color: string
  tagSx?: CSSObject
}

const StatusTag: React.FC<StatusTagProps> = ({
  backgroundColor,
  color,
  children,
  tagSx,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor,
        color,
        width: '85px',
        height: '25px',
        borderRadius: '2px',
        opacity: '1',
        fontSize: '12px',
        fontWeight: FONT_WEIGHT.MEDIUM,
        ...tagSx,
      }}
    >
      {children}
    </Box>
  )
}

export default StatusTag
