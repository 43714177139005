import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Card, Typography } from '@mui/material'

import Tooltip from 'components/Common/Table/Tooltip'

import { colors } from 'styles/theme'

type ErrorTooltipProps = {
  errorMessage: string
}

const ErrorTooltip = ({ errorMessage }: ErrorTooltipProps) => {
  return (
    <Tooltip
      customPopper={
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '15px 15px',
            width: '200px',
            height: 'fit-content',
            fontSize: '14px',
          }}
        >
          <Typography color={colors.lightRed} variant="body1">
            {errorMessage}
          </Typography>
        </Card>
      }
      popperSx={{
        zIndex: 1400,
      }}
      boxSx={{
        width: '15%',
      }}
    >
      <InfoIcon color="error" fontSize="small" sx={{ cursor: 'pointer' }} />
    </Tooltip>
  )
}

export default ErrorTooltip
