import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import {
  createSimpleStepperItems,
  LoadingAnimation,
  Stepper,
  Title,
  useNotification,
} from 'library'

import {
  arrayOfNamedDays,
  emptyInputWeekCalendar,
} from 'constants/Operation/outOfSpot'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  HoursPair,
  OutOfSpotModel,
  OutOfSpotUpdateResponse,
  PairUpdateOutOfSpotInputType,
  ScheduleResponse,
} from 'models/outOfSpot'
import { GenericData, GenericInputVariable } from 'models/services/base'
import { WeekCalendarResponseType } from 'models/services/curboSpot'
import { OUT_OF_SPOT_CONFIG as steps } from 'utils/OutOfSpot/configuration'

import { GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID } from 'graphQL/Common/Dealer/queries'
import { UPDATE_TEST_DRIVE_AND_INSPECTIONS_WEEK_CALENDAR } from 'graphQL/Operations/OutOfSpot/mutations'
import { GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID } from 'graphQL/Operations/OutOfSpot/queries'

import { StyledBody, StyledBox } from 'styles/operation/creation'
import { colors } from 'styles/theme'

import { SingleStepperBox } from './style'

const OutOfSpotPage = () => {
  const { show } = useNotification()
  const [schedules, setSchedules] = useState<OutOfSpotModel>()
  const [inspectionSchedules, setInspectionSchedules] =
    useState<ScheduleResponse>()
  const [testDriveSchedules, setTestDriveSchedules] =
    useState<ScheduleResponse>()
  const [inspectionsId, setInspectionsId] = useState<string>('')
  const [testDriveId, setTestDriveId] = useState<string>('')

  const currentStep = 0

  const { text } = useTranslation(textFiles.OUT_OF_SPOT)

  const {
    title,
    description,
    informationTab: { title: infoTitle, subtitle, stepperTitle },
    errorMessage,
    successMessage,
  } = text

  // NEEDS CHECKING
  const stepperItems = createSimpleStepperItems(stepperTitle)

  const { loading: inspectionLoading } = useQuery<
    GenericData<WeekCalendarResponseType>,
    GenericInputVariable<string | null>
  >(GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: null,
    },
    onCompleted(response) {
      const responseWeekCalendar = { ...response.data }
      setInspectionSchedules(responseWeekCalendar)
      setInspectionsId(responseWeekCalendar.id)
    },
    onError(error) {
      if (error) {
        show({
          updatedSeverity: 'error',
        })
      }
    },
  })

  const { loading: testDriveLoading } = useQuery<
    GenericData<WeekCalendarResponseType>,
    GenericInputVariable<string | null>
  >(GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: null,
    },
    onCompleted(response) {
      const responseWeekCalendar = { ...response.data }
      setTestDriveSchedules(responseWeekCalendar)
      setTestDriveId(responseWeekCalendar.id)
    },
    onError(error) {
      if (error) {
        show({
          updatedSeverity: 'error',
        })
      }
    },
  })

  const [updateInspectionAndTestDriveWeekCalendar, { loading: submitLoading }] =
    useMutation<
      GenericData<OutOfSpotUpdateResponse>,
      PairUpdateOutOfSpotInputType
    >(UPDATE_TEST_DRIVE_AND_INSPECTIONS_WEEK_CALENDAR, {
      onCompleted() {
        show({
          updatedSeverity: 'success',
          message: successMessage,
        })
      },
      onError() {
        show({
          updatedSeverity: 'error',
          message: errorMessage,
        })
      },
    })

  /* Here is the updateSchedules function:
  1. We are using the updateInspectionAndTestDriveWeekCalendar mutation to update the test drive and inspection schedules.
  2. We use the forEach method to iterate over the array of named days.
  3. We use the filter method to filter out the unchecked hours.
  4. We use the map method to map the checked hours to their value.
  5. We use the spread operator to combine the two arrays into one object .
  */
  const updateSchedules = (newSchedules: HoursPair) => {
    const testDriveSchedule = { ...emptyInputWeekCalendar }
    const inspectionsSchedule = { ...emptyInputWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      inspectionsSchedule[day] = newSchedules.inspections[day]
        .filter((h) => h.checked)
        .map((h) => h.value)
      testDriveSchedule[day] = newSchedules.testDrive[day]
        .filter((h) => h.checked)
        .map((h) => h.value)
    })
    updateInspectionAndTestDriveWeekCalendar({
      variables: {
        testDrive: {
          where: {
            id: testDriveId,
          },
          data: {
            ...testDriveSchedule,
          },
        },
        inspections: {
          where: {
            id: inspectionsId,
          },
          data: {
            ...inspectionsSchedule,
          },
        },
      },
    })

    setSchedules(newSchedules)
  }

  useEffect(() => {
    if (testDriveSchedules && inspectionSchedules) {
      setSchedules({
        testDrive: testDriveSchedules,
        inspections: inspectionSchedules,
      })
    }
  }, [inspectionSchedules, testDriveSchedules])

  if ((inspectionLoading || testDriveLoading) && !schedules) {
    return <LoadingAnimation showAnimation />
  }

  return (
    <StyledBox>
      <Title title={title} subtitle={description} />
      <StyledBody>
        <Typography variant="h3">{infoTitle}</Typography>
        <Typography variant="body2" fontSize="18px" color={colors.subtitleGray}>
          {subtitle}
        </Typography>
        <SingleStepperBox>
          <Stepper currentStep={currentStep} stepperItems={stepperItems} />
          {React.createElement(steps[currentStep], {
            schedules,
            submitLoading,
            updateSchedules,
          })}
        </SingleStepperBox>
      </StyledBody>
    </StyledBox>
  )
}
export default OutOfSpotPage
