import {
  buttonClasses,
  formControlClasses,
  inputBaseClasses,
  selectClasses,
  styled,
} from '@mui/material'
import { Box, Button, Container, TextField } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors, FONT_WEIGHT } from 'styles/theme'

export const SetButton = styled(Button)({
  border: `2px solid ${colors.lightGray} !important`,
  color: `${colors.lightGray} !important`,
  borderRadius: '4px !important',
  height: '40px !important',
  width: '100% !important',
  display: 'flex',
  justifyContent: 'flex-start !important',

  [`.${buttonClasses.endIcon}`]: {
    position: 'absolute',
    right: 10,
  },
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  border: 'unset',
  borderRadius: '8px',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('form')({
  borderRadius: '0px',
  width: '100%',

  '> div': {
    display: 'grid',
    gridTemplateColumns: '45% 35%',
    width: '100%',
    columnGap: 30,
    [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
      gridTemplateColumns: '45% 45%',
    },
    section: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      rowGap: '20px',

      '> div': {
        width: '100%',
        minWidth: '245px',
      },
    },
  },
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  marginBottom: '20px',
  '> p': {
    fontSize: '14px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledTextField = styled(TextField)({
  width: '100%',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '14px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '14px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
  },
})

export const StyledSchedule = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '50% 45%',
  width: '100%',
  columnGap: 20,

  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '100%',
  },
  '> div': {
    width: '100%',
  },
})

export const PreviewContainer = styled(Container)({
  width: '100%',
  padding: 0,
  '> div': {
    display: 'grid',
    rowGap: 10,
    width: '100% !important',
    '> div': {
      width: '100% !important',
      maxWidth: 451,
    },
  },
})

export const MessageCard = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
  height: 98,
  fontSize: '12px',
  border: 'unset',
  borderRadius: '8px',
  backgroundColor: colors.cyan,
})
