import React, { MouseEventHandler, useCallback, useState } from 'react'
import { ClickAwayListener, Popper, Typography } from '@mui/material'
import { Box, Button } from 'library'

import { StyledSquareIcon, StyledStripe } from 'components/Motives/Stripe/style'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Hour } from 'models/hours'

import { colors } from 'styles/theme'

import HourInputField from '../HourInputField'

import { StyledBox } from './style'

type StripeProps = {
  time: Hour
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

const Stripe = ({ time, handleEditMode }: StripeProps) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [newValue, setNewValue] = useState<Hour>(time)
  const [editAnchor, setEditAnchor] = useState<HTMLElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [openEditor, setOpenEditor] = useState<boolean>(false)
  const open = Boolean(anchorEl)

  const { text: translation } = useTranslation(textFiles.GENERAL)
  const { motives } = translation

  const handleOpenEditor: MouseEventHandler<HTMLDivElement> = (e) => {
    setOpenEditor((prev) => !prev)
    setEditAnchor(e.currentTarget)
  }

  const handleCloseEditor = () => {
    setEditAnchor(null)
    setOpenEditor(false)
  }

  const handleClosePopper = () => {
    setAnchorEl(null)
    setEdit(false)
    handleEditMode(false)
    setNewValue(time)
  }

  const handleChangeHour = useCallback((value: Hour) => {
    setNewValue(value)
  }, [])

  return (
    <>
      <StyledStripe>
        <section>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={edit ? { cursor: 'pointer' } : undefined}
            onClick={edit ? handleOpenEditor : undefined}
          >
            <StyledSquareIcon />
            <p>{`${newValue.name} ${newValue.am ? 'a.m.' : 'p.m.'}`}</p>
          </Box>
        </section>
      </StyledStripe>
      <Popper
        id="editor-popper"
        open={openEditor}
        anchorEl={editAnchor}
        placement="bottom-start"
        sx={{ offsetAnchor: '-15px !important' }}
      >
        <ClickAwayListener disableReactTree onClickAway={handleCloseEditor}>
          <HourInputField time={newValue} handleTimeChange={handleChangeHour} />
        </ClickAwayListener>
      </Popper>
      <Popper
        id="confirmation-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClosePopper}>
          <StyledBox>
            <Typography>{motives.popperTitle}</Typography>
            <Box display="flex" justifyContent="space-evenly" marginTop="10px">
              <Button
                size="small"
                buttonType="info"
                sx={{
                  backgroundColor: colors.background,
                }}
                onClick={handleClosePopper}
              >
                {motives.decline}
              </Button>
            </Box>
          </StyledBox>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default Stripe
