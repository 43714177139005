import React from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { CSSObject } from '@mui/material'
import {
  cypressDecreaseCounterButton,
  cypressIncreaseCounterButton,
} from 'library/constants/cypress'

import { CounterDiv, StyledButton } from './style'

export type CounterButtonProps = {
  /**
   *current counter value
   */
  value: number
  /**
   *function to toggle current value
   */
  handleValueChange:
    | ((value: number) => void)
    | React.Dispatch<React.SetStateAction<number>>
  /**
   *max value limit
   */
  maxValue?: number
  /**
   *min value limit
   */
  minValue?: number
  /*
   * container custom styles
   */
  containerStyle?: CSSObject
  /*
   * button custom styles
   */
  buttonStyle?: CSSObject
}

export const CounterButton = ({
  value,
  containerStyle,
  buttonStyle,
  handleValueChange,
  maxValue = 5,
  minValue = 0,
}: CounterButtonProps) => {
  const handleClick = (newValue: number) => {
    if (newValue !== null) {
      handleValueChange(newValue)
    }
  }

  return (
    <CounterDiv sx={containerStyle}>
      <StyledButton
        sx={buttonStyle}
        onClick={() => {
          if (value < maxValue) handleClick(value + 1)
        }}
        testId={cypressIncreaseCounterButton}
        variant="text"
      >
        <ArrowDropUpIcon />
      </StyledButton>
      <StyledButton
        sx={{ buttonStyle, justifyContent: 'flex-end' }}
        onClick={() => {
          if (value > minValue) handleClick(value - 1)
        }}
        testId={cypressDecreaseCounterButton}
        variant="text"
      >
        <ArrowDropDownIcon />
      </StyledButton>
    </CounterDiv>
  )
}
