import React from 'react'
import { useFormik } from 'formik'
import { ButtonNavigator, Slider } from 'library'
import * as yup from 'yup'

import { VEHICLE_CREATION_OPTION } from 'constants/Inventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { FifthStepModel } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/Inventory/creation'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextFieldContainer,
} from 'styles/creation'

import { maxValue, minValue } from '../../Common/NumberValueInput'

type FormikKeys = 'doors' | 'height' | 'length' | 'seats' | 'width'

const initialValue: FifthStepModel = {
  doors: 1,
  height: 1,
  length: 1,
  seats: 1,
  width: 1,
}

const VehicleMeasurements = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryBack,
  handleSecondaryContinue,
}: VehicleCreationProps) => {
  const {
    text: {
      subSteps: { fifthStep: translation },
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const initialData = vehicleData.vehicleInformation.fifthStep || initialValue

  const validationSchema = yup.object().shape({
    doors: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    height: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    length: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    seats: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    width: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
  })

  const formik = useFormik<FifthStepModel>({
    initialValues: initialData,
    validationSchema,
    onSubmit: (values) => {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
        payload: {
          ...vehicleData.vehicleInformation,
          fifthStep: values,
        },
      })
      handleSecondaryContinue()
    },
  })

  const handleSliderChange = (value: number | number[], field: FormikKeys) => {
    formik.setFieldValue(field, Number(value))
  }

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer title={translation.lengthLabel}>
            <Slider
              value={formik.values.length}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'length')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.length && formik.errors.length ? (
              <StyledErrorMessage text={formik.errors.length} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.widthLabel}>
            <Slider
              value={formik.values.width}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'width')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />
            {formik.touched.width && formik.errors.width ? (
              <StyledErrorMessage text={formik.errors.width} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.heightLabel}>
            <Slider
              value={formik.values.height}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'height')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.height && formik.errors.height ? (
              <StyledErrorMessage text={formik.errors.height} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.seatsLabel}>
            <Slider
              value={formik.values.seats}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'seats')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />
            {formik.touched.seats && formik.errors.seats ? (
              <StyledErrorMessage text={formik.errors.seats} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.doorsLabel}>
            <Slider
              value={formik.values.doors}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'doors')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.doors && formik.errors.doors ? (
              <StyledErrorMessage text={formik.errors.doors} />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        previousFunction={handleSecondaryBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehicleMeasurements
