import { CityOption } from 'pages/Operation/Dealer/Creation'

import DealerInfo from 'components/Operations/Dealer/Creation/DealerInfo'
import DealerLocation from 'components/Operations/Dealer/Creation/DealerLocation'
import DealerSummary from 'components/Operations/Dealer/Creation/DealerSummary'

import { DealerCreationAction, DealerCreationModel } from 'models/dealer'
import { Option } from 'models/select'

export type DealerCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  dealerData: DealerCreationModel
  updateDealerData: (action: DealerCreationAction) => void
  handleSubmit: () => void
  submitLoading: boolean
  cities: CityOption[]
  curboSpots: Option[]
}

export const DEALER_CREATION_STEPS: Array<React.FC<DealerCreationProps>> = [
  DealerInfo,
  DealerLocation,
  DealerSummary,
]
