import React, { useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  BodyContainerWithHeader,
  createSimpleStepperItems,
  getIsoDate,
  LoadingAnimation,
  Stepper,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'
import { uploadImageService } from 'services/uploadImage'

import { BackContainer } from 'components/Common/BackContainer'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import {
  initialOfferData,
  initialYears,
} from 'constants/Marketing/offerCreation'
import { OFFERS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  CarDiscountOptionsInput,
  DiscountTypes,
  OfferCreationInputType,
  OfferCreationYears,
  OfferStatusTypes,
  OfferTypes,
} from 'models/offer'
import {
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { Brand, Model } from 'models/services/car'
import { OfferCreationAction } from 'models/services/marketing/offerCreation'
import offerCreationReducer from 'reducers/Marketing/Offer/OfferCreationReducer'
import { INITIAL_OFFER_CREATION_STEPS as steps } from 'utils/Marketing/Offer/creation'

import {
  GET_INSPECTION_BRANDS,
  GET_INSPECTION_MODELS,
} from 'graphQL/Common/CarFeature/queries'
import { CREATE_OFFER } from 'graphQL/Marketing/Creation/mutations'
import { GET_FILTER_CONFIG } from 'graphQL/Marketing/Creation/queries'

const OfferCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [offerData, dispatch] = useReducer(
    offerCreationReducer,
    initialOfferData
  )
  const [brands, setBrands] = useState<Brand[]>([])
  const [models, setModels] = useState<Model[]>([])
  const [years, setYears] = useState<OfferCreationYears>({ ...initialYears })

  const history = useHistory()
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.OFFER_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const stepperItems = createSimpleStepperItems(text.stepper)

  const { loading: inspectionBrandsLoading } = useQuery<
    GenericData<Brand[]>,
    FilterInputVariable
  >(GET_INSPECTION_BRANDS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setBrands(response.data)
    },
  })

  const { loading: yearsLoading } = useQuery<
    GenericData<OfferCreationYears>,
    FilterInputVariable
  >(GET_FILTER_CONFIG, {
    onCompleted(response) {
      setYears(response.data)
    },
  })

  const { loading: inspectionModelsLoading } = useQuery<
    GenericData<Model[]>,
    FilterInputVariable
  >(GET_INSPECTION_MODELS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setModels(response.data)
    },
  })

  const [createOffer] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<OfferCreationInputType>
  >(CREATE_OFFER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(OFFERS_SUB_ROUTES.OFFERS_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )

      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      }
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const updateOfferData = (action: OfferCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleSubmit = async () => {
    const {
      offerInformation: {
        brands: brandsData,
        backgroundColor,
        cars,
        carDiscounts,
        description,
        discount,
        discountType,
        from,
        label,
        labelColor,
        maxPrice,
        maxYear,
        minPrice,
        minYear,
        models: modelsData,
        priority,
        isActive,
        textColor,
        to,
        type,
        logoFile,
        pictureFile,
        // backgroundColorHexcode,
        // textColorHexcode,
        // labelColorHexcode,
      },
    } = offerData

    const formattedCars: CarDiscountOptionsInput[] = cars.map((car, idx) => {
      return {
        id: car.id,
        discount:
          discountType === DiscountTypes.INDIVIDUAL
            ? carDiscounts[idx]
            : undefined,
      }
    })

    /**
     * Since right now, backend allows an array of pictures, instead of a single picture,
     * we treat the stored picture file as an array.
     */

    const picture = pictureFile
      ? await uploadImageService(pictureFile)
      : undefined

    const logo = logoFile ? await uploadImageService(logoFile) : undefined

    try {
      createOffer({
        variables: {
          input: {
            backgroundColor,
            brands: type === OfferTypes.BRAND ? brandsData : undefined,
            cars: type === OfferTypes.CAR ? formattedCars : undefined,
            description,
            discount: discount || 0,
            discountType,
            from: from ? getIsoDate(from) : undefined,
            label,
            labelColor,
            logo: logo ? logo.data : '',
            maxPrice: type === OfferTypes.PRICE ? maxPrice : undefined,
            maxYear: type === OfferTypes.YEAR ? maxYear : undefined,
            minPrice: type === OfferTypes.PRICE ? minPrice : undefined,
            minYear: type === OfferTypes.YEAR ? minYear : undefined,
            models: type === OfferTypes.MODEL ? modelsData : undefined,
            // pictures: picture ? [picture.data] : [],
            picture: picture ? picture.data : '',
            priority,
            status: isActive
              ? OfferStatusTypes.ACTIVE
              : OfferStatusTypes.INACTIVE,
            textColor,
            to: to ? getIsoDate(to) : undefined,
            type,
          },
        },
      })
    } catch (e) {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.uploadError,
      })
    }
  }

  if (inspectionBrandsLoading || yearsLoading || inspectionModelsLoading)
    return <LoadingAnimation showAnimation />

  return (
    <>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
      >
        <Stepper
          currentStep={currentStep}
          stepperItems={stepperItems}
          topSx={{
            justifyContent: 'flex-start',
          }}
        />
        {React.createElement(steps[currentStep], {
          offerData,
          updateOfferData,
          handleContinue,
          handleBack,
          brands,
          models,
          years,
          handleSubmit,
        })}
      </BodyContainerWithHeader>
    </>
  )
}

export default OfferCreationPage
