import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  BodyContainerWithHeader,
  createSimpleStepperItems,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'

import { BackContainer } from 'components/Common/BackContainer'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Feature } from 'models/car'
import {
  FeatureCreationAction,
  FeatureCreationModel,
} from 'models/featureCreation'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateCarSettingGenericInputType } from 'models/services/CarSettings/creation'
import { PublicationStatus } from 'models/status'
import featureCreationReducer from 'reducers/featureCreationReducer'
import { INITIAL_FEATURE_CREATION_STEPS as steps } from 'utils/CarSettings/Feature/creation'
import { getTranslationsFilteredByLanguage } from 'utils/translation'

import { CREATE_FEATURE } from 'graphQL/Operations/Feature/Creation/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialFeatureInformationState: Feature = {
  name: '',
  status: PublicationStatus.PUBLISHED,
  translations: [],
}

const initialData: FeatureCreationModel = {
  featureInformation: initialFeatureInformationState,
}

const FeatureCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [featureData, dispatch] = useReducer(
    featureCreationReducer,
    initialData
  )

  const { featureInformation } = featureData
  const { translations } = featureInformation

  const history = useHistory()
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.FEATURE_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const { stepper } = text

  const stepperItems = createSimpleStepperItems(stepper)

  const updateFeatureData = (action: FeatureCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const [createFeature, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateCarSettingGenericInputType>
  >(CREATE_FEATURE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CAR_SETTINGS_SUB_ROUTES.FEATURE_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(() => {
    const { valueEn, valueEs } = getTranslationsFilteredByLanguage(translations)

    createFeature({
      variables: {
        input: {
          name: featureInformation.name,
          name_translations: {
            en: valueEn ? valueEn.description : null,
            es: valueEs ? valueEs.description : null,
          },
          status: featureInformation.status
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      },
    })
  }, [featureInformation, createFeature, translations])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
        currentStep={currentStep}
        stepperItems={stepperItems}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          handleSubmit,
          submitLoading,
          featureData,
          updateFeatureData,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default FeatureCreationPage
