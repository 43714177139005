import { SelectItem } from 'library'

import { Image } from 'components/Common/Carousel'

import { InspectionLogColumnField } from 'constants/Inventory/detail'
import { InventorySlugStatus } from 'models/status'
import { FieldSelectTextType } from 'models/text/inventoryDetail'

const editablePicturesStatus: string[] = [
  InventorySlugStatus.PENDING,
  InventorySlugStatus.AVAILABLE,
  InventorySlugStatus.COMING_SOON,
  InventorySlugStatus.TO_REVIEW,
]

// functions for inventory gallery
export const canDeletePictures = (imageList: Image[], status: string) => {
  if (status === InventorySlugStatus.PENDING) return true

  if (imageList.length > 2 && editablePicturesStatus.includes(status))
    return true

  return false
}

export const canEditPictures = (status: string) => {
  return !!editablePicturesStatus.includes(status)
}

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: InspectionLogColumnField.ID,
    },
    {
      label: text.createdAt,
      value: InspectionLogColumnField.CREATED_AT,
    },
    {
      label: text.source,
      value: InspectionLogColumnField.SOURCE,
    },
    {
      label: text.provider,
      value: InspectionLogColumnField.PROVIDER,
    },
    {
      label: text.date,
      value: InspectionLogColumnField.DATE,
    },
    {
      label: text.type,
      value: InspectionLogColumnField.TYPE,
    },
    {
      label: text.status,
      value: InspectionLogColumnField.STATUS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    createdAt: text.createdAt,
    source: text.source,
    provider: text.provider,
    date: text.date,
    type: text.type,
    status: text.status,
  }
}

export const defaultInspectionLogField = [
  InspectionLogColumnField.ID,
  InspectionLogColumnField.CREATED_AT,
  InspectionLogColumnField.SOURCE,
  InspectionLogColumnField.PROVIDER,
  InspectionLogColumnField.DATE,
  InspectionLogColumnField.TYPE,
  InspectionLogColumnField.STATUS,
]
