import React, { useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import {
  DetailNavTab,
  generateTabItems,
  LoadingAnimation,
  NavBarItem,
  TabPanel,
  useNotification,
} from 'library'

import { DetailHeader } from 'components/CarSettings/Common/Detail/DetailHeader'
import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import GeneralInformation from 'components/Operations/BlackList/Detail/GeneralInformation'

import {
  BLACK_LIST_STATUS,
  blackListDetailObject,
  BlackListEntityKey,
} from 'constants/Operation/blackList'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/role'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
  GenericUpdateVariable,
} from 'models/services/base'
import {
  BlackListDetailType,
  UpdateBlackListPayload,
} from 'models/services/operations/blackList'

import { ContentContainer, Layout } from 'styles/inspection/detail'

const BlackListDetailPage = () => {
  const { validateAllowedRoles } = useUser()
  const isSupervisor = validateAllowedRoles([
    UserRoles.ADMIN,
    UserRoles.OPERATION_SUPERVISOR,
  ])

  const history = useHistory()
  const { entityType, entityId } =
    useParams<{ entityType: BlackListEntityKey; entityId: string }>()
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.BLACK_LIST_DETAIL)
  const { notificationText, general: translation } = text

  const [notFoundError, setNotFoundError] = useState<boolean | ApolloError>(
    false
  )
  const [tab, setTab] = useState<number>(0)
  const [blackListData, setBlackListData] =
    useState<BlackListDetailType | null>(null)

  const keyExists = entityType in blackListDetailObject

  const { mutation, query } =
    blackListDetailObject[keyExists ? entityType : 'make']

  const { loading: blackListLoading } = useQuery<
    GenericData<BlackListDetailType>,
    GenericInputVariable<string>
  >(query, {
    variables: { input: entityId },
    onCompleted(response) {
      const { data } = response
      // we don't want to show the page if it's not black listed
      if (data.blacklistStatus === BLACK_LIST_STATUS.INACTIVE) {
        setNotFoundError(true)
      } else {
        setBlackListData(data)
      }
    },
    onError(error) {
      setNotFoundError(error)
    },
  })

  const [updateBlackList, { loading: subtmiLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateBlackListPayload>
  >(mutation, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: notificationText.updateSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: notificationText.updateFail,
      })
    },
    refetchQueries: [query],
  })

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleUpdate = async (comment?: string | null) => {
    if (comment) {
      const response = await updateBlackList({
        variables: {
          input: {
            where: {
              id: entityId,
            },
            data: {
              comment,
              blacklistStatus: BLACK_LIST_STATUS.ACTIVE,
            },
          },
        },
      })
      if (response.errors) return false
      return true
    }
    return false
  }

  if (blackListLoading)
    return <LoadingAnimation showAnimation={blackListLoading} />

  if (!(entityType in blackListDetailObject) || notFoundError)
    return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return blackListData ? (
    <Layout>
      <DetailHeader
        title={`${translation.title} #${entityId}`}
        backButtonText={translation.backButton}
        editable={false}
      />
      <DetailSubHeader text={translation.description} />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={initialItems}
      />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <GeneralInformation
            blackListData={blackListData}
            submitLoading={subtmiLoading}
            handleSave={handleUpdate}
            entityType={entityType}
            editDisabled={!isSupervisor}
          />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default BlackListDetailPage
