import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  BodyContainerWithHeader,
  createSimpleStepperItems,
  CurboSpot,
  LoadingAnimation,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'
import { uploadImageService } from 'services/uploadImage'

import { BackContainer } from 'components/Common/BackContainer'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { DEALER_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { DealerCreationAction, DealerCreationModel } from 'models/dealer'
import { Option } from 'models/select'
import {
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  CreateCurboSpotDealerInput,
  CreateDealerInput,
} from 'models/services/operations/dealer'
import { PublicationStatus } from 'models/status'
import dealerCreationReducer from 'reducers/Operation/dealerCreationReducer'
import { DEALER_CREATION_STEPS as steps } from 'utils/Dealer/creation'

import { GET_CITIES } from 'graphQL/Common/City/queries'
import { GET_PUBLISHED_CURBO_SPOTS } from 'graphQL/Common/Dealer/queries'
import {
  CREATE_CURBOSPOT_DEALER,
  CREATE_DEALER,
} from 'graphQL/Operations/Dealer/Creation/mutation'

import { StyledBox } from 'styles/operation/creation'

const initialData: DealerCreationModel = {
  dealerInformation: {
    name: '',
    phoneNumber: '',
    city: '',
    state: '',
    dealerContacts: [],
    picture: '',
    pictureFile: null,
    isSpot: false,
    isWorkshop: false,
    spotName: '',
    linkedSpot: '',
  },
  dealerLocation: {
    address: undefined,
  },
}

export type CityOption = {
  state: {
    name: string
  }
} & Option

const DealerCreation = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [dealerData, dispatch] = useReducer(dealerCreationReducer, initialData)
  const [cities, setCities] = useState<CityOption[]>([])
  const [curboSpots, setCurboSpots] = useState<Option[]>([])
  const history = useHistory()

  const { text } = useTranslation(textFiles.DEALER_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const { title, processDescription } = text

  const stepperItems = createSimpleStepperItems(text.stepper)

  const { loading: citiesLoading } = useQuery<
    GenericData<CityOption[]>,
    FilterInputVariable
  >(GET_CITIES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCities(response.data)
    },
  })

  const { loading: curboSpotsLoading } = useQuery<GenericData<CurboSpot[]>>(
    GET_PUBLISHED_CURBO_SPOTS,
    {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        const responseData = response.data
        setCurboSpots(
          responseData.map((cs) => {
            return {
              name: cs.name,
              value: cs.id,
            }
          })
        )
      },
    }
  )

  const [createDealer, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateDealerInput>
  >(CREATE_DEALER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(DEALER_SUB_ROUTES.DEALER_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const [createCurboSpotDealer, { loading: creationLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateCurboSpotDealerInput>
  >(CREATE_CURBOSPOT_DEALER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(DEALER_SUB_ROUTES.DEALER_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const updateDealerData = (action: DealerCreationAction) => {
    dispatch(action)
  }

  const handleSubmit = useCallback(async () => {
    const { dealerInformation, dealerLocation } = dealerData

    const { address } = dealerLocation
    const {
      city,
      dealerContacts,
      name,
      phoneNumber,
      pictureFile,
      isSpot,
      isWorkshop,
      spotName,
      linkedSpot,
    } = dealerInformation

    if (address) {
      try {
        const pictureResponse = pictureFile
          ? await uploadImageService(pictureFile)
          : undefined

        const dealerVariables = {
          address: address.address,
          city,
          dealerContacts: dealerContacts.map((dealerContact) => {
            return {
              ...dealerContact,
              email: dealerContact.email || 'backoffice@curbo.com',
            }
          }),
          latitude: address.lat,
          longitude: address.lng,
          name,
          telephoneNumber: phoneNumber,
          mainPicture: pictureResponse ? pictureResponse.data : undefined,
          status: PublicationStatus.PUBLISHED,
        }

        if (isSpot) {
          createCurboSpotDealer({
            variables: {
              input: {
                ...dealerVariables,
                supportsMaintenance: isWorkshop!,
                curboSpotName: spotName!,
              },
            },
          })
        } else {
          createDealer({
            variables: {
              input: { ...dealerVariables, curboSpot: linkedSpot },
            },
          })
        }
      } catch (e) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.uploadError,
        })
      }
    }
  }, [createDealer, createCurboSpotDealer, dealerData, generalText, show])

  if (citiesLoading || curboSpotsLoading)
    return <LoadingAnimation showAnimation={citiesLoading} />
  const submitIsLoading = submitLoading || creationLoading
  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={title}
        subtitle={processDescription}
        additionalContainerStyles={{
          height: 'auto',
        }}
        currentStep={currentStep}
        stepperItems={stepperItems}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          updateDealerData,
          dealerData,
          handleSubmit,
          submitLoading: submitIsLoading,
          cities,
          curboSpots,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default DealerCreation
