import { styled } from '@mui/material'
import { Box, Button } from 'library'

import { colors } from 'styles/theme'

export const Divider = styled('span')({
  display: 'inline-block',
  borderBottom: `1px solid ${colors.border}`,
  lineHeight: 0,
  width: '100%',
})

export const RepresentativeNumberBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  width: '95%',
})

export const StyledRepresentativeContainer = styled(Box)({
  width: '100% !important',
  display: 'flex',
  flexWrap: 'wrap',

  '& > div:nth-of-type(2)': {
    width: '48%',
  },
  '& > div:nth-of-type(3)': {
    width: '48%',
  },
})

export const AddRepresentativeButton = styled(Button)({
  padding: '0!important',
  marginBottom: '0.5rem',
  width: 'fit-content',
  justifyContent: 'flex-start !important',
})
