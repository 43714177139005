import BrandInfo from 'components/CarSettings/Brand/Creation/BrandInfo'
import Summary from 'components/CarSettings/Brand/Creation/Summary'

import { BrandCreationAction, BrandCreationModel } from 'models/brandCreation'

export type BrandCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  brandData: BrandCreationModel
  submitLoading: boolean
  updateBrandData: (action: BrandCreationAction) => void
}

export const INITIAL_BRAND_CREATION_STEPS: Array<React.FC<BrandCreationProps>> =
  [BrandInfo, Summary]
