import React, { ChangeEvent, useState } from 'react'
import { useQuery } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material'
import { Box, LoadingAnimation, useNotification } from 'library'

import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'

import { DAY_ENUM, dayName } from 'constants/date'
import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import {
  arrayOfNamedDays,
  emptyCheckedWeekCalendar,
} from 'constants/Operation/outOfSpot'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  CheckHourType,
  DaysPair,
  HoursPair,
  ScheduleType,
} from 'models/outOfSpot'
import { Option } from 'models/select'
import { GenericData } from 'models/services/base'
import { HourTimeType } from 'models/services/curboSpot'
import { OutOfSpotConfigurationProps } from 'utils/OutOfSpot/configuration'

import {
  GET_INSPECTION_HOURS,
  GET_TEST_DRIVE_HOURS,
} from 'graphQL/Operations/OutOfSpot/queries'

import { ScheduleCheckboxList } from './ListCheckboxes'
import {
  FormContent,
  FormTitle,
  StyledContainer,
  StyledForm,
  StyledSelect,
  StyledTextFieldContainer,
} from './style'

const OutOfSpotConfiguration = ({
  schedules,
  submitLoading,
  updateSchedules,
}: OutOfSpotConfigurationProps) => {
  const { show } = useNotification()
  const { text } = useTranslation(textFiles.OUT_OF_SPOT)
  const {
    informationTab: {
      testDriveTitle,
      inspectionTitle,
      dayTitle,
      hourTitle,
      days,
    },
  } = text

  /* Here is the explanation for weekOptions:
  1. We use Object.keys() to get the keys of the enum.
  2. We use map() to transform the keys into Option objects.
  3. We use the key as the name of the Option and the value as the value of the Option.
  4. We use the days object to get the name of the day. */
  const weekOptions: Option[] = (
    Object.keys(DAY_ENUM) as (keyof typeof DAY_ENUM)[]
  ).map((key) => {
    const value = DAY_ENUM[key]
    return { name: days[value], value }
  })

  const [selectedDay, setSelectedDay] = useState<DaysPair>({
    testDrive: 'monday',
    inspections: 'monday',
  })

  const [selectedTime, setSelectedTime] = useState<HoursPair>({
    testDrive: emptyCheckedWeekCalendar,
    inspections: emptyCheckedWeekCalendar,
  })

  const [saveEnabled, setSaveEnabled] = useState(false)

  /* defaultWeek:
  1. We create an empty object that will hold the hours for each day of the week.
  2. We loop through the array of named days.
  3. For each day, we map the hours array to a new array of checkHourType objects.
  4. We check if the schedule has a value for the day. If it does, we set the checked property to true.
  5. If it doesn't, we set the checked property to false.
  6. We return the new object. */
  const defaultWeek = (hoursArray: HourTimeType[], type: ScheduleType) => {
    const weekHours = { ...emptyCheckedWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      weekHours[day] = hoursArray.map((hour) => {
        return {
          ...hour,
          checked:
            (schedules &&
              !!schedules[type][day].find(
                (foundHour) => foundHour.value === hour.value
              )) ||
            false,
        } as CheckHourType
      })
    })
    return weekHours
  }
  const { data: testDriveHours, loading: testDriveHoursLoading } = useQuery<
    GenericData<HourTimeType[]>
  >(GET_TEST_DRIVE_HOURS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const responseHours = response.data
      setSelectedTime({
        ...selectedTime,
        testDrive: defaultWeek(responseHours, 'testDrive'),
      })
    },
    onError: () => {
      show({
        updatedSeverity: 'error',
        message: ENTITY_NOT_FOUND_ERROR,
      })
    },
  })

  const { data: inspectionHours, loading: inspectionsHoursLoading } = useQuery<
    GenericData<HourTimeType[]>
  >(GET_INSPECTION_HOURS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const responseHours = response.data
      setSelectedTime({
        ...selectedTime,
        inspections: defaultWeek(responseHours, 'inspections'),
      })
    },
    onError: () => {
      show({
        updatedSeverity: 'error',
        message: ENTITY_NOT_FOUND_ERROR,
      })
    },
  })

  const handleResetChanges = () => {
    setSelectedTime({
      inspections: defaultWeek(inspectionHours!.data, 'inspections'),
      testDrive: defaultWeek(testDriveHours!.data, 'testDrive'),
    })
    setSaveEnabled(false)
  }

  const handleUpdateSchedules = () => {
    const testDriveArray = { ...emptyCheckedWeekCalendar }
    const inspectionsArray = { ...emptyCheckedWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      testDriveArray[day] = [
        ...selectedTime.testDrive[day].filter((foundHour) => foundHour.checked),
      ]
      inspectionsArray[day] = [
        ...selectedTime.inspections[day].filter(
          (foundHour) => foundHour.checked
        ),
      ]
    })
    updateSchedules({
      testDrive: testDriveArray,
      inspections: inspectionsArray,
    })
    setSaveEnabled(false)
  }

  const handleChangeSelectedTime = (
    e: ChangeEvent<HTMLInputElement>,
    type: ScheduleType
  ) => {
    const selectedHour = selectedTime[type][selectedDay[type]]
    const newValue = {
      ...selectedTime,
      [type]: {
        ...selectedTime[type],
        [selectedDay[type]]: selectedHour.map((hour) => {
          return {
            ...hour,
            checked:
              hour.value === e.target.value ? !hour.checked : hour.checked,
          }
        }),
      },
    }
    setSelectedTime(newValue)
    setSaveEnabled(true)
  }

  const handleChangeDay = (
    e: SelectChangeEvent<unknown>,
    type: ScheduleType
  ) => {
    const newValue = { ...selectedDay, [type]: e.target.value as dayName }
    setSelectedDay(newValue)
  }

  if (inspectionsHoursLoading || testDriveHoursLoading) {
    return <LoadingAnimation showAnimation />
  }

  return (
    <StyledContainer>
      <StyledForm>
        <div>
          <FormContent>
            <section style={{ gridTemplateColumns: '100%' }}>
              <Box display="flex" flexDirection="row">
                <StyledTextFieldContainer
                  title={testDriveTitle}
                  contentStyle={{ marginTop: '0rem' }}
                >
                  <FormTitle>{dayTitle}</FormTitle>
                  <StyledSelect
                    options={weekOptions}
                    variant="outlined"
                    value={selectedDay.testDrive}
                    onChange={(e) => handleChangeDay(e, 'testDrive')}
                  />
                  <FormTitle>{hourTitle}</FormTitle>

                  <Box display="flex" flexWrap="wrap">
                    <ScheduleCheckboxList
                      schedule={selectedTime.testDrive[selectedDay.testDrive]}
                      loading={submitLoading}
                      updateSchedules={handleChangeSelectedTime}
                      type="testDrive"
                    />
                  </Box>
                </StyledTextFieldContainer>

                <StyledTextFieldContainer
                  title={inspectionTitle}
                  contentStyle={{ marginTop: '0rem' }}
                >
                  <FormTitle>{dayTitle}</FormTitle>
                  <StyledSelect
                    options={weekOptions}
                    variant="outlined"
                    value={selectedDay.inspections}
                    onChange={(e) => handleChangeDay(e, 'inspections')}
                  />
                  <FormTitle>{hourTitle}</FormTitle>

                  <Box display="flex" flexWrap="wrap">
                    <ScheduleCheckboxList
                      schedule={
                        selectedTime.inspections[selectedDay.inspections]
                      }
                      loading={submitLoading}
                      updateSchedules={handleChangeSelectedTime}
                      type="inspections"
                    />
                  </Box>
                </StyledTextFieldContainer>
              </Box>
            </section>
          </FormContent>
        </div>
      </StyledForm>
      <SaveChangesBlock
        handleSaveChanges={handleUpdateSchedules}
        shouldRender={saveEnabled || submitLoading}
        submitLoading={submitLoading}
        resetState={handleResetChanges}
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'right !important',
          alignItems: 'center',
          padding: undefined,
          marginRight: '6rem',
        }}
      />
    </StyledContainer>
  )
}

export default OutOfSpotConfiguration
