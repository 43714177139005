import { styled } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import { colors } from 'library/styles/theme'

export const StyledToggleButton = styled(ToggleButton)({
  color: colors.placeholderGray,
  '&.Mui-selected': {
    backgroundColor: colors.orange,
    color: colors.commonWhite,
    ':hover': {
      backgroundColor: colors.orange,
    },
  },
})
