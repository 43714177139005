import {
  IconButton,
  inputBaseClasses,
  selectClasses,
  styled,
  TextField,
} from '@mui/material'
import { Box, Select } from 'library'

import { boxShadow, colors, FONT_WEIGHT } from 'styles/theme'

export const StyledBox = styled(Box)({
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  textAlign: 'left',
  minHeight: '150px',
  padding: '18px',
  width: 'auto',
  height: 'auto',
})

export const StyledIcon = styled(IconButton)({
  backgroundColor: colors.commonWhite,
  borderRadius: '4px',
  textAlign: 'left',
  height: '25px',
  width: '25px',
  alignSelf: 'flex-end',
  position: 'absolute',
  cursor: 'pointer',
  top: 10,
  right: 10,
  boxShadow,
})

export const StyledContainer = styled(Box)({
  color: colors.black,
  backgroundColor: colors.background,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  padding: '50px 40px',
  height: '100%',
})

export const StyledTextField = styled(TextField)({
  backgroundColor: colors.background,
  width: '100%',
  height: 32,
  borderRadius: 4,
  fontWeight: FONT_WEIGHT.BOLD,
  color: colors.black,
  border: 'none',
  div: {
    height: '100%',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.BOLD,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.BOLD,
  },

  input: {
    height: 32,
    width: 255,
    fontSize: '24px',
    padding: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:-internal-autofill-selected': {
      backgroundColor: colors.background,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const StyledTextFieldBox = styled(TextField)({
  backgroundColor: colors.background,
  maxWidth: 255,
  height: 120,
  borderRadius: 4,
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.gray,
  border: 'none',
  overflowY: 'auto',
  div: {
    height: '100%',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: 120,
    width: 255,
    fontSize: '16px',
    padding: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:-internal-autofill-selected': {
      backgroundColor: colors.background,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const StyledSelect = styled(Select)({
  maxWidth: 255,
  height: '32px !important',
  border: 'none',
  borderRadius: 0,
  borderBottom: `2px solid ${colors.black}`,
  svg: {
    color: `${colors.black} !important`,
  },
  [`.${selectClasses.select}`]: {
    height: 32,
    width: 255,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: FONT_WEIGHT.BOLD,
    color: colors.black,
    fontSize: '24px',
  },
  [`.${inputBaseClasses.input}`]: {
    paddingLeft: 0,
  },
})
