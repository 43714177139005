import { NavBarItem } from 'library/components'

type GenerateTabItemsProps = {
  tabs: {
    [Id: string]: string
  }
}
export const generateTabItems = ({
  tabs,
}: GenerateTabItemsProps): NavBarItem[] => {
  const items: NavBarItem[] = []
  Object.entries(tabs).forEach(([key, value]) => {
    items.push({
      label: value as string,
      url: key,
    })
  })
  return items
}
