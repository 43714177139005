import {
  formControlClasses,
  inputBaseClasses,
  selectClasses,
  styled,
} from '@mui/material'
import { Box, Container, TextField } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  width: '100%',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('form')({
  justifyContent: 'flex-start',
  alignItems: 'left',
  width: '100%',
  minHeight: '305px',
  marginBottom: '2rem',
  '> div': {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '1.5rem',
    rowGap: '2rem',
    '> div': {
      width: '290px',
    },
  },
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  minWidth: '280px',
  width: 'auto !important',
  maxWidth: '98% !important',
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledTextField = styled(TextField)({
  width: '100% !important',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '12px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '12px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
  },
})

export const RoleContent = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '1.5rem',
  rowGap: '2rem',
  '> div': {
    width: '290px',
  },
})
