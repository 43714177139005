import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Typography } from '@mui/material'
import {
  Box,
  createSimpleStepperItems,
  LoadingAnimation,
  Stepper,
  Title,
} from 'library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Hour, HourCreationModel } from 'models/hours'
import { GenericData } from 'models/services/base'
import { INITIAL_HOURS_CREATION_STEPS as steps } from 'utils/Hour'

import {
  GET_INSPECTION_HOURS,
  GET_TEST_DRIVE_HOURS,
} from 'graphQL/Setting/queries'

import { Layout } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const HoursCreation = () => {
  const { text } = useTranslation(textFiles.HOURS_CREATION)
  const { general: translation } = text

  const [currentStep, setCurrentStep] = useState<number>(0)

  const stepperItems = createSimpleStepperItems(translation.stepper)

  const [hoursData, setHoursData] = useState<HourCreationModel>({
    inspection: [],
    testDrive: [],
  })

  const { loading: inspectionHoursLoading, called: inspectionCalled } =
    useQuery<GenericData<Hour[]>>(GET_INSPECTION_HOURS, {
      onCompleted(response) {
        if (response.data) {
          setHoursData({
            ...hoursData,
            inspection: [...response.data].sort(
              (a, b) => a.continentalTime - b.continentalTime
            ),
          })
        }
      },
    })

  const { loading: testDriveHoursLoading, called: testDriveCalled } = useQuery<
    GenericData<Hour[]>
  >(GET_TEST_DRIVE_HOURS, {
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      if (response.data) {
        setHoursData({
          ...hoursData,
          testDrive: [...response.data].sort(
            (a, b) => a.continentalTime - b.continentalTime
          ),
        })
      }
    },
  })

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  return (
    <Layout maxWidth="1392px !important">
      <Title
        title={translation.title}
        icon={
          <PlaylistAddCheckIcon fontSize="medium" sx={{ color: colors.gray }} />
        }
        subtitle={`${
          hoursData.inspection.length + hoursData.testDrive.length
        } ${translation.description}`}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        sx={{ backgroundColor: colors.commonWhite }}
        marginTop="54px"
        padding="2rem 1rem"
      >
        {!testDriveCalled ||
        (!inspectionCalled && testDriveHoursLoading) ||
        inspectionHoursLoading ? (
          <LoadingAnimation
            showAnimation={testDriveHoursLoading || inspectionHoursLoading}
          />
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="100%"
          >
            <Typography variant="h3">{translation.welcome}</Typography>
            <Typography
              fontSize="18px"
              color={colors.subtitleGray}
              variant="body2"
            >
              {translation.sub_welcome}
            </Typography>
            <Stepper
              barWidth="380px"
              currentStep={currentStep}
              stepperItems={stepperItems}
            />

            {React.createElement(steps[currentStep], {
              handleContinue,
              handleBack,
              hoursData,
            })}
          </Box>
        )}
      </Box>
    </Layout>
  )
}

export default HoursCreation
