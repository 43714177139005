import {
  FilterEntryVariableType,
  FilteringOptionType,
  FiltersByCriteriaType,
} from 'library/constants/Listing/filters'
import { Filter } from 'library/models/filter'
import { FiltersByCriteriaCategoryType } from 'library/models/filtersByCriteria'
import { Option } from 'library/models/select'
import { ListingFilterType } from 'library/models/services/base'

/**
 * Function that takes the filters and returns a new object with all the
 * empty, undefined and null values removed
 */
export const cleanFilters = (filters: ListingFilterType) => {
  const object: ListingFilterType = Object.keys(filters).reduce(
    (previousValue, currentValue) => {
      const returnObject: ListingFilterType = { ...previousValue }
      if (filters[currentValue].length > 0) {
        returnObject[currentValue] = filters[currentValue]
      }
      return returnObject
    },
    {}
  )
  return object
}

export const onlySpacesText = (text: string) => {
  if (text) return text.trim().length === 0
  return true
}

export const formatFiltersTypes = (
  filtersByCriteria: FiltersByCriteriaType,
  types?: FiltersByCriteriaCategoryType
) => {
  const { fieldOrEntryOptions } = filtersByCriteria
  const fields: { [key: string]: FilterEntryVariableType } = {}

  if (types === undefined) {
    Object.entries(fieldOrEntryOptions).forEach(([key, value]) => {
      fields[key] = { name: value.name, type: value.type }
    })
  } else {
    Object.entries(fieldOrEntryOptions).forEach(([key, value]) => {
      if (types[key]) {
        fields[key] = { name: value.name, type: types[key] }
      } else {
        fields[key] = { name: value.name, type: 'string' }
      }
    })
  }
  const object = {
    ...filtersByCriteria,
    fieldOrEntryOptions: { ...fields },
  }
  return object as FiltersByCriteriaType
}

export const formatOptionsIntoFilteringOptions = (
  options: Option[]
): FilteringOptionType[] => {
  return options.map((option) => {
    return {
      ...option,
      value: String(option.value),
      disabled: false,
    }
  })
}

export const generateFilterInput = (filters: Filter[]) => {
  const filterFields = Object.values(filters)
  const ids = [] as string[]
  filterFields.forEach((item) => {
    const title = `${item.fieldOrEntry}_${item.operator}`
    if (ids.includes(title)) {
      return
    }
    ids.push(title)
  })
  const input = {} as ListingFilterType
  ids.forEach((id) => {
    const array = filterFields
      .filter((item) => `${item.fieldOrEntry}_${item.operator}` === id)
      .map((item) => item.valueToCompare)

    if (array.length > 0) {
      input[id] = array
    }
  })
  return input
}

export const checkSearchEmptiness = (input: string) => {
  return input.length === 0 || onlySpacesText(input) ? undefined : input
}
