import { Box, styled } from '@mui/material'
import { BREAK_POINTS } from 'library/styles/breakpoints'
import { boxShadow, colors } from 'library/styles/theme'

export const StyledBoxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  div: {
    marginBottom: '1.5rem',
    padding: '1rem',
    height: '95px',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export const InspectorStatsBox = styled(Box)({
  backgroundColor: colors.background,
  paddingLeft: '1.5rem',
  paddingBottom: '1rem',
  boxShadow,
  borderRadius: '8px',
})
