import { Option } from 'models/select'
import { NotificationStatus } from 'models/status'

export const statusOps = (text: Record<string, string>): Option[] => [
  {
    name: text.draft,
    value: NotificationStatus.DRAFT,
  },
  {
    name: text.schedule,
    value: NotificationStatus.SCHEDULED,
  },
  {
    name: text.complete,
    value: NotificationStatus.COMPLETED,
  },
]

export enum NOTIFICATION_CREATION_OPTION {
  UPDATE_NOTIFICATION_INFORMATION = 'updateNotificationInformation',
}
