import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PersonIcon from '@mui/icons-material/Person'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import {
  Box,
  ButtonNavigator,
  formatDateLocalizedTime,
  getIsoDate,
  LoadingAnimation,
  SummaryItem,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'

import { unknownTrimLevelId } from 'constants/car'
import { INVALID_INSPECTION_CAR } from 'constants/error'
import { INSPECTION_SUB_ROUTES } from 'constants/routes'
import { InspectionOriginEnum } from 'constants/service'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  CreateInspectionInputType,
  CreateInspectionWithCarInputType,
} from 'models/services/inspection/creation'
import { InspectionCreationProps } from 'utils/Inspection/creation'

import {
  CREATE_INSPECTION,
  CREATE_INSPECTION_WITH_EXISTING_CAR,
} from 'graphQL/Inspection/Creation/mutation'
import { GET_TEMPLATE_ID } from 'graphQL/Inspection/Creation/queries'

import { StyledContainer, StyledItem } from './style'

const InspectionSummary = ({
  handleBack,
  inspectionData,
}: InspectionCreationProps) => {
  const { show } = useNotification()
  const history = useHistory()
  const { text: translation } = useTranslation(textFiles.INSPECTION_CREATION)
  const [selectedLanguage] = useLocale()

  const [templateId, setTemplateId] = useState<string>('')

  const [createInspection, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateInspectionInputType>
  >(CREATE_INSPECTION, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.summary.inspectionSuccess,
      })
      history.push(INSPECTION_SUB_ROUTES.INSPECTION_LISTING)
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.summary.inspectionFail,
      })
    },
  })

  const [createInspectionWithExistingCar, { loading: submitExistingLoading }] =
    useMutation<
      GenericData<BaseIdEntity>,
      GenericInputVariable<CreateInspectionWithCarInputType>
    >(CREATE_INSPECTION_WITH_EXISTING_CAR, {
      onCompleted() {
        show({
          updatedSeverity: 'success',
          message: translation.summary.inspectionSuccess,
        })
        history.push(INSPECTION_SUB_ROUTES.INSPECTION_LISTING)
      },
      onError(error) {
        const { errorExists } = validateGraphQLErrorCode(
          error,
          INVALID_INSPECTION_CAR
        )
        if (errorExists) {
          show({
            updatedSeverity: 'error',
            message: translation.summary.invalidInspection,
          })
        } else
          show({
            updatedSeverity: 'error',
            message: translation.summary.inspectionFail,
          })
      },
    })

  const { loading: templateLoading } = useQuery<GenericData<BaseIdEntity>>(
    GET_TEMPLATE_ID,
    {
      onCompleted(response) {
        setTemplateId(response.data.id)
      },
    }
  )

  const { vehicleInformation, dealerInformation, schedulingInformation } =
    inspectionData

  const { address, appointmentDate, inspector, inspectionDay, meridiam } =
    schedulingInformation

  const inspectionTime = inspectionDay
    ? `${inspectionDay.name} ${meridiam}`
    : ''

  const car = vehicleInformation.length > 0 ? vehicleInformation[0] : null
  const handleSubmit = () => {
    if (car) {
      const year = car.vehicleYear ? Number(car.vehicleYear.id) : 0
      const carModel = car.vehicleModel ? String(car.vehicleModel.id) : ''
      const trimLevel =
        car.vehicleTrimLevel &&
        String(car.vehicleTrimLevel.id) !== unknownTrimLevelId
          ? String(car.vehicleTrimLevel.id)
          : undefined
      const exteriorColor = car.exteriorColor
        ? String(car.exteriorColor.id)
        : ''
      const interiorColor = car.interiorColor
        ? String(car.interiorColor.id)
        : ''
      const date = getIsoDate(schedulingInformation.appointmentDate) || ''
      const appointmentInspector = schedulingInformation.inspector
        ? String(schedulingInformation.inspector.id)
        : ''
      const curboSpotId = address?.originFromSpot ? address.id : undefined

      if (!car.isRegistered) {
        createInspection({
          variables: {
            input: {
              car: {
                year,
                carModel,
                trimLevel,
                dealer: dealerInformation.dealer.value,
                mileage: car.vehicleMileage,
                exteriorColor,
                interiorColor,
              },
              inspection: {
                date,
                template: templateId,
                inspector: appointmentInspector,
                origin: InspectionOriginEnum.DEALER,
                curboSpot: curboSpotId,
                hour: schedulingInformation.time,
                latitude: address?.lat,
                longitude: address?.lng,
                address: address?.address,
              },
            },
          },
        })
      } else {
        createInspectionWithExistingCar({
          variables: {
            input: {
              address: address?.address,
              car: car.vehicleId,
              date,
              dealer: dealerInformation.dealer.value,
              hour: schedulingInformation.time,
              inspector: appointmentInspector,
              latitude: address?.lat,
              longitude: address?.lng,
            },
          },
        })
      }
    }
  }

  return (
    <>
      <StyledContainer
        title={translation.summary.title}
        description={translation.summary.description}
      >
        <Box>
          {templateLoading ? (
            <LoadingAnimation showAnimation={templateLoading} />
          ) : (
            <>
              <StyledItem title={translation.summary.vehicleToInspect}>
                <ul>
                  {vehicleInformation.map((vehicle) => {
                    const {
                      id,
                      vehicleYear,
                      vehicleBrand,
                      vehicleModel,
                      vehicleTrimLevel,
                    } = vehicle
                    const trimLevel =
                      vehicleTrimLevel &&
                      vehicleTrimLevel.id !== unknownTrimLevelId
                        ? vehicleTrimLevel.name
                        : ''
                    const carName = `${vehicleYear?.name} ${vehicleBrand?.name} ${vehicleModel?.name} ${trimLevel}`

                    return <li key={id}>{carName}</li>
                  })}
                </ul>
              </StyledItem>
              <SummaryItem
                title={translation.summary.inspectionDate}
                text={formatDateLocalizedTime(
                  appointmentDate || new Date(),
                  'P',
                  selectedLanguage.code
                )}
                icon={<CalendarTodayIcon />}
              />
              <SummaryItem
                title={translation.summary.inspectionTime}
                text={inspectionTime}
                icon={<WatchLaterIcon />}
              />

              <SummaryItem
                title={translation.summary.dealerName}
                text={dealerInformation.dealer.name}
                icon={<AirportShuttleIcon />}
              />
              {inspector && (
                <SummaryItem
                  title={translation.summary.inspectorName}
                  text={`${inspector.name}`}
                  icon={<PersonIcon />}
                />
              )}

              <SummaryItem
                title={translation.summary.inspectionTime}
                text={inspectionTime}
                icon={<WatchLaterIcon />}
              />

              <SummaryItem
                title={translation.summary.dealerName}
                text={dealerInformation.dealer.name}
                icon={<AirportShuttleIcon />}
              />
              {inspector && (
                <SummaryItem
                  title={translation.summary.inspectorName}
                  text={`${inspector.name}`}
                  icon={<PersonIcon />}
                />
              )}
            </>
          )}
        </Box>
        <ButtonNavigator
          isLastStep
          nextFunction={handleSubmit}
          previousFunction={handleBack}
          loading={!car?.isRegistered ? submitLoading : submitExistingLoading}
        />
      </StyledContainer>
    </>
  )
}

export default InspectionSummary
