/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { DocumentNode } from '@apollo/client'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid'

import Tooltip from 'components/Common/Table/Tooltip'

import {
  BaseColumnField,
  BlackListColumnField,
} from 'constants/CarSettings/columnField'
import { commonColumnsText, commonListColumns } from 'constants/table'
import { Language } from 'models/language'
import { SelectItem } from 'models/select'
import { PublicationStatusMap } from 'models/status'
import {
  blackListMakeDefaultFields,
  blackListModelDefaultFields,
  blackListTrimDefaultFields,
  createBlackListFieldSelectLabels,
  createBlackListModelFieldSelectItems,
  createBlackListTrimFieldSelectItems,
  createDefaultBlackListFieldSelectItems,
} from 'utils/Operation/BlackList/listing'

import {
  GET_BLACK_LIST_BRAND_BY_ID,
  GET_BLACK_LIST_MODEL_BY_ID,
  GET_BLACK_LIST_TRIM_LEVEL_BY_ID,
} from 'graphQL/Operations/BlackList/Detail/queries'
import {
  UPDATE_BLACK_LIST_BRAND,
  UPDATE_BLACK_LIST_MODEL,
  UPDATE_BLACK_LIST_TRIM_LEVEL,
} from 'graphQL/Operations/BlackList/Listing/mutations'
import {
  LIST_BLACK_LISTED_BRANDS,
  LIST_BLACK_LISTED_MODELS,
  LIST_BLACK_LISTED_TRIM_LEVELS,
} from 'graphQL/Operations/BlackList/Listing/queries'

export type BlackListEntityKey = 'make' | 'model' | 'trim'

export enum BLACK_LIST_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

type BaseBlackListFeature = {
  query: DocumentNode
  mutation: DocumentNode
}

export type BlackListFeatures = {
  selectFields: string[]
  createFieldSelectItems: (text: any, isSupervisor: boolean) => SelectItem[]
  createFieldSelectLabels: (text: any) => Record<string, string>
} & BaseBlackListFeature

export const blackListFields: Record<BlackListEntityKey, BlackListFeatures> = {
  make: {
    selectFields: blackListMakeDefaultFields,
    createFieldSelectItems: createDefaultBlackListFieldSelectItems,
    createFieldSelectLabels: createBlackListFieldSelectLabels,
    query: LIST_BLACK_LISTED_BRANDS,
    mutation: UPDATE_BLACK_LIST_BRAND,
  },
  model: {
    selectFields: blackListModelDefaultFields,
    createFieldSelectItems: createBlackListModelFieldSelectItems,
    createFieldSelectLabels: createBlackListFieldSelectLabels,
    query: LIST_BLACK_LISTED_MODELS,
    mutation: UPDATE_BLACK_LIST_MODEL,
  },
  trim: {
    selectFields: blackListTrimDefaultFields,
    createFieldSelectItems: createBlackListTrimFieldSelectItems,
    createFieldSelectLabels: createBlackListFieldSelectLabels,
    query: LIST_BLACK_LISTED_TRIM_LEVELS,
    mutation: UPDATE_BLACK_LIST_TRIM_LEVEL,
  },
}

export const createBlackListColumns = (
  tab: BlackListEntityKey,
  selectedLanguage: Language,
  text: commonColumnsText,
  tableText: any,
  selectedFields: string[],
  handleEdit?: (id: GridCellValue) => void,
  handleDelete?: (id: GridRowId) => void
): GridColDef[] => {
  const {
    id: idColumn,
    isoDate: dateColumn,
    standard: standardColumn,
    actions: actionsColumn,
  } = commonListColumns(
    PublicationStatusMap,
    selectedLanguage,
    text,
    handleEdit,
    handleDelete
  )

  const commentColumn: GridColDef = {
    ...standardColumn,
    field: BlackListColumnField.COMMENT,
    headerName: tableText.comment,
    sortable: false,
    flex: 2,
    hide: !selectedFields.includes(BlackListColumnField.COMMENT),
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip>{params.row.comment || text.table.noComment}</Tooltip>
    ),
  }

  const entityTypeColumn: GridColDef = {
    ...standardColumn,
    field: BlackListColumnField.ENTITY_TYPE,
    headerName: tableText.entityType,
    sortable: false,
    hide: !selectedFields.includes(BlackListColumnField.ENTITY_TYPE),

    renderCell: () => text.table[tab],
  }

  const newDateColumn = {
    ...dateColumn,
    field: BlackListColumnField.BLACK_LISTED_AT,
    headerName: tableText.blackListedAt,
    hide: !selectedFields.includes(BlackListColumnField.BLACK_LISTED_AT),

    flex: 1,
  }

  const nameColumn = {
    ...standardColumn,
    field: BaseColumnField.NAME,
    headerName: tableText.name,
    hide: !selectedFields.includes(BaseColumnField.NAME),

    renderCell: (params: GridRenderCellParams) => (
      <Tooltip>{params.row.name}</Tooltip>
    ),
  }

  const canPerformAction = handleEdit || handleDelete

  const columns: Record<BlackListEntityKey, GridColDef[]> = {
    make: [
      { ...idColumn, flex: 1, hide: !selectedFields.includes(idColumn.field) },
      entityTypeColumn,
      nameColumn,
      newDateColumn,
      commentColumn,
      ...(canPerformAction
        ? [
            {
              ...actionsColumn,
              hide: !selectedFields.includes(actionsColumn.field),
            },
          ]
        : []),
    ],
    model: [
      { ...idColumn, flex: 1, hide: !selectedFields.includes(idColumn.field) },
      entityTypeColumn,
      nameColumn,
      {
        ...standardColumn,
        field: BlackListColumnField.BRAND_NAME,
        headerName: tableText.brandName,
        hide: !selectedFields.includes(BlackListColumnField.BRAND_NAME),
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const { brand } = params.row
          return <Tooltip>{brand ? brand.name : ''}</Tooltip>
        },
      },
      newDateColumn,
      commentColumn,
      ...(canPerformAction
        ? [
            {
              ...actionsColumn,
              hide: !selectedFields.includes(actionsColumn.field),
            },
          ]
        : []),
    ],
    trim: [
      {
        ...idColumn,
        sortable: false,
        flex: 1,
        hide: !selectedFields.includes(idColumn.field),
      },
      entityTypeColumn,
      nameColumn,
      {
        ...standardColumn,
        field: BlackListColumnField.CAR_NAME,
        headerName: tableText.carName,
        sortable: false,
        flex: 2,
        hide: !selectedFields.includes(BlackListColumnField.CAR_NAME),
        renderCell: (params: GridRenderCellParams) => {
          const { model } = params.row
          const { year } = params.row
          return (
            <Tooltip>
              {model ? `${model.brand.name} ${model.name} ${year}` : ''}
            </Tooltip>
          )
        },
      },
      newDateColumn,
      commentColumn,
      ...(canPerformAction
        ? [
            {
              ...actionsColumn,
              hide: !selectedFields.includes(actionsColumn.field),
            },
          ]
        : []),
    ],
  }

  return columns[tab]
}

export const blackListDetailObject: Record<
  BlackListEntityKey,
  BaseBlackListFeature
> = {
  make: {
    query: GET_BLACK_LIST_BRAND_BY_ID,
    mutation: UPDATE_BLACK_LIST_BRAND,
  },
  model: {
    query: GET_BLACK_LIST_MODEL_BY_ID,
    mutation: UPDATE_BLACK_LIST_MODEL,
  },
  trim: {
    query: GET_BLACK_LIST_TRIM_LEVEL_BY_ID,
    mutation: UPDATE_BLACK_LIST_TRIM_LEVEL,
  },
}
