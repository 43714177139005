import { CityColumnField } from 'constants/city'
import { SelectItem } from 'models/select'
import { FieldSelectTextType } from 'models/text/CityListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: CityColumnField.ID,
    },
    {
      label: text.name,
      value: CityColumnField.NAME,
    },
    {
      label: text.state,
      value: CityColumnField.STATE,
    },
    {
      label: text.createdAt,
      value: CityColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: CityColumnField.STATUS,
    },
    {
      label: text.actions,
      value: CityColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
    state: text.state,
  }
}

export const defaultFields = [
  CityColumnField.ID,
  CityColumnField.NAME,
  CityColumnField.STATE,
  CityColumnField.CREATED_AT,
  CityColumnField.STATUS,
  CityColumnField.ACTIONS,
]
