import { gql } from '@apollo/client'

export const GET_OFFER_BY_ID = gql`
  query getOfferById($input: ID!) {
    data: getOfferById(id: $input) {
      backgroundColor
      brands {
        id
        name
        slug
        status
        createdAt
        icon
      }
      cars {
        certified
        id
        listingPrice
        mainPicture
        slug
        title
        year
      }
      createdAt
      description
      discount
      discountType
      from
      id
      label
      labelColor
      logo
      maxPrice
      maxYear
      minPrice
      minYear
      models {
        id
        name
        slug
        status
        createdAt
      }
      pictures
      priority
      status {
        backgroundColor
        description
        id
        name
        slug
        textColor
      }
      textColor
      to
      type
    }
  }
`

export const GET_CARS_BY_OFFER = gql`
  query getListingCarsByOffer($input: FilterListingCarsWithOfferInput!) {
    getListingCarsByOffer(input: $input) {
      data: cars {
        id
        slug
        mainPicture
        brand {
          name
        }
        carModel {
          name
        }
        year
        priceInfo {
          basePrice
        }
        status {
          backgroundColor
          description
          id
          name
          slug
          textColor
        }
      }
      count
      offer {
        discount
        type
      }
    }
  }
`

export const GET_OFFER_STATUSES = gql`
  query getOfferStatuses {
    data: getOfferStatuses {
      backgroundColor
      description
      id
      name
      slug
      textColor
    }
  }
`
