import { Box, styled, Typography, typographyClasses } from '@mui/material'
import { Container } from 'library/components/Common/Container'
import { StyledFiltersBackground } from 'library/components/Listing/FilterByCriteria/style'
import { boxShadow } from 'library/styles/theme'

export const StyledRowBox = styled(Box)({
  marginLeft: '2rem',
  marginBottom: '0.5rem',
})

export const RowTitle = styled(Typography)({
  display: 'inline',
  fontSize: '14px',
})

export const FiltersBackground = styled(StyledFiltersBackground)({
  padding: '0px',
  [`&.${typographyClasses.root}`]: { width: 'auto' },
  div: { width: 'auto', h6: { marginRight: '0px' } },
  margin: 0,
})

export const StyledReportContainer = styled(Container)({
  marginBottom: '15px',
  width: '576px',
  borderRadius: '10px',
  paddingTop: '35px !important',
  paddingLeft: '38px !important',
  boxShadow,
  [`&.${typographyClasses.root}`]: { width: '493px' },
  maxHeight: '735px',
  overflowY: 'auto',
  paddingBottom: '0px !important',
})
