import { gql } from '@apollo/client'

export const LIST_LEADS = gql`
  query listLeads($input: FilterInput!) {
    listLeads(input: $input) {
      count
      data {
        id
        car {
          id
          name: title
          slug
        }
        caseId
        createdAt
        customer {
          name
          lastName
          telephoneNumber
        }
        customerEmail
        leadStep {
          id
          name
          slug
          type
          backgroundColor
          textColor
        }
        estimatedValue
        origin
        type
        customerCareRepresentative {
          value: id
          name
          lastName
        }
      }
    }
  }
`

export const GET_LEAD_STEPS = gql`
  query getLeadSteps($input: FilterInput!) {
    data: getLeadSteps(input: $input) {
      id
      name
      slug
      backgroundColor
      textColor
      description
    }
  }
`

export const GET_BDS = gql`
  query getBds($input: FilterInput) {
    getBds(input: $input) {
      data {
        value: id
        name
        lastName
      }
    }
  }
`

export const GET_CASE_TYPE_ENUMS = gql`
  query getCaseTypeEnums {
    data: getCaseTypeEnums
  }
`

export const GET_CASE_ORIGIN_ENUMS = gql`
  query getCaseOriginEnums {
    data: getCaseOriginEnums
  }
`
