import { OfferDetailColumnField } from 'constants/offer'
import { SelectItem } from 'models/select'
import { Offer, OfferCar } from 'models/services/offer/detail'

export const createFieldSelectItems = (
  text: Record<string, string>
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: OfferDetailColumnField.ID,
    },
    {
      label: text.slug,
      value: OfferDetailColumnField.SLUG,
    },
    {
      label: text.picture,
      value: OfferDetailColumnField.PICTURE,
    },
    {
      label: text.vehicle,
      value: OfferDetailColumnField.VEHICLE,
    },
    {
      label: text.price,
      value: OfferDetailColumnField.PRICE,
    },
    {
      label: text.discount,
      value: OfferDetailColumnField.DISCOUNT,
    },
    {
      label: text.netPrice,
      value: OfferDetailColumnField.NET_PRICE,
    },
    {
      label: text.status,
      value: OfferDetailColumnField.STATUS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: Record<string, string>
): Record<string, string> => {
  return {
    id: text.id,
    picture: text.picture,
    slug: text.vehicle,
    trim: text.trim,
    price: text.price,
    discount: text.discount,
    netPrice: text.netPrice,
    status: text.status,
  }
}

export const defaultFields = [
  OfferDetailColumnField.PICTURE,
  OfferDetailColumnField.VEHICLE,
  OfferDetailColumnField.PRICE,
  OfferDetailColumnField.DISCOUNT,
  OfferDetailColumnField.NET_PRICE,
  OfferDetailColumnField.STATUS,
]

export const formatOfferCars = (cars: OfferCar[], offer: Offer) =>
  cars.map((car) => ({
    id: car.id,
    carSlug: car.slug,
    picture: car.mainPicture,
    slug: `${car.brand.name} ${car.carModel.name} ${car.year}`,
    price: car.priceInfo.basePrice,
    discount: offer.discount,
    netPrice: `${car.priceInfo.basePrice - offer.discount}`,
    status: car.status.slug.toLowerCase(),
  }))
