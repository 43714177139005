import gql from 'graphql-tag'

export const GET_ANSWERS = gql`
  query getAnswers {
    data: getAnswers {
      id
      name: label
    }
  }
`
export const GET_INSPECTION_FAILURES_REASONS = gql`
  query getInspectionFailureReasons {
    data: getInspectionFailureReasons {
      value: id
      name
    }
  }
`

export const GET_ALL_PROVIDERS = gql`
  query getAllProviders {
    data: getAllProviders {
      value: slug
      name
    }
  }
`
