import React, { useEffect, useState } from 'react'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import {
  Avatar,
  Box,
  Container,
  ErrorMessage,
  maxAllowedSizePerFileInKb,
} from 'library'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import { StyledContainer } from 'components/CarSettings/Transmission/Detail/Information/style'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { TranslationDetailDataType } from 'models/services/CarSettings/detail'
import { getInitialNameTranslation } from 'utils/translation'

import placeholderImage from 'images/blueDefaultAvatar.png'

import { colors } from 'styles/theme'

import { StyledButton } from './style'

type Props = {
  information: TranslationDetailDataType
  handleSave: (
    name: string | undefined,
    fileState: File | null,
    hasChangedPicture: boolean,
    translations: TranslationItemType[]
  ) => Promise<boolean>
  submitLoading: boolean
}

const Information = ({ information, handleSave, submitLoading }: Props) => {
  const { text } = useTranslation(textFiles.BODY_STYLE_DETAIL)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const { general, bodyStyleInformation: translation } = text

  const { icon, name, nameTranslations } = information

  const [picture, setPicture] = useState(icon)
  const [fileState, setFileState] = useState<File | null>(null)
  const [pictureSizeErrorStatus, setPictureSizeErrorStatus] =
    useState<boolean>(false)

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [hasPictureChanges, setHasPictureChanges] = useState<boolean>(false)

  const initialNameInformation = [
    {
      id: '1',
      icon: <DirectionsCarIcon />,
      name,
      label: general.title,
    },
  ]

  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >([])

  const [informationRows, setInformationRows] = useState<NameInformation[]>(
    initialNameInformation
  )

  const resetState = () => {
    setPicture(icon)
    setAddedTranslations(getInitialNameTranslation(nameTranslations))
    setInformationRows(initialNameInformation)
    setHasUnsavedChanges(false)
    setHasPictureChanges(false)
  }

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setInformationRows(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleChangeTranslation = (translations: TranslationItemType[]) => {
    setAddedTranslations(translations)
    setHasUnsavedChanges(true)
  }

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const { files } = e.target
    const file = files && files.length > 0 ? files[0] : null

    if (file && file.size <= maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(false)
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPicture(String(reader.result))
        }
      }
      if (file) {
        setFileState(file)
        reader.readAsDataURL(file)
      }
      setHasUnsavedChanges(true)
      setHasPictureChanges(true)
    } else if (file && file.size > maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(true)
    }
  }

  const handleSaveChanges = async () => {
    const newName =
      informationRows.length > 0 ? informationRows[0].name : undefined

    const isUpdateSuccesful = await handleSave(
      newName === name ? undefined : String(newName),
      fileState,
      hasPictureChanges,
      addedTranslations
    )
    if (isUpdateSuccesful) {
      setHasPictureChanges(false)
      setHasUnsavedChanges(false)
    }
  }

  useEffect(() => {
    setAddedTranslations(getInitialNameTranslation(nameTranslations))
  }, [nameTranslations])

  return (
    <StyledContainer>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        sx={{
          '> div': {
            padding: '15px 20px',
          },
        }}
      >
        <NameContainer
          title={translation.information}
          description={translation.informationDescription}
          information={informationRows}
          handleSetInformation={handleChangeInformation}
          loading={submitLoading}
          width={399}
        />
        <Translation
          addedTranslations={addedTranslations}
          handleChangeTranslation={handleChangeTranslation}
          translationDescription={translation.translationsDescription}
        />
        <Container title={translation.picture}>
          <Box sx={{ position: 'relative' }}>
            <Avatar
              image={picture || placeholderImage}
              style={{
                border: `1px solid ${colors.border}`,
                borderRadius: '6px',
              }}
              height="104px"
              width="121px"
            />
            <input
              disabled={submitLoading}
              type="file"
              name="input"
              id="category-picture-input"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={imageHandler}
            />
            <StyledButton htmlFor="category-picture-input">
              <CameraAltIcon />
            </StyledButton>
          </Box>
          {pictureSizeErrorStatus && (
            <ErrorMessage
              text={generalText.fileSize.sizeError.replace('%d', 3)}
              sx={{ marginTop: '5px' }}
            />
          )}
        </Container>
        <SaveChangesBlock
          handleSaveChanges={handleSaveChanges}
          resetState={resetState}
          submitLoading={submitLoading}
          shouldRender={hasUnsavedChanges}
        />
      </Box>
      {/* <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <LogContainer>
          <HistoryLog
            height="200px"
            dateFormat="time"
            hasPadding={!!(width && width <= BREAK_POINTS.XL)}
          />
        </LogContainer>
      </Box> */}
    </StyledContainer>
  )
}

export default Information
