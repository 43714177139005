import React, { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import PersonIcon from '@mui/icons-material/Person'
import { Typography } from '@mui/material'
import { Button, Modal } from 'library'

import ResetPasswordSetup from 'components/Auth/ResetPassword'

import { cypressCancelButton, cypressSaveButton } from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors } from 'styles/theme'

import { BottomContainer } from './style'

type ResetPasswordModalProps = {
  openModal: boolean
  setOpenModal: (value: boolean) => void
}

const ResetPasswordModal = ({
  openModal,
  setOpenModal,
}: ResetPasswordModalProps) => {
  const { text } = useTranslation(textFiles.ACCOUNT)
  const {
    settings: { securitySettings: translation },
  } = text
  const [loading, setLoading] = useState<boolean>(false)
  return (
    <Modal
      open={openModal}
      handleClose={() => setOpenModal(false)}
      size="customDimension"
      customDimension={{ height: 350, width: 430 }}
    >
      <ResetPasswordSetup
        setLoading={setLoading}
        setOpenModal={() => setOpenModal(false)}
        upperSection={
          <>
            <PersonIcon
              style={{
                width: '45px',
                height: '36px',
                color: colors.orange,
              }}
            />
            <Typography variant="body2" style={{ color: colors.black }}>
              {translation.modalTitle}
            </Typography>
          </>
        }
        bottomSection={
          <BottomContainer>
            <Button
              size="small"
              style={{ width: 'auto', minWidth: 159, fontWeight: 'bold' }}
              type="submit"
              disabled={loading}
              startIcon={<CheckIcon />}
              data-cy={cypressSaveButton}
            >
              {translation.updatePasswordButton}
            </Button>
            <Button
              size="small"
              style={{ width: 122, fontWeight: 'bold' }}
              buttonType="info"
              disabled={loading}
              onClick={() => setOpenModal(false)}
              data-cy={cypressCancelButton}
            >
              {translation.cancelButton}
            </Button>
          </BottomContainer>
        }
      />
    </Modal>
  )
}

export default ResetPasswordModal
