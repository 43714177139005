import React from 'react'
import { Typography } from '@mui/material'
import { Avatar } from 'library/components/Common'
import { TimeSensitivePopper } from 'library/components/General'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

import { StyledContainer, StyledSection } from './style'

export type OutstandingInspectorTextModel = {
  title: string
  inspections: string
  uploadings: string
  temporalTitle: string
}

export type OutstandingInspectorProps = {
  img?: string
  translation?: OutstandingInspectorTextModel
}

export const OutstandingInspector = ({
  img,
  translation,
}: OutstandingInspectorProps) => {
  return (
    <StyledContainer
      extraText={<TimeSensitivePopper />}
      title={translation?.title || 'Outstanding Inspector'}
    >
      <Avatar image={img} height="120px" width="120px" variant="rounded" />
      <StyledSection component="section">
        <Typography
          variant="subtitle1"
          style={{ color: colors.black, fontWeight: FONT_WEIGHT.SEMI_BOLD }}
        >
          {translation
            ? translation.temporalTitle
            : 'Not enough data to display'}
        </Typography>
      </StyledSection>
    </StyledContainer>
  )
}
