import React from 'react'
import { SelectChangeEvent, Typography } from '@mui/material'
import { Box, Select } from 'library/components/Common'
import { Option } from 'library/models/select'

export type TimePickerTextModel = {
  timeTitle: string
  timeSubtitle: string
  noHours: string
}

export type TimePickerProps = {
  translation?: TimePickerTextModel
  hourOptions: Option[]
  meridiamOptions: Option[]
  // Necessary for it to run, but should cast the event target value when being used
  hourChange: (event: SelectChangeEvent<unknown>) => void
  hour: unknown
  meridiamChange: (event: SelectChangeEvent<unknown>) => void
  meridiam: unknown
  disabled?: boolean
}

export const TimePicker = ({
  translation,
  hourOptions,
  meridiamOptions,
  hourChange,
  hour,
  meridiam,
  meridiamChange,
  disabled,
}: TimePickerProps) => {
  const labelTranslation = translation?.timeTitle || ''
  return (
    <Box marginBottom="1em">
      <Typography variant="body1">
        {translation?.timeTitle || 'Time scheduling'}
      </Typography>
      <Typography variant="subtitle1" marginBottom="1em">
        {translation?.timeSubtitle || 'There are the available times'}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          '& > div:first-of-type': {
            flexGrow: '2',
            width: '65%',
            marginRight: '5%',
          },
          '& > div:nth-of-type(2)': {
            width: '30%',
          },
        }}
      >
        <Select
          options={hourOptions}
          noOptionsLabel={translation?.noHours || undefined}
          fullWidth
          value={hour}
          onChange={hourChange}
          sx={{ fontSize: 12 }}
          label={hourOptions.length ? labelTranslation : undefined}
          disabled={disabled}
          testId="hour-select"
        />
        <Select
          options={meridiamOptions}
          value={meridiam}
          onChange={meridiamChange}
          sx={{ fontSize: 12 }}
          disabled={disabled}
          testId="meridiam-select"
        />
      </Box>
    </Box>
  )
}
