import React, { useState } from 'react'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { Typography } from '@mui/material'
import {
  Avatar,
  Box,
  Calendar,
  Container,
  formatDateLocalizedTime,
} from 'library'

import { StyledButton } from 'components/CarSettings/Category/Detail/Information/style'
import EditButtons from 'components/CarSettings/Common/Detail/NameContainer/Items/EditButtons'
import TextArea from 'components/Template/Detail/TextArea'

import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { NotificationDetailType } from 'models/services/notification/detail'

import placeholderImage from 'images/blueDefaultAvatar.png'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

import NotificationCard from '../NotificationCard'

import {
  StyledBodyContainer,
  StyledButtonContainer,
  StyledContainer,
  StyledDateContainer,
  StyledNotificationsContainer,
  StyledPictureContainer,
} from './styles'

type NotificationDataProps = {
  notificationData: NotificationDetailType
  handleSetNotificationData: (data: NotificationDetailType) => void
}

const NotificationData = ({
  notificationData,
  handleSetNotificationData,
}: NotificationDataProps) => {
  const [info, setInfo] = useState<NotificationDetailType>(notificationData)
  const [editMode, setEditMode] = useState<boolean>(false)

  const { text } = useTranslation(textFiles.NOTIFICATION_DETAIL)
  const {
    title,
    subtitle,
    notificationTitle,
    notificationText,
    notificationName,
    notificationPreview,
    notificationDate,
  } = text.notificationData

  const [selectedLanguage] = useLocale()

  /**
   * Returns selected date as an array of Day, Year, Hour,
   * so that we then just render the Day and Hour as requested by design
   */
  const formattedDate = formatDateLocalizedTime(
    notificationData.date as Date,
    'PPpp',
    selectedLanguage.code
  ).split(',')

  const handleCancelEdit = () => {
    setEditMode(false)
    setInfo(notificationData)
  }

  const handleEdit = () => {
    if (!editMode) {
      setEditMode(true)
    } else {
      handleSetNotificationData(info)
      setEditMode(false)
    }
  }

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const { files } = e.target
    const file = files && files.length > 0 ? files[0] : null
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInfo((prevInfo) => {
          return { ...prevInfo, picture: String(reader.result) }
        })
      }
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      <StyledButtonContainer display="flex">
        <EditButtons
          edit={editMode}
          handleEdit={handleEdit}
          handleCancelEdit={handleCancelEdit}
        />
      </StyledButtonContainer>
      <Container
        title={title}
        description={subtitle}
        contentStyle={{ marginTop: '54px' }}
        titleStyle={{
          [`p:nth-of-type(1)`]: {
            fontSize: '20px !important',
          },
        }}
        descriptionStyle={{
          p: {
            color: colors.commonBlack,
          },
        }}
      >
        <StyledBodyContainer>
          <StyledContainer title={notificationTitle}>
            <TextArea
              editMode={editMode}
              rows={3}
              variant="NORMAL"
              initialValue={notificationData.title}
              updateText={(newText) =>
                setInfo((prevInfo) => {
                  return { ...prevInfo, title: newText }
                })
              }
              maxCharactersAllowed={100}
              additionalTextAreaStyles={{
                color: colors.gray,
              }}
            />
          </StyledContainer>
          <StyledContainer
            title={notificationText}
            sx={{
              [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
                width: '250px',
              },
            }}
          >
            <TextArea
              editMode={editMode}
              rows={3}
              variant="NORMAL"
              initialValue={notificationData.description}
              updateText={(newText) =>
                setInfo((prevInfo) => {
                  return { ...prevInfo, description: newText }
                })
              }
              maxCharactersAllowed={125}
              additionalTextAreaStyles={{
                color: colors.gray,
              }}
            />
          </StyledContainer>
          <StyledContainer title={notificationName}>
            <TextArea
              editMode={editMode}
              rows={3}
              variant="NORMAL"
              initialValue={notificationData.name}
              updateText={(newText) =>
                setInfo((prevInfo) => {
                  return { ...prevInfo, name: newText }
                })
              }
              maxCharactersAllowed={100}
              additionalTextAreaStyles={{
                color: colors.gray,
              }}
            />
          </StyledContainer>
          <StyledPictureContainer>
            <Box sx={{ position: 'relative' }}>
              <Avatar
                image={info.picture || placeholderImage}
                style={{
                  border: `1px solid ${colors.border}`,
                  borderRadius: '6px',
                  height: '104px',
                  width: '121px',
                }}
              />
              <input
                type="file"
                name="input"
                id="notification-picture-input"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={imageHandler}
              />
              <StyledButton
                htmlFor="notification-picture-input"
                sx={{ display: editMode ? 'flex' : 'none', border: 'none' }}
              >
                <CameraAltIcon />
              </StyledButton>
            </Box>
          </StyledPictureContainer>

          <StyledNotificationsContainer
            title={notificationPreview}
            contentStyle={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <NotificationCard
              title={info.title}
              description={info.description}
            />
            <NotificationCard
              title={info.title}
              description={info.description}
              isAndroid
              picture={info.picture || placeholderImage}
            />
          </StyledNotificationsContainer>

          <StyledDateContainer
            title={notificationDate}
            contentStyle={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {editMode ? (
              <Calendar
                date={info.date}
                handleCalendarDate={(newDate: Date | null) => {
                  if (newDate) {
                    setInfo((prevInfo) => {
                      return { ...prevInfo, date: newDate }
                    })
                  }
                }}
              />
            ) : (
              <Typography variant="body2">{`${formattedDate[0]}, ${formattedDate[2]}`}</Typography>
            )}
          </StyledDateContainer>
        </StyledBodyContainer>
      </Container>
    </>
  )
}

export default NotificationData
