import {
  CarIdPopperStatus,
  DiscountTypes,
  OfferTypes,
  PriorityTypes,
} from 'models/offer'
import { Option } from 'models/select'
import { OfferCreationModel } from 'models/services/marketing/offerCreation'

import { colors } from 'styles/theme'

export enum OFFER_CREATION_OPTION {
  UPDATE_OFFER_DATA = 'updateOfferData',
}

export const initialOfferData: OfferCreationModel = {
  offerInformation: {
    backgroundColor: '',
    description: '',
    id: '',
    brands: [],
    cars: [],
    carIds: [],
    discountType: DiscountTypes.GENERAL,
    isActive: false,
    label: '',
    discount: 0,
    labelColor: '',
    models: [],
    priority: PriorityTypes.SECONDARY,
    textColor: '',
    type: OfferTypes.CAR,
    picture: '',
    carDiscounts: [],
  },
}

export const colorOptions = {
  [CarIdPopperStatus.SUCCESS]: colors.orange,
  [CarIdPopperStatus.DUPLICATE]: colors.yellow,
  [CarIdPopperStatus.ERROR]: colors.lightRed,
}

export const getYearOptions = (min: number, max: number) => {
  const newYearOptions: Option[] = []

  for (let i = min; i <= max; i += 1) {
    newYearOptions.push({
      name: String(i),
      value: i,
    })
  }

  return newYearOptions
}

export const initialYears = {
  maxYear: 0,
  minYear: 0,
}
