import axios from 'axios'

import { ACCESS_TOKEN_KEY } from 'constants/localStorage'
import { ExtendedFilter } from 'models/listingReport'

export enum REPORT_TYPE_ENUM {
  CSV = 'csv',
  PDF = 'pdf',
  XLSX = 'xlsx',
}

export async function getListingReport(
  reportType: REPORT_TYPE_ENUM,
  reportName: string,
  filters: ExtendedFilter[],
  url: string
) {
  const formattedFilters: Record<string, string | number | string[]> = {}

  filters.forEach((filter) => {
    const prefix = `${filter.fieldOrEntry}_${filter.operator}`

    formattedFilters[prefix] = filter.valueToCompare
  })

  let token = ''
  if (typeof window !== undefined) {
    token =
      window.localStorage.getItem(ACCESS_TOKEN_KEY)?.replace(/"/g, '') || ''
  }

  return axios({
    method: 'POST',
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      filterInput: {
        where:
          filters.length > 0
            ? {
                ...formattedFilters,
              }
            : undefined,
      },
      options: {
        reportType,
        reportName,
      },
    },
  })
}
