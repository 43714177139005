import React from 'react'
import Lottie from 'react-lottie-player'
import { Box } from 'library/components/Common/Box'

import animationData from 'animations/lotties/curboLogoLoading.json'

export type LoadingAnimationProps = {
  /**
   * Function that triggeers when animation finishes playing, NOTE: if `loop` is true then the animation
   * will never stop so this function won't fire unless you stop it
   */
  handleAnimationFinish?: () => void
  /**
   * Height of the animation
   */
  height?: number | string
  /**
   *  If animation should play infinitely or not
   */
  loop?: boolean
  /**
   * Whether the animation should be displayed or not
   */
  showAnimation?: boolean
  /**
   * How fast the animation will play
   */
  speed?: number
  /**
   * Width of the animation
   */
  width?: number | string

  /**
   * Additional styles to the box
   */
  styles?: React.CSSProperties
}

/**
 * A component to be used as a loading animation for most of our tools
 */
export const LoadingAnimation = ({
  handleAnimationFinish,
  showAnimation,
  height = 200,
  loop = true,
  speed = 1,
  width = 200,
  styles,
}: LoadingAnimationProps) => {
  return showAnimation ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      sx={styles}
    >
      <Lottie
        animationData={animationData}
        speed={speed}
        play
        loop={loop}
        onComplete={handleAnimationFinish}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        style={{
          height,
          width,
        }}
      />
    </Box>
  ) : null
}
