import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DetailNavTab, generateTabItems, NavBarItem, TabPanel } from 'library'

import { DetailHeader } from 'components/CarSettings/Common/Detail/DetailHeader'
import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import NotificationData from 'components/Marketing/Notification/Detail/NotificationData'

import { defaultNotificationData } from 'constants/Notification/detail'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NotificationDetailType } from 'models/services/notification/detail'

import { ContentContainer, Layout } from 'styles/inspection/detail'

const NotificationDetailPage = () => {
  const [notificationData, setNotificationData] =
    useState<NotificationDetailType>(defaultNotificationData)
  const [tab, setTab] = useState<number>(0)
  const { text: translation } = useTranslation(textFiles.NOTIFICATION_DETAIL)
  const history = useHistory()

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleSetNotificationData = (data: NotificationDetailType) => {
    setNotificationData(data)
  }

  return (
    <Layout>
      <DetailHeader
        title={`${translation.title} ${notificationData.name}`}
        backButtonText={translation.backButton}
        editable={false}
      />
      <DetailSubHeader text={translation.description} />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={initialItems}
      />

      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <NotificationData
            notificationData={notificationData}
            handleSetNotificationData={handleSetNotificationData}
          />
        </TabPanel>
      </ContentContainer>
    </Layout>
  )
}

export default NotificationDetailPage
