import { Box, styled, Typography } from '@mui/material'
import { BREAK_POINTS } from 'library/styles/breakpoints'
import { boxShadow, colors, FONT_WEIGHT } from 'library/styles/theme'

export const StyledBoxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  div: {
    marginBottom: '1.5rem',
    padding: '1rem',
    height: '95px',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export const InspectorStatsBox = styled(Box)({
  backgroundColor: colors.background,
  paddingLeft: '1.5rem',
  paddingBottom: '1rem',
  boxShadow,
  borderRadius: '8px',
})

export const IconContainer = styled(Box)({
  position: 'absolute',
  marginLeft: '5.6rem',
  marginTop: '-3.8rem',
})

export const InspectionsChangeText = styled(Typography)({
  fontWeight: FONT_WEIGHT.BOLD,
})

/**
 ** This box is different for the summary on the first row last column of the Dashboard
 */
export const SummaryBox = styled(Box)({
  backgroundColor: colors.background,
  paddingLeft: '1.5rem',
  paddingBottom: '1rem',
  paddingTop: '0.8rem',
  marginBottom: '0.5rem',
  boxShadow,
  borderRadius: '8px',
  height: '30px',
  width: '155px',
  display: 'flex',
})

/**
 ** Typography for the Numbers of the summary container.
 */
export const SummaryNumber = styled(Typography)({
  fontWeight: FONT_WEIGHT.BOLD,
  color: colors.orange,
  marginTop: '-0.1rem',
  marginRight: '1rem',
})

/**
 ** Typography for the Titles/labels of the summary container.
 */
export const SummaryLabel = styled(Typography)({
  width: '100px',
  color: colors.black,
  lineHeight: '15px',
})
