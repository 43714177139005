import { Container, styled } from '@mui/material'

import { colors } from 'styles/theme'

export const BottomContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
  background: colors.background,
  height: 90,
  paddingTop: 30,
  paddingBottom: 30,
})
