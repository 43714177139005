import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { colors } from 'library/styles/theme'

export const CustomBarBuilder = (whitebg: boolean) => {
  return styled(LinearProgress)(({ theme }) => ({
    height: 18,
    borderRadius: 50,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: `${whitebg ? colors.commonWhite : '#F7F7F7'}`,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 50,
      backgroundColor: theme.palette.primary.main,
    },
  }))
}

export const typographyStyle = (progress: number) => ({
  transition: 'color 1s',
  color: `${progress < 50 ? colors.orange : colors.commonWhite}`,
})
