import { Badge, badgeClasses, Drawer, styled } from '@mui/material'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const StyledDrawer = styled(Drawer)({
  width: '25%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100vh',
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    width: '20%',
  },
})

export const Category = styled('p')({
  fontWeight: 'bold',
  fontSize: '12px',
  lineHeight: '15px',
  margin: '0 0.5em',
  color: colors.gray,
})

export const SubCategory = styled('p')({
  fontSize: '16px',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  color: '#585858',
  paddingLeft: '13px',
  margin: '0.75em auto',
  '& > svg': {
    marginLeft: '1em',
    paddingRight: '0.5em',
  },
})

export const StyledImage = styled('img')({
  width: '190px',
  height: '82px',
  padding: '0.5em',
  margin: '0.5em',
  objectFit: 'contain',
})

export const UserSection = styled('section')({
  marginTop: 'auto',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderTop: '1px solid #D6D6D6',
  padding: '1em 0',
  '& > svg': {
    margin: '0.25em 0',
  },
})

export const UserText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const UserName = styled('p')({
  font: 'normal normal 600 16px/19px Open Sans',
  letterSpacing: '0px',
  color: '#201F2C',
  textAlign: 'left',
  margin: '0.25em auto 0.25em auto',
  width: 'auto',
  maxWidth: '175px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const UserRole = styled('p')({
  font: 'normal normal normal 12px/15px Open Sans',
  textAlign: 'left',
  letterSpacing: '0px',
  color: '#201F2C',
  margin: '0 auto 0 0',
  textTransform: 'capitalize',
})

export const StyledBadge = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    bottom: 10,
    right: 12,
  },
})
