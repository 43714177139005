import React from 'react'
import { endOfDay, startOfDay } from 'date-fns'
import { Box, CalendarRange } from 'library/components/Common'
import {
  CalendarRangeType,
  DateRangeCalendarTabType,
} from 'library/models/date'
import {
  getPreviousMonth,
  getStartOfCurrentMonth,
  getSubDaysFromDate,
} from 'library/utils/date'

import { StyledContainer, StyledTab, StyledTabs } from './style'

export type OmitFieldsType = {
  ALL?: boolean
  TODAY?: boolean
  YESTERDAY?: boolean
  LAST_SEVEN_DAYS?: boolean
  LAST_THIRTY_DAYS?: boolean
  MONTH?: boolean
  LAST_MONTH?: boolean
  CUSTOM?: boolean
}

export type DateRangeCalendarTextModel = {
  allLabel: string
  todayLabel: string
  yesterdayLabel: string
  lastSevenDaysLabel: string
  lastThirtyDaysLabel: string
  monthLabel: string
  lastMonthLabel: string
  customLabel: string
  fromLabel: string
  toLabel: string
}

export type ExtendedCalendarRangeProps = {
  tabValue: DateRangeCalendarTabType | boolean
  handleTabChange: (value: DateRangeCalendarTabType) => void
  /**
   * Object containing the date range of the extended calendar
   */
  dateRange: CalendarRangeType
  /**
   * handleChangeDateRange is a function that takes a new CalendarRangeType as an argument
   * and replaces the last dateRange that was selected
   */
  handleChangeDateRange: (newDateRange: CalendarRangeType) => void

  /**
   * When enabled the tabs `today`, `this month` and `all`
   */
  extendedFields?: boolean

  /**
   * Object that decides which tabs to omit.
   * You have to set true the ones you wish to omit:  ALL | TODAY | YESTERDAY | LAST_SEVEN_DAYS | LAST_THIRTY_DAYS | MONTH | LAST_MONTH | CUSTOM
   */
  omitFields?: OmitFieldsType

  /**
   * Optional Maximum allowed date for the calendar to be clicked, this will be passed to the to calendar. If undefined defaults to new Date().
   * If null it will have no limit
   */
  maxDate?: Date | null
  /**
   * Minimum allowed date for the calendar to be clicked, this will be passed to the from calendar
   */
  minDate?: Date
  translation?: DateRangeCalendarTextModel
}

/**
 * This is an augmented and opinionated version of the `<CalendarRange />`
 * where you can set  predefined range of dates in tab, if `value` is different
 * than `custom`, the `<CalendarRange />` will be disabled
 */
export const ExtendedCalendarRange = ({
  handleTabChange,
  tabValue,
  dateRange,
  handleChangeDateRange,
  extendedFields,
  omitFields,
  maxDate = new Date(),
  minDate,
  translation,
}: ExtendedCalendarRangeProps) => {
  const startOfToday = startOfDay(new Date())
  const endOfToday = endOfDay(new Date())

  const handleClickYesterday = () => {
    const yesterday = getSubDaysFromDate(1, new Date())

    handleChangeDateRange({
      fromDate: startOfDay(yesterday),
      toDate: endOfDay(yesterday),
    })
  }

  const handleClickLastAmountOfDays = (amount: number) => {
    const subDate = getSubDaysFromDate(amount, new Date())
    handleChangeDateRange({ fromDate: startOfDay(subDate), toDate: endOfToday })
  }

  const handleGetPreviousMonth = () => {
    const range = getPreviousMonth()
    handleChangeDateRange({ fromDate: range[0], toDate: range[1] })
  }

  const handleAllClick = () => {
    handleChangeDateRange({ fromDate: null, toDate: null })
  }

  const handleTodayClick = () => {
    handleChangeDateRange({ fromDate: startOfToday, toDate: endOfToday })
  }

  const handleMonthClick = () => {
    const startMonth = getStartOfCurrentMonth()
    handleChangeDateRange({ fromDate: startMonth, toDate: startOfToday })
  }

  return (
    <StyledContainer>
      <StyledTabs
        orientation="vertical"
        onChange={(e, newValue) => {
          handleTabChange(newValue)
        }}
        variant="scrollable"
        value={tabValue}
      >
        {extendedFields && !omitFields?.ALL ? (
          <StyledTab
            label={translation?.allLabel || 'All'}
            onClick={handleAllClick}
            value={DateRangeCalendarTabType.ALL}
            data-cy="date-range-filter-all-button"
          />
        ) : null}
        {extendedFields && !omitFields?.TODAY ? (
          <StyledTab
            label={translation?.todayLabel || 'Today'}
            onClick={handleTodayClick}
            value={DateRangeCalendarTabType.TODAY}
            data-cy="date-range-filter-today-button"
          />
        ) : null}
        {!omitFields?.YESTERDAY && (
          <StyledTab
            label={translation?.yesterdayLabel || 'Yesterday'}
            onClick={handleClickYesterday}
            value={DateRangeCalendarTabType.YESTERDAY}
            data-cy="date-range-filter-yesterday-button"
          />
        )}

        {!omitFields?.LAST_SEVEN_DAYS && (
          <StyledTab
            label={translation?.lastSevenDaysLabel || 'Last 7 days'}
            onClick={() => handleClickLastAmountOfDays(7)}
            value={DateRangeCalendarTabType.LAST_SEVEN_DAYS}
            data-cy="date-range-filter-last-seven-days-button"
          />
        )}
        {!omitFields?.LAST_THIRTY_DAYS && (
          <StyledTab
            label={translation?.lastThirtyDaysLabel || 'Last 30 days'}
            onClick={() => handleClickLastAmountOfDays(30)}
            value={DateRangeCalendarTabType.LAST_THIRTY_DAYS}
            data-cy="date-range-filter-last-thirty-days-button"
          />
        )}
        {extendedFields && !omitFields?.MONTH ? (
          <StyledTab
            label={translation?.monthLabel || 'This month'}
            onClick={handleMonthClick}
            value={DateRangeCalendarTabType.MONTH}
            data-cy="date-range-filter-month-button"
          />
        ) : null}

        {!omitFields?.LAST_MONTH && (
          <StyledTab
            label={translation?.lastMonthLabel || 'Last month'}
            onClick={handleGetPreviousMonth}
            value={DateRangeCalendarTabType.LAST_MONTH}
            data-cy="date-range-filter-last-month-button"
          />
        )}
        {!omitFields?.CUSTOM && (
          <StyledTab
            label={translation?.customLabel || 'Custom'}
            value={DateRangeCalendarTabType.CUSTOM}
            data-cy="date-range-filter-custom-button"
          />
        )}
      </StyledTabs>
      <Box padding="2rem 2rem 0rem 2rem">
        <CalendarRange
          dateRange={dateRange}
          handleChangeCalendarRange={handleChangeDateRange}
          maxDate={maxDate === null ? undefined : maxDate}
          disableRangeCalendar={tabValue !== DateRangeCalendarTabType.CUSTOM}
          minDate={minDate}
          fromLabel={translation?.fromLabel || 'From'}
          toLabel={translation?.toLabel || 'To'}
        />
      </Box>
    </StyledContainer>
  )
}
