import React from 'react'
import { SelectChangeEvent } from '@mui/material'

import { DATAGE_LIMIT } from 'constants/date'

import { ItemProps } from '../ItemContainer'
import { StyledSelect, StyledTextField, StyledTextFieldBox } from '../style'

type SelectProps = Omit<ItemProps, 'edit'>

const EditField = ({ item, handleInfo, index }: SelectProps) => {
  const LIMIT = Number(DATAGE_LIMIT)

  const handleValidateLimit = (value: string) => {
    if (value && value.length === 4 && item.customValidation) {
      if (Number(value) > LIMIT) {
        return LIMIT
      }
      if (Number(value) < 1900) {
        return 1900
      }
      return value
    }
    if (typeof item.name === 'number') {
      return Number(value)
    }
    return value
  }

  if (item.options) {
    return (
      <StyledSelect
        defaultValue={item.options[0].value}
        options={item.options}
        variant="standard"
        value={item.name}
        onChange={(e: SelectChangeEvent<unknown>) => {
          handleInfo((prevState) => {
            const infoItems = [...prevState]
            const infoItem = {
              ...infoItems[index],
              name: e.target.value as string,
            }
            infoItems[index] = infoItem
            return infoItems
          })
        }}
        sx={{ fontSize: 12, height: 40 }}
      />
    )
  }

  return (
    <>
      {!item.inverted ? (
        <StyledTextField
          placeholder={item.label}
          variant="standard"
          value={item.name}
          onChange={(e) => {
            handleInfo((prevState) => {
              const infoItems = [...prevState]
              const infoItem = {
                ...infoItems[index],
                name: handleValidateLimit(e.target.value),
              }
              infoItems[index] = infoItem
              return infoItems
            })
          }}
          InputProps={item.inputProps}
          type={typeof item.name === 'number' ? 'number' : 'text'}
        />
      ) : (
        <StyledTextFieldBox
          placeholder={item.label}
          variant="outlined"
          multiline
          rows={5}
          value={item.name}
          onChange={(e) => {
            handleInfo((prevState) => {
              const infoItems = [...prevState]
              const infoItem = {
                ...infoItems[index],
                name: e.target.value,
              }
              infoItems[index] = infoItem
              return infoItems
            })
          }}
          inputProps={{
            maxLength: 120,
          }}
          type={typeof item.name === 'number' ? 'number' : 'text'}
        />
      )}
    </>
  )
}
export default EditField
