import { Option } from 'models/select'

export const statesList: Option[] = [
  {
    name: 'Florida',
    value: '1',
  },
  {
    name: 'Washington',
    value: '2',
  },
  {
    name: 'Colorado',
    value: '3',
  },
  {
    name: 'Arizona',
    value: '4',
  },
]

export const dummyCityInformation = [
  {
    cityName: 'florida',
    stateName: 'florida',
    dealers: '13',
    curboSpots: 4,
  },
]

export enum CityColumnField {
  ID = 'id',
  NAME = 'name',
  STATE = 'state',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
}
