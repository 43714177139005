import { Box, styled } from '@mui/material'
import { colors } from 'library/styles/theme'

export const StyledBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 3rem',
  justifyContent: 'start',
  alignContent: 'center',
  marginTop: '50px',
  backgroundColor: colors.commonWhite,
})
