import { CurboSpot } from 'library/models/curboSpot'
import { FilterInputType } from 'library/models/filter'
import { ProviderEntity } from 'library/models/services/providerEntity'
import { ExtendedStatus } from 'library/models/services/statusEntity'

export type FilterEntryNameType = { name: string }

export type FilterEntryStringType = {
  type: 'string'
} & FilterEntryNameType

export type FilterEntryVariableType = {
  type: FilterInputType
} & FilterEntryNameType

export type FiltersByCriteriaType = {
  filtersButton: string
  fieldOrEntryOptions: {
    [key: string]: FilterEntryVariableType
  }
  operatorOptions: {
    [key: string]: string
  }
  statusOptions?: {
    [key: string]: string
  }
  provinceOptions?: {
    [key: string]: string
  }
  curboSpotOptions?: {
    [key: string]: string
  }
  providerOptions?: {
    [key: string]: string
  }
}

/**
 *
 * @param text This is the translated text object for all fields,
 *  as declared in the inspectionDashboard.json file
 * @returns Returns all of the fields with the translated text
 */
export type FilteringOptionType = {
  disabled: boolean
  name: string
  value: string
}

type FilteringInputOptions = Pick<FilteringOptionType, 'disabled' | 'value'> &
  FilterEntryVariableType

export const getFilteringOptions = (
  text: FiltersByCriteriaType,
  statuses?: ExtendedStatus[],
  curboSpots?: CurboSpot[],
  providerList?: ProviderEntity[]
) => {
  const {
    filtersButton,
    fieldOrEntryOptions: fieldOrEntryOptionsText,
    operatorOptions: operatorOptionsText,
    provinceOptions: provinceOptionsText,
  } = text

  const fieldOrEntryOptions: FilteringInputOptions[] = []
  const operatorOptions: FilteringOptionType[] = []
  const provinceOptions: FilteringOptionType[] = []
  const statusOptions: FilteringOptionType[] = []
  const providerOptions: FilteringOptionType[] = []
  const curboSpotOptions: FilteringOptionType[] = []

  Object.keys(fieldOrEntryOptionsText).forEach((key: string) => {
    fieldOrEntryOptions.push({
      disabled: false,
      value: key,
      name: fieldOrEntryOptionsText[key].name,
      type: fieldOrEntryOptionsText[key].type,
    })
  })

  Object.keys(operatorOptionsText).forEach((key: string) => {
    operatorOptions.push({
      disabled: false,
      name: operatorOptionsText[key],
      value: key,
    })
  })

  if (provinceOptionsText) {
    Object.keys(provinceOptionsText).forEach((key: string) => {
      provinceOptions.push({
        disabled: false,
        name: provinceOptionsText[key],
        value: key,
      })
    })
  }

  if (statuses) {
    statuses.forEach((status) => {
      statusOptions.push({
        disabled: false,
        name: status.name,
        value: status.slug,
      })
    })
  }

  if (curboSpots && curboSpots.length > 0) {
    curboSpots.forEach((curboSpot) => {
      curboSpotOptions.push({
        disabled: false,
        name: curboSpot.name,
        value: curboSpot.id,
      })
    })
  }

  if (providerList && providerList.length > 0) {
    providerList.forEach((provider) => {
      providerOptions.push({
        disabled: false,
        name: provider.name,
        value: provider.id,
      })
    })
  }

  const singleIsOperatorOption = [
    {
      disabled: false,
      name: text.operatorOptions.in,
      value: 'in',
    },
  ]

  return {
    fieldOrEntryOptions,
    operatorOptions,
    provinceOptions,
    singleIsOperatorOption,
    statusOptions,
    curboSpotOptions,
    filtersButton,
    providerOptions,
  }
}
