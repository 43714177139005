import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { ClickAwayListener, Popper } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'

import HourInputField from 'components/Hours/HourInputField'
import { AddSection, StyledTextField } from 'components/Hours/style'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Hour } from 'models/hours'

import { colors } from 'styles/theme'

type ContentBoxProps = {
  singleHour?: Hour
  handleChangeSingleHour: (value: Hour) => void
}

export const HourCreation = ({
  singleHour,
  handleChangeSingleHour,
}: ContentBoxProps) => {
  const { text } = useTranslation(textFiles.HOURS_CREATION)
  const { general: translation } = text

  const meridiem = singleHour && singleHour.am ? 'a.m.' : 'p.m.'

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClosePopper = () => {
    setAnchorEl(null)
  }

  return (
    <AddSection style={{ margin: 0 }}>
      <StyledTextField
        variant="outlined"
        name="add"
        value={
          singleHour
            ? `${singleHour.name} ${meridiem}`
            : translation.creationPlaceHolder
        }
        disabled
        sx={{
          width: '65% !important',
          input: {
            paddingLeft: '0px',
            cursor: 'pointer',
          },
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(anchorEl ? null : event.currentTarget)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <WatchLaterIcon sx={{ color: colors.lightGray, fontSize: 18 }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <KeyboardArrowDownIcon
                sx={{
                  color: colors.lightGray,
                  fontSize: 18,
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <Popper
        id="translation-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClosePopper}>
          <HourInputField
            time={singleHour}
            handleTimeChange={handleChangeSingleHour}
          />
        </ClickAwayListener>
      </Popper>
    </AddSection>
  )
}

export default HourCreation
