import React, { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton } from '@mui/material'

import ConfirmationModal from 'components/General/ConfirmationModal'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Role, UserRoles } from 'models/role'
import { Option } from 'models/select'

import { SelectRoleField } from './SelectRoleField'
import { StaticRoleField } from './StaticRoleField'
import { AddNewRoleButton, flexStyle, RoleTitle, StyledDivider } from './style'

export type RoleAndAreasListType = {
  availableRoles: Role[]
  availableAreas: Option[]
}

type RoleFieldProps = {
  roles: Role[]
  edit?: boolean
  handleSelectIndex: (index: number) => void
  handleSetNewRoles: (newRoles: Role[]) => void
  handleRemoveRole: () => void
  disabledAdd: boolean
} & RoleAndAreasListType
export const RoleFields = ({
  roles,
  availableRoles,
  availableAreas,
  edit = false,
  disabledAdd,
  handleSelectIndex,
  handleSetNewRoles,
  handleRemoveRole,
}: RoleFieldProps) => {
  const { text } = useTranslation(textFiles.USER_MANAGEMENT_DETAIL)
  const { roleManagement, confirmationModal } = text
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const handleChangeLocalRoles = (newRole: Role, index: number) => {
    const newRoles = [...roles]
    newRoles[index] = newRole
    handleSetNewRoles(newRoles)
  }

  const handleAddNewRole = () => {
    handleSetNewRoles([
      ...roles,
      {
        description: '',
        slug: UserRoles.CLIENT,
        name: '',
        value: '',
        area: {
          name: '',
          value: '',
        },
      },
    ])
  }

  const openDeleteModal = (index: number) => {
    handleSelectIndex(index)
    setDeleteModal(true)
  }

  const handleDeleteRole = () => {
    handleRemoveRole()
    setDeleteModal(false)
  }

  return (
    <>
      <Box sx={{ maxHeight: '29rem', overflowY: 'auto', paddingBottom: '5px' }}>
        {roles.map((role, index) => (
          <Box key={`${role.value}-#${index + 1}`}>
            <Box key={`${role.value}-title`} sx={flexStyle} width="95%">
              <StyledDivider
                sx={{
                  marginY: '10px',
                }}
              >
                <RoleTitle variant="body1">
                  {roleManagement.indexTitle} #{index + 1}
                </RoleTitle>
              </StyledDivider>
              {edit && (
                <IconButton
                  disabled={!edit}
                  onClick={() => openDeleteModal(index)}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
            {!edit ? (
              <StaticRoleField
                key={`${role.value}-static`}
                role={role}
                area={availableAreas.find((a) => role.area.value === a.value)}
              />
            ) : (
              <SelectRoleField
                key={`${role.value}-select`}
                availableAreas={availableAreas}
                availableRoles={availableRoles}
                role={role}
                handleChangeRole={handleChangeLocalRoles}
                index={index}
              />
            )}
          </Box>
        ))}
      </Box>
      {edit && (
        <AddNewRoleButton
          disabled={disabledAdd}
          onClick={handleAddNewRole}
          variant="text"
        >
          {roleManagement.addNewRole}
        </AddNewRoleButton>
      )}
      <ConfirmationModal
        open={deleteModal}
        onConfirmHandler={handleDeleteRole}
        onCancelHandler={() => setDeleteModal(false)}
        text={confirmationModal}
        descriptionSx={{
          fontSize: '1.2rem',
        }}
      />
    </>
  )
}
