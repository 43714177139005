import React from 'react'
import { Toggle } from 'library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledContainer } from '../style'

type ToggleProps = {
  title: string
  description?: string
  status: boolean
  handleStatus:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((value: boolean) => void)
  width?: string | number
  /*
   * Adds cypress tag to toggle buttons
   */
  testId?: string
}

/*
This component is used in any of the information step of a setting creation process, 
includes a container with toggle buttons of Yes or No
*/

const ToggleContainer = ({
  title,
  description,
  status,
  width,
  handleStatus,
  testId,
}: ToggleProps) => {
  const { text: translation } = useTranslation(textFiles.GENERAL)
  const { buttons } = translation
  return (
    <StyledContainer title={title} description={description} width={width}>
      <Toggle
        value={status}
        handleValueChange={handleStatus}
        testId={testId}
        trueLabel={buttons.yes}
        falseLabel={buttons.no}
      />
    </StyledContainer>
  )
}

export default ToggleContainer
