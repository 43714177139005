import React, { FunctionComponent } from 'react'
import NumberFormat from 'react-number-format'
import { InputBaseComponentProps, Slider as MuiSlider } from '@mui/material'
import { Box } from 'library/components/Common/Box'

import { StyledTextField } from './style'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<number>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator
        isNumericString
        allowNegative={false}
      />
    )
  }
)

export type SliderProps = {
  /**
   * function to change slider value
   */
  handleValueChange:
    | ((value: number) => void)
    | React.Dispatch<React.SetStateAction<number>>
  /**
   * The value of the item
   */
  value: number
  /**
   * mac value limit
   */
  maxValue?: number
  /**
   * min value limit
   */
  minValue?: number
  /**
   * Optional texfield
   */
  withTextField?: boolean
  /**
   * Optional flag for disabling input
   */
  disableInput?: boolean
}

export const Slider = ({
  handleValueChange,
  value,
  maxValue = 100,
  minValue = 0,
  withTextField = false,
  disableInput = false,
}: SliderProps) => {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    handleValueChange(newValue as number)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleValueChange(Number(event.target.value))
  }

  const handleBlur = () => {
    if (value > maxValue) {
      handleValueChange(maxValue)
    }
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <MuiSlider
        value={value}
        onChange={handleSliderChange}
        max={maxValue}
        min={minValue}
        aria-labelledby="input-slider"
        sx={{
          width: withTextField ? '70%' : '100%',
        }}
      />
      {withTextField ? (
        <StyledTextField
          variant="outlined"
          name="slider-textfield"
          value={value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          disabled={disableInput}
          InputProps={{
            inputComponent:
              NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
          }}
          sx={{
            width: '25% !important',
          }}
        />
      ) : null}
    </Box>
  )
}
