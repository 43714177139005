export enum CarProviderEnum {
  CLIENT = 'CLIENT',
  CURBO_SPOT = 'CURBO_SPOT',
  DEALER = 'DEALER',
}

export enum PICTURE_TYPE_OPTION {
  INTERIOR = 'interior',
  EXTERIOR = 'exterior',
}

export enum InspectionLogColumnField {
  ID = 'id',
  CREATED_AT = 'createdAt',
  SOURCE = 'source',
  PROVIDER = 'provider',
  DATE = 'date',
  TYPE = 'type',
  STATUS = 'status',
}
