import { styled } from '@mui/material'
import { Box } from 'library/components/Common/Box'
import { Button } from 'library/components/Common/Button'
import { colors } from 'library/styles/theme'

export const CounterDiv = styled(Box)({
  width: '60px',
  height: '50px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  border: `2px solid ${colors.gray}`,
  borderRadius: 8,
})

export const StyledButton = styled(Button)({
  width: '100%',
  height: '25px',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
