export enum UserRoles {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  INSPECTOR = 'INSPECTOR',
  INSPECTOR_SUPERVISOR = 'INSPECTOR_SUPERVISOR',
  INSPECTOR_MANAGER = 'INSPECTOR_MANAGER',
  MARKETING = 'MARKETING',
  SUPPORT = 'SUPPORT',
  UPLOADER = 'UPLOADER',
  OPERATION_SPECIALIST = 'OPERATION_SPECIALIST',
  OPERATION_SUPERVISOR = 'OPERATION_SUPERVISOR',
  INVENTORY_SPECIALIST = 'INVENTORY_SPECIALIST',
  INVENTORY_SUPERVISOR = 'INVENTORY_SUPERVISOR',
  INVENTORY_READER = 'INVENTORY_READER',
  MARKETING_SPECIALIST = 'MARKETING_SPECIALIST',
  CUSTOMER_CARE_READER = 'CUSTOMER_CARE_READER',
  CUSTOMER_CARE_SPECIALIST = 'CUSTOMER_CARE_SPECIALIST',
  CUSTOMER_CARE_SUPERVISOR = 'CUSTOMER_CARE_SUPERVISOR',
}

export type Role = {
  area: {
    name: string
    value: string
  }
  description: string
  value: string
  name: string
  slug: UserRoles
}

/*
 * This would be updated accordingly to the modules that we keep
 * creating if we don't have available modules for certain roles
 * chances are the user doesn't have permissions to access the BO
 */
export const BackOfficeAllowedRoles = [
  UserRoles.ADMIN,
  UserRoles.INSPECTOR_SUPERVISOR,
  UserRoles.INSPECTOR_MANAGER,
  UserRoles.OPERATION_SPECIALIST,
  UserRoles.OPERATION_SUPERVISOR,
  UserRoles.INVENTORY_SPECIALIST,
  UserRoles.INVENTORY_SUPERVISOR,
  UserRoles.INVENTORY_READER,
  UserRoles.MARKETING_SPECIALIST,
  UserRoles.CUSTOMER_CARE_READER,
  UserRoles.CUSTOMER_CARE_SPECIALIST,
  UserRoles.CUSTOMER_CARE_SUPERVISOR,
]
