import StateInfo from 'components/Operations/State/Creation/StateInfo'
import StateLocation from 'components/Operations/State/Creation/StateLocation'
import StateSummary from 'components/Operations/State/Creation/Summary'

import { StateCreationModel } from 'models/stateCreation'

export type StateCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  stateData: StateCreationModel
  updateStateData: (newState: StateCreationModel) => void
  handleSubmit: () => void
  submitLoading: boolean
}

export const STATE_CREATION_STEPS: Array<React.FC<StateCreationProps>> = [
  StateInfo,
  StateLocation,
  StateSummary,
]
