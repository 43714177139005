/* eslint-disable import/no-duplicates */
import { addHours, parseISO } from 'date-fns'

import { LocalAbsenceType } from 'components/Inspector/Absence'

/**
 * Returns a dateRange from an absence input
 */
export const getRangeFromAbsence = (absence: LocalAbsenceType) => {
  const range = {
    fromDate: parseISO(absence.startDate),
    toDate: parseISO(absence.endDate),
  }
  return range
}

/**
 * Returns a date as pm depending on variable
 */
export const convertToPm = (date: Date, isAm: boolean) => {
  if (isAm) {
    return date
  }
  return addHours(date, 12)
}
