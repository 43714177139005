import React from 'react'
import { styled } from '@mui/material'
import { colors } from 'library/styles/theme'
import { BarChart as ReBarChart } from 'recharts'

export const StyledSpan = styled('span')({
  color: colors.black,
  margin: '0px 10px',
})

export const LegendTextFormatter = (value: string) => {
  return <StyledSpan>{value}</StyledSpan>
}

export const StyledBarChart = styled(ReBarChart)({
  '.recharts-cartesian-grid-vertical line:last-child': {
    strokeOpacity: 0,
  },

  '& > div:nth-of-type(1) ul': {
    padding: 'unset',
    margin: 'unset',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
})
