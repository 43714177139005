import { gql } from '@apollo/client'

export const UPDATE_DEALER = gql`
  mutation updateDealer($input: UpdateDealerInput!) {
    data: updateDealer(input: $input) {
      id
    }
  }
`

export const UPGRADE_DEALER_TO_SPOT = gql`
  mutation updateDealerToSpot($id: ID!) {
    data: updateDealerToCurboSpot(id: $id) {
      id
    }
  }
`
