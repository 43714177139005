import React from 'react'
import { CSSObject, Divider, Typography } from '@mui/material'
import { TabObjects, Tabs } from 'library/components/Common/Tabs'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

import {
  StyledContainer,
  StyledContent,
  StyledSubTitleContainer,
  StyledTitleContainer,
} from './style'

export type TitleProps = {
  /**
   * If `true` the divider at the end will not appear
   */
  disableDivider?: boolean
  /**
   * Subtitle which is below the title
   */
  subtitle?: string
  /**
   * Icon which is before the subtitle
   */
  icon?: React.ReactNode
  /**
   * Optional tabs menu that goes on the right side of the title
   */
  tabs?: TabObjects[]
  /**
   * The title of the content
   */
  title: string
  /**
   * value of tabs
   */
  tabValue?: number | string | boolean
  /**
   * Function to change tabs
   */
  handleTabChange?: (value: number | string) => void
  /**
   * Optional container styles
   */
  containerSx?: CSSObject
}

export const Title: React.FC<TitleProps> = ({
  subtitle,
  icon,
  tabs,
  title,
  tabValue,
  handleTabChange,
  disableDivider = false,
  containerSx,
}) => {
  return (
    <StyledContainer component="header" sx={containerSx}>
      <StyledContent>
        <StyledTitleContainer sx={{ width: tabs ? '50%' : '100%' }}>
          <Typography variant="h3">{title}</Typography>
          <StyledSubTitleContainer component="span">
            {icon}
            <Typography
              color={colors.gray}
              fontWeight={FONT_WEIGHT.REGULAR}
              variant="body1"
            >
              {subtitle}
            </Typography>
          </StyledSubTitleContainer>
        </StyledTitleContainer>
        {tabs && tabValue !== undefined && handleTabChange ? (
          <Tabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        ) : null}
      </StyledContent>
      {!disableDivider ? (
        <Divider
          sx={{
            display: 'flex',
            flexDirection: 'row',
            borderColor: colors.gray,
          }}
        />
      ) : null}
    </StyledContainer>
  )
}
