import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  BodyContainerWithHeader,
  createSimpleStepperItems,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'
import { uploadImageService } from 'services/uploadImage'

import { BackContainer } from 'components/Common/BackContainer'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  BodyStyleCreationAction,
  BodyStyleCreationModel,
} from 'models/bodyStyleCreation'
import { BodyStyle } from 'models/car'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateCarSettingPictureInputType } from 'models/services/CarSettings/creation'
import { PublicationStatus } from 'models/status'
import bodyStyleCreationReducer from 'reducers/bodyStyleCreationReducer'
import { INITIAL_BODY_STYLE_CREATION_STEPS as steps } from 'utils/CarSettings/BodyStyle/creation'
import { getTranslationsFilteredByLanguage } from 'utils/translation'

import { CREATE_BODY_STYLE } from 'graphQL/Operations/BodyStyle/Creation/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialBodyStyleInformationState: BodyStyle = {
  name: '',
  picture: '',
  pictureFile: null,
  status: PublicationStatus.PUBLISHED,
  translations: [],
}

const initialData: BodyStyleCreationModel = {
  bodyStyleInformation: initialBodyStyleInformationState,
}

const BodyStyleCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [bodyStyleData, dispatch] = useReducer(
    bodyStyleCreationReducer,
    initialData
  )

  const { bodyStyleInformation } = bodyStyleData
  const { translations } = bodyStyleInformation

  const history = useHistory()
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.BODY_STYLE_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { stepper } = text

  const stepperItems = createSimpleStepperItems(stepper)

  const updateBodyStyleData = (action: BodyStyleCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const [createBodyStyle, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateCarSettingPictureInputType>
  >(CREATE_BODY_STYLE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CAR_SETTINGS_SUB_ROUTES.BODY_STYLE_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )

      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(async () => {
    const { valueEn, valueEs } = getTranslationsFilteredByLanguage(translations)

    const { pictureFile } = bodyStyleInformation

    try {
      const response = pictureFile
        ? await uploadImageService(pictureFile)
        : undefined
      createBodyStyle({
        variables: {
          input: {
            icon: response ? response.data : undefined,
            name: bodyStyleInformation.name,
            name_translations: {
              en: valueEn ? valueEn.description : null,
              es: valueEs ? valueEs.description : null,
            },
            status: bodyStyleInformation.status
              ? PublicationStatus.PUBLISHED
              : PublicationStatus.UNPUBLISHED,
          },
        },
      })
    } catch (e) {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.uploadError,
      })
    }
  }, [bodyStyleInformation, createBodyStyle, generalText, show, translations])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
        stepperItems={stepperItems}
        currentStep={currentStep}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          bodyStyleData,
          handleSubmit,
          submitLoading,
          updateBodyStyleData,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default BodyStyleCreationPage
