import { gql } from '@apollo/client'

export const LOGIN_USER = gql`
  mutation logInUser($userLogVars: SignInUserInput!) {
    signInUser(input: $userLogVars) {
      accessToken
      user {
        name
        lastName
        address
        documentId
        drivingLicense
        email
        id
        profilePicture
        telephoneNumber
        roles {
          id
          description
          name
          slug
        }
        organization {
          type
          status
          name
          id
        }
      }
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordInput: CreateAuthTokenInput!) {
    resetUserPassword(input: $resetPasswordInput)
  }
`

export const REFRESH_TOKEN = `
  mutation refreshToken($refreshTokenVars: RefreshAccessTokenInput!) {
    refreshAccessToken(input: $refreshTokenVars) {
      accessToken
      refreshToken
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($newPasswordInput: ValidateAuthTokenInput!) {
    validateToken(input: $newPasswordInput)
  }
`

export const UPDATE_PASSWORD = gql`
  mutation updateUserPassword($updatePasswordInput: UpdateUserPasswordInput!) {
    updateSelfPassword(input: $updatePasswordInput) {
      passwordUpdated
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateSelfUserPayload!) {
    updateSelf(input: $input) {
      name
      lastName
      address
      email
      documentId
      drivingLicense
      profilePicture
      telephoneNumber
      roles {
        id
        name
        description
        slug
      }
    }
  }
`
