import React from 'react'
import { Activity } from 'library/components/Dashboard/Inbox/Activity'
import { ActivityType } from 'library/models/activity'
import { Language } from 'library/models/language'

import { StyledContainer } from './style'

export type RecentActivitiesTextModel = {
  title: string
  description: string
  sideTitle: string
}

export type InboxProps = {
  activities: ActivityType[]
  translation?: RecentActivitiesTextModel
  language: Language
  isEmpty?: boolean
}

export const Inbox = ({
  activities,
  translation,
  language,
  isEmpty = false,
}: InboxProps) => {
  return (
    <StyledContainer
      title={translation?.title || 'Recent activities'}
      height={437}
      isEmpty={isEmpty}
    >
      {activities.map((activity: ActivityType) => (
        <Activity
          key={activity.id}
          img={activity.img}
          name={activity.name}
          description={activity.description}
          hour={activity.hour}
          language={language}
        />
      ))}
    </StyledContainer>
  )
}
