import Information from 'components/Marketing/Notification/Creation/Information'
import Summary from 'components/Marketing/Notification/Creation/Summary'

import {
  NotificationCreationAction,
  NotificationCreationModel,
} from 'models/notification'

export type NotificationCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  notificationData: NotificationCreationModel
  updateNotificationData: (action: NotificationCreationAction) => void
  handleSubmit: () => void
  submitLoading: boolean
}

export const NOTIFICATION_CREATION_STEPS: Array<
  React.FC<NotificationCreationProps>
> = [Information, Summary]
