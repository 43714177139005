import React, { useEffect, useState } from 'react'
import BuildIcon from '@mui/icons-material/Build'
import { Box } from 'library'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { TranslationDetailDataType } from 'models/services/CarSettings/detail'
import { getInitialNameTranslation } from 'utils/translation'

import { StyledContainer } from './style'

type Props = {
  information: TranslationDetailDataType
  loading: boolean
  handleSave: (
    name: string | undefined,
    translations: TranslationItemType[]
  ) => Promise<boolean>
}

const Information = ({ information, handleSave, loading }: Props) => {
  const { name, nameTranslations } = information
  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >([])

  const { text } = useTranslation(textFiles.TRANSMISSION_DETAIL)
  const { general, transmissionInformation: translation } = text

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const initialNameInformation = [
    {
      id: '1',
      icon: <BuildIcon />,
      name,
      label: general.title,
    },
  ]

  const [informationRows, setInformationRows] = useState<NameInformation[]>(
    initialNameInformation
  )

  const resetState = () => {
    setAddedTranslations(getInitialNameTranslation(nameTranslations))
    setInformationRows(initialNameInformation)
    setHasUnsavedChanges(false)
  }

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setInformationRows(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleChangeTranslation = (translations: TranslationItemType[]) => {
    setAddedTranslations(translations)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    const newName =
      informationRows.length > 0 ? informationRows[0].name : undefined

    const isUpdateSuccesful = await handleSave(
      newName === name ? undefined : String(newName),
      addedTranslations
    )
    if (isUpdateSuccesful) {
      setHasUnsavedChanges(false)
    }
  }

  useEffect(() => {
    setAddedTranslations(getInitialNameTranslation(nameTranslations))
  }, [nameTranslations])

  return (
    <StyledContainer>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        sx={{
          '> div': {
            padding: '15px 20px',
          },
        }}
      >
        <NameContainer
          title={translation.information}
          description={translation.informationDescription}
          information={informationRows}
          handleSetInformation={handleChangeInformation}
          width={399}
          loading={loading}
        />
        <Translation
          addedTranslations={addedTranslations}
          handleChangeTranslation={handleChangeTranslation}
          translationDescription={translation.translationsDescription}
        />
        <SaveChangesBlock
          handleSaveChanges={handleSaveChanges}
          resetState={resetState}
          submitLoading={loading}
          shouldRender={hasUnsavedChanges}
        />
      </Box>
      {/* <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <LogContainer>
          <HistoryLog
            height="200px"
            dateFormat="time"
            hasPadding={!!(width && width <= BREAK_POINTS.XL)}
          />
        </LogContainer>
      </Box> */}
    </StyledContainer>
  )
}

export default Information
