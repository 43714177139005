import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import LeadBoardPage from 'pages/CustomerRelationship/Lead/Board'
import LeadCreationPage from 'pages/CustomerRelationship/Lead/Creation'
import LeadDetailPage from 'pages/CustomerRelationship/Lead/Detail'
import LeadListingPage from 'pages/CustomerRelationship/Lead/Listing'

import RoleRoute from 'components/Auth/RoleRoute'

import {
  CUSTOMER_RELATIONSHIP_ROUTE,
  CUSTOMER_RELATIONSHIP_SUB_ROUTES,
} from 'constants/routes'
import { UserRoles } from 'models/role'

export const CustomerRelationshipRouter = () => (
  <Switch>
    <RoleRoute
      allowedRoles={[
        UserRoles.ADMIN,
        UserRoles.CUSTOMER_CARE_READER,
        UserRoles.CUSTOMER_CARE_SPECIALIST,
        UserRoles.CUSTOMER_CARE_SUPERVISOR,
      ]}
      path={CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_BOARD}
    >
      <Route
        path={CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_BOARD}
        component={LeadBoardPage}
      />
    </RoleRoute>

    <RoleRoute
      allowedRoles={[
        UserRoles.ADMIN,
        UserRoles.CUSTOMER_CARE_READER,
        UserRoles.CUSTOMER_CARE_SPECIALIST,
        UserRoles.CUSTOMER_CARE_SUPERVISOR,
      ]}
      path={CUSTOMER_RELATIONSHIP_ROUTE}
    >
      <Route
        exact
        path={CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_LISTING}
        component={LeadListingPage}
      />
      <Route
        path={CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_DETAIL}
        component={LeadDetailPage}
      />
    </RoleRoute>

    <RoleRoute
      allowedRoles={[
        UserRoles.ADMIN,
        UserRoles.CUSTOMER_CARE_SPECIALIST,
        UserRoles.CUSTOMER_CARE_SUPERVISOR,
      ]}
      path={CUSTOMER_RELATIONSHIP_ROUTE}
    >
      <Route
        path={CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_CREATION}
        component={LeadCreationPage}
      />
    </RoleRoute>

    <Redirect to={CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_BOARD} />
  </Switch>
)
