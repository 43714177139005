import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import {
  Autocomplete,
  AutocompleteItem,
  ButtonNavigator,
  ColorBox,
  onlySpacesText,
  Slider,
} from 'library'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'

import { maxMileage, unknownTrimLevelId } from 'constants/car'
import { textFiles } from 'constants/textFiles'
import useDebounce from 'hooks/useDebounce'
import useTranslation from 'hooks/useTranslation'
import { VehicleInformationModel } from 'models/inspection'
import {
  BaseEntity,
  FilterInput,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { Color, InspectionCar, ModelTrimYears } from 'models/services/car'
import { InspectionCreationProps } from 'utils/Inspection/creation'

import { GET_INSPECTION_MODELS } from 'graphQL/Common/CarFeature/queries'
import {
  GET_CAR_BY_ID_OR_PLATE,
  GET_INSPECTION_TRIM_LEVELS,
  GET_INSPECTION_TRIM_YEARS,
} from 'graphQL/Inspection/Creation/queries'

import {
  BoxContainer,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
  StyledTitleSection,
} from 'styles/creation'
import { colors, FONT_WEIGHT } from 'styles/theme'

type VehicleInfoError = {
  search: boolean
  brand: boolean
  model: boolean
  year: boolean
  trim: boolean
  exteriorColor: boolean
  interiorColor: boolean
}

const initialErrors: VehicleInfoError = {
  search: false,
  brand: false,
  model: false,
  year: false,
  trim: false,
  exteriorColor: false,
  interiorColor: false,
}

const initialValue: VehicleInformationModel = {
  isRegistered: false,
  searchParam: '',
  vehicleId: '',
  vehicleBrand: null,
  vehicleModel: null,
  vehicleYear: null,
  vehicleTrimLevel: null,
  vehicleMileage: 100000,
  exteriorColor: null,
  interiorColor: null,
  currentModelList: [],
  currentYearList: [],
  currentTrimLevelList: [],
  id: 0,
}

const VehicleInformation = ({
  currentSecondaryStep,
  handleSecondaryBack,
  handleSecondaryContinue,
  inspectionData,
  brands,
  colorList,
}: InspectionCreationProps) => {
  const [vehicleInfo, setVehicleInfo] =
    useState<VehicleInformationModel>(initialValue)

  const [models, setModels] = useState<BaseEntity[]>(
    vehicleInfo.currentModelList
  )
  const [years, setYears] = useState<BaseEntity[]>(vehicleInfo.currentYearList)
  const [trimLevels, setTrimLevels] = useState<BaseEntity[]>(
    vehicleInfo.currentTrimLevelList
  )

  const [errors, setErrors] = useState<VehicleInfoError>(initialErrors)

  const [registered, setRegistered] = useState<boolean>(
    vehicleInfo.isRegistered
  )
  const [search, setSearch] = useState<string>(vehicleInfo.searchParam)

  const { text: translation } = useTranslation(textFiles.INSPECTION_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { vehicleInformation } = translation

  const debouncer = useDebounce(onlySpacesText(search) ? '' : search, 500)

  const [fetchModels, { loading: modelsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    GenericInputVariable<FilterInput>
  >(GET_INSPECTION_MODELS, {
    onCompleted(response) {
      setModels(response.data)
    },
  })

  const [fetchYears, { loading: yearsLoading }] = useLazyQuery<
    GenericData<ModelTrimYears>,
    GenericInputVariable<string>
  >(GET_INSPECTION_TRIM_YEARS, {
    onCompleted(response) {
      setYears(
        response.data.trimYears.map((trimYear) => {
          return {
            name: String(trimYear),
            id: trimYear,
          }
        })
      )
    },
  })

  const [fetchTrims, { loading: trimsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    GenericInputVariable<FilterInput>
  >(GET_INSPECTION_TRIM_LEVELS, {
    onCompleted(response) {
      const newTrims = [
        ...response.data,
        {
          id: unknownTrimLevelId,
          name: vehicleInformation.unknownTrimLevel,
        },
      ]
      setTrimLevels(newTrims)
    },
  })

  const [fetchCarById] = useLazyQuery<
    GenericData<InspectionCar>,
    GenericInputVariable<string>
  >(GET_CAR_BY_ID_OR_PLATE, {
    onCompleted(response) {
      if (response && response.data !== null) {
        setVehicleInfo((prevInfo) => {
          return {
            ...prevInfo,
            vehicleId: response.data.id,
            vehicleBrand: response.data.brand,
            vehicleModel: response.data.carModel,
            vehicleYear: {
              name: String(response.data.year),
              id: response.data.year,
            },
            vehicleTrimLevel: response.data.trimLevel,
            vehicleMileage: response.data.mileage,
            exteriorColor: response.data.exteriorColor,
            interiorColor: response.data.interiorColor,
          }
        })
        setErrors({ ...errors, search: false })
      }
    },
    onError() {
      setErrors({ ...errors, search: true })
      if (registered) setVehicleInfo(initialValue)
    },
  })

  const handleSelectBrand = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleBrand: value,
        vehicleModel: null,
        vehicleYear: null,
        vehicleTrimLevel: null,
      }
    })
    fetchModels({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            brand_eq: value.id,
          },
        },
      },
    })
  }

  const handleSelectModel = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleModel: value,
        vehicleYear: null,
        vehicleTrimLevel: null,
      }
    })
    fetchYears({
      variables: {
        input: value.id as string,
      },
    })
  }

  const handleSelectYear = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleYear: value,
        vehicleTrimLevel: null,
      }
    })
    fetchTrims({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            year: value.id,
            carModel: vehicleInfo.vehicleModel?.id,
          },
        },
      },
    })
  }

  const handleSelectTrim = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleTrimLevel: value,
      }
    })
  }

  const handleSliderChange = (newValue: number) => {
    setVehicleInfo({ ...vehicleInfo, vehicleMileage: newValue as number })
  }

  const handleToggleChange = (newValue: boolean) => {
    setRegistered(newValue)
    setVehicleInfo(initialValue)
    setSearch('')
    setErrors({ ...errors, search: false })
  }

  const handleSubmit = () => {
    const {
      vehicleBrand,
      vehicleModel,
      vehicleYear,
      vehicleTrimLevel,
      exteriorColor,
      interiorColor,
    } = vehicleInfo

    if (
      !vehicleBrand ||
      !vehicleModel ||
      !vehicleYear ||
      !vehicleTrimLevel ||
      !exteriorColor ||
      !interiorColor
    ) {
      setErrors({
        search: false,
        brand: !vehicleBrand && true,
        model: !vehicleModel && true,
        year: !vehicleYear && true,
        trim: !vehicleTrimLevel && true,
        exteriorColor: !exteriorColor && true,
        interiorColor: !interiorColor && true,
      })
      return
    }
    setErrors(initialErrors)
    handleSecondaryContinue({
      ...vehicleInfo,
      isRegistered: registered,
      vehicleId: vehicleInfo.vehicleId,
      searchParam: !errors.search ? search : '',
      currentModelList: models,
      currentYearList: years,
      currentTrimLevelList: trimLevels,
      id: currentSecondaryStep,
    })
  }

  const handlePrev = () => {
    handleSecondaryBack({
      ...vehicleInfo,
      isRegistered: registered,
      vehicleId: vehicleInfo.vehicleId,
      searchParam: !errors.search ? search : '',
      currentModelList: models,
      currentYearList: years,
      currentTrimLevelList: trimLevels,
      id: currentSecondaryStep,
    })
  }

  useEffect(() => {
    if (
      typeof inspectionData.vehicleInformation[currentSecondaryStep] ===
      'undefined'
    ) {
      setVehicleInfo(initialValue)
    } else {
      const { currentModelList, currentYearList, currentTrimLevelList } =
        inspectionData.vehicleInformation[currentSecondaryStep]
      setVehicleInfo(inspectionData.vehicleInformation[currentSecondaryStep])
      setRegistered(
        inspectionData.vehicleInformation[currentSecondaryStep].isRegistered
      )
      setSearch(
        inspectionData.vehicleInformation[currentSecondaryStep].searchParam
      )
      setModels(currentModelList)
      setYears(currentYearList)
      setTrimLevels(currentTrimLevelList)
    }
  }, [currentSecondaryStep, inspectionData.vehicleInformation])

  useEffect(() => {
    fetchCarById({
      variables: {
        input: debouncer,
      },
    })
  }, [fetchCarById, debouncer])

  return (
    <StyledContainer>
      <StyledTitleSection>
        <div>{currentSecondaryStep + 1}</div>
        <Typography
          variant="h5"
          color={colors.black}
          fontWeight={FONT_WEIGHT.BOLD}
        >
          {vehicleInformation.title.replace('%d', currentSecondaryStep + 1)}
        </Typography>
        <Typography
          variant="subtitle1"
          color={colors.placeholderGray}
          fontWeight={FONT_WEIGHT.MEDIUM}
        >
          {vehicleInformation.description}
        </Typography>
      </StyledTitleSection>
      <StyledForm sx={{ minHeight: '200px' }}>
        <div>
          <ToggleContainer
            title={vehicleInformation.registeredVehicle}
            status={registered}
            handleStatus={handleToggleChange}
            width={280}
          />
          {registered ? (
            <StyledTextFieldContainer title={vehicleInformation.searchTitle}>
              <StyledTextField
                placeholder={vehicleInformation.searchPlaceholder}
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value as string)}
                name="search"
              />
              {errors.search && (
                <StyledErrorMessage text={vehicleInformation.notAvailable} />
              )}
            </StyledTextFieldContainer>
          ) : null}
          <StyledTextFieldContainer title={vehicleInformation.vehicleBrand}>
            <Autocomplete
              options={brands}
              onChangeHandler={handleSelectBrand}
              placeholder={vehicleInformation.vehicleBrand}
              value={vehicleInfo.vehicleBrand}
              disablePortal={false}
              testId="brands-autocomplete"
              disabled={registered}
            />
            {errors.brand && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleModel}>
            <Autocomplete
              options={models}
              onChangeHandler={handleSelectModel}
              placeholder={vehicleInformation.vehicleModel}
              value={vehicleInfo.vehicleModel}
              disabled={
                registered || modelsLoading || !vehicleInfo.vehicleBrand
              }
              disablePortal={false}
              testId="models-autocomplete"
            />
            {errors.model && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleYear}>
            <Autocomplete
              options={years}
              onChangeHandler={handleSelectYear}
              placeholder={vehicleInformation.vehicleYear}
              value={vehicleInfo.vehicleYear}
              disabled={registered || yearsLoading || !vehicleInfo.vehicleModel}
              disablePortal={false}
              testId="years-autocomplete"
            />
            {errors.year && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleTrimLevel}>
            <Autocomplete
              options={trimLevels}
              onChangeHandler={handleSelectTrim}
              placeholder={vehicleInformation.vehicleTrimLevel}
              value={vehicleInfo.vehicleTrimLevel}
              disabled={registered || trimsLoading || !vehicleInfo.vehicleYear}
              disablePortal={false}
              testId="trims-autocomplete"
            />
            {errors.trim && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.exteriorColor}>
            <BoxContainer>
              <Autocomplete
                options={colorList}
                onChangeHandler={(value) => {
                  setVehicleInfo((prevInfo) => {
                    return {
                      ...prevInfo,
                      exteriorColor: value as Color,
                    }
                  })
                }}
                placeholder={vehicleInformation.exteriorColor}
                value={vehicleInfo.exteriorColor}
                disablePortal={false}
                testId="exterior-colors-autocomplete"
                width="75%"
                disabled={registered}
              />
              <ColorBox
                hexCode={vehicleInfo.exteriorColor?.hexCode as string}
                width="20%"
                height={38}
              />
            </BoxContainer>
            {errors.exteriorColor && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.interiorColor}>
            <BoxContainer>
              <Autocomplete
                options={colorList}
                onChangeHandler={(value) => {
                  setVehicleInfo((prevInfo) => {
                    return {
                      ...prevInfo,
                      interiorColor: value as Color,
                    }
                  })
                }}
                placeholder={vehicleInformation.interiorColor}
                value={vehicleInfo.interiorColor}
                disablePortal={false}
                testId="interior-colors-autocomplete"
                width="75%"
                disabled={registered}
              />
              <ColorBox
                hexCode={vehicleInfo.interiorColor?.hexCode as string}
                width="20%"
                height={38}
              />
            </BoxContainer>
            {errors.interiorColor && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleMileage}>
            <Slider
              value={vehicleInfo.vehicleMileage}
              handleValueChange={
                !registered ? handleSliderChange : () => undefined
              }
              maxValue={maxMileage}
              withTextField
              disableInput={registered}
            />
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        previousFunction={handlePrev}
        nextFunction={handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehicleInformation
