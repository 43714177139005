import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  BodyContainerWithHeader,
  LoadingAnimation,
  Stepper,
  useNotification,
} from 'library'

import { BackContainer } from 'components/Common/BackContainer'

import { TEMPLATE_SUB_ROUTES } from 'constants/routes'
import {
  noProviderId,
  TEMPLATE_CREATION_OPTION,
} from 'constants/Template/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/select'
import { Answer } from 'models/services/answers'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  CreateTemplateInputType,
  TemplateStatus,
} from 'models/services/template/creation'
import { IdentityModel, StepModel } from 'models/template'
import {
  TemplateCreationAction,
  TemplateCreationModel,
} from 'models/templateCreation'
import templateCreationReducer from 'reducers/Template/templateCreationReducer'
import {
  createTemplateStepperItems,
  INITIAL_TEMPLATE_CREATION_STEPS as steps,
} from 'utils/Template/creation'

import { GET_ANSWERS } from 'graphQL/Answers/queries'
import { CREATE_TEMPLATE } from 'graphQL/Template/Creation/mutations'
import { GET_INSPECTION_FAILURES_REASONS } from 'graphQL/Template/Creation/queries'

import { StyledBox } from 'styles/operation/creation'

const initialIdentityState: IdentityModel = {
  visibility: true,
  stepsNumber: 1,
  templateName: '',
  providerName: noProviderId,
}

const initialStepCreationState: StepModel[] = []

const initialData: TemplateCreationModel = {
  identity: initialIdentityState,
  stepCreation: initialStepCreationState,
}

const TemplateCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentSecondaryStep, setCurrentSecondaryStep] = useState<number>(0)
  const [reasonOptions, setReasonOptions] = useState<Option[]>([])
  const [answers, setAnswers] = useState<Option[]>([])

  const [isDraft, setIsDraft] = useState<boolean>(false)

  const history = useHistory()
  const { show } = useNotification()

  const [templateData, dispatch] = useReducer(
    templateCreationReducer,
    initialData
  )

  const { text } = useTranslation(textFiles.TEMPLATE_CREATION)
  const { summary } = text
  const stepperItems = createTemplateStepperItems(text.stepper)

  const updateTemplateData = (action: TemplateCreationAction) => {
    dispatch(action)
  }

  const { identity, stepCreation } = templateData

  const [createTemplate, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateTemplateInputType>
  >(CREATE_TEMPLATE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: identity.visibility
          ? summary.templateSuccess
          : summary.draftSuccess,
      })
      history.push(TEMPLATE_SUB_ROUTES.TEMPLATE_LISTING)
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: identity.visibility ? summary.templateFail : summary.draftFail,
      })
    },
  })

  useQuery<GenericData<Option[]>>(GET_INSPECTION_FAILURES_REASONS, {
    onCompleted(response) {
      setReasonOptions(response.data)
    },
  })

  const { loading: answersLoading } = useQuery<GenericData<Answer[]>>(
    GET_ANSWERS,
    {
      onCompleted(response) {
        setAnswers(
          response.data.map((item) => {
            return {
              name: item.name,
              value: item.id,
            }
          })
        )
      },
    }
  )

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleSecondaryContinue = (
    StepCreationData: StepModel,
    shouldMove: boolean
  ) => {
    updateTemplateData({
      type: TEMPLATE_CREATION_OPTION.UPDATE_STEP,
      payload: StepCreationData,
      index: currentSecondaryStep,
    })

    if (shouldMove)
      if (currentSecondaryStep + 1 < templateData.identity.stepsNumber)
        setCurrentSecondaryStep(currentSecondaryStep + 1)
      else setCurrentStep((step) => step + 1)
    else setIsDraft(true)
  }

  const handleSecondaryBack = (StepCreationData: StepModel) => {
    updateTemplateData({
      type: TEMPLATE_CREATION_OPTION.UPDATE_STEP,
      payload: StepCreationData,
      index: currentSecondaryStep,
    })

    if (currentSecondaryStep - 1 >= 0)
      setCurrentSecondaryStep((step) => step - 1)
    else setCurrentStep((step) => step - 1)
  }

  const handleSubmit = useCallback(
    (status?: TemplateStatus) => {
      createTemplate({
        variables: {
          input: {
            name: identity.templateName,
            provider:
              identity.providerName !== noProviderId
                ? identity.providerName
                : undefined,
            status:
              status === TemplateStatus.DRAFT || !identity.visibility
                ? TemplateStatus.DRAFT
                : TemplateStatus.PUBLISHED,
            steps: stepCreation.map((step) => {
              return {
                label: step.name,
                checkpoints: step.checkpoints.map((checkpoint) => {
                  return {
                    label: checkpoint.title,
                    description: checkpoint.description.length
                      ? checkpoint.description
                      : undefined,
                    failureReason: checkpoint.reason,
                    questions: checkpoint.questions.map((question) => {
                      return {
                        label: question.title,
                        answers: checkpoint.standardizeAnswer
                          ? checkpoint.answers
                          : question.answers,
                        isPictureRequired: question.requiresPicture,
                        isRequired: question.isRequired,
                        value: question.score,
                      }
                    }),
                  }
                }),
              }
            }),
          },
        },
      })
    },
    [createTemplate, stepCreation, identity]
  )

  useEffect(() => {
    if (templateData.stepCreation.length > 0 && isDraft) {
      handleSubmit()
      setIsDraft(false)
    }
  }, [templateData, isDraft, handleSubmit])

  if (answersLoading) return <LoadingAnimation />

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.subtitle}
        additionalContainerStyles={{
          justifyContent: 'unset',
        }}
      >
        <Stepper
          currentStep={currentStep}
          currentSecondaryStep={currentSecondaryStep}
          secondaryStepAmount={templateData.identity.stepsNumber}
          stepperItems={stepperItems}
          topSx={{
            justifyContent: 'flex-start',
          }}
        />
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          handleSecondaryContinue,
          handleSecondaryBack,
          currentSecondaryStep,
          templateData,
          reasons: reasonOptions,
          updateTemplateData,
          setSecondaryBack: setCurrentSecondaryStep,
          submitLoading,
          handleSubmit,
          answers,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default TemplateCreationPage
