import { BaseIdEntity } from './services/base'
import { BasicCarType } from './car'

export enum DiscountTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  GENERAL = 'GENERAL',
}

export enum PriorityTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum OfferTypes {
  BRAND = 'BRAND',
  CAR = 'CAR',
  MODEL = 'MODEL',
  PRICE = 'PRICE',
  YEAR = 'YEAR',
}

export enum OfferStatusTypes {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CarIdPopperStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  DUPLICATE = 'DUPLICATE',
}

export type CarDiscountOptionsInput = {
  discount?: number
  id: string
}

export type OfferCreationYears = {
  maxYear: number
  minYear: number
}

export type OfferCarType = {
  certified: boolean
  listingPrice: number
  mainPicture: string
  slug: string
  title: string
  year: number
} & BaseIdEntity

export type OfferModelType = {
  slug: string
} & BasicCarType

export type OfferDataType = {
  description: string
  discountType: DiscountTypes
  discount: number
  logo?: string
  picture: string
  backgroundColor: string
  textColor: string
  label: string
  labelColor: string
  from?: Date | null
  to?: Date | null
  maxPrice?: number
  maxYear?: number
  minPrice?: number
  minYear?: number
  cars: OfferCarType[]
  carIds: string[]
  brands: string[]
  models: string[]
  priority: PriorityTypes
  isActive: boolean
  type: OfferTypes
  carDiscounts: number[]
  carsInInterval?: number
  pictureFile?: File | null
  logoFile?: File | null
} & BaseIdEntity

export type OfferCreationInputType = {
  backgroundColor: string
  brands?: string[]
  cars?: CarDiscountOptionsInput[]
  description: string
  discount?: number
  discountType: DiscountTypes
  from?: string
  label: string
  labelColor: string
  logo: string
  maxPrice?: number
  maxYear?: number
  minPrice?: number
  minYear?: number
  models?: string[]
  picture: string
  priority?: PriorityTypes
  status?: OfferStatusTypes // isActive
  textColor: string // labelColor
  to?: string
  type: OfferTypes
}
