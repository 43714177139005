import { colors } from 'styles/theme'

export type PresetColorType = {
  name: string
  code: string
  id: number
}

export const presetColorsList: PresetColorType[] = [
  {
    name: 'black',
    code: colors.commonBlack,
    id: 1,
  },
  {
    name: 'white',
    code: colors.commonWhite,
    id: 2,
  },
  {
    name: 'gray',
    code: colors.gray,
    id: 3,
  },
  {
    name: 'red',
    code: colors.red,
    id: 4,
  },
  {
    name: 'blue',
    code: colors.orange,
    id: 5,
  },
  {
    name: 'yellow',
    code: colors.yellow,
    id: 6,
  },
  {
    name: 'orange',
    code: colors.orange,
    id: 7,
  },
]
export const colorTranslations = [
  {
    id: 1,
    name: 'Español',
    value: 'es',
    description: 'Gris',
  },
]
