import { styled } from '@mui/material'
import { colors } from 'library/styles/theme'

export const StyledCard = styled('div')({
  backgroundColor: colors.commonWhite,
  borderRadius: '4px',
  border: `6px solid ${colors.background}`,
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  padding: '6px 14px',
  margin: '6px 0px',
  '& > h6': {
    color: colors.commonBlack,
    marginRight: '12px',
  },
  '& > svg': {
    fontSize: '14px',
    cursor: 'pointer',
  },
})
