import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'
import {
  BaseListData,
  BaseSlugType,
  Box,
  checkSearchEmptiness,
  cleanFilters,
  colors,
  Container,
  ExtendedStatus,
  Filter,
  FilterByCriteria,
  FilterCardTextModel,
  FilteringOption,
  FilterInputVariable,
  generateFilterInput,
  GenericData,
  ListingFilterType,
  LoadingAnimation,
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeSearch,
  serializeSortModel,
  UrlParamNames,
  useNotification,
  verifyParam,
} from 'library'

import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'

import { FilterEntryVariableType } from 'constants/Inspection/filterByCriteria'
import { InspectionLogColumnField } from 'constants/Inventory/detail'
import { routes } from 'constants/routes'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import { FiltersByCriteriaTranslationType } from 'models/filtersByCriteria'
import { ListStatus } from 'models/services/inspection/listing'
import { InspectionLogType } from 'models/services/inventory/detail'
import { InspectionStatusMap } from 'models/status'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultInspectionLogField,
} from 'utils/Inventory/detail'

import { GET_INSPECTION_STATUSES } from 'graphQL/Inspection/Listing/queries'
import {
  GET_PROVIDERS,
  LIST_INSPECTIONS_BY_CAR,
} from 'graphQL/Inventory/Detail/queries'

import { StyledTextField } from 'styles/inspection/listing'

type CustomFilterOption = {
  type: FilteringOption[]
}

type InspectionLogsProps = {
  carId: string
}

const InspectionLog = ({ carId }: InspectionLogsProps) => {
  const { show } = useNotification()
  const location = useLocation()
  const [selectedLanguage] = useLocale()
  const {
    text: { inspectionLog: translation },
  } = useTranslation(textFiles.INVENTORY_DETAIL)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const {
    filtersByCriteria,
  }: { filtersByCriteria: FiltersByCriteriaTranslationType } = translation
  const { fieldOrEntryOptions: filterOptionText } = filtersByCriteria

  const fieldOrEntryOptions: Record<string, FilterEntryVariableType> = {
    id: {
      name: filterOptionText.id,
      type: 'string',
    },
    provider: {
      name: filterOptionText.provider,
      type: 'string',
    },
    type: {
      name: filterOptionText.type,
      type: 'string',
    },
    status: {
      name: filterOptionText.status,
      type: 'string',
    },
  }

  const { search } = location
  const defaultSortedFields = [...defaultInspectionLogField].sort()

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )
  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )
  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )
  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize
  )

  const [searchInput, setSearchInput] = useState<string>(searchValue || '')
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )
  const [status, setStatus] = useState<ExtendedStatus[]>([])
  const [showOrigin, setShowOrigin] = useState<boolean>(true)
  const [inspectionCount, setInspectionCount] = useState<number>(0)
  const [data, setData] = useState<GridRowsProp>([])
  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const [customFilterOptions, setCustomFilterOptions] =
    useState<CustomFilterOption>({ type: [] })

  const fieldSelectItems = createFieldSelectItems(translation.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(translation.fieldSelect)

  const { data: inspectionData, loading: inspectionLoading } = useQuery<
    BaseListData<InspectionLogType[]>,
    FilterInputVariable
  >(LIST_INSPECTIONS_BY_CAR, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        limit: Number(pageSize),
        start: (page - 1) * Number(pageSize),
        where: {
          car: carId,
          text_search: searchValue,
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const { data: statusData, loading: statusLoading } = useQuery<
    ListStatus,
    FilterInputVariable
  >(GET_INSPECTION_STATUSES, {
    variables: {
      input: {
        sort: {
          slug: 'asc',
        },
      },
    },
  })

  const { loading: providersLoading } = useQuery<GenericData<BaseSlugType[]>>(
    GET_PROVIDERS,
    {
      onCompleted(response) {
        setCustomFilterOptions((prevCustomFilter) => {
          return {
            ...prevCustomFilter,
            type: response.data.map((provider) => {
              return {
                disabled: false,
                name: provider.name,
                value: provider.slug.toLowerCase(),
              }
            }),
          }
        })
      },
    }
  )

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const submitSearchValue = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const getPageCount = () => {
    return Math.ceil(inspectionCount / pageSize)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const createColumns = useCallback(
    (statuses?: ExtendedStatus[]) => {
      const {
        id: idColumn,
        statusBackend: statusColumn,
        standard: standardColumn,
        provider: providerColumn,
        isoDate: dateColumn,
      } = commonListColumns(
        InspectionStatusMap,
        selectedLanguage,
        generalText,
        undefined,
        undefined,
        statuses
      )

      return [
        { ...idColumn, hide: !selectedFields.includes(idColumn.field) },
        {
          ...dateColumn,
          field: InspectionLogColumnField.CREATED_AT,
          hide: !selectedFields.includes(InspectionLogColumnField.CREATED_AT),
          headerName: translation.table.createdAt,
        },
        {
          ...providerColumn,
          hide: !selectedFields.includes(providerColumn.field),
        },
        {
          ...dateColumn,
          headerName: translation.table.date,
          hide: !selectedFields.includes(dateColumn.field),
        },
        {
          ...standardColumn,
          field: InspectionLogColumnField.TYPE,
          headerName: translation.table.type,
          hide: !selectedFields.includes(InspectionLogColumnField.TYPE),
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Tooltip>{params.row.type ? params.row.type.name : ''}</Tooltip>
            )
          },
        },
        {
          ...statusColumn,
          hide: !selectedFields.includes(statusColumn.field),
        },
      ]
    },
    [selectedLanguage, generalText, selectedFields, translation]
  )

  const [columns, setColumns] = useState<GridColDef[]>(createColumns())

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        if (valueInput === InspectionLogColumnField.SOURCE) setShowOrigin(false)
        return prevFields.filter((field) => field !== valueInput)
      }
      if (valueInput === InspectionLogColumnField.SOURCE) setShowOrigin(true)
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultInspectionLogField)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultInspectionLogField.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
    setShowOrigin(true)
  }

  useEffect(() => {
    if (inspectionData) {
      const { count, data: inspData } = inspectionData.listData
      setData(
        inspData.map((inspection) => {
          return { ...inspection, showOrigin }
        })
      )
      setInspectionCount(count)
    }
  }, [inspectionData, showOrigin])

  useEffect(() => {
    if (statusData) {
      const formattedStatuses = statusData.getInspectionStatuses.map(
        (currentStatus) => ({
          ...currentStatus,
          slug: currentStatus.slug.toLowerCase().replace('_', '-'),
        })
      )
      setColumns(createColumns(formattedStatuses))
      setStatus(formattedStatuses)
    }
  }, [statusData, createColumns])

  if (providersLoading || statusLoading)
    return <LoadingAnimation showAnimation />

  return (
    <Container
      description={`${inspectionCount} ${translation.description}`}
      title={translation.title}
      minHeight="500px"
      noDataBoxStyles={{
        margin: 'auto',
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="flex-end"
        marginBottom="2rem"
        width="100%"
      >
        <Box display="flex" flex="1 0.8 auto" justifyContent="flex-end">
          <Box marginRight="1rem" flex="0 1 430px">
            <StyledTextField
              placeholder={translation.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterInput={filterInput}
              statusList={status}
              staticFields={['type', 'status']}
              options={customFilterOptions}
              filterCardText={
                filtersByCriteria as unknown as FilterCardTextModel
              }
              filtersByCriteriaText={{
                ...filtersByCriteria,
                fieldOrEntryOptions,
              }}
              labels={generalText.filterByCriteria}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        searchValue={searchValue}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={Number(pageSize)}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        route={routes.INSPECTION_DETAIL}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={inspectionLoading}
        checkboxSelection={false}
        inspectionCount={inspectionCount}
        hideDownloadModal
        footerBackgroundColor={colors.commonWhite}
        componentsBackgroundColor={colors.commonWhite}
      />
    </Container>
  )
}

export default InspectionLog
