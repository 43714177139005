import { styled, typographyClasses } from '@mui/material'
import { Button, Container } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledNameSection = styled('section')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: 16,
  width: '100%',
  '> p': {
    marginRight: '0.25rem',
  },
})

export const StyledItem = styled(Container)({
  minHeight: 100,
  color: colors.placeholderGray,
  padding: '0 0.5rem',
  '& > div:nth-of-type(2)': {
    marginTop: '0.5rem',
  },
  '> div': {
    [`> .${typographyClasses.body1}`]: {
      fontSize: 14,
      fontWeight: FONT_WEIGHT.MEDIUM,
    },
  },
})

export const StyledElement = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  fontSize: 14,
  width: '100%',
  fontWeight: FONT_WEIGHT.REGULAR,
  padding: '5px 0px',
  svg: {
    color: colors.orange,
    height: 20,
    width: 20,
    marginRight: '0.5rem',
  },
  span: {
    marginLeft: '0.25rem',
    color: colors.orange,
  },
})

export const StyledButtonContainer = styled('section')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  button: {
    marginLeft: '1rem',
  },
})

export const StyledButton = styled(Button)({
  height: '30px !important',
  fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
})
