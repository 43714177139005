import React from 'react'
import { CSSObject } from '@mui/material'
import defaultAvatarImage from 'library/assets/defaultAvatar.png'

import { StyledAvatar } from './style'

export type AvatarProps = {
  /**
   * Attribute for the rendered img element.
   */
  alt?: string
  /**
   * Classes for avatar
   */
  className?: string
  /**
   * Height of the portrait
   */
  height?: string | number
  /**
   * Source of the component, if not defined it will have a default image
   */
  image?: string | null
  /**
   * Shape of the avatar, use rounded when you don't want completly spherical avatar otherwise use circular or square
   */
  variant?: 'circular' | 'rounded' | 'square'
  /**
   * Width of the portrait
   */
  width?: string | number
  /**
   * Optional styling done to the box of the `<img />`
   */
  style?: CSSObject
}

/**
 * A component that contains a circular image, if the prop for image is not defined it will contain a defaultImage
 */
export const Avatar = ({
  alt,
  className,
  height,
  image,
  variant = 'circular',
  width,
  style,
}: AvatarProps) => {
  return (
    <StyledAvatar
      alt={alt}
      className={className}
      height={height}
      src={image || defaultAvatarImage}
      variant={variant}
      width={width}
      sx={style}
    />
  )
}
