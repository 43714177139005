import React from 'react'
import { BoxProps as MuiBoxProps, CSSObject, Typography } from '@mui/material'
import { LoadingAnimation } from 'library/components/Common/LoadingAnimation'
import { NoDataBox } from 'library/components/General/NoDataBox'

import { StyledBox, StyledContent, StyledTitleContainer } from './style'

export type ContainerProps = {
  /**
   * The content to be displayed inside the box
   */
  children?: React.ReactNode
  /**
   * Inline style to pass to the content container
   */
  contentStyle?: CSSObject
  /**
   * Inline style to pass to the title container
   */
  titleStyle?: CSSObject
  /**
   * Subtitle of the box which is below the title
   */
  description?: string | React.ReactNode
  /**
   * Optional additional text or `React.ReactNode` which goes on the right side of the box
   */
  extraText?: string | React.ReactNode

  /**
   * When `true` the component will display the `<NoDataBox />`
   */
  isEmpty?: boolean

  /**
   * When `true` the component will display a loading animation
   */
  loading?: boolean

  /**
   * Styles for the no data box
   */
  noDataBoxStyles?: CSSObject

  /**
   * The title of the content box
   */
  title?: string
  /**
   * Optional font size of title
   */
  titleSize?: string
  /**
   * Displayed no data text's first part
   */
  firstPartText?: string
  /**
   * Displayed no data text's second part
   */
  secondPartText?: string
  /**
   * Optional styles for description
   */
  descriptionStyle?: CSSObject
} & MuiBoxProps

/**
 * A container that allows to wrap most of our components with
 * a box that contains text
 */
export const Container = ({
  children,
  title,
  description,
  extraText,
  isEmpty,
  contentStyle,
  titleStyle,
  loading,
  noDataBoxStyles,
  firstPartText,
  secondPartText,
  descriptionStyle,
  ...props
}: ContainerProps) => (
  <StyledBox {...props}>
    <StyledTitleContainer sx={{ ...titleStyle }}>
      {title ? <Typography variant="body1">{title}</Typography> : null}

      {extraText && typeof extraText === 'string' ? (
        <Typography variant="subtitle2">{extraText}</Typography>
      ) : null}

      {extraText && typeof extraText !== 'string' ? extraText : null}
    </StyledTitleContainer>

    {description ? (
      <Typography variant="body2" sx={{ ...descriptionStyle }}>
        {description}
      </Typography>
    ) : null}

    <LoadingAnimation showAnimation={loading} />

    {isEmpty && !loading ? (
      <NoDataBox
        boxSx={noDataBoxStyles}
        firstPart={firstPartText}
        secondPart={secondPartText}
      />
    ) : null}

    {children && !loading && !isEmpty ? (
      <StyledContent sx={contentStyle}>{children}</StyledContent>
    ) : null}
  </StyledBox>
)
