import {
  buttonClasses,
  inputBaseClasses,
  selectClasses,
  styled,
  TextField,
} from '@mui/material'
import { Box, Button, Container } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors, FONT_WEIGHT } from 'styles/theme'

export const GridBox = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '23% 23% 23% 23%',
  columnGap: 10,
  rowGap: 20,
  marginBottom: 20,
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 50%',
  },
})

export const StyledContainer = styled(Container)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  position: 'relative',
  padding: 0,
})

export const StyledTextField = styled(TextField)({
  backgroundColor: colors.background,
  width: 255,
  minHeight: 26,
  borderRadius: 4,
  border: 'none',
  div: {
    height: '100%',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: 26,
    width: 255,
    fontSize: '14px',
    padding: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:-internal-autofill-selected': {
      backgroundColor: colors.background,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const SetButton = styled(Button)({
  border: `2px solid ${colors.lightGray} !important`,
  color: `${colors.lightGray} !important`,
  borderRadius: '4px !important',
  height: '26px !important',
  width: 'fit-content !important',
  display: 'flex',
  justifyContent: 'flex-start !important',

  [`.${buttonClasses.endIcon}`]: {
    position: 'absolute',
    right: 10,
  },
})

export const BoolBox = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '45% 45%',
  columnGap: '10px',
})
