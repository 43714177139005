import React, { FunctionComponent } from 'react'
import { InputBaseComponentProps } from '@mui/material'
import { Box } from 'library'

import NumberValueInput from 'components/Inventory/Creation/Common/NumberValueInput'
import { StyledTextField } from 'components/Motives/Stripe/style'

type DiscountBoxProps = {
  editMode: boolean
  id: string
  name: string
  discountValue: number
  handleDiscountValue: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleEditMode: (mode: boolean) => void
  disableEditing: boolean | undefined
  discountField: number
}

const DiscountBox = ({
  editMode,
  id,
  name,
  discountValue,
  handleDiscountValue,
  handleEditMode,
  disableEditing,
  discountField,
}: DiscountBoxProps) => {
  return (
    <>
      {editMode ? (
        <StyledTextField
          id={`${id}-${name}`}
          fullWidth
          sx={{
            width: '200px !important',
            height: '18px',
          }}
          type="text"
          value={discountValue}
          onChange={handleDiscountValue}
          InputProps={{
            inputComponent:
              NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
          }}
          onKeyDownCapture={(e) => {
            if (e.key === 'Enter') {
              handleEditMode(false)
            }
          }}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          onClick={!disableEditing ? () => handleEditMode(true) : undefined}
        >
          {discountField ? discountField.toLocaleString('en-US') : 0}
        </Box>
      )}
    </>
  )
}

export default DiscountBox
