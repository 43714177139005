import React from 'react'
import { Avatar } from 'library/components/Common/Avatar'
import { colors } from 'library/styles/theme'

export type FlagProps = {
  image: string
}

export const Flag = ({ image }: FlagProps) => {
  return (
    <Avatar
      height={20}
      image={image}
      width={32}
      variant="square"
      style={{
        border: `2px solid ${colors.commonWhite}`,
        borderRadius: '4px',
      }}
    />
  )
}
