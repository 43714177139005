import React, { useCallback, useReducer, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  BodyContainerWithHeader,
  createSimpleStepperItems,
  useNotification,
} from 'library'

import { BackContainer } from 'components/Common/BackContainer'

import {
  BLACK_LIST_STATUS,
  BlackListEntityKey,
} from 'constants/Operation/blackList'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  BlackEntity,
  EntityCreationAction,
  EntityCreationModel,
} from 'models/blackListCreation'
import {
  BaseIdEntity,
  GenericData,
  GenericUpdateVariable,
} from 'models/services/base'
import { UpdateBlackListPayload } from 'models/services/operations/blackList'
import entityCreationReducer from 'reducers/blackEntityCreationReducer'
import { INITIAL_ENTITY_CREATION_STEPS as steps } from 'utils/CarSettings/BlackList/creation'

import {
  UPDATE_BLACK_LIST_BRAND,
  UPDATE_BLACK_LIST_MODEL,
  UPDATE_BLACK_LIST_TRIM_LEVEL,
} from 'graphQL/Operations/BlackList/Listing/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialEntityInformationState: BlackEntity = {
  type: '',
  name: '',
  comments: '',
  options: [],
}

const initialData: EntityCreationModel = {
  entityInformation: initialEntityInformationState,
}

const EntityCreationPage = () => {
  const { entity } = useParams<{ entity: string }>()

  const [currentStep, setCurrentStep] = useState<number>(0)

  const [entityData, dispatch] = useReducer(entityCreationReducer, initialData)

  const { text } = useTranslation(textFiles.BLACK_LIST_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { stepper } = text

  const stepperItems = createSimpleStepperItems(stepper)

  const history = useHistory()
  const { show } = useNotification()

  const updateEntityData = (action: EntityCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: generalText.notificationText.deleteFail,
    })
  }

  const entityMutation = {
    make: UPDATE_BLACK_LIST_BRAND,
    model: UPDATE_BLACK_LIST_MODEL,
    trim: UPDATE_BLACK_LIST_TRIM_LEVEL,
  }

  const [updateBlackList, { loading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateBlackListPayload>
  >(entityMutation[entity as BlackListEntityKey], {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(`${routes.BLACK_LIST_LISTING}#${entity}`)
    },
    onError() {
      handleShowError()
    },
  })

  const handleSubmit = useCallback(async () => {
    const {
      entityInformation: { comments, name },
    } = entityData
    updateBlackList({
      variables: {
        input: {
          where: {
            id: name,
          },
          data: {
            blacklistStatus: BLACK_LIST_STATUS.ACTIVE,
            comment: comments || undefined,
          },
        },
      },
    })
  }, [updateBlackList, entityData])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
        currentStep={currentStep}
        stepperItems={stepperItems}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          handleSubmit,
          entityData,
          submitLoading: loading,
          updateEntityData,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default EntityCreationPage
