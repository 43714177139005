import { inputBaseClasses, styled } from '@mui/material'
import { Box } from 'library'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'start',
  width: '100%',
  height: 'fit-content',
  backgroundColor: colors.commonWhite,
})

export const StyledContainer = styled(Box)({
  width: '80%',
  padding: '0.5em 1em',
  height: '90%',
  marginBottom: '5em',
})

export const ContainerStyles = {
  height: '378px',
  width: '657px',
  '& > div:nth-of-type(1)': {
    border: 'none',
    padding: '0px',
    margin: '18px 0px 12px 0px',
    justifyContent: 'space-between',
    borderRadius: '10px',
  },
  '& > div:nth-of-type(2)': {
    borderRadius: '10px',
    border: `1px solid ${colors.border}`,
    boxSizing: 'border-box',
    '& div': {
      borderRadius: '10px',
    },
  },

  '& > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) > div': {
    height: '40px',

    fieldset: {
      border: `1px solid ${colors.border}`,
    },

    '& > div': {
      padding: '6px',
    },

    [`.${inputBaseClasses.input}`]: {
      padding: '4.5px 4px 6.5px 6px',
    },
  },
}
