import { StepperModel } from 'library'

import Identity from 'components/Template/Creation/Identity'
import StepCreation from 'components/Template/Creation/Step'
import TemplateSummary from 'components/Template/Creation/Summary'

import { Option } from 'models/select'
import { TemplateStatus } from 'models/services/template/creation'
import { StepModel } from 'models/template'
import {
  TemplateCreationAction,
  TemplateCreationModel,
} from 'models/templateCreation'
import { StepperTextType } from 'models/text/General'

export type TemplateCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  setSecondaryBack: React.Dispatch<React.SetStateAction<number>>
  handleSecondaryBack: (secondaryStepData: StepModel) => void
  handleSecondaryContinue: (
    secondaryStepData: StepModel,
    shouldMove: boolean
  ) => void
  currentSecondaryStep: number
  templateData: TemplateCreationModel
  reasons: Option[]
  updateTemplateData: (action: TemplateCreationAction) => void
  submitLoading: boolean
  handleSubmit: (status?: TemplateStatus) => void
  answers: Option[]
}

export const INITIAL_TEMPLATE_CREATION_STEPS: Array<
  React.FC<TemplateCreationProps>
> = [Identity, StepCreation, TemplateSummary]

export const createTemplateStepperItems = (
  text: Partial<StepperTextType>
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep as string,
    },
    { label: text.secondStep as string, isDynamicStep: true },
    {
      label: text.thirdStep as string,
    },
  ]

  return stepperItems
}
