import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  useLocalStorage,
  useNotification,
  useSessionStorage,
  validateGraphQLErrorCode,
} from 'library'

import { INVALID_CREDENTIALS } from 'constants/error'
import { ACCESS_TOKEN_KEY, USER_KEY } from 'constants/localStorage'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Role, UserRoles } from 'models/role'
import { LogInUserData, UserInfoData } from 'models/services/user'
import {
  checkOrganization,
  grantAccess,
  grantPermissions,
} from 'utils/BasicUtil'

import { CustomGraphQLError } from 'graphQL/client'
import { LOGIN_USER } from 'graphQL/User/mutations'
import { USER_INFO } from 'graphQL/User/queries'

export enum OrganizationTypeEnum {
  CURBO = 'CURBO',
  DEALER = 'DEALER',
}

export enum OrganizationStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type Organization = {
  id: string
  name: string
  status: OrganizationStatusEnum
  type: OrganizationTypeEnum
}

type UserProviderProps = { children: React.ReactNode }

const emptyRole: Role = {
  area: {
    name: '',
    value: '',
  },
  description: '',
  value: '',
  name: '',
  slug: UserRoles.CLIENT,
}

export const emptyOrganization: Organization = {
  id: '',
  name: '',
  status: OrganizationStatusEnum.INACTIVE,
  type: OrganizationTypeEnum.CURBO,
}

export type User = {
  address: string
  email: string
  id: string
  lastName: string
  name: string
  profilePicture: string
  roles: Role[]
  telephoneNumber: string
  organization: Organization
}

const emptyUser: User = {
  address: '',
  email: '',
  id: '',
  lastName: '',
  name: '',
  profilePicture: '',
  roles: [emptyRole],
  telephoneNumber: '',
  organization: emptyOrganization,
}

type signInInput = {
  email: string
  password: string
}

export type UserContextType = {
  user: User | undefined
  handleUpdateUser: (user: User) => void
  handleSignOut: () => void
  handleSignIn: (input: signInInput) => void
  accessToken: string
  userRoles: UserRoles[]
  userSignInLoading: boolean
  errorMessage: string | null
  validateAllowedRoles: (allowedRoles: UserRoles[]) => boolean
}

export const UserContext = React.createContext<UserContextType | undefined>(
  undefined
)

const UserProvider = ({ children }: UserProviderProps) => {
  const { show } = useNotification()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [persistedUser, setPersistedUser] = useSessionStorage<User>(
    USER_KEY,
    emptyUser
  )

  const [accessToken, setAccessToken] = useLocalStorage<string>(
    ACCESS_TOKEN_KEY,
    ''
  )
  const [user, setUser] = useState<User | undefined>(undefined)
  const history = useHistory()
  const [fetchUser, { called }] = useLazyQuery<UserInfoData>(USER_INFO, {
    onCompleted({ self }) {
      const newUser = {
        ...self,
      }
      if (
        !checkOrganization(newUser) ||
        !grantAccess(newUser.roles.map((role) => role.slug))
      ) {
        history.push(routes.UNAUTHORIZED_ERROR)
      }
      setUser(newUser)
      setPersistedUser(newUser)
    },
  })
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [logInUser, { called: signInCalled, reset }] =
    useMutation<LogInUserData>(LOGIN_USER, {
      onCompleted(data) {
        const { user: signedUser, accessToken: responseAccessToken } =
          data.signInUser

        if (
          checkOrganization(signedUser) &&
          grantAccess(signedUser.roles.map((role) => role.slug))
        ) {
          setAccessToken(responseAccessToken)
          setPersistedUser(signedUser)
          history.push(routes.HOME)
        } else {
          history.push(routes.UNAUTHORIZED_ERROR)
        }
        setLoading(false)
        setErrorMessage(null)
      },
      onError(error) {
        const { graphQLErrors } = error
        const customGraphQLErrors = graphQLErrors as CustomGraphQLError[]
        if (!customGraphQLErrors || !error) return

        const { errorExists } = validateGraphQLErrorCode(
          error,
          INVALID_CREDENTIALS
        )
        if (errorExists) {
          setErrorMessage(generalText.notificationText.badCredentials)
          setTimeout(() => {
            setErrorMessage(null)
          }, 2500)
        } else if (customGraphQLErrors.length > 0) {
          show({
            updatedSeverity: 'error',
            message: customGraphQLErrors[0].message,
          })
        }
        setLoading(false)
        console.error('ERROR FROM LOGIN', error)
      },
    })

  const handleSignOut = () => {
    setUser(undefined)
    setPersistedUser(emptyUser)
    if (typeof window !== undefined) {
      window.localStorage.removeItem(ACCESS_TOKEN_KEY)
    }
    reset()
    history.push(routes.LOGIN)
  }

  const handleSignIn = (userData: signInInput) => {
    const { email, password } = userData
    setLoading(true)
    logInUser({
      variables: {
        userLogVars: {
          email,
          password,
        },
      },
    })
  }

  const handleUpdateUser = (newUser: User) => {
    setUser(newUser)
    setPersistedUser(newUser)
  }

  const userRoles = useMemo(() => {
    return user?.roles.map((userRole) => userRole.slug) || []
  }, [user?.roles])

  const validateAllowedRoles = useCallback(
    (allowedRoles: UserRoles[]) => {
      const checkPermission = grantPermissions(userRoles, allowedRoles)

      return checkPermission
    },
    [userRoles]
  )

  useEffect(() => {
    if (persistedUser.name.length > 0 && !user) {
      setUser(persistedUser)
    }
  }, [persistedUser, user])

  useEffect(() => {
    if (accessToken.length === 0 && user && user.name.length > 0) {
      setUser(undefined)
      setPersistedUser(emptyUser)
    }
  }, [accessToken, user, setPersistedUser])

  useEffect(() => {
    if (
      accessToken.length > 0 &&
      !called &&
      !signInCalled &&
      user === undefined
    ) {
      fetchUser()
    }
  }, [accessToken, fetchUser, called, user, signInCalled])

  useEffect(() => {
    if (persistedUser.name.length > 0 && !user) {
      setUser(persistedUser)
    }
  }, [persistedUser, user])

  useEffect(() => {
    if (accessToken.length === 0 && user && user.name.length > 0) {
      setUser(undefined)
      setPersistedUser(emptyUser)
    }
  }, [accessToken, user, setPersistedUser])

  useEffect(() => {
    if (
      accessToken.length > 0 &&
      !called &&
      !signInCalled &&
      user === undefined
    ) {
      fetchUser()
    }
  }, [accessToken, fetchUser, called, user, signInCalled])

  const value: UserContextType = {
    user,
    handleUpdateUser,
    handleSignOut,
    handleSignIn,
    accessToken,
    userRoles,
    userSignInLoading: loading,
    errorMessage,
    validateAllowedRoles,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserProvider
