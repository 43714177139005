export enum OfferColumnField {
  ID = 'id',
  CREATED_AT = 'createdAt',
  LABEL = 'label',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export const inactiveHash = '#inactive'

export enum OfferDetailColumnField {
  ID = 'ID',
  SLUG = 'carSlug',
  PICTURE = 'picture',
  VEHICLE = 'slug',
  PRICE = 'price',
  DISCOUNT = 'discount',
  NET_PRICE = 'netPrice',
  STATUS = 'status',
  ACTIONS = 'actions',
}
