import CountryVersionInfo from 'components/CarSettings/CountryVersion/Creation/CountryVersionInfo'
import Summary from 'components/CarSettings/CountryVersion/Creation/Summary'

import {
  CountryVersionCreationAction,
  CountryVersionCreationModel,
} from 'models/countryVersionCreation'

export type CountryVersionCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  countryVersionData: CountryVersionCreationModel
  updateCountryVersionData: (action: CountryVersionCreationAction) => void
}

export const INITIAL_COUNTRY_VERSION_CREATION_STEPS: Array<
  React.FC<CountryVersionCreationProps>
> = [CountryVersionInfo, Summary]
