import React from 'react'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import { colors } from 'library/styles/theme'

export const renderWeekPickerDay = (
  date: Date,
  selectedDates: Array<Date | null>,
  pickersDayProps: PickersDayProps<Date>
) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        color: colors.commonBlack,
        backgroundColor: 'transparent',
        fontSize: '12px',
        ':hover': {
          backgroundColor: colors.orange,
          color: colors.commonWhite,
        },
        ':focus': {
          backgroundColor: colors.orange,
        },
        ':target': {
          backgroundColor: colors.orange,
        },
        '& button:hover': {
          backgroundColor: colors.orange,
          color: colors.commonWhite,
        },
        '&.MuiPickersDay-dayOutsideMonth': {
          backgroundColor: 'transparent',
          color: colors.gray,
          cursor: 'auto',
        },
        '&.MuiPickersDay-dayOutsideMonth:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
          backgroundColor: colors.orange,
          color: `${colors.commonWhite} !important`,
        },
        '&.Mui-selected:hover': {
          backgroundColor: colors.orange,
          color: `${colors.commonWhite} !important`,
        },
        '&.MuiPickersDay-root:focus.Mui-selected': {
          backgroundColor: colors.orange,
          color: `${colors.commonWhite} !important`,
        },
      }}
      disableHighlightToday
      showDaysOutsideCurrentMonth
    />
  )
}
