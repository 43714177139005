import React from 'react'
import { Typography } from '@mui/material'
import { Cell, Pie, PieChart as RcPieChart, Tooltip } from 'recharts'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { ResponsiveStyledContainer, StyledPieLabel } from './style'

/**
 ** Color Variations Presets for the PieChart
 */
const pieChartColors = {
  'blue-accents': [
    '#B6E8F0',
    '#06C8E7',
    '#A6CEE3',
    colors.orange,
    colors.orange,
    colors.aquaBlue,
  ],
  green: [colors.green, colors.lightGray],
  yellow: [colors.yellow, colors.lightGray],
  blue: [colors.orange, colors.lightGray],
  red: [colors.red, colors.lightGray],
}

/**
 ** Data for creating the Chart
 */
export type DataProps = {
  id: number
  name: string
  value: number
}

export type LabelProps = {
  /**
   ** Small subtitle inside the Pie Chart
   */
  label?: string

  /**
   ** Big percentage number inside the Pie Chart
   */
  percentage?: string

  /**
   * Variant modifies the fontsize of the percentage label inside the chart
   */

  variant?: string

  color?: string | string[]
}

/**
 ** Size of the Pie Chart
 */
type SizeProps = {
  height: number
  width: number
}

type PieChartProps = {
  /**
   ** Data to be passed to the Pie Chart
   */
  data: DataProps[]

  /**
   ** Labels to be shown inside the Pie Chart
   */
  labelData?: LabelProps

  /**
   ** Width and Height of the Pie Chart
   */
  size: SizeProps

  /**
   ** List of color presets for the Pie Chart
   ** You can pass custom colors via string[] of Hex Color Strings
   */
  color?: 'blue' | 'yellow' | 'green' | 'red' | 'blue-accents' | string[]

  /**
   * Optional variant. The default size of a Pie Chart is for 300px+ of width.
   * This other variant is meant for smaller sizes
   */

  variant?: string
}

export const getColors = (
  color: 'blue' | 'yellow' | 'green' | 'red' | 'blue-accents' | string[]
) => {
  if (typeof color === 'string') {
    return pieChartColors[color]
  }

  return color
}

const PieChartLabel = ({ label, percentage, variant, color }: LabelProps) => {
  return (
    <StyledPieLabel
      sx={{
        top: variant === 'small' ? '33%' : '42%',
      }}
    >
      <Typography
        variant={variant === 'small' ? 'h5' : 'h1'}
        color={color ? color[0] : undefined}
        fontWeight={FONT_WEIGHT.BOLD}
      >
        {percentage}%
      </Typography>
      <Typography
        variant="body1"
        color={color ? color[0] : undefined}
        fontWeight="normal"
      >
        {label}
      </Typography>
    </StyledPieLabel>
  )
}

const PieChart = ({
  data,
  labelData,
  size,
  color = 'blue-accents',
  variant,
}: PieChartProps) => {
  const colorsVariation = getColors(color)

  return (
    <div
      style={{
        width: size.width,
        height: size.height,
        margin: 'auto',
      }}
    >
      <ResponsiveStyledContainer>
        <>
          <PieChartLabel
            label={labelData?.label}
            percentage={labelData?.percentage}
            variant={variant}
            color={colorsVariation}
          />
          <RcPieChart width={size.width} height={size.height}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={variant === 'small' ? 50 : 90}
              outerRadius={variant === 'small' ? 63 : 110}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${entry.id}`}
                  fill={colorsVariation[index % colorsVariation.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </RcPieChart>
        </>
      </ResponsiveStyledContainer>
    </div>
  )
}

export default PieChart
