import React from 'react'
import { Container } from 'library'

import RelatedVehicleCard from 'components/Inventory/Common/RelatedVehicleCard'

import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { RelatedCar } from 'models/services/inventory/detail'

import { GridContainer } from './style'

type RelatedVehiclesProps = {
  relatedCars: RelatedCar[]
}

const RelatedVehicles = ({ relatedCars }: RelatedVehiclesProps) => {
  const {
    text: { relatedVehicles: translation },
  } = useTranslation(textFiles.INVENTORY_DETAIL)

  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''

  return (
    <Container
      description={translation.description}
      title={translation.title}
      minHeight="500px"
      noDataBoxStyles={{
        margin: 'auto',
      }}
      isEmpty={relatedCars.length === 0}
    >
      <GridContainer>
        {relatedCars.map((car) => (
          <RelatedVehicleCard
            car={car}
            linkText={translation.detailLink}
            priceCurrency={priceCurrency}
            key={car.id}
          />
        ))}
      </GridContainer>
    </Container>
  )
}

export default RelatedVehicles
