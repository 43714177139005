import { styled } from '@mui/material'
import { Box, Button, colors, Container } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'

export const StyledBrandImg = styled('img')({
  alignSelf: 'center',
  objectFit: 'contain',
  height: '100%',
  width: '100%',
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
})

export const StyledEditButton = styled(Button)({
  marginTop: '14px',
  width: '180px',
  height: '35px',
})

export const GridBox = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '31% 34% 34% !important',
  columnGap: '15px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 50% !important',
    columnGap: '10px',
    rowGap: '0px',
  },
})

export const ImgContainer = styled(Container)({
  width: '100%',
  // height: 600, (height for vertical image carousel to be further implemented)
  padding: '0',
  paddingTop: '1rem !important',
})

export const DetailsHeader = styled(Container)({
  width: '95%',
  padding: '0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  '> div:last-child': {
    width: '100%',
    height: '50px',
    marginTop: '0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export const StyledImg = styled('img')({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
})

export const StyledBorder = styled(Box)({
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  textAlign: 'left',
  padding: '18px',
  width: 'auto',
  margin: '16px 0px',

  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    marginTop: '0',
  },
})

export const StyledBox = styled(Box)({
  color: colors.black,
  backgroundColor: colors.background,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-start',
  borderRadius: '10px',
  paddingLeft: '43px',
  height: '100%',
})

export const StyledButton = styled('label')({
  width: '36px',
  height: '37px',
  position: 'absolute',
  bottom: '5%',
  right: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    color: colors.gray,
  },
})

export const StyledButtonContainer = styled(Box)({
  justifyContent: 'end',
  gap: '0px 15px',
  button: {
    display: 'inherit',
    position: 'static',
    span: {
      display: 'none',
    },
  },
})
