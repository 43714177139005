import { gql } from '@apollo/client'

export const GET_TRIM_BY_ID = gql`
  query getTrimLevelById($input: ID!) {
    data: getTrimLevelById(id: $input) {
      alias
      backHeadRoom
      backLegRoom
      bodyStyle {
        name
        value: id
      }
      cargo
      cargoCapacity
      cylinders
      doors
      driveTrain {
        name
        value: id
      }
      engineDisplacement
      features {
        name
        value: id
      }
      frontHeadRoom
      frontLegRoom
      fuelCapacity
      fuelType {
        name
        value: id
      }
      height
      horsePower
      horsePowerRpm
      length
      model {
        name
        value: id
        brand {
          name
          value: id
          icon
        }
      }
      mpg
      mpgCity
      mpgHgw
      name
      price
      seats
      status
      torque
      torqueRpm
      transmission {
        name
        value: id
      }
      width
      year
    }
  }
`
export const GET_COLORS = gql`
  query getColors($input: FilterInput) {
    data: getColors(input: $input) {
      value: id
      name
      createdAt
      hexCode
      status
    }
  }
`

export const GET_MODELS = gql`
  query getModels($input: FilterInput) {
    data: getModels(input: $input) {
      value: id
      name
      brand {
        id
      }
    }
  }
`

export const GET_TRANSMISSIONS = gql`
  query getTransmissions($input: FilterInput) {
    data: getTransmissions(input: $input) {
      value: id
      name
    }
  }
`

export const GET_BODY_STYLES = gql`
  query getBodyStyles($input: FilterInput) {
    data: getBodyStyles(input: $input) {
      value: id
      name
    }
  }
`

export const GET_DRIVE_TRAINS = gql`
  query getDriveTrains($input: FilterInput) {
    data: getDriveTrains(input: $input) {
      value: id
      name
    }
  }
`

export const GET_FUEL_TYPES = gql`
  query getFuelTypes($input: FilterInput) {
    data: getFuelTypes(input: $input) {
      value: id
      name
    }
  }
`

export const GET_FEATURES = gql`
  query getFeatures($input: FilterInput) {
    data: getFeatures(input: $input) {
      value: id
      name
    }
  }
`

export const GET_CATEGORIES = gql`
  query getCategories($input: FilterInput) {
    data: getCategories(input: $input) {
      value: id
      name
    }
  }
`

export const GET_COUNTRY_VERSIONS = gql`
  query getCountryVersions($input: FilterInput) {
    data: getCountryVersions(input: $input) {
      value: id
      name
    }
  }
`
export const GET_TRIM_LEVELS = gql`
  query getTrimLevels($input: FilterInput) {
    data: getTrimLevels(input: $input) {
      value: id
      name
    }
  }
`
