import React, { useEffect, useState } from 'react'
import FlagIcon from '@mui/icons-material/Flag'
import { Box } from 'library'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import { StyledContainer } from 'components/CarSettings/Transmission/Detail/Information/style'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { TranslationDetailDataType } from 'models/services/CarSettings/detail'
import { getInitialNameTranslation } from 'utils/translation'

type CountryVersionInfoProps = {
  countryVersion: TranslationDetailDataType
  handleSave: (
    name: string | undefined,
    translations: TranslationItemType[]
  ) => Promise<boolean>
  submitLoading: boolean
}

const CountryVersionInformation = ({
  countryVersion,
  handleSave,
  submitLoading,
}: CountryVersionInfoProps) => {
  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >([])
  const { text } = useTranslation(textFiles.COUNTRY_VERSION_DETAIL)
  const { general, countryVersionInformation: translation } = text
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const { name, nameTranslations } = countryVersion
  const initialNameInformation: NameInformation[] = [
    {
      id: '1',
      icon: <FlagIcon />,
      name,
      label: general.title,
    },
  ]
  const [informationRows, setInformationRows] = useState<NameInformation[]>(
    initialNameInformation
  )

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setInformationRows(newInformation)
    setHasUnsavedChanges(true)
  }
  const resetState = () => {
    setAddedTranslations(getInitialNameTranslation(nameTranslations))
    setInformationRows(initialNameInformation)
    setHasUnsavedChanges(false)
  }

  const handleChangeTranslation = (translations: TranslationItemType[]) => {
    setAddedTranslations(translations)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    const newName =
      informationRows.length > 0 ? informationRows[0].name : undefined

    const isUpdateSuccesful = await handleSave(
      newName === name ? undefined : String(newName),
      addedTranslations
    )
    if (isUpdateSuccesful) {
      setHasUnsavedChanges(false)
    }
  }

  useEffect(() => {
    setAddedTranslations(getInitialNameTranslation(nameTranslations))
  }, [nameTranslations])

  return (
    <StyledContainer>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        sx={{
          '> div': {
            padding: '15px 20px',
          },
        }}
      >
        <NameContainer
          title={translation.information}
          description={translation.informationDescription}
          information={informationRows}
          handleSetInformation={handleChangeInformation}
          loading={submitLoading}
          width={399}
        />
        <Translation
          addedTranslations={addedTranslations}
          handleChangeTranslation={handleChangeTranslation}
          translationDescription={translation.translationsDescription}
        />
        <SaveChangesBlock
          handleSaveChanges={handleSaveChanges}
          resetState={resetState}
          submitLoading={submitLoading}
          shouldRender={hasUnsavedChanges}
        />
      </Box>
      {/* <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        paddingBottom={width && width <= BREAK_POINTS.XL ? '1rem' : undefined}
      >
        <LogContainer>
          <HistoryLog
            height="200px"
            dateFormat="time"
            hasPadding={!!(width && width <= BREAK_POINTS.XL)}
          />
        </LogContainer>
      </Box> */}
    </StyledContainer>
  )
}

export default CountryVersionInformation
