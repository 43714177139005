import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Typography } from '@mui/material'
import {
  Avatar,
  Button,
  ErrorMessage,
  maxAllowedSizePerFileInKb,
} from 'library'

import {
  cypressAddPictureButton,
  cypressAddPictureInput,
} from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledContainer } from '../style'

type AvatarProps = {
  title: string
  description?: string
  picture: string | null
  handlePictureChange: React.Dispatch<React.SetStateAction<string>>
  handlePictureStateChange?: (picture: string) => void
  handleFileChange?:
    | React.Dispatch<React.SetStateAction<File | null>>
    | ((file: File | null) => void)
  handleFileStateChange?: (file: File) => void
  size?: 'small' | 'medium' | 'large'
  buttonText?: string
  disabled?: boolean
  id?: string
  additionalButtonStyles?: React.CSSProperties
  showPreview?: boolean
}

/*
This component is used in any of the information step of a setting creation process, 
includes a container with text adapted to its name and an avatar input
*/

const AvatarContainer = ({
  title,
  description,
  picture,
  handlePictureChange,
  handlePictureStateChange,
  handleFileChange,
  handleFileStateChange,
  size,
  buttonText,
  disabled,
  id = 'profile-picture-input',
  additionalButtonStyles,
  showPreview = true,
}: AvatarProps) => {
  const [fileSizeError, setFileSizeError] = useState<boolean>(false)
  const { text: translation } = useTranslation(textFiles.BRAND_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const { buttons } = translation
  const [fileName, setFileName] = useState<string>('')

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const { files } = e.target
    const file = files && files.length > 0 ? files[0] : null

    if (file && file.size <= maxAllowedSizePerFileInKb) {
      setFileSizeError(false)
      reader.onload = () => {
        if (reader.readyState === 2) {
          handlePictureChange(String(reader.result))
          if (handlePictureStateChange)
            handlePictureStateChange(String(reader.result))
        }
      }
      if (file) {
        if (handleFileChange) handleFileChange(file)
        if (handleFileStateChange) handleFileStateChange(file)
        reader.readAsDataURL(file)
        setFileName(file ? file.name : '')
      }
    } else if (file && file.size > maxAllowedSizePerFileInKb) {
      setFileSizeError(true)
    }
  }

  return (
    <>
      <StyledContainer
        title={title}
        description={description}
        contentStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
        sx={{
          ...additionalButtonStyles,
        }}
      >
        <Button
          variant="text"
          role="button"
          sx={{
            fontWeight: FONT_WEIGHT.MEDIUM,
            border: `2px solid ${
              !disabled ? colors.orange : colors.gray
            } !important`,
            padding: '0px !important',
            width: 'auto',
            ...additionalButtonStyles,
          }}
          size={size}
          disabled={disabled}
          testId={cypressAddPictureButton}
        >
          <label
            htmlFor={id}
            style={{
              cursor: 'pointer',
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              margin: '0 10px',
            }}
          >
            <AddIcon />
            {buttonText || buttons.file}
          </label>
        </Button>
        <input
          type="file"
          name="input"
          id={id}
          accept="image/*"
          style={{ display: 'none' }}
          onChange={imageHandler}
          disabled={disabled}
          data-cy={cypressAddPictureInput}
        />
        {picture && showPreview ? (
          <div style={{ marginTop: '1rem' }}>
            <Avatar height={95} width={95} variant="rounded" image={picture} />
          </div>
        ) : null}
        {picture && !showPreview && (
          <Typography variant="subtitle1">{fileName}</Typography>
        )}
      </StyledContainer>
      {fileSizeError ? (
        <ErrorMessage
          text={generalText.fileSize.sizeError.replace('%d', 3)}
          sx={{ marginTop: '5px' }}
        />
      ) : null}
    </>
  )
}

export default AvatarContainer
