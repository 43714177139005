import Summary from 'components/CarSettings/Transmission/Creation/Summary'
import TransmissionInfo from 'components/CarSettings/Transmission/Creation/TransmissionInfo'

import {
  TransmissionCreationAction,
  TransmissionCreationModel,
} from 'models/transmissionCreation'

export type TransmissionCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  transmissionData: TransmissionCreationModel
  updateTransmissionData: (action: TransmissionCreationAction) => void
}

export const INITIAL_TRANSMISSION_CREATION_STEPS: Array<
  React.FC<TransmissionCreationProps>
> = [TransmissionInfo, Summary]
