import { styled } from '@mui/material'
import { Button } from 'library'

import { FONT_WEIGHT } from 'styles/theme'

export const StyledButtonContainer = styled('section')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
})

export const StyledButton = styled(Button)({
  fontSize: `14px !important`,
  height: `38px !important`,
  fontWeight: FONT_WEIGHT.MEDIUM,
})
