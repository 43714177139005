import { OfferColumnField } from 'constants/Marketing/offer'
import { SelectItem } from 'models/select'
import { OfferFieldSelectTextType } from 'models/text/offerListing'

export const createFieldSelectItems = (
  text: OfferFieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: OfferColumnField.ID,
    },
    {
      label: text.label,
      value: OfferColumnField.LABEL,
    },
    {
      label: text.createdAt,
      value: OfferColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: OfferColumnField.STATUS,
    },
    {
      label: text.actions,
      value: OfferColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: OfferFieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    label: text.label,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
  }
}

export const defaultFields = [
  OfferColumnField.ID,
  OfferColumnField.LABEL,
  OfferColumnField.CREATED_AT,
  OfferColumnField.STATUS,
  OfferColumnField.ACTIONS,
]
