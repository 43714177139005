import { Box, styled } from '@mui/material'
import { Button, TextField } from 'library'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.commonWhite,
})

export const StyledChartContainer = styled(Box)({
  marginTop: '32px',
  marginBottom: '60px',
  display: 'flex',
  width: '450px',
  backgroundColor: colors.background,
  borderRadius: '10px',
  svg: {
    overflow: 'visible',
  },
})

export const StyledProgressContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 30px',
})

export const StyledTextField = styled(TextField)({
  width: '70px',
  height: '40px',
  marginBottom: '22px',
  input: {
    padding: '10px 14px',
  },
})
export const StyledButton = styled(Button)({
  width: '155px',
  height: '35px',
})
