import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Button, Modal } from 'library'

import { cypressBackButton } from 'constants/cypress'
import { DealerContact } from 'models/services/operations/dealer'

import DealerForm from './DealerForm'
import { BottomContainer, StyledBox } from './style'

export type DealerEditModalProps = {
  open: boolean
  onConfirmHandler: () => void
  header: string
  confirmButtonText: string
  dealerContacts: DealerContact[]
  handleSubmit: (dealerContact: DealerContact[]) => Promise<boolean>
}

const DealerEditModal = ({
  open,
  onConfirmHandler,
  header,
  confirmButtonText,
  dealerContacts,
  handleSubmit,
}: DealerEditModalProps) => {
  return (
    <Modal
      open={open}
      size="customDimension"
      customDimension={{
        width: 562,
        height: 445,
      }}
      handleClose={() => {
        onConfirmHandler()
      }}
    >
      <StyledBox>
        <DealerForm
          dealerContacts={dealerContacts}
          header={header}
          handleSubmit={handleSubmit}
        />
        <BottomContainer>
          <Button
            size="small"
            style={{
              width: 'auto',
              minWidth: 159,
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
            }}
            onClick={onConfirmHandler}
            testId={cypressBackButton}
          >
            <ArrowBackIosIcon />
            {confirmButtonText}
          </Button>
        </BottomContainer>
      </StyledBox>
    </Modal>
  )
}

export default DealerEditModal
