import React from 'react'
import { TextFieldProps, Typography } from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledTextField } from './style'

const getTextStyle = (editType: 'bold' | 'normal'): React.CSSProperties => {
  const isBolded = editType === 'bold'

  const style: React.CSSProperties = {
    fontWeight: isBolded ? FONT_WEIGHT.BOLD : FONT_WEIGHT.MEDIUM,
    fontSize: isBolded ? '14px' : '12px',
    color: isBolded ? colors.black : colors.gray,
  }

  return style
}

type EditableTextProps = {
  edit: boolean
  editType?: 'bold' | 'normal'
  formatFunction?: (text: string) => string
} & TextFieldProps

const EditableText = ({
  edit,
  editType = 'bold',
  formatFunction,
  ...props
}: EditableTextProps) => {
  const { value, sx } = props
  const stringValue = String(value)
  const formatValue = formatFunction ? formatFunction(stringValue) : stringValue

  const textStyle = getTextStyle(editType)

  return !edit ? (
    <Typography
      variant="body1"
      sx={{
        ...textStyle,
        ...sx,
      }}
    >
      {formatValue}
    </Typography>
  ) : (
    <StyledTextField
      value={value}
      {...props}
      variant="standard"
      sx={{
        input: {
          ...textStyle,
        },
        ...sx,
      }}
    />
  )
}

export default EditableText
