import React from 'react'
import { formatPhoneNumber } from 'library'

import InformationCell from 'components/Inventory/Common/InformationCell'

import { Contact } from 'models/services/curboSpot'

type DealerRepresentativeProps = {
  dealerContacts: Contact[]
  nameLabel: string
  phoneLabel: string
}

const DealerRepresentative = ({
  dealerContacts,
  nameLabel,
  phoneLabel,
}: DealerRepresentativeProps) => {
  return dealerContacts.length > 0 ? (
    <>
      <InformationCell label={nameLabel} value={dealerContacts[0].agent} />
      <InformationCell
        label={phoneLabel}
        value={formatPhoneNumber(dealerContacts[0].telephoneNumber)}
      />
    </>
  ) : null
}

export default DealerRepresentative
