import React from 'react'
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material'

import { StyledCheckboxIcon, StyledLabel } from './style'

export type CheckboxProps = {
  /**
   *  Whether the checkbox is ticked or not.
   */
  checked: boolean

  /**
   *  Text that goes along with the checkbox
   */
  label: string

  /**
   *  Cypress test ID for the entire label
   */
  testId?: string

  /**
   *  Label first
   */
  labelFirst?: boolean
} & MuiCheckboxProps

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  label,
  disableRipple = true,
  testId,
  labelFirst = false,
  ...props
}) => (
  <StyledLabel
    sx={{
      display: 'flex',
      flexDirection: labelFirst ? 'row-reverse' : 'row',
      marginLeft: labelFirst ? '0' : '-11px',
      width: 'fit-content',
    }}
    control={
      <MuiCheckbox
        checked={checked}
        disableRipple={disableRipple}
        icon={<StyledCheckboxIcon />}
        {...props}
      />
    }
    label={label}
    data-cy={testId}
  />
)
