import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import {
  Box,
  DetailNavTab,
  generateTabItems,
  LoadingAnimation,
  NavBarItem,
  TabPanel,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'

import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import StatusChangePopper from 'components/CarSettings/Common/Detail/StatusChangePopper'
import Information from 'components/CarSettings/Trim/Detail/Information'
import Mileage from 'components/CarSettings/Trim/Detail/Mileage'
import Settings from 'components/CarSettings/Trim/Detail/Settings'
import Spacing from 'components/CarSettings/Trim/Detail/Spacing'
import Tag from 'components/Common/Tag'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES, routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/select'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  TrimDetailDataType,
  UpdateGenericInput,
  UpdateTrimInputType,
} from 'models/services/CarSettings/detail'
import { PublicationStatus } from 'models/status'
import { PictureOption } from 'models/trim'
import { validateClone } from 'utils/CarSettings/Trim/detail'

import { GET_BRANDS } from 'graphQL/Operations/Model/Creation/queries'
import {
  PUBLISH_TRIM_LEVEL,
  UNPUBLISH_TRIM_LEVEL,
  UPDATE_TRIM_LEVEL,
} from 'graphQL/Operations/Trim/Detail/mutations'
import {
  GET_BODY_STYLES,
  GET_DRIVE_TRAINS,
  GET_FEATURES,
  GET_FUEL_TYPES,
  GET_TRANSMISSIONS,
  GET_TRIM_BY_ID,
} from 'graphQL/Operations/Trim/Detail/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const TrimDetailPage = () => {
  const { carSettingsId } = useParams<{ carSettingsId: string }>()

  const [tab, setTab] = useState<number>(0)
  const [status, setStatus] = useState<PublicationStatus>(
    PublicationStatus.UNPUBLISHED
  )

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const [trim, setTrim] = useState<TrimDetailDataType | null>(null)
  const [brands, setBrands] = useState<PictureOption[]>([])
  const [transmissions, setTransmissions] = useState<Option[]>([])
  const [bodyStyles, setBodyStyles] = useState<Option[]>([])
  const [driveTrains, setDriveTrains] = useState<Option[]>([])
  const [featuresList, setFeaturesList] = useState<Option[]>([])
  const [fuelTypes, setFuelTypes] = useState<Option[]>([])

  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const { general: translation } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()
  const history = useHistory()
  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading: trimLoading } = useQuery<
    GenericData<TrimDetailDataType>,
    GenericInputVariable<string>
  >(GET_TRIM_BY_ID, {
    variables: {
      input: carSettingsId,
    },
    onCompleted(response) {
      const { data } = response
      setTrim({
        ...data,
        alias: data.alias || 'N/A',
        price: data.price || 1,
      })
      setStatus(response.data.status)
    },
    onError(error) {
      setApolloError(error)
    },
    context: {
      noTranslation: true,
    },
  })

  const { loading: brandLoading } = useQuery<GenericData<PictureOption[]>>(
    GET_BRANDS,
    {
      onCompleted(response) {
        setBrands(response.data.filter((brand) => brand.models.length > 0))
      },
    }
  )

  const { loading: bodyStyleLoading } = useQuery<GenericData<Option[]>>(
    GET_BODY_STYLES,
    {
      onCompleted(response) {
        setBodyStyles(response.data)
      },
    }
  )

  const { loading: driveTrainLoading } = useQuery<GenericData<Option[]>>(
    GET_DRIVE_TRAINS,
    {
      onCompleted(response) {
        setDriveTrains(response.data)
      },
    }
  )

  const { loading: featureLoading } = useQuery<GenericData<Option[]>>(
    GET_FEATURES,
    {
      onCompleted(response) {
        setFeaturesList(response.data)
      },
    }
  )

  const { loading: fuelTypeLoading } = useQuery<GenericData<Option[]>>(
    GET_FUEL_TYPES,
    {
      onCompleted(response) {
        setFuelTypes(response.data)
      },
    }
  )

  const { loading: transmissionLoading } = useQuery<GenericData<Option[]>>(
    GET_TRANSMISSIONS,
    {
      onCompleted(response) {
        setTransmissions(response.data)
      },
    }
  )

  const [updateTrim, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateGenericInput<UpdateTrimInputType>>
  >(UPDATE_TRIM_LEVEL, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: translation.updateFail,
        })
    },
    refetchQueries: [GET_TRIM_BY_ID],
  })

  const [publishTrimLevel, { loading: publishTrimLevelLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(PUBLISH_TRIM_LEVEL)

  const [unpublishTrimLevel, { loading: unpublishTrimLevelLoading }] =
    useMutation<GenericData<BaseIdEntity>, GenericInputVariable<string>>(
      UNPUBLISH_TRIM_LEVEL
    )

  const updateStatusMutations = {
    [PublicationStatus.PUBLISHED]: publishTrimLevel,
    [PublicationStatus.UNPUBLISHED]: unpublishTrimLevel,
  }

  const handleSave = async (data: TrimDetailDataType) => {
    try {
      const dataObject: {
        [key: string]: number | string | undefined | string[] | null
      } = {}
      for (const [key, value] of Object.entries(data)) {
        if (value === undefined || value === null) {
          dataObject[key] = undefined
        } else if (
          typeof value === 'string' &&
          key !== '__typename' &&
          key !== 'status'
        ) {
          dataObject[key] = String(value)
        } else if (typeof value === 'number') {
          dataObject[key] = Number(value)
        } else if (key === 'features') {
          const featuresField = value as Option[]
          dataObject[key] =
            featuresField.map((item) => item.value as string) || []
        } else {
          const optionField = value as Option
          if (key === 'model') {
            dataObject.carModel = optionField.value
          } else {
            dataObject[key] = optionField.value as string
          }
        }
      }

      const clone = validateClone(dataObject)

      const response = await updateTrim({
        variables: {
          input: {
            where: {
              id: carSettingsId,
            },
            data: {
              ...clone,
            },
          },
        },
      })
      if (response.errors) return false
      return true
    } catch {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.error,
      })
      return false
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleStatusChange = (newStatus: PublicationStatus) => {
    if (newStatus !== status) {
      updateStatusMutations[newStatus]({
        variables: {
          input: carSettingsId,
        },
        onError() {
          show({
            updatedSeverity: 'error',
            message: translation.updateFail,
          })
        },
        onCompleted() {
          show({
            updatedSeverity: 'success',
            message: translation.updateSuccess,
          })
          setStatus(newStatus)
        },
        refetchQueries: [GET_TRIM_BY_ID],
      })
    }
  }

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })
      setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs])

  const settingsOptions = {
    transmissions,
    bodyStyles,
    driveTrains,
    featuresList,
    fuelTypes,
  }

  const isLoading =
    trimLoading ||
    bodyStyleLoading ||
    driveTrainLoading ||
    featureLoading ||
    fuelTypeLoading ||
    brandLoading ||
    transmissionLoading

  const isUpdating =
    submitLoading || publishTrimLevelLoading || unpublishTrimLevelLoading

  if (isLoading) return <LoadingAnimation showAnimation={isLoading} />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return trim ? (
    <Layout>
      <StyledLink to={CAR_SETTINGS_SUB_ROUTES.TRIM_LISTING}>
        <Typography
          variant="h3"
          color={colors.orange}
        >{`< ${translation.backButton}`}</Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{carSettingsId}
        </Typography>
        <Tag status={status} />
        <StatusChangePopper status={status} setStatus={handleStatusChange} />
      </Box>
      <DetailSubHeader text={translation.description} />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={initialItems}
      />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          {!brands.length ? (
            <LoadingAnimation showAnimation={!brands.length} />
          ) : (
            <Information
              information={trim}
              handleSave={handleSave}
              submitLoading={isUpdating}
              brands={brands}
            />
          )}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Settings
            information={trim}
            options={settingsOptions}
            handleSave={handleSave}
            submitLoading={isUpdating}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Spacing
            information={trim}
            handleSave={handleSave}
            submitLoading={isUpdating}
          />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Mileage
            information={trim}
            handleSave={handleSave}
            submitLoading={isUpdating}
          />
        </TabPanel>
        {/* <TabPanel value={tab} index={4}>
          <History />
        </TabPanel> */}
      </ContentContainer>
    </Layout>
  ) : null
}

export default TrimDetailPage
