import { styled } from '@mui/material'
import { Box } from 'library'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  backgroundColor: colors.commonWhite,
})

export const StyledContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})
