import DriveTrainInfo from 'components/CarSettings/DriveTrain/Creation/DriveTrainInfo'
import Summary from 'components/CarSettings/DriveTrain/Creation/Summary'

import {
  DriveTrainCreationAction,
  DriveTrainCreationModel,
} from 'models/driveTrainCreation'

export type DriveTrainCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  driveTrainData: DriveTrainCreationModel
  updateDriveTrainData: (action: DriveTrainCreationAction) => void
}

export const INITIAL_DRIVE_TRAIN_CREATION_STEPS: Array<
  React.FC<DriveTrainCreationProps>
> = [DriveTrainInfo, Summary]
