import React from 'react'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import { Typography } from '@mui/material'
import { CHANGES_TYPE_ENUM } from 'library/constants/dashboard'
import { StatusAndPercent } from 'library/models/services/dashboard'
import { colors } from 'library/styles/theme'

import {
  IconContainer,
  InspectionsChangeText,
  InspectorStatsBox,
  StyledBoxContainer,
} from './style'

export type InspectionsStatisticsTextType = {
  total: string
  inspectionsPrev: string
  positive: string
  approved: string
  neutral: string
  negative: string
  rejected: string
  registered: string
  active: string
  inactive: string
  POSITIVE: string
  NEGATIVE: string
  NEUTRAL: string
}

const ChangeIcons: Record<CHANGES_TYPE_ENUM, React.ReactNode> = {
  [CHANGES_TYPE_ENUM.POSITIVE]: (
    <KeyboardArrowUpRoundedIcon sx={{ fontSize: 60 }} />
  ),
  [CHANGES_TYPE_ENUM.NEGATIVE]: (
    <KeyboardArrowDownRoundedIcon sx={{ fontSize: 60 }} />
  ),
  [CHANGES_TYPE_ENUM.NEUTRAL]: <RemoveRoundedIcon sx={{ fontSize: 60 }} />,
}

const StatisticColors: Record<CHANGES_TYPE_ENUM, string> = {
  [CHANGES_TYPE_ENUM.POSITIVE]: colors.green,
  [CHANGES_TYPE_ENUM.NEGATIVE]: colors.red,
  [CHANGES_TYPE_ENUM.NEUTRAL]: colors.yellow,
}

export type PreviousChanges = {
  approved: StatusAndPercent
  rejected: StatusAndPercent
  total: StatusAndPercent
}

export type InspectionsChangeProps = {
  changes: PreviousChanges
  translation: InspectionsStatisticsTextType
}
/**
 ** The Inspections on Change container with the three boxes.
 */
export const InspectionsChange = ({
  changes,
  translation,
}: InspectionsChangeProps) => {
  const { approved, rejected, total } = changes
  return (
    <StyledBoxContainer>
      <InspectorStatsBox width="155px" paddingTop="25px">
        <Typography
          variant="h2"
          color={StatisticColors[total.status]}
          fontSize="32px"
        >
          {parseFloat(total.percentage.toFixed(2))}%
          <IconContainer>{ChangeIcons[total.status]}</IconContainer>
        </Typography>
        <InspectionsChangeText
          variant="body2"
          color={StatisticColors[total.status]}
        >
          {translation[total.status]}
        </InspectionsChangeText>
        <Typography variant="body2" marginTop="5px" marginBottom="10px">
          {translation.total}
        </Typography>
      </InspectorStatsBox>
      <InspectorStatsBox width="155px" paddingTop="25px">
        <Typography
          variant="h2"
          color={StatisticColors[approved.status]}
          fontSize="32px"
        >
          {parseFloat(approved.percentage.toFixed(2))}%
          <IconContainer>{ChangeIcons[approved.status]}</IconContainer>
        </Typography>
        <InspectionsChangeText
          variant="body2"
          color={StatisticColors[approved.status]}
        >
          {translation[approved.status]}
        </InspectionsChangeText>
        <Typography variant="body2" marginTop="5px" marginBottom="10px">
          {translation.approved}
        </Typography>
      </InspectorStatsBox>
      <InspectorStatsBox width="155px" paddingTop="25px">
        <Typography
          variant="h2"
          color={StatisticColors[rejected.status]}
          fontSize="32px"
        >
          {parseFloat(rejected.percentage.toFixed(2))}%
          <IconContainer>{ChangeIcons[rejected.status]}</IconContainer>
        </Typography>
        <InspectionsChangeText
          variant="body2"
          color={StatisticColors[rejected.status]}
        >
          {translation[rejected.status]}
        </InspectionsChangeText>
        <Typography variant="body2" marginTop="5px" marginBottom="10px">
          {translation.rejected}
        </Typography>
      </InspectorStatsBox>
    </StyledBoxContainer>
  )
}
