import { styled } from '@mui/material'
import { Box, colors } from 'library'

export const BoardContainer = styled(Box)({
  display: 'flex',
  height: '650px',
  backgroundColor: colors.commonWhite,
  overflow: 'auto',
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '0.4em',
    height: '0.4em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.orange,
    borderRadius: '5px',
  },
})
