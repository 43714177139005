import { BaseColumnField } from 'constants/CarSettings/columnField'
import { SelectItem } from 'models/select'
import {
  BaseSelectTextType,
  ExtendedBaseSelectTextType,
} from 'models/text/carSettingsListing'

export const createBaseFieldSelectItems = (
  text: BaseSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: BaseColumnField.ID,
    },
    {
      label: text.name,
      value: BaseColumnField.NAME,
    },
    {
      label: text.createdAt,
      value: BaseColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: BaseColumnField.STATUS,
    },
    {
      label: text.actions,
      value: BaseColumnField.ACTIONS,
    },
  ]
}

export const createBaseFieldSelectLabels = (
  text: BaseSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
  }
}

export const createExtendedBaseFieldSelectItems = (
  text: ExtendedBaseSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.picture,
      value: BaseColumnField.PICTURE,
    },
    {
      label: text.id,
      value: BaseColumnField.ID,
    },
    {
      label: text.name,
      value: BaseColumnField.NAME,
    },
    {
      label: text.createdAt,
      value: BaseColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: BaseColumnField.STATUS,
    },
    {
      label: text.actions,
      value: BaseColumnField.ACTIONS,
    },
  ]
}

export const createExtendedBaseFieldSelectLabels = (
  text: ExtendedBaseSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    picture: text.picture,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
  }
}

export const baseDefaultFields = [
  BaseColumnField.ID,
  BaseColumnField.NAME,
  BaseColumnField.CREATED_AT,
  BaseColumnField.STATUS,
  BaseColumnField.ACTIONS,
]

export const extendedDefaultFields = [
  BaseColumnField.ID,
  BaseColumnField.NAME,
  BaseColumnField.CREATED_AT,
  BaseColumnField.STATUS,
  BaseColumnField.ACTIONS,
  BaseColumnField.PICTURE,
]
