import { Container, styled } from '@mui/material'
import { Box } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.black,
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  margin: '1rem 0',

  svg: {
    color: colors.orange,
    width: '31px',
    height: '35px',
  },
})

export const BottomContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
  background: colors.background,
  height: 90,
  paddingTop: 30,
  paddingBottom: 30,

  svg: {
    color: `${colors.commonWhite} !important`,
    width: '15px',
    height: '18px',
    marginRight: '10px',
  },
})

export const StyledList = styled('ul')({
  height: '150px',
  overflow: 'auto',
  width: '100%',

  li: {
    color: colors.lightRed,
  },
})
