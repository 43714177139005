import React, { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { CSSObject } from '@mui/material'
import { Box, Container } from 'library'

import {
  StyledBox,
  StyledContainer,
  StyledIcon,
} from 'components/CarSettings/Common/Detail/NameContainer/style'

import { NameInformation } from 'models/car'
import { Option } from 'models/select'
import { PictureOption } from 'models/trim'

import { colors } from 'styles/theme'

import ItemContainer from './ItemContainer'

type Props = {
  height?: number | string
  width?: number | string
  containerSx?: CSSObject
  loading?: boolean
  translation: Record<string, string>
  brands: PictureOption[]
  information: NameInformation[]
  handleSetInformation?: (information: NameInformation[]) => void
}

const NameContainer = ({
  translation,
  height,
  width,
  containerSx,
  information,
  loading,
  brands,
  handleSetInformation,
}: Props) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [info, setInfo] = useState<NameInformation[]>(information)

  const handleEdit = () => {
    if (!edit) {
      setEdit(true)
    } else if (handleSetInformation) {
      const isEmpty = info.some((item) => String(item.name).length < 1)
      if (!isEmpty) {
        handleSetInformation(info)
      }
      setEdit(false)
    }
  }

  useEffect(() => {
    setInfo(information)
  }, [edit, information])

  useEffect(() => {
    const currentBrand = brands.find((brand) => brand.value === info[0].name)
    const newModels: Option[] = currentBrand!.models
    setInfo((prevState) => {
      const newInfo = [...prevState]
      const isModelOfBrand = newModels.some(
        (newModel) => newModel.value === info[1].name
      )
      const newOptions = {
        ...newInfo[1],
        name: isModelOfBrand ? info[1].name : newModels[0].value,
        options: newModels,
      }
      newInfo[1] = newOptions
      return newInfo
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info[0].name])

  return (
    <Container
      title={translation.title}
      description={translation.description}
      sx={containerSx}
      width={width}
    >
      <StyledBox height={height}>
        <StyledContainer height={height}>
          {!edit ? (
            <StyledIcon disabled={loading} onClick={handleEdit}>
              <EditIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
            </StyledIcon>
          ) : (
            <>
              <StyledIcon
                onClick={handleEdit}
                sx={{ right: '50px !important' }}
              >
                <SaveIcon
                  sx={{ color: colors.placeholderGray, fontSize: 16 }}
                />
              </StyledIcon>
              <StyledIcon onClick={() => setEdit(false)}>
                <ClearIcon
                  sx={{ color: colors.placeholderGray, fontSize: 16 }}
                />
              </StyledIcon>
            </>
          )}
          {info.map((item, index) => (
            <Box
              key={item.id}
              display="flex"
              width="auto"
              marginBottom={index !== info.length - 1 ? '12px' : 0}
            >
              <Box justifyContent="center" paddingTop="4px" marginRight="24px">
                {item.icon}
              </Box>
              <ItemContainer
                item={item}
                handleInfo={setInfo}
                edit={edit}
                index={index}
              />
            </Box>
          ))}
        </StyledContainer>
      </StyledBox>
    </Container>
  )
}

export default NameContainer
