import {
  InspectionSlugStatusEnum,
  InspectionStatusEnum,
  InspectorStatusEnum,
  InventoryStatusEnum,
  PublicationStatusEnum,
  StatusStyling,
  TemplateStatusEnum,
  UserStatusEnum,
} from 'library/models/statusTypes'
import { colors } from 'library/styles/theme'

export const PENDING_STATUS = 'pending'
export const INACTIVE_STATUS = 'inactive'
export const ACTIVE_STATUS = 'active'

export const StatusStyles: StatusStyling = {
  [InspectionStatusEnum.PENDING]: {
    color: colors.yellow,
    backgroundColor: colors.lightYellow,
  },
  [InspectionStatusEnum.SCHEDULED]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [InspectionStatusEnum.IN_PROCESS]: {
    color: colors.darkBlue,
    backgroundColor: colors.backgroundDarkBlue,
  },
  [InspectionStatusEnum.APPROVED]: {
    color: colors.green,
    backgroundColor: colors.lightGreen,
  },
  [InspectionStatusEnum.REJECTED]: {
    color: colors.red,
    backgroundColor: colors.lightPink,
  },
  [InspectionStatusEnum.FOR_REPAIR]: {
    color: colors.black,
    backgroundColor: colors.backgroundGray,
  },
  [InspectionStatusEnum.ABORTED]: {
    color: colors.pink,
    backgroundColor: colors.lightPink,
  },
  [InspectionStatusEnum.PATCHING_UP]: {
    color: colors.purple,
    backgroundColor: colors.lightPurple,
  },
  [InspectorStatusEnum.ACTIVE]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [InspectorStatusEnum.INACTIVE]: {
    color: colors.red,
    backgroundColor: colors.lightPink,
  },
  [TemplateStatusEnum.PUBLISHED]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [TemplateStatusEnum.DRAFT]: {
    color: colors.red,
    backgroundColor: colors.lightPink,
  },
  [PublicationStatusEnum.PUBLISHED]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [PublicationStatusEnum.UNPUBLISHED]: {
    color: colors.red,
    backgroundColor: colors.backgroundRed,
  },
  [InventoryStatusEnum.AVAILABLE]: {
    color: colors.green,
    backgroundColor: colors.lightGreen,
  },
  [InventoryStatusEnum.NOT_AVAILABLE]: {
    color: colors.red,
    backgroundColor: colors.backgroundRed,
  },
  [InventoryStatusEnum.COMING_SOON]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [InventoryStatusEnum.PENDING]: {
    color: colors.yellow,
    backgroundColor: colors.lightYellow,
  },
  [InventoryStatusEnum.SOLD]: {
    color: colors.darkBlue,
    backgroundColor: colors.backgroundBlue,
  },
  [InventoryStatusEnum.TO_REVIEW]: {
    color: colors.yellow,
    backgroundColor: colors.lightYellow,
  },
  [UserStatusEnum.ACTIVE]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [UserStatusEnum.CANCELLED]: {
    color: colors.red,
    backgroundColor: colors.backgroundRed,
  },
}

export const completedStatus: string[] = [
  InspectionSlugStatusEnum.APPROVED,
  InspectionSlugStatusEnum.ABORTED,
  InspectionSlugStatusEnum.FOR_REPAIR,
  InspectionSlugStatusEnum.REJECTED,
  InspectionSlugStatusEnum.PATCHING_UP,
]
