import React from 'react'
import { SxProps, Typography } from '@mui/material'
import { colors } from 'library/styles/theme'

import { Box } from '../../Common/Box'

export type DetailStatusTagProps = {
  backgroundColor?: string
  color?: string
  text?: string
  boxStyle?: SxProps
  textStyle?: SxProps
}

export const DetailStatusTag = ({
  backgroundColor,
  color,
  text,
  boxStyle,
  textStyle,
}: DetailStatusTagProps) => {
  return (
    <Box sx={boxStyle}>
      <Typography
        variant="body2"
        sx={{
          width: '120px',
          height: '37px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          userSelect: 'none',
          border: `2px solid ${colors.commonWhite}`,
          color,
          backgroundColor,
          textAlign: 'center',
          ...textStyle,
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}
