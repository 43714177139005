import { CurboSpotColumnField } from 'constants/Operation/curboSpot'
import { SelectItem } from 'models/select'
import { FieldSelectTextType } from 'models/text/curboSpotListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: CurboSpotColumnField.ID,
    },
    {
      label: text.name,
      value: CurboSpotColumnField.NAME,
    },
    {
      label: text.state,
      value: CurboSpotColumnField.STATE,
    },
    {
      label: text.city,
      value: CurboSpotColumnField.CITY,
    },
    {
      label: text.telephoneNumber,
      value: CurboSpotColumnField.TELEPHONE_NUMBER,
    },
    {
      label: text.status,
      value: CurboSpotColumnField.STATUS,
    },
    {
      label: text.createdAt,
      value: CurboSpotColumnField.CREATED_AT,
    },
    {
      label: text.actions,
      value: CurboSpotColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
    state: text.state,
    city: text.city,
    telephoneNumber: text.telephoneNumber,
  }
}

export const defaultFields = [
  CurboSpotColumnField.ID,
  CurboSpotColumnField.NAME,
  CurboSpotColumnField.STATE,
  CurboSpotColumnField.CITY,
  CurboSpotColumnField.TELEPHONE_NUMBER,
  CurboSpotColumnField.STATUS,
  CurboSpotColumnField.CREATED_AT,
  CurboSpotColumnField.ACTIONS,
]
