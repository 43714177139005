import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { ButtonNavigator } from 'library'
import * as yup from 'yup'

import CheckpointComponent from 'components/Template/Creation/Step/Checkpoint'

import { cypressSaveDraftButton } from 'constants/cypress'
import { buildCheckpoint, buildStep } from 'constants/Template/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { FormValuesType, StepModel } from 'models/template'
import { TemplateCreationProps } from 'utils/Template/creation'

import { FONT_WEIGHT } from 'styles/theme'

import { ContentBox, StyledContainer, StyledForm } from '../Identity/style'

import {
  StepTitle,
  StyledButton,
  StyledNumber,
  StyledTextField,
  StyledTextFieldContainer,
} from './style'

const mapStepToTemplate = (data: FormValuesType): StepModel => {
  const stepCheckpoints = data.checkpoints.map((check) => {
    return {
      ...check,
      score: 100,
    }
  })
  return {
    id: data.id,
    name: data.stepName,
    description: data.stepDescription,
    checkpoints: stepCheckpoints,
  }
}

const StepCreation = ({
  handleSecondaryBack,
  currentSecondaryStep,
  handleSecondaryContinue,
  templateData,
  reasons,
  submitLoading,
  answers,
}: TemplateCreationProps) => {
  const stepInitialValue = buildStep(
    currentSecondaryStep,
    reasons[0].value as string
  )
  const defaultCheckpoint = buildCheckpoint(reasons[0].value as string)

  const [stepInfo, setStepInfo] = useState<StepModel>(stepInitialValue)
  const [shouldContinue, setShouldContinue] = useState<boolean>(true)

  const { text } = useTranslation(textFiles.TEMPLATE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { step, buttons } = text

  const {
    step: { checkpoints: checkpointText, placeholders },
  } = text

  const validationSchema = yup.object({
    stepName: yup.string().required(validationText.fieldRequired),
    stepDescription: yup
      .string()
      .min(5, validationText.errorMessageMinCharacters.replace('%d', '5')),
    checkpoints: yup.array().of(
      yup.object().shape({
        title: yup.string().required(validationText.fieldRequired),
        reason: yup.string().required(validationText.fieldRequired),
        description: yup
          .string()
          .required(validationText.fieldRequired)
          .min(5, validationText.errorMessageMinCharacters.replace('%d', '5')),
        standardizeAnswer: yup.boolean(),
        answers: yup.array().when('standardizeAnswer', {
          is: (standardizeAnswer: boolean) => standardizeAnswer,
          then: yup
            .array()
            .min(1, validationText.errorRequiredItems.replace('%d', 1)),
          otherwise: yup.array(),
        }),
        questions: yup.array().of(
          yup.object().shape({
            title: yup.string().required(validationText.fieldRequired),
            isRequired: yup.boolean(),
            requiresPicture: yup.boolean(),
            score: yup
              .number()
              .min(0, validationText.greaterOrEqualThanZero)
              .max(100, validationText.lessOrEqualThan.replace('%d', '100')),
            answers: yup.array().test(
              'standardAnswer',
              validationText.errorRequiredItems.replace('%d', 1),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (value, context: any) => {
                const standardAnswer = context.from[1].value.standardizeAnswer
                if (value && !standardAnswer && value.length === 0) return false
                return true
              }
            ),
          })
        ),
      })
    ),
  })

  const formik = useFormik<FormValuesType>({
    initialValues: {
      stepName: stepInfo.name,
      stepDescription: stepInfo.description,
      ...stepInfo,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      handleSecondaryContinue(mapStepToTemplate(values), shouldContinue)
    },
  })

  const handlePrev = () => {
    handleSecondaryBack(stepInfo)
  }

  const handleStandardizeAnswer = (
    isAnswerStandard: boolean,
    index: number
  ) => {
    formik.setFieldValue(
      `checkpoints.${index}.standardizeAnswer`,
      isAnswerStandard
    )
  }

  useEffect(() => {
    if (
      typeof templateData.stepCreation[currentSecondaryStep] === 'undefined'
    ) {
      setStepInfo(stepInitialValue)
    } else setStepInfo(templateData.stepCreation[currentSecondaryStep])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSecondaryStep, templateData.stepCreation])

  return (
    <StyledContainer>
      <FormikProvider value={formik}>
        <StyledForm onSubmit={formik.handleSubmit}>
          <ContentBox
            sx={{
              flexDirection: 'column',
              marginBottom: 0,
              flexWrap: 'unset',
              gap: '0px',
            }}
          >
            <StyledNumber>{currentSecondaryStep + 1}</StyledNumber>
            <StepTitle>
              <StyledButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: -10,
                }}
                variant="text"
                onClick={() => {
                  setShouldContinue(false)
                  formik.handleSubmit()
                }}
                testId={cypressSaveDraftButton}
              >
                {buttons.saveDraft}
              </StyledButton>

              <StyledTextFieldContainer
                title={step.titleName}
                description={step.subtitleName}
                contentStyle={{ flexDirection: 'column', marginLeft: '2px' }}
              >
                <StyledTextField
                  variant="outlined"
                  id="stepName"
                  name="stepName"
                  placeholder={placeholders.template}
                  value={formik.values.stepName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.stepName && Boolean(formik.errors.stepName)
                  }
                  helperText={formik.touched.stepName && formik.errors.stepName}
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                title={step.titleDescription}
                description={step.subtitleDescription}
              >
                <StyledTextField
                  variant="outlined"
                  name="stepDescription"
                  placeholder={placeholders.descriptionMinimun}
                  value={formik.values.stepDescription}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.stepDescription &&
                    Boolean(formik.errors.stepDescription)
                  }
                  helperText={
                    formik.touched.stepDescription &&
                    formik.errors.stepDescription
                  }
                  inputProps={{
                    style: {
                      fontSize: '12px',
                      fontWeight: FONT_WEIGHT.MEDIUM,
                    },
                  }}
                  sx={{ height: 113 }}
                  minRows={5}
                  multiline
                />
              </StyledTextFieldContainer>
            </StepTitle>
            <StyledTextFieldContainer
              title={checkpointText.title}
              description={checkpointText.subtitle}
              contentStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start !important',
                width: '100%',
              }}
            >
              <FieldArray
                name="checkpoints"
                render={(arrayHelpers) => (
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    {formik.values.checkpoints.length > 0 &&
                      formik.values.checkpoints.map((checkpoint, index) => (
                        <CheckpointComponent
                          key={`checkpoint${String(
                            index
                          )}${currentSecondaryStep}`}
                          checkpointIndex={index}
                          stepNumber={currentSecondaryStep}
                          checkpoint={checkpoint}
                          arrayHelpers={arrayHelpers}
                          formik={formik}
                          reasons={reasons}
                          answers={answers}
                          handleStandardizeAnswer={handleStandardizeAnswer}
                        />
                      ))}
                    <StyledButton
                      startIcon={<AddIcon />}
                      size="small"
                      sx={{
                        marginBottom: '5px',
                      }}
                      onClick={() => {
                        const { push: checkpointPush } = arrayHelpers
                        checkpointPush(defaultCheckpoint)
                      }}
                      testId="add-checkpoint-button"
                    >
                      {checkpointText.addCheckpoint}
                    </StyledButton>
                  </Box>
                )}
              />
            </StyledTextFieldContainer>
          </ContentBox>
          <ButtonNavigator
            previousFunction={handlePrev}
            nextFunction={formik.handleSubmit}
            loading={submitLoading}
          />
        </StyledForm>
      </FormikProvider>
    </StyledContainer>
  )
}

export default StepCreation
