import BodyStyleInfo from 'components/CarSettings/BodyStyle/Creation/BodyStyleInfo'
import Summary from 'components/CarSettings/BodyStyle/Creation/Summary'

import {
  BodyStyleCreationAction,
  BodyStyleCreationModel,
} from 'models/bodyStyleCreation'

export type BodyStyleCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  bodyStyleData: BodyStyleCreationModel
  updateBodyStyleData: (action: BodyStyleCreationAction) => void
}

export const INITIAL_BODY_STYLE_CREATION_STEPS: Array<
  React.FC<BodyStyleCreationProps>
> = [BodyStyleInfo, Summary]
