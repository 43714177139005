import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { Typography } from '@mui/material'
import { Box, ExtendedAvatar } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledContainer } from './styles'

type CardProps = {
  image?: string
  name: string
  spot: string
}

const InspectorCard = ({ image, name, spot }: CardProps) => {
  return (
    <StyledContainer>
      <ExtendedAvatar
        variant="circular"
        image={image}
        sx={{ width: 'unset', height: 'unset' }}
      />
      <Box
        display="flex"
        flexDirection="column"
        width="auto"
        marginLeft="0.5rem"
      >
        <Typography
          variant="body2"
          fontWeight={FONT_WEIGHT.MEDIUM}
          color={colors.black}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          fontWeight={FONT_WEIGHT.MEDIUM}
          color={colors.orange}
          alignItems="center"
          display="flex"
        >
          <DirectionsCarIcon sx={{ marginRight: '5px' }} />
          {spot}
        </Typography>
      </Box>
    </StyledContainer>
  )
}

export default InspectorCard
