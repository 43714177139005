import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import { Box, CSSObject } from '@mui/material'
import { saveAs } from 'file-saver'
import defaultAvatarImage from 'library/assets/defaultAvatar.png'

import { AvatarPopper, AvatarPopperTextType } from './AvatarPopper'
import { StyledAvatar, StyledButton, StyledSection } from './style'

export type ExtendedAvatarProps = {
  /**
   * Attribute for the rendered img element.
   */
  alt?: string
  /**
   * Classes for avatar
   */
  className?: string
  /**
   * Height of the portrait
   */
  height?: string | number
  /**
   * Source of the component, if not defined it will have a default image
   */
  image?: string
  /**
   * Shape of the avatar, use rounded when you don't want completly spherical avatar otherwise use circular or square
   */
  variant?: 'circular' | 'rounded' | 'square'
  /**
   * Width of the portrait
   */
  width?: string | number
  /**
   * Optional styling done to the box of the `<img />`
   */
  style?: CSSObject
  /**
   * Optional styling done to the Section component
   */
  sx?: CSSObject
  /**
   * Prop that indicates whether or not a download option should be rendered within the Avatar
   */
  isDownloadable?: boolean

  /**
   * Prop that indicates whether or not a image should be draggable
   */
  isDraggable?: boolean

  /**
   * Optional prop that renders a delete button, calling the following method
   */

  onDelete?: () => void
  translation?: AvatarPopperTextType
}

/**
 * A component that contains a circular image, if the prop for image is not defined it will contain a defaultImage
 */
export const ExtendedAvatar = ({
  alt,
  className,
  height,
  image = defaultAvatarImage,
  variant = 'circular',
  width,
  style,
  isDownloadable,
  isDraggable = true,
  onDelete,
  sx,
  translation,
}: ExtendedAvatarProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClosePopper = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    if (onDelete) onDelete()
    setAnchorEl(null)
  }

  const handleOpenModal = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  return (
    <>
      <StyledSection sx={sx}>
        <StyledAvatar
          alt={alt}
          className={className}
          height={height}
          src={image}
          variant={variant}
          width={width}
          sx={style}
          imgProps={{
            draggable: isDraggable ? 'true' : 'false',
          }}
        />

        {isDownloadable && !onDelete && (
          <StyledButton
            onClick={() => saveAs(image || ' ', 'sampleImage')}
            sx={{
              right: '10%',
            }}
            disableRipple
          >
            <DownloadIcon />
          </StyledButton>
        )}
        {onDelete && !isDownloadable && (
          <StyledButton onClick={handleOpenModal} disableRipple>
            <CloseIcon />
          </StyledButton>
        )}

        {onDelete && isDownloadable && (
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              right: '5%',
              bottom: '5%',
            }}
          >
            <StyledButton
              onClick={() =>
                saveAs(
                  image || ' ',
                  (image &&
                    `image.${image.substring(image.lastIndexOf('.') + 1)}`) ||
                    ''
                )
              }
              sx={{
                position: 'static',
                bottom: 'auto',
                right: 'auto',
                marginRight: '10px',
              }}
              style={{ width: '30px', height: '30px' }}
              disableRipple
            >
              <DownloadIcon />
            </StyledButton>

            <StyledButton
              onClick={handleOpenModal}
              sx={{ position: 'static', bottom: 'auto', right: 'auto' }}
              style={{ width: '30px', height: '30px' }}
              disableRipple
            >
              <CloseIcon />
            </StyledButton>
          </Box>
        )}
      </StyledSection>
      {onDelete && (
        <AvatarPopper
          anchorEl={anchorEl}
          handleClosePopper={handleClosePopper}
          handleConfirmRemove={handleDelete}
          open={open}
          translation={translation}
        />
      )}
    </>
  )
}
