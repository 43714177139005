import React from 'react'
import { CSSObject, Modal as MUIModal } from '@mui/material'
import { Button } from 'library/components/Common/Button'

import { backdropStyle, BottomContainer, CardStyler } from './style'

type BottomButtonsActionType = {
  /**
   * Modal Card bottom left button function
   */
  primaryFunction: () => void
  /**
   * Modal Card bottom right button function
   */
  secondaryFunction: () => void
  /**
   * Modal Card bottom left button
   */
  leftButtonText: string
  /**
   * Modal Card bottom left icon
   */
  leftIcon?: JSX.Element
  /**
   * Modal Card bottom right button
   */
  rightButtonText: string
}

export type ModalProps = {
  /**
   * Function to handle the close function
   */
  handleClose?: () => void
  /**
   ** Modal Card style
   */
  cardStyle?: CSSObject | null
  /**
   ** Modal backdrop enables
   */
  hiddenBackdrop?: boolean
  /**
   ** Modal Optional Bottom Container
   */
  enableBottom?: BottomButtonsActionType
  /**
   ** Boolean to toggle open and close states of the Modal
   */
  open: boolean
  /**
   * Size variations for ease of use
   */
  size?: 'small' | 'medium' | 'large' | 'customDimension'
  /**
   * Content inside the card
   */
  children: JSX.Element
  /**
   *  Custom size of the modal
   */
  customDimension?: { width: number; height: number }
}

const Modal: React.FC<ModalProps> = ({
  open,
  handleClose,
  size = 'small',
  cardStyle = null,
  enableBottom,
  hiddenBackdrop = false,
  customDimension,
  children,
}: ModalProps) => {
  const StyledCard = CardStyler(cardStyle, size, customDimension)
  return (
    <MUIModal
      hideBackdrop={hiddenBackdrop}
      open={open}
      onClose={handleClose}
      BackdropProps={backdropStyle}
    >
      <StyledCard>
        {children}
        {enableBottom ? (
          <BottomContainer>
            <Button
              size="small"
              style={{ width: 'auto', minWidth: 159, fontWeight: 'bold' }}
              onClick={enableBottom.primaryFunction}
            >
              {enableBottom.leftIcon}
              {enableBottom.leftButtonText}
            </Button>
            <Button
              size="small"
              style={{ width: 122, fontWeight: 'bold' }}
              buttonType="info"
              onClick={enableBottom.secondaryFunction}
            >
              {enableBottom.rightButtonText}
            </Button>
          </BottomContainer>
        ) : null}
      </StyledCard>
    </MUIModal>
  )
}
export { Modal }
