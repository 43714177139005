import React, { FunctionComponent, useState } from 'react'
import { useMutation } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { InputBaseComponentProps } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { useNotification, validateGraphQLErrorCode } from 'library'

import NumberValueInput from 'components/Inventory/Common/NumberValueInput'
import Stripe from 'components/Motives/Stripe'

import { cypressAddButton } from 'constants/cypress'
import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Motive, RejectionMotiveInput } from 'models/motives'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { UpdateGenericInput } from 'models/services/CarSettings/detail'

import {
  CREATE_REJECTION_MOTIVE,
  DELETE_REJECTION_MOTIVE,
  UPDATE_REJECTION_MOTIVE,
} from 'graphQL/Motives/mutations'
import { GET_REJECTION_MOTIVES } from 'graphQL/Motives/queries'

import { colors } from 'styles/theme'

import {
  AddSection,
  ContentBox,
  StripeContainer,
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledTextField,
} from './style'

type RejectionProps = {
  rejectionMotives: Motive[]
}

const VehicleMotives = ({ rejectionMotives }: RejectionProps) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [rejectionArr, setRejectionArr] = useState<Motive[]>(rejectionMotives)

  const [singleMotive, setSingleMotive] = useState<Motive>({
    id: '',
    name: '',
    weight: 0,
  })
  const {
    text: { motives },
  } = useTranslation(textFiles.GENERAL)
  const { rejection } = motives

  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const [createRejection] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<RejectionMotiveInput>
  >(CREATE_REJECTION_MOTIVE, {
    onCompleted(response) {
      show({
        updatedSeverity: 'success',
        message: motives.createSuccess,
      })
      setRejectionArr((prevState) => {
        const newRejections = [...prevState]
        newRejections.push({
          id: response.data.id as string,
          name: singleMotive.name,
          weight: singleMotive.weight,
        })
        return newRejections
      })
      setSingleMotive({
        id: '',
        name: '',
        weight: 0,
      })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: motives.createFail,
        })
    },
    refetchQueries: [GET_REJECTION_MOTIVES],
  })

  const [updateRejection] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateGenericInput<RejectionMotiveInput>>
  >(UPDATE_REJECTION_MOTIVE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: motives.updateSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: motives.updateFail,
      })
    },
    refetchQueries: [GET_REJECTION_MOTIVES],
  })

  const [deleteRejection] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(DELETE_REJECTION_MOTIVE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: motives.deleteSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: motives.deleteFail,
      })
    },
    refetchQueries: [GET_REJECTION_MOTIVES],
  })

  const handleAdding = () => {
    if (singleMotive.name !== '') {
      createRejection({
        variables: {
          input: {
            name: singleMotive.name,
            weight: singleMotive.weight,
          },
        },
      })
    }
  }

  const handleEditItem = (id: string, newName: string, newValue?: number) => {
    const neededRejection = rejectionArr.find((rej) => rej.id === id)
    if (
      neededRejection?.name !== newName ||
      neededRejection?.weight !== newValue!
    )
      updateRejection({
        variables: {
          input: {
            where: {
              id,
            },
            data: {
              name: newName,
              weight: newValue as number,
            },
          },
        },
      })
    setRejectionArr((prevState) => {
      const newRejections = prevState.map((rej) => {
        if (rej.id === id) {
          return { ...rej, name: newName, weight: newValue as number }
        }
        return rej
      })
      return newRejections
    })
  }

  const handleConfirmRemove = (id: string) => {
    setRejectionArr((prevState) => {
      const newRejections = prevState.filter((rej) => rej.id !== id)
      return newRejections
    })
    deleteRejection({
      variables: {
        input: id,
      },
    })
  }

  const handleBlur = () => {
    if (!singleMotive.weight || singleMotive.weight <= 0) {
      setSingleMotive({ ...singleMotive, weight: 0 })
    } else if (singleMotive.weight > 100) {
      setSingleMotive({ ...singleMotive, weight: 100 })
    }
  }

  return (
    <StyledContainer>
      <StyledForm>
        <ContentBox>
          <StripeContainer>
            {rejectionArr.map((vehicleMotive) => (
              <Stripe
                key={vehicleMotive.id}
                id={vehicleMotive.id as string}
                name={vehicleMotive.name}
                value={vehicleMotive.weight}
                handleRemove={handleConfirmRemove}
                handleEditItem={handleEditItem}
                handleEditMode={setEditMode}
              />
            ))}
          </StripeContainer>

          <AddSection>
            <StyledTextField
              variant="outlined"
              name="name"
              placeholder={rejection.typeInVehiclePlaceholder}
              value={singleMotive.name}
              sx={{
                width: '60% !important',
                input: {
                  paddingLeft: '0px',
                },
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSingleMotive({ ...singleMotive, name: e.target.value })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaylistAddIcon
                      sx={{ color: colors.placeholderGray, fontSize: 18 }}
                    />
                  </InputAdornment>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                'data-cy': 'add-input',
              }}
            />
            <StyledTextField
              variant="outlined"
              name="weight"
              placeholder={rejection.valuePlaceholder}
              value={singleMotive.weight}
              sx={{
                width: '10% !important',
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSingleMotive({
                  ...singleMotive,
                  weight: Number(e.target.value),
                })
              }
              onBlur={handleBlur}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
              }}
            />
            <StyledButton
              startIcon={<AddIcon />}
              size="small"
              role="button"
              variant="text"
              disabled={editMode}
              onClick={handleAdding}
              sx={{
                border: `2px solid ${
                  editMode ? 'unset' : colors.orange
                } !important`,
                backgroundColor: editMode ? colors.lightGray : 'unset',
              }}
              testId={cypressAddButton}
            >
              {motives.addButton}
            </StyledButton>
          </AddSection>
        </ContentBox>
      </StyledForm>
    </StyledContainer>
  )
}

export default VehicleMotives
