import { OFFER_CREATION_OPTION } from 'constants/Marketing/offerCreation'
import {
  OfferCreationAction,
  OfferCreationModel,
} from 'models/services/marketing/offerCreation'

const offerCreationReducer = (
  state: OfferCreationModel,
  action: OfferCreationAction
): OfferCreationModel => {
  switch (action.type) {
    case OFFER_CREATION_OPTION.UPDATE_OFFER_DATA:
      return { ...state, offerInformation: { ...action.payload } }
    default:
      return state
  }
}

export default offerCreationReducer
