import { styled } from '@mui/material'
import { Box } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const GridBox = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '33% 33% 33%',
  columnGap: '20px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 50%',
    columnGap: '10px',
    rowGap: '20px',
  },
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
})

export const StyledButton = styled('label')({
  width: '36px',
  height: '37px',
  position: 'absolute',
  bottom: '6%',
  right: '6%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    color: colors.gray,
  },
})
