export enum InspectionStatusEnum {
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  IN_PROCESS = 'in-process',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  FOR_REPAIR = 'for-repair',
  ABORTED = 'aborted',
  PATCHING_UP = 'patching-up',
}

export enum InspectionSlugStatusEnum {
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  IN_PROCESS = 'IN_PROCESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FOR_REPAIR = 'FOR_REPAIR',
  ABORTED = 'ABORTED',
  PATCHING_UP = 'PATCHING_UP',
}

export enum InspectorStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum TemplateStatusEnum {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

export enum InventoryStatusEnum {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not-available',
  SOLD = 'sold',
  PENDING = 'pending',
  COMING_SOON = 'coming-soon',
  TO_REVIEW = 'to-review',
}

export enum InventorySlugStatusEnum {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  SOLD = 'SOLD',
  PENDING = 'PENDING',
  COMING_SOON = 'COMING_SOON',
  TO_REVIEW = 'TO_REVIEW',
}

export enum backendTemplateStatusEnum {
  published = 'PUBLISHED',
  draft = 'DRAFT',
}

export enum PublicationStatusEnum {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum UserStatusEnum {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}

export const InspectorStatusMap: Record<string, InspectorStatusEnum> = {
  active: InspectorStatusEnum.ACTIVE,
  inactive: InspectorStatusEnum.INACTIVE,
}

export const TemplateStatusMap: Record<string, TemplateStatusEnum> = {
  published: TemplateStatusEnum.PUBLISHED,
  draft: TemplateStatusEnum.DRAFT,
}

export const InventoryStatusMap: Record<string, InventoryStatusEnum> = {
  available: InventoryStatusEnum.AVAILABLE,
  not_available: InventoryStatusEnum.NOT_AVAILABLE,
  sold: InventoryStatusEnum.SOLD,
  pending: InventoryStatusEnum.PENDING,
  coming_soon: InventoryStatusEnum.COMING_SOON,
  to_review: InventoryStatusEnum.TO_REVIEW,
}

export const InspectionStatusMap: Record<string, InspectionStatusEnum> = {
  pending: InspectionStatusEnum.PENDING,
  schedule: InspectionStatusEnum.SCHEDULED,
  inProcess: InspectionStatusEnum.IN_PROCESS,
  approved: InspectionStatusEnum.APPROVED,
  rejected: InspectionStatusEnum.REJECTED,
  forRepair: InspectionStatusEnum.FOR_REPAIR,
  aborted: InspectionStatusEnum.ABORTED,
}

export const PublicationStatusMap: Record<string, PublicationStatusEnum> = {
  [PublicationStatusEnum.PUBLISHED]: PublicationStatusEnum.PUBLISHED,
  [PublicationStatusEnum.UNPUBLISHED]: PublicationStatusEnum.UNPUBLISHED,
}

export type StatusKey =
  | InspectionStatusEnum.PENDING
  | InspectionStatusEnum.SCHEDULED
  | InspectionStatusEnum.IN_PROCESS
  | InspectionStatusEnum.APPROVED
  | InspectionStatusEnum.REJECTED
  | InspectionStatusEnum.FOR_REPAIR
  | InspectionStatusEnum.ABORTED
  | InspectionStatusEnum.PATCHING_UP
  | InspectorStatusEnum.INACTIVE
  | InspectorStatusEnum.ACTIVE
  | TemplateStatusEnum.PUBLISHED
  | TemplateStatusEnum.DRAFT
  | PublicationStatusEnum.PUBLISHED
  | PublicationStatusEnum.UNPUBLISHED
  | InventoryStatusEnum.AVAILABLE
  | InventoryStatusEnum.NOT_AVAILABLE
  | InventoryStatusEnum.SOLD
  | InventoryStatusEnum.PENDING
  | InventoryStatusEnum.COMING_SOON
  | InventoryStatusEnum.TO_REVIEW
  | UserStatusEnum.CANCELLED

export type StatusCSSObject = {
  color: string
  backgroundColor: string
}

export type StatusStyling = {
  [key in StatusKey]: StatusCSSObject
}
