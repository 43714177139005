/* eslint-disable @typescript-eslint/no-unused-vars */
import { TrimDetailDataType } from 'models/services/CarSettings/detail'

// CLONE OBJECT WITHOUT SOME PROPS
export const validateClone = (data: {
  [key: string]: number | string | undefined | string[] | null
}) => {
  let clone = { ...data }

  if (data.alias === undefined) {
    clone = (({ alias, ...rest }) => rest)(data)
  }

  if (data.price === undefined) {
    clone = (({ price, ...rest }) => rest)(data)
  }

  return clone
}
