import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import InventoryCreationPage from 'pages/Inventory/Creation'
import InventoryDetailPage from 'pages/Inventory/Detail'
import InventoryListingPage from 'pages/Inventory/Listing'
import PreInventoryListingPage from 'pages/Inventory/PreInventory/Listing'

import RoleRoute from 'components/Auth/RoleRoute'

import { INVENTORY_SUB_ROUTES } from 'constants/routes'
import { UserRoles } from 'models/role'

export const InventoryRouter = () => (
  <Switch>
    <Route
      exact
      path={INVENTORY_SUB_ROUTES.INVENTORY_LISTING}
      component={InventoryListingPage}
    />
    <Route
      path={INVENTORY_SUB_ROUTES.INVENTORY_DETAIL}
      component={InventoryDetailPage}
    />
    <RoleRoute
      allowedRoles={[
        UserRoles.ADMIN,
        UserRoles.INVENTORY_SPECIALIST,
        UserRoles.INVENTORY_SUPERVISOR,
      ]}
      path={INVENTORY_SUB_ROUTES.PRE_INVENTORY_LISTING}
    >
      <Route
        path={INVENTORY_SUB_ROUTES.PRE_INVENTORY_LISTING}
        component={PreInventoryListingPage}
      />
    </RoleRoute>

    <RoleRoute
      allowedRoles={[
        UserRoles.ADMIN,
        UserRoles.INVENTORY_SUPERVISOR,
        UserRoles.INVENTORY_SPECIALIST,
      ]}
      path={INVENTORY_SUB_ROUTES.INVENTORY_CREATION}
    >
      <Route
        path={INVENTORY_SUB_ROUTES.INVENTORY_CREATION}
        component={InventoryCreationPage}
      />
    </RoleRoute>

    <Redirect to={INVENTORY_SUB_ROUTES.INVENTORY_LISTING} />
  </Switch>
)
