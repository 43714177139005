import OutOfSpotConfiguration from 'components/Operations/OutOfSpot/Configuration'

import { HoursPair, OutOfSpotModel } from 'models/outOfSpot'

export type OutOfSpotConfigurationProps = {
  schedules?: OutOfSpotModel
  submitLoading: boolean
  updateSchedules: (newSchedules: HoursPair) => void
}
export const OUT_OF_SPOT_CONFIG: Array<React.FC<OutOfSpotConfigurationProps>> =
  [OutOfSpotConfiguration]
