import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ReplyIcon from '@mui/icons-material/Reply'
import { Typography } from '@mui/material'
import { Avatar, Box } from 'library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import {
  androidStyles,
  StyledArrowContainer,
  StyledBodyContainer,
  StyledBox,
  StyledTypography,
} from './style'

export type NotificationCardProps = {
  title: string
  description: string
  isAndroid?: boolean
  picture?: string
  time?: string
}
const NotificationCard = ({
  title,
  description,
  isAndroid,
  picture,
  time,
}: NotificationCardProps) => {
  const { text } = useTranslation(textFiles.NOTIFICATION_DETAIL)

  return (
    <StyledBox sx={isAndroid ? { ...androidStyles } : null}>
      <Box
        sx={{
          display: 'flex',
          marginBottom: '4px',
        }}
      >
        <DirectionsCarIcon
          sx={{ color: colors.orange, fontSize: '13px', marginRight: '8px' }}
        />
        <Typography variant="body2" sx={{ fontSize: '11px' }}>
          {isAndroid
            ? text.notificationCard.curbo
            : text.notificationCard.uppercaseCurbo}
        </Typography>

        {!isAndroid ? (
          <Typography
            variant="body2"
            sx={{ fontSize: '11px', textAlign: 'end', flexGrow: '1' }}
          >
            {time || <>Yesterday, 11:02 PM</>}
          </Typography>
        ) : null}

        {isAndroid ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '4px',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: '11px', marginRight: '4px' }}
            >
              &middot;
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '11px' }}>
              {time || text.notificationCard.now}
            </Typography>
            <KeyboardArrowDownIcon
              sx={{ color: colors.orange, fontSize: '16px' }}
            />
          </Box>
        ) : (
          <Typography
            variant="body2"
            sx={{ fontSize: '11px', textAlign: 'end', flexGrow: '1' }}
          >
            {text.notificationCard.yesterday}
          </Typography>
        )}
      </Box>

      <StyledBodyContainer>
        <Box sx={{ width: isAndroid ? '75%' : '100%' }}>
          <StyledTypography
            variant="body1"
            sx={{
              fontWeight: FONT_WEIGHT.MEDIUM,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: '18px',
            }}
          >
            {title}
          </StyledTypography>
          <StyledTypography
            variant="body1"
            sx={{
              fontWeight: FONT_WEIGHT.REGULAR,
              height: '35px',
              whiteSpace: 'wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {description}
          </StyledTypography>
        </Box>
        {picture ? (
          <Box sx={{ position: 'relative' }}>
            <Avatar
              image={picture}
              style={{ borderRadius: '50', height: '44px', width: '44px' }}
            />
            <StyledArrowContainer>
              <ReplyIcon />
            </StyledArrowContainer>
          </Box>
        ) : null}
      </StyledBodyContainer>
    </StyledBox>
  )
}

export default NotificationCard
