import FuelTypeInfo from 'components/CarSettings/FuelType/Creation/FuelTypeInfo'
import Summary from 'components/CarSettings/FuelType/Creation/Summary'

import {
  FuelTypeCreationAction,
  FuelTypeCreationModel,
} from 'models/fuelTypeCreation'

export type FuelTypeCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  fuelTypeData: FuelTypeCreationModel
  updateFuelTypeData: (action: FuelTypeCreationAction) => void
}

export const INITIAL_FUEL_TYPE_CREATION_STEPS: Array<
  React.FC<FuelTypeCreationProps>
> = [FuelTypeInfo, Summary]
