import { ColorColumnField } from 'constants/CarSettings/columnField'
import { SelectItem } from 'models/select'
import { ColorTextType } from 'models/text/carSettingsListing'

export const createColorFieldSelectItems = (
  text: ColorTextType
): SelectItem[] => {
  return [
    {
      label: text.color,
      value: ColorColumnField.COLOR,
    },
    {
      label: text.id,
      value: ColorColumnField.ID,
    },
    {
      label: text.name,
      value: ColorColumnField.NAME,
    },
    {
      label: text.hexCode,
      value: ColorColumnField.HEX_CODE,
    },
    {
      label: text.createdAt,
      value: ColorColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: ColorColumnField.STATUS,
    },
    {
      label: text.actions,
      value: ColorColumnField.ACTIONS,
    },
  ]
}

export const createColorFieldSelectLabels = (
  text: ColorTextType
): Record<string, string> => {
  return {
    id: text.id,
    color: text.color,
    hexCode: text.hexCode,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
  }
}

export const colordDefaultFields = [
  ColorColumnField.COLOR,
  ColorColumnField.ID,
  ColorColumnField.NAME,
  ColorColumnField.HEX_CODE,
  ColorColumnField.CREATED_AT,
  ColorColumnField.STATUS,
  ColorColumnField.ACTIONS,
]
