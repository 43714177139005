import React, { useState } from 'react'
import { ClickAwayListener, Typography } from '@mui/material'
import Popper from '@mui/material/Popper'
import { Box, CalendarRange } from 'library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CalendarRangeType } from 'models/date'

import { colors } from 'styles/theme'

import { SetButton } from '../../Information/style'

type Props = {
  text: string
  dateRange: CalendarRangeType
  handleChange: (newDateRange: CalendarRangeType) => void
}

const RangeButton = ({ text, dateRange, handleChange }: Props) => {
  const { text: translation } = useTranslation(textFiles.GENERAL)
  const { dateRangeCalendarText } = translation

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  return (
    <>
      <SetButton
        buttonType="secondary"
        onClick={handlePopperOpen}
        testId="set-range-button"
      >
        <Typography variant="body2">{text}</Typography>
      </SetButton>
      <Popper
        id="range-picker-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null)
            setOpen(false)
          }}
        >
          <Box
            padding="2rem 2rem 0rem 2rem"
            sx={{ backgroundColor: colors.commonWhite }}
          >
            <CalendarRange
              dateRange={dateRange}
              handleChangeCalendarRange={handleChange}
              maxDate={undefined}
              fromLabel={dateRangeCalendarText.fromLabel}
              toLabel={dateRangeCalendarText.toLabel}
            />
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default RangeButton
