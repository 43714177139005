import { gql } from '@apollo/client'

export const GET_INVENTORY_CAR_BY_ID = gql`
  query getInventoryCarById($input: ID!) {
    data: getInventoryCarById(id: $input) {
      id
      slug
      certified
      brand {
        name
        icon
      }
      carModel {
        id
        name
      }
      trimLevel {
        value: id
        name
      }
      year
      countryVersion {
        value: id
        name
      }
      mainPicture
      categories {
        value: id
        name
      }
      exteriorColor {
        value: id
        name
        hexCode
      }
      interiorColor {
        value: id
        name
        hexCode
      }
      transmission {
        name
        value: id
      }
      fuelType {
        name
        value: id
      }
      driveTrain {
        name
        value: id
      }
      features {
        name
        value: id
      }
      bodyStyle {
        name
        value: id
      }
      interiorPictures
      exteriorPictures
      mileage
      vinNumber
      chassisNumber
      licensePlate
      frontLegRoom
      backLegRoom
      frontHeadRoom
      backHeadRoom
      engineDisplacement
      cargoCapacity
      cargo
      length
      width
      seats
      doors
      mpg
      mpgCity
      mpgHgw
      fuelCapacity
      cylinders
      torque
      torqueRpm
      horsePower
      horsePowerRpm
      publishedAt
      status {
        backgroundColor
        textColor
        slug
        name
        description
      }
      provider
      dealer {
        name
        telephoneNumber
        dealerContacts {
          agent
          telephoneNumber
        }
        address
      }
      curboSpot {
        name
        telephoneNumber
        address
      }
      owner {
        address
        email
        lastName
        name
        telephoneNumber
      }
      offerData {
        discount
        hasOffer
      }
      priceInfo {
        basePrice
        fee
        transfer
        licensePlate
        warranty
        repairCost
        customPrice
      }
      relatedCars {
        id
        listingPrice
        exteriorColor {
          name
        }
        brand {
          name
        }
        carModel {
          name
        }
        mainPicture
        bodyStyle {
          name
        }
        fuelType {
          name
        }
        transmission {
          name
        }
        status {
          id
          name
          backgroundColor
          textColor
        }
        year
      }
    }
  }
`
export const GET_COLORS = gql`
  query getColors($input: FilterInput) {
    data: getColors(input: $input) {
      value: id
      hexCode
      name
    }
  }
`

export const GET_MODEL = gql`
  query getModelById($input: ID!) {
    data: getModelById(id: $input) {
      trimYears
      trims {
        value: id
        name
        year
      }
    }
  }
`

export const LIST_INSPECTIONS_BY_CAR = gql`
  query listInspectionsByCar($input: ListInspectionsByCarInput!) {
    listData: listInspectionsByCar(input: $input) {
      count
      data {
        id
        createdAt
        provider
        origin
        date
        type {
          name
          description
        }
        status {
          backgroundColor
          name
          slug
          textColor
        }
      }
    }
  }
`

export const GET_PROVIDERS = gql`
  query getProviders($input: FilterInput) {
    data: getProviders(input: $input) {
      name
      slug
    }
  }
`
