import { gql } from '@apollo/client'

export const USER_INFO = gql`
  query fetchUser {
    self {
      address
      documentId
      drivingLicense
      email
      id
      lastName
      name
      profilePicture
      telephoneNumber
      roles {
        id
        name
        description
        slug
      }
      organization {
        type
        status
        name
        id
      }
    }
  }
`
