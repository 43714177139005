import OfferData from 'components/Marketing/Offer/Creation/OfferData'
import Summary from 'components/Marketing/Offer/Creation/Summary'

import { OfferCreationYears } from 'models/offer'
import { BaseEntity } from 'models/services/base'
import {
  OfferCreationAction,
  OfferCreationModel,
} from 'models/services/marketing/offerCreation'

export type OfferCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  offerData: OfferCreationModel
  updateOfferData: (action: OfferCreationAction) => void
  brands: BaseEntity[]
  models: BaseEntity[]
  years: OfferCreationYears
  handleSubmit: () => void
}

export const INITIAL_OFFER_CREATION_STEPS: Array<React.FC<OfferCreationProps>> =
  [OfferData, Summary]
