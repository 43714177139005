import React, { useState } from 'react'
import { ExitToApp, ManageAccounts, Settings } from '@mui/icons-material'
import { Box } from 'library'

import Popper from 'components/Common/Popper'
import { SidebarItem } from 'components/General/Sidebar'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { DashboardTextType } from 'models/text/Dashboard'

const SettingsWidget = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { handleSignOut } = useUser()
  const open = Boolean(anchorEl)
  const { text }: { text: DashboardTextType } = useTranslation(
    textFiles.INSPECTION_DASHBOARD
  )
  const {
    sidebar: { settings: translation },
  } = text
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopperClose = () => {
    setAnchorEl(null)
  }
  const maintainPopover = () => {
    setAnchorEl(anchorEl)
  }

  const items: SidebarItem[] = [
    {
      icon: <ManageAccounts fontSize="small" />,
      name: translation.accountSettings,
      key: 'manage-account',
      url: routes.ACCOUNT_SETTINGS,
    },
    {
      icon: <ExitToApp fontSize="small" />,
      name: translation.logOut,
      key: 'log-out',
      onClick: handleSignOut,
      url: routes.LOGIN,
    },
  ]

  return (
    <>
      <Box
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
        sx={{
          margin: '0.4rem 0',
          height: '24px',
          ':hover': {
            cursor: 'pointer',
          },
        }}
        data-cy="settings-button"
      >
        <Settings fontSize="small" />
      </Box>
      <Popper
        open={open}
        handlePopperClose={handlePopperClose}
        handlePopperOpen={maintainPopover}
        anchorEl={anchorEl}
        items={items}
      />
    </>
  )
}

export default SettingsWidget
