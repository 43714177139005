import { styled } from '@mui/material'
import { Box } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'

export const DownloadBox = styled(Box)({
  width: '295px',
  marginRight: 'auto',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    width: '205px',
    marginRight: 'inherit',
  },
})
