import { Card, styled } from '@mui/material'
import { Box } from 'library'

import { colors } from 'styles/theme'

export const CardContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})

export const StyledTriangleIcon = styled(Box)({
  borderColor: `transparent transparent ${colors.orange} transparent`,
  borderStyle: 'solid',
  borderWidth: ' 0px 8px 8px 8px',
  height: '0px',
  width: '0px',
})

export const StyledCard = styled(Card)({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  height: '45px',
  padding: '0 15px',
  fontSize: '14px',
  backgroundColor: colors.orange,
})
