/**
 *
 * @returns This returns an array of the indexes used to paint each bar according
 * to the current barChartColors array (ex. 0,1,2,0,1,2...)
 */
export type BarsToRenderType = {
  name: string
  stackId?: string
  dataKey: string
}

export const getSelectedColorsPerBar = (
  bars: BarsToRenderType[],
  lastColorIndex: number
) => {
  let colorIndex = -1

  const colorsIndexesToPaintBars = bars.map(() => {
    if (colorIndex >= lastColorIndex) {
      colorIndex = 0
      return colorIndex
    }
    colorIndex += 1
    return colorIndex
  })

  return colorsIndexesToPaintBars
}
