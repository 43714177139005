import { styled } from '@mui/material'
import { Box } from 'library'

import Link from 'components/Common/Link'

import { colors } from 'styles/theme'

export const Layout = styled(Box)({
  position: 'relative',
  maxWidth: '1380px',
  width: '100%',
})

export const StyledLink = styled(Link)({
  position: 'absolute',
  top: '-40px',
  '&:hover': {
    textDecoration: 'none',
  },
})

export const ContentContainer = styled(Box)({
  maxWidth: '1380px',
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${colors.commonWhite}`,
  borderRadius: '0 0 10px 10px',
  backgroundColor: colors.commonWhite,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '2rem',
})
