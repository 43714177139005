import React from 'react'
import { Box as MuiBox, BoxProps } from '@mui/material'

/**
 * A utility component that serves as a wrapper for every needs
 * that requires CSS, this is recommended to use instead of
 * styling a `<div/>` because of built in CSS utils.
 */

export const Box = React.forwardRef(
  (props: BoxProps, ref: React.Ref<unknown>) => {
    return <MuiBox ref={ref} {...props} />
  }
)
