import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { ArrayHelpers, FormikValues, getIn } from 'formik'
import { Box, CounterButton } from 'library'

import MultipleSelect from 'components/Inventory/Creation/Common/MultipleSelect'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/select'
import { FormQuestionType } from 'models/template'

import { StyledErrorMessage } from 'styles/creation'
import { colors } from 'styles/theme'

import { SelectContainer } from '../Checkpoint/style'

import {
  IconButton,
  Stripe,
  StyledCounterContainer,
  StyledCounterField,
  StyledTextField,
} from './style'

type QuestionProps = {
  checkpointIndex: number
  questionIndex: number
  question: FormQuestionType
  formik: FormikValues
  arrayHelpers: ArrayHelpers
  answers: Option[]
  standardizeAnswer: boolean
}

const QuestionStripe = ({
  checkpointIndex,
  questionIndex,
  question,
  formik,
  arrayHelpers,
  answers,
  standardizeAnswer,
}: QuestionProps) => {
  const { text } = useTranslation(textFiles.TEMPLATE_CREATION)
  const { remove } = arrayHelpers
  const {
    step: { checkpoints: checkpointText, placeholders },
  } = text

  const handleAnswerRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    const newAnswers = [...question.answers]
    newAnswers.splice(index, 1)
    formik.setFieldValue(
      `checkpoints.${checkpointIndex}.questions.${questionIndex}.answers`,
      newAnswers
    )
  }

  const hasAnswersError = () => {
    if (
      getIn(
        formik.touched,
        `checkpoints.${checkpointIndex}.questions.${questionIndex}.answers`
      ) &&
      Boolean(
        getIn(
          formik.errors,
          `checkpoints.${checkpointIndex}.questions.${questionIndex}.answers`
        )
      )
    )
      return true

    return false
  }

  return (
    <Box borderBottom={`1px solid ${colors.border}`} marginBottom="1rem">
      <Stripe>
        <Box width="50%" marginBottom="1.5em">
          <Typography variant="subtitle1">{checkpointText.question}</Typography>
          <StyledTextField
            variant="outlined"
            name={`checkpoints.${checkpointIndex}.questions.${questionIndex}.title`}
            value={question.title}
            onChange={formik.handleChange}
            placeholder={`${placeholders.question} ${questionIndex + 1}`}
            error={
              getIn(
                formik.touched,
                `checkpoints.${checkpointIndex}.questions.${questionIndex}.title`
              ) &&
              Boolean(
                getIn(
                  formik.errors,
                  `checkpoints.${checkpointIndex}.questions.${questionIndex}.title`
                )
              )
            }
            helperText={
              getIn(
                formik.touched,
                `checkpoints.${checkpointIndex}.questions.${questionIndex}.title`
              ) &&
              getIn(
                formik.errors,
                `checkpoints.${checkpointIndex}.questions.${questionIndex}.title`
              )
            }
          />
        </Box>
        <Box width="12%">
          <Typography variant="subtitle1">
            {checkpointText.isRequired}
          </Typography>
          <Checkbox
            checked={question.isRequired}
            inputProps={{ 'aria-label': 'controlled' }}
            name={`checkpoints.${checkpointIndex}.questions.${questionIndex}.isRequired`}
            value={question.isRequired}
            onChange={formik.handleChange}
            data-cy="is-required-checkbox"
          />
        </Box>
        <Box width="10%">
          <Typography variant="subtitle1">{checkpointText.pictures}</Typography>
          <Checkbox
            checked={question.requiresPicture}
            inputProps={{ 'aria-label': 'controlled' }}
            name={`checkpoints.${checkpointIndex}.questions.${questionIndex}.requiresPicture`}
            value={question.requiresPicture}
            onChange={formik.handleChange}
            data-cy="pictures-checkbox"
          />
        </Box>
        <StyledCounterContainer
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1">{checkpointText.value}</Typography>
          <Box display="flex" justifyContent="space-between" width="100%">
            <StyledCounterField
              variant="outlined"
              type="number"
              style={{ width: '65px' }}
              name={`checkpoints.${checkpointIndex}.questions.${questionIndex}.score`}
              value={question.score}
              onChange={formik.handleChange}
              error={
                getIn(
                  formik.touched,
                  `checkpoints.${checkpointIndex}.questions.${questionIndex}.score`
                ) &&
                Boolean(
                  getIn(
                    formik.errors,
                    `checkpoints.${checkpointIndex}.questions.${questionIndex}.score`
                  )
                )
              }
              helperText={
                getIn(
                  formik.touched,
                  `checkpoints.${checkpointIndex}.questions.${questionIndex}.score`
                ) &&
                getIn(
                  formik.errors,
                  `checkpoints.${checkpointIndex}.questions.${questionIndex}.score`
                )
              }
              sx={{
                '.MuiFormHelperText-root': {
                  marginLeft: '0px',
                  marginRight: '0px',
                  width: '130px',
                },
              }}
            />
            <CounterButton
              value={question.score}
              maxValue={101}
              handleValueChange={(currentScore: number) => {
                if (currentScore + 1 < 101) {
                  formik.setFieldValue(
                    `checkpoints.${checkpointIndex}.questions.${questionIndex}.score`,
                    currentScore
                  )
                }
              }}
              containerStyle={{
                height: '36px',
                width: '40px',
                borderRadius: '4px',
                button: {
                  height: '18px !important',
                },
              }}
            />
          </Box>
        </StyledCounterContainer>
        <IconButton
          sx={{
            cursor: questionIndex === 0 ? 'default' : 'pointer',
          }}
          onClick={() => {
            if (questionIndex !== 0) remove(questionIndex)
          }}
          data-cy={`delete-checkpoint-${checkpointIndex}-question-${questionIndex}-button`}
        >
          <CloseIcon
            sx={{
              color: colors.gray,
              fontSize: 18,
              marginBottom: 1,
              visibility: questionIndex === 0 ? 'hidden' : 'visible',
              pointerEvents: questionIndex === 0 ? 'none' : 'all',
            }}
          />
        </IconButton>
      </Stripe>
      {!standardizeAnswer ? (
        <SelectContainer description={checkpointText.selectDescription}>
          <MultipleSelect
            name={`checkpoints.${checkpointIndex}.questions.${questionIndex}.answers`}
            values={question.answers}
            label=""
            options={answers}
            onChange={formik.handleChange}
            onRemove={handleAnswerRemove}
            error={hasAnswersError()}
          />
          {hasAnswersError() ? (
            <StyledErrorMessage
              text={getIn(
                formik.errors,
                `checkpoints.${checkpointIndex}.questions.${questionIndex}.answers`
              )}
              hasIcon={false}
            />
          ) : null}
        </SelectContainer>
      ) : null}
    </Box>
  )
}

export default QuestionStripe
