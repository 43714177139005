import React from 'react'

import {
  NotificationContext,
  NotificationContextType,
} from '../context/NotificationContext'

export const useNotification = (): NotificationContextType => {
  const context = React.useContext(NotificationContext)

  if (context === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationsProvider'
    )
  }

  return context
}

export default useNotification
