import React, { useReducer, useState } from 'react'
import { BodyContainerWithHeader, createSimpleStepperItems } from 'library'

import { BackContainer } from 'components/Common/BackContainer'

import { DAY_ENUM } from 'constants/date'
import { meridiamOptions } from 'constants/inspection'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  NotificationCreationAction,
  NotificationCreationModel,
} from 'models/notification'
import { NotificationStatus } from 'models/status'
import notificationCreationReducer from 'reducers/Marketing/notificationCreationReducer'
import { NOTIFICATION_CREATION_STEPS as steps } from 'utils/Marketing/Notification/creation'

import { StyledBox } from 'styles/operation/creation'

const initialData: NotificationCreationModel = {
  notificationInformation: {
    title: '',
    description: '',
    name: '',
    status: NotificationStatus.DRAFT,
    picture: '',
    pictureFile: null,
    time: {
      id: '0',
      name: '01:00',
      am: true,
      continentalTime: 0,
      slug: '0100',
      visible: true,
    },
    meridiam: meridiamOptions[0].value,
    date: new Date(),
    currentDayKey: DAY_ENUM.MONDAY,
  },
}
const NotificationCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [notificationData, dispatch] = useReducer(
    notificationCreationReducer,
    initialData
  )

  const { text } = useTranslation(textFiles.NOTIFICATION_CREATION)

  const { title, processDescription } = text

  const stepperItems = createSimpleStepperItems(text.stepper)

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const updateNotificationData = (action: NotificationCreationAction) => {
    dispatch(action)
  }

  const handleSubmit = () => {
    // eslint-disable-next-line no-console
    console.log('here')
  }

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={title}
        subtitle={processDescription}
        currentStep={currentStep}
        stepperItems={stepperItems}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          updateNotificationData,
          notificationData,
          handleSubmit,
          submitLoading: false,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default NotificationCreationPage
