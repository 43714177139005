/* eslint-disable import/no-duplicates */
import {
  endOfMonth,
  format,
  formatISO,
  isPast,
  isSameDay,
  Locale,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import { CalendarRangeType } from 'library/models/date'

const dateLocales: Record<string, Locale> = {
  en: enUS,
  es,
}

// Note: For more information about format function please refer to: https://date-fns.org/v2.26.0/docs/format
export const formatDateLocalizedTime = (
  date: Date,
  dateFormat = 'PPPP',
  language?: string
) => {
  const formatedDate = format(date, dateFormat, {
    locale:
      language && language in dateLocales
        ? dateLocales[language]
        : dateLocales.es,
  })

  return formatedDate
}

/**
 * Gets the date of the last X amount of days depending on the given date,
 */
export const getSubDaysFromDate = (amount: number, date: Date): Date => {
  const substratedDays = subDays(new Date(date), amount)
  return substratedDays
}

/**
 * Gets date of the start of the current year depending on the current Date
 */
export const getStartOfCurrentYear = () => {
  return startOfYear(new Date())
}

/**
 * Gets date of the start of the current month depending on the current Date
 */
export const getStartOfCurrentMonth = () => {
  return startOfMonth(new Date())
}

/**
 * Gets date of the start of the current week depending on the current Date,
 * where week starts on monday
 */
export const getStartOfCurrentWeek = () => {
  return startOfWeek(new Date(), { weekStartsOn: 1 })
}

/**
 * Returns a tuple containing the start and end of the previous month
 */
export const getPreviousMonth = (): [Date, Date] => {
  const previousMonth = subMonths(new Date(), 1)
  const monthStart = startOfMonth(new Date(previousMonth))
  const monthEnd = endOfMonth(new Date(previousMonth))

  return [monthStart, monthEnd]
}

export const formatDateTitle = (
  dateRange: CalendarRangeType,
  text: string,
  language: string
): string => {
  const { fromDate, toDate } = dateRange
  const formatPattern = 'PPP'

  if (fromDate) {
    const formattedFromDate = formatDateLocalizedTime(
      fromDate,
      formatPattern,
      language
    )
    if (toDate) {
      const formattedToDate = formatDateLocalizedTime(
        toDate,
        formatPattern,
        language
      )
      if (isSameDay(toDate, fromDate)) {
        return `${formattedFromDate} `
      }
      return `${formattedFromDate} - ${formattedToDate}`
    }
  }

  return `${text}`
}

/**
 * Returns the Date Range as a string
 */

export const getDateRangeString = (range: CalendarRangeType) => {
  const fromDate = range.fromDate
    ? formatDateLocalizedTime(range.fromDate, 'dd/MM/yyyy')
    : 'n/a'

  const toDate = range.toDate
    ? formatDateLocalizedTime(range.toDate, 'dd/MM/yyyy')
    : 'n/a'

  return `${fromDate} - ${toDate}`
}

/**
 * Returns the date as ISO date string, if date is `null`, returns `undefined`
 */
export const getIsoDate = (
  date: Date | null,
  representation?: 'complete' | 'date' | 'time'
) => {
  if (!date) return undefined

  return formatISO(date, { representation })
}

/**
 * Returns a boolean that its true when the date is in the past
 */
export const getPastCheck = (date: Date | null) => {
  if (!date) return undefined

  return isPast(date)
}
