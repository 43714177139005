import React, { useState } from 'react'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { Card, SelectChangeEvent } from '@mui/material'
import Popper from '@mui/material/Popper'
import { Box, colors, Container, Option, Select } from 'library'

type Props = {
  text: string
  handleUpdate: (id: string) => void
  specialist: Option
  options: Option[]
}

const SpecialistPicker = ({
  text,
  handleUpdate,
  specialist,
  options,
}: Props) => {
  const [value, setValue] = useState<string>(specialist.value as string)
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  const maintainPopover = () => {
    setAnchorEl(anchorEl)
    setOpen(true)
  }

  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    setValue(event.target.value as string)
    handleUpdate(event.target.value as string)
  }

  return (
    <>
      <Box
        width="98%"
        height="85%"
        onMouseEnter={(e) => {
          handleOpen(e)
        }}
        onMouseLeave={handleClose}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <ManageAccountsIcon fontSize="medium" sx={{ color: colors.gray }} />
      </Box>
      <Popper
        id="info-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        onMouseEnter={maintainPopover}
        onMouseLeave={handleClose}
      >
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            fontSize: '14px',
          }}
        >
          <Container
            title={text}
            alignItems="flex-start"
            height="100%"
            width="100%"
          >
            <Select
              options={options}
              variant="outlined"
              name="specialist"
              value={value}
              label={text}
              onChange={(e) => handleSelect(e)}
              sx={{ fontSize: 12, width: 255 }}
              testId="specialist-select"
            />
          </Container>
        </Card>
      </Popper>
    </>
  )
}

export default SpecialistPicker
