import React from 'react'
import { Typography } from '@mui/material'
import { Box, formatPhoneNumber } from 'library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { ItemProps } from '../ItemContainer'

type FieldProps = Pick<ItemProps, 'item'>

const TextName = ({ item }: FieldProps) => {
  const { text } = useTranslation(textFiles.GENERAL)
  const { table: translation } = text

  const formatField = () => {
    if (item.options) {
      return item.options.find((option) => option.value === item.name)?.name
    }
    if (item.inputProps) {
      const name = item.name as string
      return name.length < 10
        ? translation.notRegistered
        : formatPhoneNumber(name)
    }

    return typeof item.name === 'number'
      ? item.name.toLocaleString('en-US')
      : item.name || item.emptyNameText || 'N/A'
  }
  return (
    <Box display="flex">
      {!item.inverted ? (
        <Typography
          variant="h5"
          fontWeight={FONT_WEIGHT.BOLD}
          color={item.onClick ? colors.orange : colors.black}
          noWrap
          sx={{
            textDecoration: item.onClick ? 'underline' : 'none',
            cursor: item.onClick ? 'pointer' : 'unset',
          }}
          onClick={item.onClick}
          fontSize="23px"
        >
          {formatField()}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          fontWeight={FONT_WEIGHT.MEDIUM}
          color={colors.gray}
          fontSize="16px"
          width="255px"
          maxHeight="120px"
          sx={{
            whiteSpace: 'normal',
            overflowY: 'auto',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            margin: 0,
          }}
        >
          {item.name || item.emptyNameText || 'N/A'}
        </Typography>
      )}
      {item.unit && item.name && (
        <Typography
          variant="h5"
          fontWeight={FONT_WEIGHT.BOLD}
          color={colors.black}
          sx={{ marginLeft: '5px' }}
        >
          {item.unit}
        </Typography>
      )}
    </Box>
  )
}

export default TextName
