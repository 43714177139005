import React from 'react'
import { Avatar } from 'library'

import defaultAvatarImage from 'images/blueDefaultAvatar.png'

import { StyledItem } from '../style'

type Props = {
  title: string
  picture: string
  width?: string | number
  containerStyles?: React.CSSProperties
  imageWidth?: number | string
  imageHeight?: number | string
}

/*
This component is used in any of the summary step of a setting creation process, 
includes a container with text adapted to its name and a selected avatar from previous step
*/

const AvatarItem = ({
  containerStyles,
  title,
  picture,
  width = 315,
  imageWidth = 105,
  imageHeight = 105,
}: Props) => {
  return (
    <StyledItem
      title={title}
      contentStyle={{ marginTop: '1rem !important' }}
      width={width}
      sx={containerStyles}
    >
      <Avatar
        height={imageHeight}
        width={imageWidth}
        variant="rounded"
        image={picture || defaultAvatarImage}
      />
    </StyledItem>
  )
}

export default AvatarItem
