import { NOTIFICATION_CREATION_OPTION } from 'constants/notification'
import {
  NotificationCreationAction,
  NotificationCreationModel,
} from 'models/notification'

const notificationCreationReducer = (
  state: NotificationCreationModel,
  action: NotificationCreationAction
): NotificationCreationModel => {
  switch (action.type) {
    case NOTIFICATION_CREATION_OPTION.UPDATE_NOTIFICATION_INFORMATION:
      return { ...state, notificationInformation: action.payload }
    default:
      return state
  }
}

export default notificationCreationReducer
