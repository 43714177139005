import { Box, IconButton, SnackbarContent, styled } from '@mui/material'
import { colors } from 'library/styles/theme'

export const StyledCloseButton = styled(IconButton)({
  position: 'absolute',
  right: 10,
  top: 10,
})

export const StyledIconContainer = styled('div')({
  position: 'absolute',
  left: 28,
  top: 20,
  color: colors.lime,
  height: '30px',
  width: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    width: 'auto',
    height: 'auto',
  },
})

export const StyledMessage = styled(Box)({
  position: 'absolute',
  width: '285px',
  marginLeft: '60px',
  top: '20px',
})

export const StyledSnackbarContent = styled(SnackbarContent)({
  width: '432px',
  minHeight: '75px',
  height: '135px',
  background: colors.commonWhite,
})
