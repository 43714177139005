import React, { FunctionComponent } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MailIcon from '@mui/icons-material/Mail'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import { InputBaseComponentProps } from '@mui/material'

import { NumberFormatCustom } from 'components/General/PhoneNumberFormat'

import { emptyOrganization, User } from 'context/UserContext'
import { Role } from 'models/role'
import { UserDetailData } from 'models/services/user'

export const initialUserDetailData: User = {
  address: '',
  email: '',
  id: '',
  name: '',
  lastName: '',
  profilePicture: '',
  roles: [],
  telephoneNumber: '',
  organization: emptyOrganization,
}

export const initialUserData = (
  userInformationText: Record<string, string>,
  user: User
): UserDetailData => {
  const filteredRoles = Array.from(new Set(user.roles.map((a) => a.value))).map(
    (id) => {
      return user.roles.find((a) => a.value === id)
    }
  ) as Role[]

  return {
    picture: user.profilePicture,
    personal: [
      {
        id: 1,
        icon: <PersonIcon />,
        label: userInformationText.nameLabel,
        name: user.name,
      },
      {
        id: 2,
        icon: <PersonIcon />,
        label: userInformationText.lastNameLabel,
        name: user.lastName,
      },
      {
        id: 3,
        icon: <LocationOnIcon />,
        label: user.address || 'N/A',
        name: userInformationText.addressLabel,
        disabled: true,
      },
    ],
    contact: [
      {
        id: 1,
        icon: <PhoneIcon />,
        label: userInformationText.phoneLabel,
        name: user.telephoneNumber
          ? user.telephoneNumber.replace(/-/g, '')
          : '',
        inputProps: {
          inputComponent:
            NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
        },
      },
      {
        id: 2,
        icon: <MailIcon />,
        label: userInformationText.emailLabel,
        name: user.email,
        disabled: true,
      },
    ],
    roles: filteredRoles,
  }
}
