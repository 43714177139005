import React, { useState } from 'react'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { ImageList, ImageListItem, Typography } from '@mui/material'
import { Box, ErrorMessage, maxAllowedSizePerFileInKb } from 'library'
import { uploadImageService } from 'services/uploadImage'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import {
  DealerDetailDataType,
  handleUpdateDealerType,
} from 'models/services/operations/dealer'

import placeholderImage from 'images/blueDefaultAvatar.png'

import { FONT_WEIGHT } from 'styles/theme'

import DealerDetails from './DealerDetails'
import {
  GridBox,
  ImgContainer,
  StyledBorder,
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledImg,
} from './style'

type Props = {
  information: {
    picture: string
    specifications: NameInformation[]
    contact: NameInformation[]
    vehicles: number
  }
  dealerData: DealerDetailDataType
  handleUpdateDealer: ({
    newSpecifications,
    newLocation,
  }: handleUpdateDealerType) => void
  handleOptionsChange?: (cityId: string) => string
  isDealerContactEmpty: boolean
  submitLoading: boolean
}

const GeneralInformation = ({
  information,
  dealerData,
  handleUpdateDealer,
  handleOptionsChange,
  isDealerContactEmpty,
  submitLoading,
}: Props) => {
  const [specifications, setSpecifications] = useState<NameInformation[]>(
    information.specifications
  )
  const [picture, setPicture] = useState<string>(information.picture)
  const [pictureFile, setPictureFile] = useState<File | null>(null)
  const [pictureSizeErrorStatus, setPictureSizeErrorStatus] =
    useState<boolean>(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const { text } = useTranslation(textFiles.DEALER_DETAIL)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const { dealerInformation: translation } = text

  const handleSetSpecifications = (newSpecifications: NameInformation[]) => {
    setSpecifications(newSpecifications)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    /**
     * Check for changes in the current picture. Using an array instead of an string for the picture, because
     * further I will be changing this to support a whole array instead of just an image
     */
    if (information.picture !== picture) {
      if (pictureFile) {
        try {
          const response = await uploadImageService(pictureFile)
          handleUpdateDealer({
            newMainPicture: response.data,
            newSpecifications: specifications,
          })
          setPictureFile(null)
        } catch (e) {
          console.error('Failed to upload image', e)
        }
      }
    } else {
      handleUpdateDealer({
        newSpecifications: specifications,
      })
    }

    setHasUnsavedChanges(false)
  }

  const resetState = () => {
    setSpecifications(information.specifications)
    setPictureFile(null)
    setPicture('')
    setHasUnsavedChanges(false)
  }

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const { files } = e.target
    const file = files && files.length > 0 ? files[0] : null

    if (file && file.size <= maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(false)
      reader.onload = () => {
        if (reader.readyState === 2) {
          setHasUnsavedChanges(true)
          setPicture(String(reader.result))
        }
      }
      if (file) {
        setPictureFile(file)
        reader.readAsDataURL(file)
      }
    } else if (file && file.size > maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(true)
    }
  }

  return (
    <StyledContainer>
      <GridBox>
        <ImgContainer
          title={translation.images}
          description={translation.imagesDescription}
          contentStyle={{
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <ImageList
            sx={{
              width: '100%',
              height: 'auto',
              margin: '0',
              maxHeight: '675px',
            }}
            cols={1}
            rowHeight={322}
          >
            {picture ? (
              <ImageListItem key={picture} style={{ height: 'auto' }}>
                <section
                  style={{
                    position: 'relative',
                    height: '282px',
                    width: '375px',
                  }}
                >
                  <StyledImg
                    src={picture}
                    srcSet={picture}
                    alt="dealer-picture"
                    loading="lazy"
                  />
                  <input
                    disabled={submitLoading}
                    type="file"
                    name="input"
                    id="dealer-picture"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={imageHandler}
                  />
                  <StyledButton htmlFor="dealer-picture">
                    <CameraAltIcon />
                  </StyledButton>
                </section>
                {pictureSizeErrorStatus && (
                  <ErrorMessage
                    text={generalText.fileSize.sizeError.replace('%d', 3)}
                    sx={{ marginTop: '5px' }}
                  />
                )}
              </ImageListItem>
            ) : (
              <ImageListItem style={{ height: 'auto' }}>
                <section
                  style={{
                    position: 'relative',
                    height: '282px',
                    width: '375px',
                  }}
                >
                  <StyledImg
                    src={placeholderImage}
                    srcSet={placeholderImage}
                    alt="dealer-picture"
                    loading="lazy"
                  />
                  <input
                    disabled={submitLoading}
                    type="file"
                    name="input"
                    id="dealer-picture"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={imageHandler}
                  />
                  <StyledButton htmlFor="dealer-picture">
                    <CameraAltIcon />
                  </StyledButton>
                </section>
                {pictureSizeErrorStatus && (
                  <ErrorMessage
                    text={generalText.fileSize.sizeError.replace('%d', 3)}
                    sx={{ marginTop: '5px' }}
                  />
                )}
              </ImageListItem>
            )}
          </ImageList>
          <DealerDetails dealer={dealerData} />
        </ImgContainer>
        <NameContainer
          title={translation.specifications}
          description={translation.specificationsDescription}
          width="100%"
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
          }}
          information={specifications}
          handleSetInformation={handleSetSpecifications}
          handleOptionsChange={handleOptionsChange}
        />
        <Box>
          <NameContainer
            title={translation.contact}
            description={translation.contactDescription}
            height="313px"
            width="100%"
            containerSx={{
              padding: '0px !important',
              paddingTop: '1rem !important',
            }}
            information={information.contact}
            editDisabled
          />

          <StyledBorder
            sx={{
              marginTop: isDealerContactEmpty ? '74px' : '22px',
            }}
          >
            <StyledBox>
              <Typography
                variant="h1"
                fontSize="70px"
                fontWeight={FONT_WEIGHT.BOLD}
                marginRight="10px"
              >
                {information.vehicles}
              </Typography>
              <Box paddingTop="15px">
                <Typography
                  variant="h1"
                  fontSize="22px"
                  fontWeight={FONT_WEIGHT.BOLD}
                >
                  {translation.uploadedVehicles}
                </Typography>
                <Typography variant="body2" fontSize="16px">
                  {translation.platform}
                </Typography>
              </Box>
            </StyledBox>
          </StyledBorder>
        </Box>
      </GridBox>
      <SaveChangesBlock
        handleSaveChanges={handleSaveChanges}
        resetState={resetState}
        submitLoading={submitLoading}
        shouldRender={hasUnsavedChanges}
      />
    </StyledContainer>
  )
}

export default GeneralInformation
