import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Avatar, ProgressBar } from 'library/components/Common'
import { Score } from 'library/models/inspector'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

import { StyledBox } from './style'

export type InspectorProps = {
  image?: string
  name: string
  lastName: string
  scores: Score
  inspectionTotal: number
}

export const Inspector = ({
  image,
  name,
  lastName,
  scores,
  inspectionTotal,
}: InspectorProps) => {
  return (
    <StyledBox>
      <section style={{ width: '10%', marginRight: '1rem' }}>
        <Avatar image={image} height={58} width={58} />
      </section>
      <section style={{ width: '90%' }}>
        <Grid
          justifyContent="space-around"
          container
          columns={16}
          spacing={1}
          marginBottom="0.5rem"
          width="100%"
        >
          <Grid item xs={7}>
            <Typography
              color={colors.black}
              fontWeight={FONT_WEIGHT.MEDIUM}
              variant="body2"
            >
              {name} {lastName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              color={colors.black}
              fontWeight={FONT_WEIGHT.MEDIUM}
              variant="body2"
            >
              {inspectionTotal}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              color={colors.black}
              fontWeight={FONT_WEIGHT.MEDIUM}
              variant="body2"
            >
              {scores.monthly[1]}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              color={colors.black}
              fontWeight={FONT_WEIGHT.MEDIUM}
              variant="body2"
            >
              {Math.trunc((scores.monthly[0] / scores.monthly[1]) * 100)}%
            </Typography>
          </Grid>
        </Grid>
        <ProgressBar
          progress={Math.trunc((scores.monthly[0] / scores.monthly[1]) * 100)}
          isWhiteBackground
        />
      </section>
    </StyledBox>
  )
}
