import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  cypressNextStepButton,
  cypressPreviousStepButton,
  cypressSubmitButton,
} from 'library/constants/cypress'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledButton, StyledButtonContainer } from './style'

export type ButtonNavigatorTextModel = {
  finishCreation: string
  next: string
  previous: string
}

export type ButtonNavigatorProps = {
  isFirstStep?: boolean
  isLastStep?: boolean
  previousFunction?: () => void
  nextFunction?: () => void
  confirmButtonType?: 'button' | 'submit' | 'reset'
  loading?: boolean
  width?: string | number
  additionalContainerStyles?: React.CSSProperties
  translation?: ButtonNavigatorTextModel
  /**
   * Optional button used to submit the form, in case the buttonContainer is placed outside the form.
   */
  formId?: string
}

export const ButtonNavigator = ({
  isFirstStep = false,
  isLastStep = false,
  previousFunction,
  nextFunction,
  confirmButtonType = 'button',
  loading = false,
  width,
  additionalContainerStyles,
  translation,
  formId,
}: ButtonNavigatorProps) => {
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const finish =
    translation?.finishCreation || generalText.buttons.finishCreation
  const next = translation?.next || generalText.buttons.nextStep

  return (
    <StyledButtonContainer
      component="section"
      width={width}
      sx={{ ...additionalContainerStyles }}
    >
      {isFirstStep ? null : (
        <StyledButton
          variant="text"
          onClick={previousFunction}
          data-cy={cypressPreviousStepButton}
          disabled={loading}
        >
          {translation?.previous || generalText.buttons.previousStep}
        </StyledButton>
      )}
      <StyledButton
        disabled={loading}
        startIcon={<ArrowForwardIosIcon />}
        size="small"
        type={confirmButtonType}
        role="button"
        onClick={nextFunction}
        data-cy={isLastStep ? cypressSubmitButton : cypressNextStepButton}
        form={formId}
      >
        {isLastStep ? finish : next}
      </StyledButton>
    </StyledButtonContainer>
  )
}
