import React from 'react'
import { ButtonNavigator } from 'library'

import { StyledContainer, StyledForm } from 'styles/creation'

import SliderContainer, { SliderProps } from '../SliderContainer'

export type ExtendedSliderProps = {
  id: string | number
  max?: number
} & SliderProps

type Props = {
  handleBack: () => void
  handleNext: () => void
  sliderItems: ExtendedSliderProps[]
}

const Skeleton = ({ handleBack, handleNext, sliderItems }: Props) => {
  return (
    <StyledContainer>
      <StyledForm>
        <div>
          {sliderItems.map((item) => (
            <SliderContainer
              key={item.name}
              title={item.title}
              name={item.name}
              info={item.info}
              max={item.max}
              setInfo={item.setInfo}
            />
          ))}
        </div>
      </StyledForm>
      <ButtonNavigator
        previousFunction={handleBack}
        nextFunction={handleNext}
      />
    </StyledContainer>
  )
}

export default Skeleton
