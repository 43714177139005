import { Container, styled } from '@mui/material'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

export const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.black,
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  margin: '1rem 0',

  svg: {
    color: colors.orange,
    width: '31px',
    height: '35px',
  },
})

export const StyledSpan = styled('span')({
  width: '90%',
  margin: '0.5em auto',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
})

export const BottomContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
  background: colors.background,
  height: 90,
  paddingTop: 30,
  paddingBottom: 30,

  svg: {
    color: `${colors.commonWhite} !important`,
    width: '21px',
    height: '23px',
    marginRight: '0.5em',
  },
})
