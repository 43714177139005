import React, { useState } from 'react'
import { Box, ButtonNavigator } from 'library'

import LocationSection from 'components/Common/Location'

import { DEALER_CREATION_OPTION } from 'constants/Operation/dealer'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { DealerCreationProps } from 'utils/Dealer/creation'

import { ContainerStyles, StyledBox, StyledContainer } from './style'

const DealerLocation = ({
  dealerData,
  handleBack,
  handleContinue,
  updateDealerData,
}: DealerCreationProps) => {
  const { dealerLocation } = dealerData

  const [error, setError] = useState<boolean>(false)

  const [address, setAddress] = useState<Address | undefined>(
    dealerLocation.address
  )
  const { text } = useTranslation(textFiles.DEALER_CREATION)

  const { location: translation } = text

  const handleSubmit = () => {
    if (address === undefined) {
      setError(true)
    } else {
      updateDealerData({
        type: DEALER_CREATION_OPTION.UPDATE_DEALER_LOCATION,
        payload: { address },
      })
      handleContinue()
    }
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    setError(false)
  }

  return (
    <StyledBox>
      <StyledContainer>
        <LocationSection
          translation={translation}
          address={address}
          showError={error}
          handleAddressChange={handleAddressChange}
          containerStyling={ContainerStyles}
          hasCoordinatePicker
          curboSpots={[]}
          circleCenter={
            address ? { lat: address.lat, lng: address.lng } : undefined
          }
          mapHeight="90%"
        />
      </StyledContainer>
      <Box sx={{ width: '100%' }}>
        <ButtonNavigator
          previousFunction={handleBack}
          nextFunction={handleSubmit}
          confirmButtonType="button"
        />
      </Box>
    </StyledBox>
  )
}

export default DealerLocation
