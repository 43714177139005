import { selectClasses, styled } from '@mui/material'
import { Select } from 'library'

export const StyledSelect = styled(Select)({
  width: '90%',
  height: '29px',
  [`.${selectClasses.select}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px',
  },
})
