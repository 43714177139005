import React from 'react'
import { Redirect } from 'react-router-dom'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Divider, Typography } from '@mui/material'
import { Box, formatDateLocalizedTime } from 'library'

import {
  GeneralViewContainer,
  ModuleInfoType,
} from 'components/General/GeneralView'

import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'

import { Layout } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

type Props = {
  modules: ModuleInfoType[]
}

const Home = ({ modules }: Props) => {
  const { text } = useTranslation(textFiles.GENERAL_VIEW)
  const { general: translation } = text
  const [selectedLanguage] = useLocale()
  const userName = useUser().user?.name
  const today = new Date()
  const todayInLetters = `${formatDateLocalizedTime(
    today,
    'MMMM do yyyy',
    selectedLanguage.code
  )}`

  if (modules.length === 1) {
    return <Redirect to={modules[0].route} />
  }

  return (
    <Layout maxWidth="1462px !important">
      <Typography variant="h3">{translation.title}</Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="50%"
      >
        <PlaylistAddCheckIcon
          fontSize="medium"
          sx={{ color: colors.gray, marginRight: '0.5rem' }}
        />
        <Typography
          variant="body2"
          color={colors.gray}
          sx={{
            span: {
              color: colors.orange,
            },
          }}
        >
          {translation.welcome} <span>{userName}</span>,{' '}
          {translation.date.replace('%date', todayInLetters)}
        </Typography>
      </Box>
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
          marginBottom: '1rem',
        }}
      />
      <GeneralViewContainer modules={modules} />
    </Layout>
  )
}

export default Home
