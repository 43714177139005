import React from 'react'
import { useMutation } from '@apollo/client'
import LockIcon from '@mui/icons-material/Lock'
import { useFormik } from 'formik'
import { ErrorMessage, TextField, useNotification } from 'library'
import * as yup from 'yup'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { UpdatePasswordModel } from 'models/AuthModel'

import { UPDATE_PASSWORD } from 'graphQL/User/mutations'

import { StyledForm, StyledTextfieldContainer } from './style'

type UpdatePasswordResult = {
  updateSelfPassword: {
    passwordUpdated: boolean
  }
}

type UpdatePasswordVariables = {
  updatePasswordInput: {
    newPassword: string
    oldPassword: string
  }
}

type ResetPasswordProps = {
  setLoading: React.Dispatch<boolean>
  setOpenModal: () => void
  upperSection?: JSX.Element
  bottomSection?: JSX.Element
}

const ResetPasswordSetup = ({
  setLoading,
  setOpenModal,
  upperSection,
  bottomSection,
}: ResetPasswordProps) => {
  const {
    text: {
      settings: { changePassword: translation },
    },
  } = useTranslation(textFiles.ACCOUNT)

  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { show } = useNotification()
  const [updatePassword, { loading }] = useMutation<
    UpdatePasswordResult,
    UpdatePasswordVariables
  >(UPDATE_PASSWORD, {
    onCompleted(data) {
      setLoading(false)
      if (data.updateSelfPassword.passwordUpdated) setOpenModal()
    },
    onError(error) {
      console.error('Error', error)
      show({
        updatedSeverity: 'error',
      })
      setLoading(false)
    },
  })
  const validationSchema = yup.object({
    oldPassword: yup
      .string()
      .min(8, validationText.invalidPassword)
      .required(validationText.fieldRequired),
    password: yup
      .string()
      .min(8, validationText.invalidPassword)
      .required(validationText.fieldRequired),
  })

  const formik = useFormik<UpdatePasswordModel>({
    initialValues: {
      oldPassword: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const { password, oldPassword } = values
      setLoading(true)
      updatePassword({
        variables: {
          updatePasswordInput: {
            newPassword: password,
            oldPassword,
          },
        },
      })
    },
  })

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault()
        formik.handleSubmit(e)
      }}
    >
      {upperSection}
      <StyledTextfieldContainer>
        <TextField
          disabled={loading}
          icon={
            <LockIcon
              color={
                formik.touched.password && Boolean(formik.errors.password)
                  ? 'error'
                  : 'inherit'
              }
            />
          }
          fullWidth
          placeholder={translation.currentPasswordPlaceholder}
          name="oldPassword"
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
          }
          sx={{ marginTop: '1.5rem' }}
          type="password"
        />
        {formik.touched.oldPassword && formik.errors.oldPassword ? (
          <ErrorMessage
            sx={{
              alignSelf: 'flex-start',
              position: 'absolute',
              margin: '0.5em 0 0 0.5em',
            }}
            text={formik.errors.oldPassword}
          />
        ) : null}
      </StyledTextfieldContainer>
      <StyledTextfieldContainer>
        <TextField
          disabled={loading}
          icon={
            <LockIcon
              color={
                formik.touched.password && Boolean(formik.errors.password)
                  ? 'error'
                  : 'inherit'
              }
            />
          }
          fullWidth
          placeholder={translation.newPasswordPlaceholder}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          sx={{ marginTop: '2.5rem' }}
          type="password"
        />
        {formik.touched.password && formik.errors.password ? (
          <ErrorMessage
            sx={{
              alignSelf: 'flex-start',
              position: 'absolute',
              margin: '0.5em 0 0 0.5em',
            }}
            text={formik.errors.password}
          />
        ) : null}
      </StyledTextfieldContainer>
      {bottomSection}
    </StyledForm>
  )
}

export default ResetPasswordSetup
