import { styled } from '@mui/material'
import { Box } from 'library/components/Common'
import { boxShadow, colors } from 'library/styles/theme'

export const StyledBox = styled(Box)({
  width: '92%',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  padding: '0.6rem',
  marginBottom: '1rem',
  backgroundColor: colors.backgroundGray,
  boxShadow,
  borderRadius: '4px',
})
