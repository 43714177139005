import React, { useState } from 'react'
import { ColorResult } from 'react-color'
import { ClickAwayListener, Typography } from '@mui/material'
import Popper from '@mui/material/Popper'

import {
  ColorPickerContainer,
  StyledHuePicker,
  StyledSketchPicker,
} from 'components/CarSettings/Color/Creation/ColorInfo/style'
import ColorsBoard from 'components/CarSettings/Color/Creation/ColorsBoard'

import { SetButton } from '../../Information/style'

type Props = {
  text: string
  keyCode: string
  handleChange: (value: string, keyCode: string) => void
  sx?: React.CSSProperties
}

const ColorButton = ({ text, keyCode, handleChange, sx }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleColorChange = (color: ColorResult) => {
    handleChange(color.hex, keyCode)
  }

  const handleColorBoardChange = (color: string) => {
    handleChange(color, keyCode)
  }

  return (
    <>
      <SetButton
        buttonType="secondary"
        onClick={handlePopperOpen}
        testId="set-color-button"
        sx={{ ...sx }}
      >
        <Typography variant="body2">{text}</Typography>
      </SetButton>
      <Popper
        id="picker-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null)
            setOpen(false)
          }}
        >
          <ColorPickerContainer>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <StyledSketchPicker
                color={text}
                onChangeComplete={handleColorChange}
              />
              <StyledHuePicker
                color={text}
                onChangeComplete={handleColorChange}
                width="230px"
              />
            </div>
            <ColorsBoard handleColorBoardChange={handleColorBoardChange} />
          </ColorPickerContainer>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default ColorButton
