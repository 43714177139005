import { GridSortModel } from '@mui/x-data-grid'
import { addDays, endOfDay, formatISO, startOfDay } from 'date-fns'
import { pageSizes } from 'library/constants/Listing/pageSizes'
import { UrlParamNames } from 'library/constants/urlQuery'
import { CalendarRangeType } from 'library/models/date'
import { Filter } from 'library/models/filter'
import queryString from 'query-string'
import { number } from 'yup'

export const verifyNumber = (numberString: string) => {
  // Verify that the string is a number
  if (!/\d+/.test(numberString)) return false

  const isNumber = !Number.isNaN(number)
  if (isNumber) {
    return Number(numberString)
  }

  return false
}

export const verifyPageSize = (numberString: string) => {
  // Verify that the string is a number and is either 10,25 or 50
  const value = verifyNumber(numberString)
  if (value && pageSizes.includes(value)) {
    return value
  }

  return 10
}

export const verifySortModel = (sort: string) => {
  // Verify that the string has this pattern [{"field":"columnField","sort":"asc|desc"}]
  if (!/[{"field":"[a-zA-Z0-9]+","sort":"(asc|desc)"\}\]/s.test(sort))
    return false

  const sortArray = sort.replace(/[\u005B|\u005D]/g, '').split('|')
  return sortArray.map((item) => JSON.parse(item)) as GridSortModel
}

export const verifyFieldsFormat = (fields: string) => {
  // Verify that the string has this pattern ["field1","field2"]
  if (!/[(\s+)(,a-zA-Z*)]/.test(fields)) return false
  const fieldsArray = fields.replace(/[\u005B|\u005D]/g, '').split(',')

  return fieldsArray.sort()
}

/**
 *  Returns a dateRange object with the correct format for the state or returns false if the input is not valid
 */
export const verifyIsoRangeFormat = (isoRangeDate: string) => {
  // Verify that the string has this pattern [{"from":"yyyy-MM-dd","to":"yyyy-MM-dd"}]
  if (
    !/\{("from":"(\d{4}-\d{2}-\d{2})")?,?("to":"(\d{4}-\d{2}-\d{2})")?\}/.test(
      isoRangeDate
    )
  )
    return false
  const range = JSON.parse(isoRangeDate) as { from: string; to: string }
  const dateFrom = startOfDay(addDays(new Date(range.from), 1))
  const dateTo = endOfDay(addDays(new Date(range.to), 1))

  const fromIsValid =
    dateFrom instanceof Date &&
    !Number.isNaN(dateFrom.getTime()) &&
    formatISO(dateFrom, { representation: 'date' }) === range.from

  const toIsValid =
    dateTo instanceof Date &&
    !Number.isNaN(dateTo.getTime()) &&
    formatISO(dateTo, { representation: 'date' }) === range.to

  const result: CalendarRangeType = {
    fromDate: fromIsValid ? dateFrom : null,
    toDate: toIsValid ? dateTo : null,
  }
  return result
}

type FilterInputType = {
  key: string
  value: string | number
}
export const verifyFilters = (filters: string) => {
  // Verify that the string has this pattern [{"field_(in|nin)":"value"}]
  if (
    !/\[({"[a-zA-Z0-9]+_(nin|in)+":("[\w.-]+"|[\w.-]+)\},)*{"[a-zA-Z0-9]+_(nin|in)+":("[\w.-]+"|[\w.-]+)\}+\]/.test(
      filters
    )
  )
    return false

  if (filters && filters.length > 0) {
    const filtersArray = filters.replace(/[\u005B|\u005D]/g, '').split(',')

    const validatedArray: FilterInputType[] = []
    filtersArray.forEach((item) => {
      try {
        const filter = Object.keys(JSON.parse(item))[0]
        validatedArray.push({
          key: filter,
          value: JSON.parse(item)[filter],
        } as FilterInputType)
      } catch (e) {
        console.error("Couldn't parse filter: ", item)
      }
    })

    return validatedArray.map((item) => {
      const title = item.key.split('_')
      return {
        fieldOrEntry: title[0],
        operator: title[1],
        valueToCompare: item.value,
      } as Filter
    })
  }

  return false
}

export const verifyParam = (param: string, search: string) => {
  const paramExists = queryString.parse(search)[param]
  if (!paramExists) return undefined
  switch (param) {
    case UrlParamNames.LIMIT:
      return verifyPageSize(queryString.parse(search).limit as string)
    case UrlParamNames.PAGE:
      return verifyNumber(queryString.parse(search).page as string)
    case UrlParamNames.SORT:
      return verifySortModel(queryString.parse(search).sort as string)
    case UrlParamNames.FIELDS:
      return verifyFieldsFormat(queryString.parse(search).fields as string)
    case UrlParamNames.FILTERS:
      return verifyFilters(queryString.parse(search).filters as string)
    case UrlParamNames.SEARCH:
      return queryString.parse(search).search
    case UrlParamNames.DATE:
      return verifyIsoRangeFormat(queryString.parse(search).date as string)
    default:
      return undefined
  }
}
