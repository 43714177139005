import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Typography } from '@mui/material'
import { Box } from 'library/components/Common/Box'
import { Button } from 'library/components/Common/Button'
import { Country } from 'library/models/country'
import { colors, FONT_WEIGHT, smallBoxShadow } from 'library/styles/theme'

import { Flag } from './Flag'
import { StyledLink, StyledMenu, StyledMenuItem } from './style'

export type CountryMenuProps = {
  currentCountry: Country
  countries: Country[]
  label: string
}
export const CountryMenu = ({
  currentCountry,
  countries,
  label,
}: CountryMenuProps) => {
  const filteredCountries = currentCountry
    ? countries.filter(
        (country) => country.countryCode !== currentCountry.countryCode
      )
    : countries

  const currentBackOfficeCountry = currentCountry
    ? countries.find(
        (country) => country.countryCode === currentCountry.countryCode
      )
    : null

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return currentBackOfficeCountry ? (
    <Box display="flex" justifyContent="flex-end" width="73%">
      <Box alignItems="flex-end" display="flex" flexDirection="column">
        <Button
          buttonType="info"
          size="small"
          disableRipple
          sx={{
            boxShadow: smallBoxShadow,
            color: colors.altBlack,
            ':hover': {
              boxShadow: smallBoxShadow,
            },
            width: '250px',
          }}
          startIcon={<Flag image={currentBackOfficeCountry.flag} />}
          endIcon={<KeyboardArrowDownIcon sx={{ color: colors.altBlack }} />}
          onClick={handleClick}
          testId="country-button"
        >
          <Typography
            color={colors.altBlack}
            fontWeight={FONT_WEIGHT.MEDIUM}
            variant="body2"
            marginRight="auto"
          >
            {currentBackOfficeCountry.name}
          </Typography>
        </Button>
        <Typography color={colors.gray} variant="subtitle1">
          {label}
        </Typography>

        {filteredCountries.length > 0 ? (
          <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {filteredCountries.map((country) => (
              <StyledLink
                href={country.website}
                target="_blank"
                rel="noopener noreferrer"
                key={country.countryCode}
                data-cy={`${country.countryCode}-link`}
              >
                <StyledMenuItem onClick={handleClose}>
                  <Flag image={country.flag} />
                  <Typography
                    color={colors.gray}
                    fontWeight={FONT_WEIGHT.MEDIUM}
                    variant="body2"
                    marginRight="auto"
                    marginLeft="8px"
                  >
                    {country.name}
                  </Typography>
                </StyledMenuItem>
              </StyledLink>
            ))}
          </StyledMenu>
        ) : null}
      </Box>
    </Box>
  ) : null
}
