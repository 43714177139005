import React, { FunctionComponent } from 'react'
import {
  DirectionsCar,
  LocationCity,
  LocationOn,
  Map,
  Phone,
} from '@mui/icons-material'
import { InputBaseComponentProps } from '@mui/material'

import { StateOption } from 'pages/Operation/CurboSpot/Detail'

import { NumberFormatCustom } from 'components/General/PhoneNumberFormat'

import { Option } from 'models/select'
import {
  CurboSpotDetailDataType,
  WeekDayEnum,
} from 'models/services/operations/curboSpot'

export enum CurboSpotColumnField {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  STATE = 'state',
  CITY = 'city',
  TELEPHONE_NUMBER = 'telephoneNumber',
}

export const initialCurboSpotData = (
  translation: Record<string, string>,
  information: CurboSpotDetailDataType,
  states: StateOption[]
) => {
  const { address, city, name, telephoneNumber } = information
  const { id, state } = city

  const currentState = states.find((item) => item.value === state.id)
  const newCities: Option[] = currentState!.cities

  return [
    {
      id: 0,
      icon: <DirectionsCar />,
      label: translation.nameLabel,
      name,
    },
    {
      id: 1,
      icon: <Phone />,
      label: translation.phoneLabel,
      name: telephoneNumber,
      inputProps: {
        inputComponent:
          NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
      },
    },
    {
      id: 2,
      icon: <Map />,
      label: translation.cityLabel,
      name: id,
      options: newCities,
    },
    {
      id: 3,
      icon: <LocationCity />,
      label: translation.stateLabel,
      name: state.id,
      options: states,
    },
    {
      id: 4,
      icon: <LocationOn />,
      label: translation.addressLabel,
      name: address,
      inverted: true,
      disabled: true,
    },
  ]
}

export const getDaysOptions = (
  translation: Record<string, string>
): Option[] => {
  return [
    {
      name: translation.monday,
      value: WeekDayEnum.MONDAY,
    },
    {
      name: translation.tuesday,
      value: WeekDayEnum.TUESDAY,
    },
    {
      name: translation.wednesday,
      value: WeekDayEnum.WEDNESDAY,
    },
    {
      name: translation.thursday,
      value: WeekDayEnum.THURSDAY,
    },
    {
      name: translation.friday,
      value: WeekDayEnum.FRIDAY,
    },
    {
      name: translation.saturday,
      value: WeekDayEnum.SATURDAY,
    },
    {
      name: translation.sunday,
      value: WeekDayEnum.SUNDAY,
    },
  ]
}

export enum CURBO_SPOT_CREATION_OPTION {
  UPDATE_CURBO_SPOT_INFORMATION = 'updateSpotInformation',
  UPDATE_CURBO_SPOT_LOCATION = 'updateSpotLocation',
  UPDATE_CURBO_SPOT_SCHEDULE = 'updateSpotSchedule',
}
