import React, { useState } from 'react'
import { ApolloConsumer } from '@apollo/client'
import CheckIcon from '@mui/icons-material/Check'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import { SelectChangeEvent, Typography } from '@mui/material'
import { Box, Button, Container, Select } from 'library'

import { LANGUAGES } from 'constants/language'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/select'

import { colors } from 'styles/theme'

const createLanguageSelection = (): Option[] => {
  return LANGUAGES.map((language) => {
    return {
      name: language.name,
      value: language.code,
    }
  })
}

const LanguageSettings = () => {
  const [currentLanguage, updateLanguage] = useLocale()
  const { text } = useTranslation(textFiles.ACCOUNT)
  const {
    settings: { languageSettings: translation },
  } = text
  const [languageValue, setLanguageValue] = useState<string>(
    currentLanguage.code
  )
  const languages = createLanguageSelection()

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    setLanguageValue(event.target.value as string)
  }

  const handleUpdateLanguage = () => {
    const selectedLanguage = LANGUAGES.find(
      (language) => language.code === languageValue
    )
    updateLanguage(selectedLanguage!)
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <>
          <Container
            title={translation.title}
            description={translation.description}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="252px"
            width="auto"
            paddingBottom="0.75rem!important"
          >
            <Box display="flex" flexDirection="column">
              <Typography
                color={colors.black}
                variant="body2"
                marginBottom="1rem"
              >
                {translation.selectTitle}
              </Typography>
              <Select
                icon={<TextFormatIcon />}
                onChange={handleLanguageChange}
                options={languages}
                value={languageValue}
                testId="language-select"
              />
              <Button
                disabled={currentLanguage.code === languageValue}
                size="medium"
                startIcon={<CheckIcon />}
                onClick={() => {
                  handleUpdateLanguage()
                  client.resetStore()
                }}
                sx={{ marginTop: '1rem' }}
                data-cy="save-language-button"
              >
                {translation.selectButton}
              </Button>
            </Box>
          </Container>
        </>
      )}
    </ApolloConsumer>
  )
}

export default LanguageSettings
