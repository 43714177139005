import { styled } from '@mui/material'
import { Box, Container } from 'library/components/Common'
import { BREAK_POINTS } from 'library/styles/breakpoints'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

export const StyledItem = styled(Container)({
  maxHeight: 145,
  color: colors.placeholderGray,
  borderRadius: 0,
  padding: 0,
  '& > div:nth-of-type(2)': {
    margin: 0,
  },
  ul: {
    paddingLeft: '35px',
    fontSize: 14,
    fontWeight: FONT_WEIGHT.MEDIUM,
    li: {
      '&::marker': {
        fontSize: 16,
        color: colors.orange,
      },
      span: {
        color: colors.black,
      },
    },
  },
  [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
    width: '32%',
  },
})

export const StyledElement = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: 14,
  minHeight: 48,
  width: '100%',
  fontWeight: FONT_WEIGHT.MEDIUM,
  padding: '0px 8px',
  svg: {
    color: colors.orange,
    height: 20,
    width: 20,
    marginRight: '0.5rem',
  },
})
