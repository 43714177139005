import React, { useState } from 'react'
import { useFormik } from 'formik'
import { ButtonNavigator, ErrorMessage, sleep } from 'library'
import * as yup from 'yup'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'
import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import { TranslationContainer } from 'components/CarSettings/style'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { CATEGORY_CREATION_OPTION } from 'constants/category'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Category } from 'models/car'
import { PublicationStatus } from 'models/status'
import { TranslationChange } from 'models/translations'
import { CategoryCreationProps } from 'utils/CarSettings/Category/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const CategoryInfo = ({
  handleContinue,
  categoryData,
  updateCategoryData,
}: CategoryCreationProps) => {
  const { categoryInformation } = categoryData
  const [categoryStatus, setCategoryStatus] = useState<boolean>(
    categoryInformation.status === PublicationStatus.PUBLISHED
  )
  const [pictureState, setPictureState] = useState<string>(
    categoryInformation.picture
  )
  const [fileState, setFileState] = useState<File | null>(null)

  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >(categoryInformation.translations)

  const { text: translation } = useTranslation(textFiles.CATEGORY_CREATION)
  const { information } = translation

  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const handleChangeTranslation = (
    translations: TranslationItemType[],
    type: TranslationChange
  ) => {
    sessionStorage.setItem('type', type)
    setAddedTranslations(translations)
  }

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<Category>>({
    initialValues: {
      name: categoryInformation.name,
    },
    validationSchema,
    onSubmit: async (values) => {
      await sleep(1000)
      updateCategoryData({
        type: CATEGORY_CREATION_OPTION.UPDATE_CATEGORY_INFORMATION,
        payload: {
          name: values.name as string,
          translations: addedTranslations,
          picture: pictureState,
          pictureFile: fileState,
          status: categoryStatus
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      })

      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={information.isPublished}
            description={information.isPublishedDescription}
            status={categoryStatus}
            handleStatus={setCategoryStatus}
            testId="published"
          />
          <StyledTextFieldContainer
            title={information.name}
            description={information.nameDescription}
          >
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={information.placeholder}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <AvatarContainer
            title={information.logo}
            description={information.logoDescription}
            picture={pictureState}
            handlePictureChange={setPictureState}
            handleFileChange={setFileState}
          />
          <TranslationContainer>
            <Translation
              addedTranslations={addedTranslations}
              handleChangeTranslation={handleChangeTranslation}
              translationDescription={information.translationsDescription}
            />
          </TranslationContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default CategoryInfo
