import React from 'react'
import CommentIcon from '@mui/icons-material/Comment'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { SummaryItem } from 'library'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'

import { getEntityTypes } from 'constants/blackList'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { EntityCreationProps } from 'utils/CarSettings/BlackList/creation'

import { SectionContainer } from 'styles/creation'

const Summary = ({
  handleBack,
  handleSubmit,
  entityData,
  submitLoading,
}: EntityCreationProps) => {
  const { entityInformation } = entityData
  const { text } = useTranslation(textFiles.BLACK_LIST_CREATION)
  const { summary, typeLabels } = text

  const types = getEntityTypes(typeLabels)

  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      title={summary.title}
      description={summary.description}
      submitLoading={submitLoading}
    >
      <SectionContainer>
        <SummaryItem
          title={summary.type}
          text={
            types.find((type) => type.value === entityInformation.type)!.name
          }
          icon={<DirectionsCarIcon />}
        />
        <SummaryItem
          title={summary.name}
          text={
            entityInformation.options.find(
              (option) => option.value === entityInformation.name
            )!.name
          }
          icon={<DirectionsCarIcon />}
        />
        {entityInformation.comments ? (
          <SummaryItem
            title={summary.comments}
            text={entityInformation.comments}
            icon={<CommentIcon />}
          />
        ) : null}
      </SectionContainer>
    </Skeleton>
  )
}

export default Summary
