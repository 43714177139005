import React from 'react'
import { Typography } from '@mui/material'
import { Avatar } from 'library/components/Common/Avatar'
import { Language } from 'library/models/language'
import { colors, FONT_WEIGHT } from 'library/styles/theme'
import { formatDateLocalizedTime } from 'library/utils/date'

import { StyledBox, StyledHour, StyledText } from './style'

export type ActivityProps = {
  img?: string
  name: string
  description: string
  hour: Date
  language: Language
}

export const Activity = ({
  img,
  name,
  description,
  hour,
  language,
}: ActivityProps) => {
  return (
    <StyledBox>
      <Avatar image={img} height="48px" width="48px" />
      <StyledText component="section">
        <Typography
          variant="subtitle1"
          style={{ color: colors.black, fontWeight: FONT_WEIGHT.SEMI_BOLD }}
        >
          {name}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            color: colors.gray,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {description}
        </Typography>
      </StyledText>
      <StyledHour component="section">
        <Typography
          variant="subtitle2"
          style={{ fontWeight: FONT_WEIGHT.SEMI_BOLD, paddingBottom: '.5rem' }}
        >
          {formatDateLocalizedTime(hour, 'p', language.code)}
        </Typography>
      </StyledHour>
    </StyledBox>
  )
}
