import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import {
  ClickAwayListener,
  ListItem,
  MenuItem,
  Popper,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Box, Container } from 'library'

import NotesComponent, { NoteType } from 'components/Common/Notes'
import { ArrowIcon } from 'components/General/DownloadModal/style'
import CommissionAmountCard from 'components/Inspector/Detail/CommissionAmountCard'
import CommissionsDetail, {
  CommissionsDetailProps,
} from 'components/Inspector/Detail/CommissionsDetail'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors } from 'styles/theme'

import {
  CommissionAmountContainer,
  RowTitle,
  StyledAmountEditContainer,
  StyledBox,
  StyledButton,
  StyledList,
  StyledTextField,
} from './style'

type CommissionsProps = {
  notes: NoteType[]
  handleNotesChange: (newNotes: NoteType[]) => void
  commission: number
  commissionCurrency: string
} & CommissionsDetailProps

const Commissions = ({
  inspectorData,
  pieChartData,
  percentage,
  carsInspected,
  carsInspectedObjective,
  notes,
  handleNotesChange,
  commission,
  commissionCurrency,
}: CommissionsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectOpen, setSelectOpen] = useState<boolean>(false)
  const [commissionValue, setCommissionValue] = useState<string>('usd')

  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)

  const open = Boolean(anchorEl)

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopperClose = () => {
    setAnchorEl(null)
  }
  const handleCommissionValueOpen = () => {
    setSelectOpen(true)
  }
  const handleCommissionValueClose = () => {
    setSelectOpen(false)
  }
  const handleCommissionValueChange = (event: SelectChangeEvent) => {
    setCommissionValue(event.target.value as string)
  }

  return (
    <StyledBox>
      <CommissionsDetail
        inspectorData={inspectorData}
        pieChartData={pieChartData}
        percentage={percentage}
        carsInspected={carsInspected}
        carsInspectedObjective={carsInspectedObjective}
      />
      <NotesComponent notes={notes} handleDataChange={handleNotesChange} />
      <Container
        title={text.commissions.commissionAmount.title}
        description={text.commissions.commissionAmount.description}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CommissionAmountCard
            name={inspectorData.name}
            commission={commission}
            commissionCurrency={commissionCurrency}
            carsInspected={carsInspected}
            carsInspectedObjective={carsInspectedObjective}
          />
          <StyledButton startIcon={<EditIcon />} onClick={handlePopperOpen}>
            {text.commissions.commissionAmount.editButton}
          </StyledButton>
        </Box>
        <Popper
          id="commissions-edit"
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <ClickAwayListener
            onClickAway={() => {
              if (selectOpen) return
              handlePopperClose()
            }}
          >
            <StyledAmountEditContainer>
              <Container
                title={text.commissions.editCommissionAmount.title}
                description={text.commissions.editCommissionAmount.description}
                sx={{
                  width: '525px',
                  height: '275px',
                }}
              >
                <StyledList>
                  <ListItem>
                    {text.commissions.editCommissionAmount.currentCommision}
                  </ListItem>
                </StyledList>
                <CommissionAmountContainer>
                  <Box sx={{ display: 'flex' }}>
                    <StyledTextField
                      type="number"
                      defaultValue={`${commission}`}
                    />
                    <Select
                      value={commissionValue}
                      onChange={handleCommissionValueChange}
                      displayEmpty
                      defaultValue={`${commission}`}
                      sx={{
                        width: '82px',
                        color: colors.border,
                        backgroundColor: colors.commonWhite,
                        borderColor: 'transparent',
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                        TransitionProps: {
                          onExited: handleCommissionValueClose,
                        },
                      }}
                      onOpen={handleCommissionValueOpen}
                      IconComponent={ArrowIcon}
                    >
                      <MenuItem value="dop">
                        <RowTitle variant="body2">RD$</RowTitle>
                      </MenuItem>
                      <MenuItem selected value="usd">
                        <RowTitle variant="body2">US$</RowTitle>
                      </MenuItem>
                    </Select>
                  </Box>

                  <StyledButton
                    startIcon={<SaveIcon />}
                    onClick={handlePopperClose}
                  >
                    {text.commissions.editCommissionAmount.saveChanges}
                  </StyledButton>
                </CommissionAmountContainer>
              </Container>
            </StyledAmountEditContainer>
          </ClickAwayListener>
        </Popper>
      </Container>
    </StyledBox>
  )
}
export default Commissions
