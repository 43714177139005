import React from 'react'
import { Slider } from 'library'

import {
  MEASUREMENT_OPTION,
  MILEAGE_OPTION,
  SPACING_OPTION,
} from 'constants/trim'
import {
  MeasurementInformationModel,
  MileageInformationModel,
  SpacingInformationModel,
} from 'models/trimCreation'

import { StyledTextFieldContainer } from './style'

export type SliderProps = {
  title: string
  name: MILEAGE_OPTION | SPACING_OPTION | MEASUREMENT_OPTION
  info:
    | MileageInformationModel
    | MeasurementInformationModel
    | SpacingInformationModel
  setInfo: (newValue: number | number[]) => void
  max?: number
}

const SliderContainer = ({ title, name, info, max, setInfo }: SliderProps) => {
  const MAX = 1000
  const MIN = 1
  return (
    <StyledTextFieldContainer title={title}>
      <Slider
        value={info[name]}
        handleValueChange={setInfo}
        maxValue={max || MAX}
        minValue={MIN}
        withTextField
      />
    </StyledTextFieldContainer>
  )
}

export default SliderContainer
