import React from 'react'
import { Typography } from '@mui/material'
import { Box } from 'library'

import { NotificationInformation } from 'components/Common/Notification'

import { colors } from 'styles/theme'

export const dummyHistoryLogItems: NotificationInformation[] = [
  {
    userName: 'John Doe',
    type: 'redirect',
    url: '/inspectors/detail/23',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodsit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
    time: '12:50',
    id: 'not1',
  },
  {
    userName: 'John Doe',
    type: 'redirect',
    url: '/inspectors/detail/23',
    content: (
      <Box>
        Probando esto a ver como lo renderiza{' '}
        <Typography
          variant="subtitle1"
          sx={{
            color: colors.yellow,
            display: 'inline',
            backgroundColor: colors.lightYellow,
            padding: '3px',
          }}
        >
          Pending
        </Typography>{' '}
        to{' '}
        <Typography
          variant="subtitle1"
          sx={{
            color: colors.red,
            display: 'inline',
            backgroundColor: colors.lightPink,
            padding: '3px',
          }}
        >
          Rejected
        </Typography>
      </Box>
    ),
    time: '12:50',
    id: 'not2',
  },
  {
    userName: 'John Doe',
    type: 'default',
    url: '/inspectors/detail/23',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
    time: '12:50',
    id: 'not3',
  },
  {
    userName: 'John Doe',
    type: 'redirect',
    url: '/inspectors/detail/23',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodsit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
    time: '12:50',
    id: 'not4',
  },
  {
    userName: 'John Doe',
    type: 'default',
    url: '/inspectors/detail/23',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodsit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
    time: '12:50',
    id: 'not7',
  },
  {
    userName: 'John Doe',
    type: 'redirect',
    url: '/inspectors/detail/23',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodsit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
    time: '12:50',
    id: 'not6',
  },
  {
    userName: 'John Doe',
    type: 'redirect',
    url: '/inspectors/detail/23',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodsit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
    time: '12:50',
    id: 'not8',
  },
  {
    userName: 'John Doe',
    type: 'redirect',
    url: '/inspectors/detail/23',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodsit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
    time: '12:50',
    id: 'not5',
  },
]

export enum InspectionColumnField {
  ID = 'id',
  DATE = 'date',
  PROVIDER = 'provider',
  PHONE = 'providerTelephoneNumber',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  CAR_NAME = 'carName',
  SOURCE = 'source',
}

export const meridiamOptions = [
  {
    name: 'AM',
    value: 'AM',
  },
  {
    name: 'PM',
    value: 'PM',
  },
]

export const testReport = {
  fields: ['id', 'name'],
  reportName: 'Listing_Report',
  entries: 123,
}

export const pendingHash = '#pending'
