import React, { FunctionComponent, useState } from 'react'
import { InputBaseComponentProps, SelectChangeEvent } from '@mui/material'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { ButtonNavigator, ErrorMessage, Select } from 'library'

import { NumberFormatCustom } from 'components/General/PhoneNumberFormat'

import { placeholderPhone } from 'constants/dealer'
import { INSPECTION_CREATION_OPTION } from 'constants/Inspection/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { DealerInformationModel } from 'models/inspection'
import { InspectionCreationProps } from 'utils/Inspection/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const DealerInformation = ({
  handleContinue,
  inspectionData,
  updateInspectionData,
  dealers,
}: InspectionCreationProps) => {
  const { text: translation } = useTranslation(textFiles.INSPECTION_CREATION)
  const { dealerInformation } = translation
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const [dealerInfo, setDealerInfo] = useState<DealerInformationModel>(
    inspectionData.dealerInformation
  )
  const [
    stepAmount,
    // setStepAmount
  ] = useState<number>(dealerInfo.inspectionAmount)
  const [hasError, setHasError] = useState<boolean>(false)

  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    const dealer = dealers.find(
      (element) => element.value === event.target.value
    )

    setDealerInfo({
      ...dealerInfo,
      dealer: dealer!,
    })
    setHasError(false)
  }

  // const handleAlignment = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newAlignment: boolean
  // ) => {
  //   if (newAlignment !== null) {
  //     setDealerInfo({
  //       ...dealerInfo,
  //       multipleInspections: newAlignment,
  //     })
  //   }
  // }

  const handleSubmit = () => {
    if (!dealerInfo.dealer.value) {
      setHasError(true)
      return
    }

    updateInspectionData({
      type: INSPECTION_CREATION_OPTION.UPDATE_DEALER_INFORMATION,
      payload: {
        ...dealerInfo,
        inspectionAmount: !dealerInfo.multipleInspections ? 1 : stepAmount,
        multipleInspections: dealerInfo.multipleInspections,
        dealer: dealerInfo.dealer,
      },
    })

    handleContinue()
  }

  return (
    <StyledContainer>
      <StyledForm>
        <div>
          {/* <StyledTextFieldContainer
            title={dealerInformation.multipleInspection}
            description={dealerInformation.multipleInspectionDescription}
          >
            <ToggleButtonGroup
              value={dealerInfo.multipleInspections}
              exclusive
              onChange={handleAlignment}
            >
              <StyledToggleButton value>
                {dealerInformation.toggleYes}
              </StyledToggleButton>
              <StyledToggleButton value={false}>
                {dealerInformation.toggleNo}
              </StyledToggleButton>
            </ToggleButtonGroup>
          </StyledTextFieldContainer> */}
          {/* <div style={{ height: '97.1562px' }}>
            {dealerInfo.multipleInspections ? (
              <StyledTextFieldContainer
                title={dealerInformation.carsAmount}
                description={dealerInformation.carsAmountDescription}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <StyledTextField
                    variant="outlined"
                    value={stepAmount}
                    disabled
                    sx={{ width: '87px !important' }}
                  />
                  <CounterDiv>
                    <CounterButton
                      onClick={() => {
                        if (stepAmount < 5) setStepAmount(stepAmount + 1)
                      }}
                    >
                      <ArrowDropUpIcon />
                    </CounterButton>
                    <CounterButton
                      style={{ justifyContent: 'flex-end' }}
                      onClick={() => {
                        if (stepAmount > 1) setStepAmount(stepAmount - 1)
                      }}
                    >
                      <ArrowDropDownIcon />
                    </CounterButton>
                  </CounterDiv>
                </div>
              </StyledTextFieldContainer>
            ) : null}
          </div> */}
          <StyledTextFieldContainer title={dealerInformation.dealerName}>
            <Select
              options={dealers}
              variant="outlined"
              name="dealerName"
              value={dealerInfo.dealer.value}
              label={dealerInformation.dealerName}
              onChange={handleSelect}
              sx={{ fontSize: 12, height: 40 }}
              error={hasError}
              testId="dealer-select"
            />
            {hasError && (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={validationText.fieldRequired}
              />
            )}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={dealerInformation.dealerPhone}>
            <StyledTextField
              variant="outlined"
              name="dealerPhone"
              placeholder={placeholderPhone}
              value={dealerInfo.dealer.telephoneNumber}
              disabled
              InputProps={{
                inputComponent:
                  NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
              }}
            />
          </StyledTextFieldContainer>

          {dealerInfo.dealer.dealerContacts.length > 0 ? (
            <>
              <StyledTextFieldContainer
                title={dealerInformation.representativeName}
              >
                <StyledTextField
                  variant="outlined"
                  name="representativeName"
                  value={dealerInfo.dealer.dealerContacts[0].agent}
                  placeholder="Marcos Pereyra"
                  disabled
                />
              </StyledTextFieldContainer>

              <StyledTextFieldContainer
                title={dealerInformation.representativePhone}
              >
                <StyledTextField
                  variant="outlined"
                  name="representativePhone"
                  placeholder={placeholderPhone}
                  value={dealerInfo.dealer.dealerContacts[0].telephoneNumber}
                  disabled
                  InputProps={{
                    inputComponent:
                      NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                  }}
                />
              </StyledTextFieldContainer>
            </>
          ) : null}
          <StyledTextFieldContainer
            sx={{ width: '450px !important' }}
            title={dealerInformation.dealerLocation}
          >
            <StyledTextField
              variant="outlined"
              name="dealerLocation"
              value={dealerInfo.dealer.address}
              placeholder="Av 4ta María Auxiliadora STA 33"
              fullWidth
              disabled
              sx={{
                width: `${dealerInfo.dealer.address.length * 6.5}px !important`,
                minWidth: '300px!important',
              }}
            />
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator isFirstStep nextFunction={handleSubmit} />
    </StyledContainer>
  )
}

export default DealerInformation
