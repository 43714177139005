import React from 'react'
import { Typography } from '@mui/material'
import { Avatar, Box } from 'library'

import { textFiles } from 'constants/textFiles'
import { HistoryLogType } from 'models/historyLog'

import UserImage from 'images/UserImagePlaceholder.png'

import { HistoryLogMessage } from './HistoryLogMessage'
import { TimeStamp } from './style'
import { formatTimeStamp } from '.'

const HistoryRowItem = ({
  name,
  timestamp,
  content,
  dateFormat,
  recent,
  file = textFiles.HISTORY_LOG,
}: HistoryLogType) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      marginX={recent ? '1rem' : '4rem'}
      marginY="2rem"
    >
      <Box width="55px">
        <Avatar height={54} width={54} variant="circular" image={UserImage} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        paddingLeft="1rem"
        paddingY="5px"
      >
        <Typography variant="body1">{name}</Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <HistoryLogMessage {...content} file={file} />
          <TimeStamp variant="subtitle2">
            {formatTimeStamp(timestamp, dateFormat!)}
          </TimeStamp>
        </Box>
      </Box>
    </Box>
  )
}

export { HistoryRowItem }
