import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { ClickAwayListener, Popper } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { LoadingAnimation } from 'library'

import Stripe from 'components/Hours/Stripe'
import {
  ContentBox,
  StripeContainer,
  StyledButton,
} from 'components/Motives/VehicleMotives/style'

import { cypressAddButton } from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Hour } from 'models/hours'

import { colors } from 'styles/theme'

import HourInputField from './HourInputField'
import { AddSection, StyledTextField } from './style'

type ContentBoxProps = {
  loading?: boolean
  singleHour?: Hour
  hours: Hour[]
  editHour: boolean
  editMode: boolean
  handleAdding: () => void
  handleChangeSingleHour: (value: Hour) => void
  handleEditHour: React.Dispatch<React.SetStateAction<boolean>>
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

export const HourContentBox = ({
  loading = false,
  singleHour,
  hours,
  editHour,
  editMode,
  handleChangeSingleHour,
  handleAdding,
  handleEditMode,
  handleEditHour,
}: ContentBoxProps) => {
  const { text } = useTranslation(textFiles.HOURS_CREATION)
  const { general: translation } = text

  const meridiem = singleHour && singleHour.am ? 'a.m.' : 'p.m.'

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClosePopper = () => {
    setAnchorEl(null)
    handleEditHour(false)
  }

  const hourSplit = singleHour?.name.split(':')
  const disabled =
    editMode ||
    !singleHour ||
    (hourSplit && (hourSplit[0] === '' || hourSplit[1] === ''))

  return (
    <ContentBox>
      {loading ? (
        <LoadingAnimation showAnimation={loading} />
      ) : (
        <StripeContainer>
          {hours.map((vehicleHour) => (
            <Stripe
              key={vehicleHour.id}
              time={vehicleHour}
              handleEditMode={handleEditMode}
            />
          ))}
        </StripeContainer>
      )}
      <AddSection>
        <StyledTextField
          variant="outlined"
          name="add"
          placeholder={
            singleHour
              ? `${singleHour.name} ${meridiem}`
              : translation.creationPlaceHolder
          }
          disabled
          sx={{
            width: '78% !important',
            input: {
              paddingLeft: '0px',
              cursor: 'pointer',
            },
          }}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            if (editHour) handleEditHour(false)
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WatchLaterIcon
                  sx={{ color: colors.placeholderGray, fontSize: 18 }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <KeyboardArrowDownIcon
                  sx={{
                    color: colors.placeholderGray,
                    fontSize: 18,
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Popper
          id="translation-popper"
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={handleClosePopper}>
            <HourInputField
              time={singleHour}
              handleTimeChange={handleChangeSingleHour}
            />
          </ClickAwayListener>
        </Popper>
        <StyledButton
          startIcon={<AddIcon />}
          size="small"
          role="button"
          variant="text"
          disabled={disabled}
          onClick={handleAdding}
          sx={{
            width: '134px',
            border: `2px solid ${
              disabled ? 'unset' : colors.orange
            } !important`,
            backgroundColor: disabled ? colors.lightGray : 'unset',
          }}
          testId={cypressAddButton}
        >
          {translation.buttons.add}
        </StyledButton>
      </AddSection>
    </ContentBox>
  )
}
