import { styled } from '@mui/material'
import { Box } from 'library/components/Common/Box'
import { boxShadow, colors } from 'library/styles/theme'

export const StyledBox = styled(Box)({
  backgroundColor: colors.commonWhite,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 168,
  height: 120,
  borderRadius: 8,
  padding: '1.5rem',
  boxShadow,
})
