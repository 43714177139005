import React, { useState } from 'react'
import { FormControl, Select } from '@mui/material'
import {
  Box,
  Button,
  CalendarRange,
  formatDateLocalizedTime,
  getTestId,
} from 'library'

import {
  ArrowIcon,
  StyledInputBase,
} from 'components/General/DownloadModal/style'

import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { CalendarRangeType } from 'models/date'

import { CalendarIcon, CheckIcon, StyledInputLabel } from './style'

export type DateRangeType = {
  /**
   * Object containing the date range of the extended calendar
   */
  dateRange: CalendarRangeType
  /**
   * handleChangeDateRange is a function that takes a new CalendarRangeType as an argument
   * and replaces the last dateRange that was selected
   */
  handleChangeDateRange: (newDateRange: CalendarRangeType) => void

  /**
   * Optional Maximum allowed date for the calendar to be clicked, this will be passed to the to calendar. If undefined defaults to new Date().
   * If null it will have no limit
   */
  maxDate?: Date | null
}

export type SelectDateRangeProps = {
  label: string
  name: string
  testId?: string
} & DateRangeType

const SelectDateRange = ({
  label,
  name,
  testId,
  dateRange,
  handleChangeDateRange,
  maxDate = null,
}: SelectDateRangeProps) => {
  const { text } = useTranslation(textFiles.GENERAL)
  const { dateRangeCalendarText: translation } = text
  const language = useLocale()[0].code

  const [tempRange, setTempRange] = useState<CalendarRangeType>(dateRange)
  const getTextDate = (date: Date | null) => {
    if (date) {
      return formatDateLocalizedTime(date, 'MMMM do yyyy', language)
    }
    return ''
  }

  const handleChangeTempRange = (newDateRange: CalendarRangeType) => {
    setTempRange(newDateRange)
  }

  const applyRange = () => {
    handleChangeDateRange(tempRange)
  }

  const cancelChanges = () => {
    setTempRange(dateRange)
  }

  const cleanRange = () => {
    handleChangeDateRange({ fromDate: null, toDate: null })
  }

  return (
    <Box>
      <FormControl>
        {label && <StyledInputLabel shrink={false}>{label}</StyledInputLabel>}
        <Select
          sx={{ minWidth: '280px', height: '40px' }}
          name={name}
          IconComponent={ArrowIcon}
          input={
            <StyledInputBase
              startAdornment={<CalendarIcon sx={{ marginLeft: '5px' }} />}
            />
          }
          inputProps={{
            'data-cy': getTestId(testId, name),
          }}
        >
          <CalendarRange
            dateRange={tempRange}
            handleChangeCalendarRange={handleChangeTempRange}
            maxDate={maxDate === null ? undefined : maxDate}
            fromLabel={`${translation.fromLabel} ${getTextDate(
              dateRange.fromDate
            )}`}
            toLabel={`${translation.toLabel} ${getTextDate(dateRange.toDate)}`}
          />
          <Box marginLeft="2rem" marginY="1rem" display="flex" columnGap="1rem">
            <Button
              startIcon={<CheckIcon />}
              onClick={applyRange}
              testId="apply-range-button"
            >
              {translation.apply}
            </Button>
            <Button
              buttonType="secondary"
              onClick={cancelChanges}
              testId="cancel-range-button"
            >
              {translation.cancel}
            </Button>
            <Button
              buttonType="info"
              variant="text"
              onClick={cleanRange}
              testId="clean-range-button"
            >
              {translation.clear}
            </Button>
          </Box>
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectDateRange
