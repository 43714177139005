import { styled } from '@mui/material'
import { Box, Container } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'

export const StyledContainer = styled(Container)({
  width: '336px',
  padding: 0,
})

export const StyledButtonContainer = styled(Box)({
  justifyContent: 'end',
  gap: '0px 15px',
  button: {
    display: 'inherit',
    position: 'static',
    span: {
      display: 'none',
    },
  },
})

export const StyledBodyContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '20px 30px',
})

export const StyledPictureContainer = styled(Container)({
  width: '336px',
  padding: 0,

  [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
    width: '250px',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    width: '140px',
    flex: 'unset',
  },
})

export const StyledNotificationsContainer = styled(Container)({
  padding: 0,
  width: 'fit-content',
})

export const StyledDateContainer = styled(Container)({
  width: '336px',
  padding: 0,
  [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
    width: '250px',
  },
})
