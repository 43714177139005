import React from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LabelIcon from '@mui/icons-material/Label'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ShortTextIcon from '@mui/icons-material/ShortText'
import { formatDateLocalizedTime, SummaryItem } from 'library'

import AvatarItem from 'components/CarSettings/Common/Creation/Summary/AvatarItem'
import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'

import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { Hour } from 'models/hours'
import { NotificationCreationProps } from 'utils/Marketing/Notification/creation'

import defaultAvatarImage from 'images/defaultAvatar.png'

import NotificationCard from '../../Detail/NotificationCard'
import { MessageCard, PreviewContainer } from '../Information/style'

const elementStyle: React.CSSProperties = {
  alignItems: 'flex-start',
  marginTop: '1rem',
}

const Summary = ({
  notificationData,
  handleBack,
  submitLoading,
  handleSubmit,
}: NotificationCreationProps) => {
  const [selectedLanguage] = useLocale()

  const { notificationInformation } = notificationData
  const { text } = useTranslation(textFiles.NOTIFICATION_CREATION)

  const {
    information: {
      title: titleLabel,
      description: descLabel,
      name,
      preview,
      previewTitle,
      previewDescription,
      paragraph,
    },
    summary: { title, description, picture, status: statusLabel, dateTime },
    status,
  } = text

  return (
    <Skeleton
      previousFunction={handleBack}
      title={title}
      description={description}
      nextFunction={handleSubmit}
      submitLoading={submitLoading}
      gridContainerStyles={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '20px',
      }}
      containerStyles={{ height: '100%' }}
      formStyles={{
        height: '100%',
      }}
      bodyStyles={{
        height: '100%',
        overflowY: 'inherit',
      }}
    >
      <SummaryItem
        title={titleLabel}
        text={notificationInformation.title}
        icon={<NotificationsIcon />}
        elementStyle={elementStyle}
        width={340}
      />

      <SummaryItem
        title={descLabel}
        text={notificationInformation.description}
        icon={<ShortTextIcon />}
        elementStyle={elementStyle}
        width={340}
      />
      <SummaryItem
        title={name}
        text={notificationInformation.name || 'N/A'}
        icon={<LabelIcon />}
        elementStyle={elementStyle}
        width={340}
      />

      <AvatarItem
        title={picture}
        picture={notificationInformation.picture || defaultAvatarImage}
        containerStyles={{
          gridColumnStart: 3,
          gridRowStart: 2,
          gridRowEnd: 4,
        }}
        width={200}
        imageHeight={145}
        imageWidth={145}
      />

      <PreviewContainer title={preview} width="340px !important">
        <NotificationCard
          title={previewTitle}
          description={previewDescription}
        />
        <NotificationCard
          title={previewTitle}
          description={previewDescription}
          isAndroid
          picture={defaultAvatarImage}
        />
        <MessageCard>
          <p style={{ margin: '11px' }}>{paragraph}</p>
        </MessageCard>
      </PreviewContainer>
      <SummaryItem
        title={dateTime}
        text={`${formatDateLocalizedTime(
          notificationInformation.date as Date,
          'PPP',
          selectedLanguage.code
        )}, ${
          (notificationInformation.time as Hour).name
        } ${notificationInformation.meridiam.toLowerCase()}`}
        icon={<CalendarMonthIcon />}
        elementStyle={elementStyle}
        width={340}
      />

      <SummaryItem
        title={statusLabel}
        text={status[notificationInformation.status.toLowerCase()]}
        icon={<CheckCircleIcon />}
        elementStyle={elementStyle}
        width={340}
      />
    </Skeleton>
  )
}

export default Summary
