import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import AddBoxIcon from '@mui/icons-material/AddBox'
import {
  InputAdornment,
  InputBaseComponentProps,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import {
  Box,
  ButtonNavigator,
  Checkbox,
  ColorBox,
  getIsoDate,
  Select,
} from 'library'
import * as yup from 'yup'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'
import MultipleSelect from 'components/Inventory/Creation/Common/MultipleSelect'
import NumberValueInput from 'components/Inventory/Creation/Common/NumberValueInput'
import { StripeContainer } from 'components/Motives/VehicleMotives/style'

import {
  getYearOptions,
  OFFER_CREATION_OPTION,
} from 'constants/Marketing/offerCreation'
import { textFiles } from 'constants/textFiles'
import useDebounce from 'hooks/useDebounce'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { CalendarRangeType } from 'models/date'
import {
  CarIdPopperStatus,
  DiscountTypes,
  OfferCarType,
  OfferDataType,
  OfferTypes,
  PriorityTypes,
} from 'models/offer'
import { Option } from 'models/select'
import {
  FilterInputVariable,
  GenericCount,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { OfferCreationProps } from 'utils/Marketing/Offer/creation'

import {
  GET_LISTING_CAR_BY_ID,
  GET_LISTING_CARS_COUNT,
} from 'graphQL/Marketing/Creation/queries'

import {
  BoxContainer,
  sliderFormProps,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'
import { colors } from 'styles/theme'

import ColorButton from '../../Detail/Common/ColorButton'

import CarIdPopper from './CarIdPopper'
import { OfferStripe } from './OfferStripe'
import SelectDateRange from './SelectDateRange'

const OfferData = ({
  offerData,
  updateOfferData,
  handleContinue,
  brands,
  models,
  years,
}: OfferCreationProps) => {
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text } = useTranslation(textFiles.OFFER_CREATION)
  const {
    information: {
      currentSelectionOptions: {
        placeholderSelectionText,
        carIdText,
        brandText,
        modelText,
      },
    },
  } = text

  const placeholderSelectionOptions = {
    [OfferTypes.CAR]: placeholderSelectionText.replace('%d', carIdText),
    [OfferTypes.BRAND]: placeholderSelectionText.replace('%d', brandText),
    [OfferTypes.MODEL]: placeholderSelectionText.replace('%d', modelText),
  }

  const [logo, setLogo] = useState<string>(
    offerData.offerInformation.logo || ''
  )

  const [logoFile, setLogoFile] = useState<File | null>(
    offerData.offerInformation.logoFile || null
  )

  const [picture, setPicture] = useState<string>(
    offerData.offerInformation.picture
  )

  const [pictureFile, setPictureFile] = useState<File | null>(
    offerData.offerInformation.pictureFile || null
  )

  const [CarIdStatus, setCarIdStatus] = useState<CarIdPopperStatus>(
    CarIdPopperStatus.SUCCESS
  )

  const [carDiscounts, setCarDiscounts] = useState<number[]>(
    offerData.offerInformation.carDiscounts
  )

  const [carsInInterval, setCarsInInterval] = useState<number>(
    offerData.offerInformation.carsInInterval || 0
  )

  /**
   * Here we store reference to the current car ID that is in the input.
   * Then, we call the API to get that car's data, and store it in
   * currentCarData state; then we can further use it to get more
   * details about that vehicle
   */
  const [currentCarId, setCurrentCarId] = useState<string>('')

  const [dateRange, setDateRange] = useState<CalendarRangeType>({
    fromDate: offerData.offerInformation.from || null,
    toDate: offerData.offerInformation.to || null,
  })

  /**
   * Constants used to debounce max and min price
   */

  const [searchMaxPrice, setSearchMaxPrice] = useState<string>('')

  const [searchMinPrice, setSearchMinPrice] = useState<string>('')

  const debouncedMaxPrice = useDebounce(searchMaxPrice, 1000)

  const debouncedMinPrice = useDebounce(searchMinPrice, 1000)

  const [priorityState, setPriorityState] = useState<boolean>(
    offerData.offerInformation.priority === PriorityTypes.PRIMARY
  )

  const [activeState, setActiveState] = useState<boolean>(
    offerData.offerInformation.isActive
  )

  const OptionsDiscount: Option[] = Object.keys(DiscountTypes).map((value) => ({
    value,
    name: String(text.information.discountOptions[value]),
  }))

  const OptionsOffers: Option[] = Object.keys(OfferTypes).map((value) => ({
    value,
    name: String(text.information.typeOptions[value]),
  }))

  const brandOptions: Option[] = brands.map((brandItem) => {
    return { name: brandItem.name, value: brandItem.id }
  })

  const modelOptions: Option[] = models.map((modelItem) => {
    return { name: modelItem.name, value: modelItem.id }
  })

  const yearOptions: Option[] = getYearOptions(years.minYear, years.maxYear)

  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriorityState(Boolean(event.target.checked))
  }

  const handleStateActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveState(event.target.checked)
  }

  const handleUpdateCurrentCar = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentCarId(event.target.value as string)
  }

  const validationSchema = yup.object().shape({
    label: yup.string().required(validationText.fieldRequired),
    description: yup.string().required(validationText.fieldRequired),
    discountType: yup.string().required(validationText.fieldRequired),
    /**
     * Discount schema goes as following: if discount type is individual, then it is set to optional.
     * But, when discount type is price, the discount cant be more than 50% off a car's price.
     */
    discount: yup.number().when('discountType', {
      is: (discountValue: string) => discountValue === DiscountTypes.GENERAL,
      then: yup.number().when('type', {
        is: (typeValue: string) => typeValue === OfferTypes.PRICE,
        then: yup
          .number()
          .required(validationText.fieldRequired)
          .test({
            message: validationText.lessOrEqualThan.replace(
              '%d',
              text.information.minPriceLabel
            ),
            name: 'discount-test',
            test: (value, context) => {
              const { minPrice: minPriceValue } = context.parent
              if (value) {
                if (value > minPriceValue / 2) {
                  return false
                }
              }
              return true
            },
          }),
        otherwise: yup.number().required(validationText.fieldRequired),
      }),
      otherwise: yup.number(),
    }),
    backgroundColor: yup.string().required(validationText.fieldRequired),
    textColor: yup.string().required(validationText.fieldRequired),
    labelColor: yup.string().required(validationText.fieldRequired),
    type: yup.string().required(validationText.fieldRequired),
    picture: yup.string().required(validationText.fieldRequired),
    cars: yup.array().when('type', {
      is: (typeValue: string) => typeValue === OfferTypes.CAR,
      then: yup
        .array()
        .of(
          yup.object({
            certified: yup.boolean(),
            listingPrice: yup.number(),
            mainPicture: yup.string(),
            slug: yup.string(),
            title: yup.string(),
            year: yup.number(),
            id: yup.string(),
          })
        )
        .min(1, validationText.fieldRequired),
      otherwise: yup.array().of(yup.string()),
    }),
    brands: yup.array().when('type', {
      is: (typeValue: string) => typeValue === OfferTypes.BRAND,
      then: yup.array().of(yup.string()).min(1, validationText.fieldRequired),
      otherwise: yup.array().of(yup.string()),
    }),
    models: yup.array().when('type', {
      is: (typeValue: string) => typeValue === OfferTypes.MODEL,
      then: yup.array().of(yup.string()).min(1, validationText.fieldRequired),
      otherwise: yup.array().of(yup.string()),
    }),
    maxPrice: yup.number().when('type', {
      is: (typeValue: string) => typeValue === OfferTypes.PRICE,
      then: yup
        .number()
        .required(validationText.fieldRequired)
        .positive(validationText.positiveNumber),
      otherwise: yup.number(),
    }),
    minPrice: yup.number().when('type', {
      is: (typeValue: string) => typeValue === OfferTypes.PRICE,
      then: yup
        .number()
        .required(validationText.fieldRequired)
        .positive(validationText.positiveNumber)
        .max(
          yup.ref('maxPrice'),
          validationText.lessOrEqualThan.replace(
            '%d',
            text.information.maxPriceLabel
          )
        ),
      otherwise: yup.number(),
    }),
    maxYear: yup.number().when('type', {
      is: (typeValue: string) => typeValue === OfferTypes.YEAR,
      then: yup
        .number()
        .required(validationText.fieldRequired)
        .positive(validationText.positiveNumber),
      otherwise: yup.number(),
    }),
    minYear: yup.number().when('type', {
      is: (typeValue: string) => typeValue === OfferTypes.YEAR,
      then: yup
        .number()
        .required(validationText.fieldRequired)
        .positive(validationText.positiveNumber)
        .max(
          yup.ref('maxYear'),
          validationText.lessOrEqualThan.replace(
            '%d',
            text.information.maxYearLabel
          )
        ),
      otherwise: yup.number(),
    }),
  })

  const formik = useFormik<OfferDataType>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: offerData.offerInformation,
    validationSchema,
    onSubmit: (values) => {
      updateOfferData({
        type: OFFER_CREATION_OPTION.UPDATE_OFFER_DATA,
        payload: {
          ...values,
          logo,
          picture,
          carDiscounts,
          carsInInterval,
          pictureFile,
          logoFile,
          from: dateRange.fromDate,
          to: dateRange.toDate,
        },
      })

      handleContinue()
    },
  })

  const [getCurrentCarById] = useLazyQuery<
    GenericData<OfferCarType>,
    GenericInputVariable<string>
  >(GET_LISTING_CAR_BY_ID, {
    onCompleted(response) {
      if (response && response.data) {
        const newCarsArray = [...formik.values.cars]
        newCarsArray.push(response.data)
        formik.setFieldValue('cars', newCarsArray)
        setCarIdStatus(CarIdPopperStatus.SUCCESS)
        setOpen(true)

        setTimeout(() => {
          setOpen(false)
        }, 4000)
      }
    },
    onError() {
      setOpen(true)
      setCarIdStatus(CarIdPopperStatus.ERROR)
      setTimeout(() => {
        setOpen(false)
      }, 4000)
    },
  })

  const [getListingCarsInInterval] = useLazyQuery<
    GenericData<GenericCount>,
    FilterInputVariable
  >(GET_LISTING_CARS_COUNT, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
        where: {
          year_gte:
            formik.values.type === OfferTypes.YEAR
              ? formik.values.minYear
              : undefined,
          year_lte:
            formik.values.type === OfferTypes.YEAR
              ? formik.values.maxYear
              : undefined,
          listingPrice_gte:
            formik.values.type === OfferTypes.PRICE
              ? debouncedMinPrice
              : undefined,
          listingPrice_lte:
            formik.values.type === OfferTypes.PRICE
              ? debouncedMaxPrice
              : undefined,
        },
      },
    },
    onCompleted(response) {
      if (response.data) {
        setCarsInInterval(response.data.count)
      }
    },
  })

  useEffect(() => {
    if (debouncedMaxPrice) {
      getListingCarsInInterval()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMaxPrice])

  useEffect(() => {
    if (debouncedMinPrice) {
      getListingCarsInInterval()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMinPrice])

  const offerInfo = formik.touched

  const handleAddCar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    if (currentCarId.length === 24) {
      const currentCars = [...formik.values.cars]
      if (currentCars.some((car) => car.id === currentCarId)) {
        setOpen(true)
        setCarIdStatus(CarIdPopperStatus.DUPLICATE)
        setTimeout(() => {
          setOpen(false)
        }, 4000)
      } else {
        getCurrentCarById({
          variables: {
            input: currentCarId,
          },
        })
      }
    } else {
      setOpen(true)

      setCarIdStatus(CarIdPopperStatus.ERROR)

      setTimeout(() => {
        setOpen(false)
      }, 4000)
    }
  }

  const handleAddCarDiscount = (amount: number, index: number) => {
    const newCarDiscounts = [...carDiscounts]
    newCarDiscounts[index] = amount
    setCarDiscounts(newCarDiscounts)
  }

  const handleBrandRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    const newBrands = [...formik.values.brands]
    newBrands.splice(index, 1)
    formik.setFieldValue('brands', newBrands)
  }

  const handleModelRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    const newModels = [...formik.values.models]
    newModels.splice(index, 1)
    formik.setFieldValue('models', newModels)
  }

  const handleEntityRemove = (index: number, entityType: OfferTypes) => {
    if (entityType === OfferTypes.BRAND) {
      const newBrands = [...formik.values.brands]
      newBrands.splice(index, 1)
      formik.setFieldValue('brands', newBrands)
    } else if (entityType === OfferTypes.MODEL) {
      const newModels = [...formik.values.models]
      newModels.splice(index, 1)
      formik.setFieldValue('models', newModels)
    } else {
      const newCars = [...formik.values.cars]
      newCars.splice(index, 1)
      formik.setFieldValue('cars', newCars)

      const newCarDiscounts = [...carDiscounts]
      newCarDiscounts.splice(index, 1)
      setCarDiscounts(newCarDiscounts)
    }
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
  }

  return (
    <StyledContainer>
      <StyledForm
        sx={{
          ...sliderFormProps,
        }}
        onSubmit={formik.handleSubmit}
      >
        <Box>
          <StyledTextFieldContainer title={text.information.name}>
            <StyledTextField
              variant="outlined"
              name="label"
              value={formik.values.label}
              onChange={formik.handleChange}
              placeholder={text.information.placeholderName}
              error={formik.touched.label && Boolean(formik.errors.label)}
              inputProps={{ maxLength: 40 }}
            />
            {formik.touched.label && formik.errors.label ? (
              <StyledErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.label}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={text.information.description}>
            <StyledTextField
              variant="outlined"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              placeholder={text.information.placeholderDescription}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              inputProps={{ maxLength: 40 }}
            />
            {formik.touched.description && formik.errors.description ? (
              <StyledErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.description}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={text.information.discountType}>
            <Select
              options={OptionsDiscount}
              onChange={formik.handleChange}
              label={text.information.discountType}
              value={formik.values.discountType}
              name="discountType"
              sx={{ fontSize: 12, height: 40 }}
              error={
                offerInfo.discountType && Boolean(formik.errors.discountType)
              }
            />
            {offerInfo.discountType && formik.errors.discountType ? (
              <StyledErrorMessage text={formik.errors.discountType} />
            ) : null}
          </StyledTextFieldContainer>

          {formik.values.discountType === DiscountTypes.GENERAL && (
            <StyledTextFieldContainer title={text.information.discount}>
              <StyledTextField
                variant="outlined"
                name="discount"
                value={formik.values.discount}
                placeholder={text.information.discount}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="body2">{priceCurrency}</Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => {
                  formik.handleChange(event)
                  if (
                    (event.target.value as string) === DiscountTypes.GENERAL
                  ) {
                    formik.setFieldValue('type', OfferTypes.CAR)
                  }
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.discount)}
              />
              {formik.errors.discount ? (
                <StyledErrorMessage text={formik.errors.discount} />
              ) : null}
            </StyledTextFieldContainer>
          )}

          {/* <Box maxWidth={220}> */}
          <Box>
            <AvatarContainer
              id="logo"
              title={text.information.logo}
              picture={logo}
              handlePictureChange={setLogo}
              handlePictureStateChange={(image) => {
                formik.setFieldValue('logo', image)
              }}
              handleFileChange={setLogoFile}
              showPreview={false}
              additionalButtonStyles={{
                marginBottom: 0,
              }}
            />
            {formik.errors.logo ? (
              <StyledErrorMessage text={formik.errors.logo} />
            ) : null}
          </Box>

          <Box
            sx={{
              '& >div': {
                maxHeight: 'unset',
              },
            }}
          >
            <AvatarContainer
              id="picture"
              title={text.information.picture}
              picture={picture}
              handlePictureChange={setPicture}
              handlePictureStateChange={(image) => {
                formik.setFieldValue('picture', image)
              }}
              handleFileChange={setPictureFile}
              showPreview={false}
              additionalButtonStyles={{
                marginBottom: 0,
              }}
            />
            {formik.errors.picture ? (
              <StyledErrorMessage text={formik.errors.picture} />
            ) : null}
          </Box>

          <StyledTextFieldContainer title={text.information.backgroundColor}>
            <BoxContainer
              sx={{
                columnGap: '10px',
                section: {
                  flex: 1,
                },
                '& > button:nth-of-type(1)': {
                  flex: 5,
                },
              }}
            >
              <ColorBox
                hexCode={formik.values.backgroundColor}
                width="100% !important"
              />
              <ColorButton
                text={
                  formik.values.backgroundColor ||
                  text.information.backgroundColor
                }
                sx={{
                  width: '100% !important',
                  height: '38px !important',
                }}
                keyCode="backgroundColor"
                handleChange={(value: string, keyCode) => {
                  formik.setFieldValue(keyCode, value.toUpperCase())
                }}
              />
            </BoxContainer>
            {offerInfo.backgroundColor && formik.errors.backgroundColor ? (
              <StyledErrorMessage text={formik.errors.backgroundColor} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={text.information.textColor}>
            <BoxContainer
              sx={{
                columnGap: '10px',
                section: {
                  flex: 1,
                },
                '& > button:nth-of-type(1)': {
                  flex: 5,
                },
              }}
            >
              <ColorBox
                hexCode={formik.values.textColor}
                width="100% !important"
              />

              <ColorButton
                text={formik.values.textColor || text.information.textColor}
                sx={{
                  width: '100% !important',
                  height: '38px !important',
                }}
                keyCode="textColor"
                handleChange={(value: string, keyCode) => {
                  formik.setFieldValue(keyCode, value.toUpperCase())
                }}
              />
            </BoxContainer>
            {offerInfo.textColor && formik.errors.textColor ? (
              <StyledErrorMessage text={formik.errors.textColor} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={text.information.labelColor}>
            <BoxContainer
              sx={{
                columnGap: '10px',
                section: {
                  flex: 1,
                },
                '& > button:nth-of-type(1)': {
                  flex: 5,
                },
              }}
            >
              <ColorBox
                hexCode={formik.values.labelColor}
                width="100% !important"
              />

              <ColorButton
                text={formik.values.labelColor || text.information.labelColor}
                sx={{
                  width: '100% !important',
                  height: '38px !important',
                }}
                keyCode="labelColor"
                handleChange={(value: string, keyCode) => {
                  formik.setFieldValue(keyCode, value.toUpperCase())
                }}
              />
            </BoxContainer>

            {offerInfo.labelColor && formik.errors.labelColor ? (
              <StyledErrorMessage text={formik.errors.labelColor} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={text.information.range}>
            <SelectDateRange
              name={text.information.range}
              label={
                dateRange.fromDate && dateRange.toDate
                  ? `${getIsoDate(dateRange.fromDate, 'date')} -
              ${getIsoDate(dateRange.toDate, 'date')}
            `
                  : text.information.clickRange
              }
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
            />
          </StyledTextFieldContainer>

          {formik.values.discountType === DiscountTypes.GENERAL ? (
            <StyledTextFieldContainer title={text.information.type}>
              <Select
                options={OptionsOffers}
                /**
                 * When discount type changes, we reset values of all adjacent fields to their
                 * "default", so that we dont store unused data.
                 */
                onChange={(event: SelectChangeEvent<unknown>) => {
                  formik.handleChange(event)

                  if ((event.target.value as string) !== OfferTypes.PRICE) {
                    formik.setFieldValue('maxPrice', undefined)
                    formik.setFieldValue('minPrice', undefined)
                  }

                  if ((event.target.value as string) !== OfferTypes.BRAND) {
                    formik.setFieldValue('brands', [])
                  }

                  if ((event.target.value as string) !== OfferTypes.CAR) {
                    formik.setFieldValue('cars', [])
                  }

                  if ((event.target.value as string) !== OfferTypes.MODEL) {
                    formik.setFieldValue('models', [])
                  }

                  if ((event.target.value as string) !== OfferTypes.PRICE) {
                    formik.setFieldValue('maxYear', undefined)
                    formik.setFieldValue('minYear', undefined)
                  }

                  setCarsInInterval(0)
                }}
                label={text.information.type}
                value={formik.values.type}
                name="type"
                sx={{ fontSize: 12, height: 40 }}
                error={offerInfo.type && Boolean(formik.errors.type)}
              />
              {offerInfo.type && formik.errors.type ? (
                <StyledErrorMessage text={formik.errors.type} />
              ) : null}
            </StyledTextFieldContainer>
          ) : null}

          {formik.values.type === OfferTypes.CAR ||
          formik.values.discountType === DiscountTypes.INDIVIDUAL ? (
            <StyledTextFieldContainer title={text.information.carId}>
              <CarIdPopper
                open={open}
                anchorEl={anchorEl}
                id="car-id-popper"
                popperStatus={CarIdStatus}
              >
                <StyledTextField
                  variant="outlined"
                  name="carId"
                  value={currentCarId}
                  placeholder={text.information.carId}
                  onChange={handleUpdateCurrentCar}
                  inputProps={{
                    maxLength: 24,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <button
                          type="button"
                          aria-label="Add Car"
                          onClick={handleAddCar}
                          aria-describedby="car-id-popper"
                          style={{
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            border: 'none',
                          }}
                        >
                          <AddBoxIcon
                            sx={{
                              width: '30px',
                              height: '30px',
                              color: colors.orange,
                            }}
                          />
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />
              </CarIdPopper>

              {formik.errors.cars ? (
                <StyledErrorMessage text={String(formik.errors.cars)} />
              ) : null}
            </StyledTextFieldContainer>
          ) : null}

          {formik.values.type === OfferTypes.PRICE &&
            formik.values.discountType === DiscountTypes.GENERAL && (
              <>
                <StyledTextFieldContainer title={text.information.minPrice}>
                  <StyledTextField
                    variant="outlined"
                    name="minPrice"
                    value={formik.values.minPrice}
                    placeholder={text.information.minPrice}
                    InputProps={{
                      inputComponent:
                        NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="body2">
                            {priceCurrency}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => {
                      formik.handleChange(event)
                      setSearchMinPrice(event.target.value)
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.minPrice)}
                  />
                  {formik.errors.minPrice ? (
                    <StyledErrorMessage text={formik.errors.minPrice} />
                  ) : null}
                </StyledTextFieldContainer>

                <StyledTextFieldContainer title={text.information.maxPrice}>
                  <StyledTextField
                    variant="outlined"
                    name="maxPrice"
                    value={formik.values.maxPrice}
                    placeholder={text.information.maxPrice}
                    InputProps={{
                      inputComponent:
                        NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="body2">
                            {priceCurrency}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => {
                      formik.handleChange(event)
                      setSearchMaxPrice(event.target.value)
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.maxPrice)}
                  />
                  {formik.errors.maxPrice ? (
                    <StyledErrorMessage text={formik.errors.maxPrice} />
                  ) : null}
                </StyledTextFieldContainer>
              </>
            )}

          {formik.values.type === OfferTypes.YEAR &&
            formik.values.discountType === DiscountTypes.GENERAL && (
              <>
                <StyledTextFieldContainer title={text.information.minYear}>
                  <Select
                    options={yearOptions}
                    onChange={(event: SelectChangeEvent<unknown>) => {
                      formik.handleChange(event)

                      if (
                        formik.values.maxYear &&
                        Number(event.target.value) <= formik.values.maxYear
                      ) {
                        getListingCarsInInterval()
                      }
                    }}
                    label={text.information.minYear}
                    value={formik.values.minYear || ''}
                    name="minYear"
                    sx={{ fontSize: 12, height: 40 }}
                    error={offerInfo.minYear && Boolean(formik.errors.minYear)}
                  />
                  {formik.errors.minYear ? (
                    <StyledErrorMessage text={formik.errors.minYear} />
                  ) : null}
                </StyledTextFieldContainer>

                <StyledTextFieldContainer title={text.information.maxYear}>
                  <Select
                    options={yearOptions}
                    onChange={(event: SelectChangeEvent<unknown>) => {
                      formik.handleChange(event)
                      if (
                        formik.values.minYear &&
                        formik.values.minYear <= Number(event.target.value)
                      ) {
                        getListingCarsInInterval()
                      }
                    }}
                    label={text.information.minYear}
                    value={formik.values.maxYear || ''}
                    name="maxYear"
                    sx={{ fontSize: 12, height: 40 }}
                    error={offerInfo.maxYear && Boolean(formik.errors.maxYear)}
                  />
                  {formik.errors.maxYear ? (
                    <StyledErrorMessage text={formik.errors.maxYear} />
                  ) : null}
                </StyledTextFieldContainer>
              </>
            )}

          {formik.values.type === OfferTypes.BRAND &&
            formik.values.discountType === DiscountTypes.GENERAL && (
              <StyledTextFieldContainer title={text.information.brand}>
                <MultipleSelect
                  options={brandOptions}
                  onChange={formik.handleChange}
                  label={text.information.brand}
                  values={formik.values.brands}
                  name="brands"
                  error={formik.touched.brands && Boolean(formik.errors.brands)}
                  onRemove={handleBrandRemove}
                />
                {offerInfo.brands && formik.errors.brands ? (
                  <StyledErrorMessage text={formik.errors.brands as string} />
                ) : null}
              </StyledTextFieldContainer>
            )}

          {formik.values.type === OfferTypes.MODEL &&
            formik.values.discountType === DiscountTypes.GENERAL && (
              <StyledTextFieldContainer title={text.information.model}>
                <MultipleSelect
                  options={modelOptions}
                  onChange={formik.handleChange}
                  label={text.information.model}
                  values={formik.values.models}
                  name="models"
                  error={formik.touched.models && Boolean(formik.errors.models)}
                  onRemove={handleModelRemove}
                />
                {offerInfo.models && formik.errors.models ? (
                  <StyledErrorMessage text={formik.errors.models as string} />
                ) : null}
              </StyledTextFieldContainer>
            )}

          <StyledTextFieldContainer title={text.information.priority}>
            <Checkbox
              label={text.information.priorized}
              checked={priorityState}
              onChange={handlePriorityChange}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={text.information.stateCheck}>
            <Checkbox
              label={text.information.isActive}
              checked={activeState}
              onChange={handleStateActiveChange}
            />
          </StyledTextFieldContainer>

          <StyledTextFieldContainer
            minWidth="500px !important"
            title={text.information.currentSelection}
            sx={{
              maxHeight: 'none !important',
              height: 'fit-content',
              '& > div:nth-of-type(2)': {
                '& > div': {
                  height: 'fit-content',
                },
              },
            }}
          >
            {formik.values.type === OfferTypes.PRICE ||
            formik.values.type === OfferTypes.YEAR ? (
              <Typography variant="body2" color={colors.black}>
                <span
                  style={{
                    color: colors.orange,
                    marginRight: '10px',
                  }}
                >
                  ●
                </span>
                {`${carsInInterval} ${text.information.vehiclesInRange}`}
              </Typography>
            ) : (
              <StripeContainer>
                <Box>
                  {formik.values.brands.length === 0 &&
                  formik.values.models.length === 0 &&
                  formik.values.cars.length === 0 ? (
                    <Typography variant="body2" color={colors.gray}>
                      <span
                        style={{ color: colors.orange, marginRight: '10px' }}
                      >
                        ●
                      </span>
                      {placeholderSelectionOptions[formik.values.type]}
                    </Typography>
                  ) : null}

                  {formik.values.brands.length > 0
                    ? formik.values.brands.map((brandId, idx) => {
                        return (
                          <OfferStripe
                            key={brandId}
                            name={
                              brands.find((br) => br.id === brandId)?.name || ''
                            }
                            id={brandId}
                            enableRemove
                            type={OfferTypes.BRAND}
                            index={idx}
                            handleEntityRemove={handleEntityRemove}
                          />
                        )
                      })
                    : null}

                  {formik.values.models.length > 0
                    ? formik.values.models.map((modelId, idx) => {
                        return (
                          <OfferStripe
                            key={modelId}
                            name={
                              models.find((mod) => mod.id === modelId)?.name ||
                              ''
                            }
                            id={modelId}
                            enableRemove
                            type={OfferTypes.MODEL}
                            index={idx}
                            handleEntityRemove={handleEntityRemove}
                          />
                        )
                      })
                    : null}

                  {formik.values.cars.length > 0
                    ? formik.values.cars.map((car, idx) => {
                        return (
                          <OfferStripe
                            key={car.id}
                            name={car.title}
                            id={car.id}
                            individualDiscount={
                              formik.values.discountType ===
                              DiscountTypes.INDIVIDUAL
                            }
                            enableRemove
                            type={OfferTypes.CAR}
                            index={idx}
                            handleEntityRemove={handleEntityRemove}
                            valueHandler={(value) => {
                              handleAddCarDiscount(value, idx)
                            }}
                            discountValue={carDiscounts[idx]}
                          />
                        )
                      })
                    : null}
                </Box>
              </StripeContainer>
            )}
          </StyledTextFieldContainer>
        </Box>
      </StyledForm>
      <ButtonNavigator isFirstStep nextFunction={formik.handleSubmit} />
    </StyledContainer>
  )
}

export default OfferData
