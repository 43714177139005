import { Box, styled } from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledBox = styled(Box)({
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  width: '345px',
  backgroundColor: colors.commonWhite,
  padding: '18px',
})

export const StyledCardBackground = styled(Box)({
  backgroundColor: colors.background,
  borderRadius: '10px',
  border: `1px solid ${colors.transparent}`,
  padding: '14px 18px',
  fontSize: '12px',
  'span:nth-of-type(2)': {
    textTransform: 'capitalize',
  },
})

export const HighlightedSpan = styled('span')({
  color: colors.orange,
  fontWeight: FONT_WEIGHT.SEMI_BOLD,
})
