import React from 'react'
import { Typography } from '@mui/material'
import { Box } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledCard } from './style'

export type InfoRowType = {
  title: string
  subtitle: string
  icon: JSX.Element
}

export type InfoCardType = {
  rows: InfoRowType[]
}

export const InformationCard = ({ rows }: InfoCardType) => {
  return (
    <StyledCard>
      {rows.map((row) => (
        <Box
          key={`${row.title}-${row.subtitle}`}
          display="flex"
          flexDirection="row"
          width="auto"
        >
          <Box justifyContent="center" paddingTop="4px">
            {row.icon}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            marginBottom={2}
            marginLeft="1rem"
          >
            <Typography
              variant="h5"
              fontWeight={FONT_WEIGHT.BOLD}
              color={colors.black}
            >
              {row.title}
            </Typography>
            <Typography
              variant="body2"
              fontWeight={FONT_WEIGHT.MEDIUM}
              color={colors.gray}
            >
              {row.subtitle}
            </Typography>
          </Box>
        </Box>
      ))}
    </StyledCard>
  )
}
