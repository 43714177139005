import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import ErrorPage from 'pages/ErrorPage'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { checkOrganization, grantAccess } from 'utils/BasicUtil'

import ForbiddenImage from 'images/ForbiddenAvatar.svg'

export const ForbiddenErrorPage = () => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const {
    text: { forbidden: translation },
  } = useTranslation(textFiles.ERROR)
  const { user, userRoles, handleSignOut } = useUser()

  const grantedAccess =
    user && checkOrganization(user) && grantAccess(userRoles)

  const pageText = {
    ...translation,
    title: grantedAccess ? translation.title : translation.alternativeTitle,
    subtitle: grantedAccess
      ? translation.subtitle
      : translation.alternativeSubtitle,
    leftButtonText: grantedAccess
      ? translation.leftButtontranslation
      : translation.signOut,
  }
  const handleGoBack = () => {
    if (!grantedAccess) {
      handleSignOut()
    } else {
      history.goBack()
    }
  }
  const handleOpenHelpModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseHelpModal = () => {
    setIsModalOpen(false)
  }

  return (
    <ErrorPage
      text={pageText}
      rightButtonProps={{ startIcon: <HelpOutlineIcon /> }}
      handleLeftButtonClick={handleGoBack}
      handleRightButtonClick={handleOpenHelpModal}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseHelpModal}
      imageSrc={ForbiddenImage}
    />
  )
}

export default ForbiddenErrorPage
