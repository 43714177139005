import { CURBO_SPOT_CREATION_OPTION } from 'constants/Operation/curboSpot'
import { Address } from 'models/map'
import { OutOfSpotModel, ScheduleInputType } from 'models/outOfSpot'
import { Option } from 'models/select'
import { PublicationStatus } from 'models/status'

import { BaseEntity, BaseIdEntity, GenericListData } from '../base'

type City = {
  state: BaseEntity
  name: string
  id: string | number
}

export type CityOption = {
  state: Option
} & Option

export enum WeekDayEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export type ListingCurboSpot = {
  id: string
  name: string
  city: City
  phone: string
  createdAt: Date
  status: PublicationStatus
}

export type ListSpots = { listCurboSpots: GenericListData<ListingCurboSpot[]> }

export type CurboSpotDetailDataType = {
  address: string
  city: City
  dealerParent: BaseEntity | null
  latitude: number
  longitude: number
  mainPicture: string | null
  name: string
  status: PublicationStatus
  telephoneNumber: string
  supportsTestDrive: boolean
  supportsInspection: boolean
  supportsMaintenance: boolean
  supportsOffSiteTestDrive: boolean
  supportsOffSiteInspection: boolean
  hasChangedPicture?: boolean
  fileState?: File | null
}

export type CurboSpotInputType = {
  address?: string
  city?: string
  latitude?: number
  longitude?: number
  mainPicture?: string | null
  name?: string
  status?: PublicationStatus
  telephoneNumber?: string
  supportsTestDrive?: boolean
  supportsInspection?: boolean
  supportsOffSiteTestDrive?: boolean
  supportsOffSiteInspection?: boolean
  supportsMaintenance?: boolean
}

export type UpdateCurboSpotInput = {
  where: BaseIdEntity
  data: CurboSpotInputType
}

export type CreateCurboSpotWeekCalendarInput = {
  curboSpot: string
} & ScheduleInputType

export type SpotInformation = {
  status: PublicationStatus
  city: string
  state: string
  picture: string
  pictureFile: File | null
  name: string
  telephoneNumber: string
}

export type SpotLocation = {
  address: Address | undefined
}

export type SpotSchedule = {
  supportsTestDrive: boolean
  supportsInspection: boolean
  supportsOffSiteTestDrive: boolean
  supportsOffSiteInspection: boolean
  schedules: OutOfSpotModel
}

export type SpotCreationModel = {
  spotInformation: SpotInformation
  spotLocation: SpotLocation
  spotSchedule: SpotSchedule
}

export type SpotCreationAction =
  | {
      type: CURBO_SPOT_CREATION_OPTION.UPDATE_CURBO_SPOT_INFORMATION
      payload: SpotInformation
    }
  | {
      type: CURBO_SPOT_CREATION_OPTION.UPDATE_CURBO_SPOT_LOCATION
      payload: SpotLocation
    }
  | {
      type: CURBO_SPOT_CREATION_OPTION.UPDATE_CURBO_SPOT_SCHEDULE
      payload: SpotSchedule
    }
