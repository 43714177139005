import { ExtendedStatus } from 'models/services/status'
import {
  OfferStatus as OfferStatusEnum,
  PublicationStatus,
} from 'models/status'

import {
  BaseEntity,
  BaseIdEntity,
  BaseNameEntity,
  FilterInput,
  GenericInputVariable,
  GenericListData,
} from '../base'

export enum OfferTypes {
  BRAND = 'BRAND',
  CAR = 'CAR',
  MODEL = 'MODEL',
  PRICE = 'PRICE',
  YEAR = 'YEAR',
}

export enum DiscountTypeEnum {
  GENERAL = 'GENERAL',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum OfferPriorityEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type CarModel = {
  slug: string
  status: PublicationStatus
  createdAt: string
} & BaseEntity

export type CarBrand = {
  icon: string
} & CarModel

export type Offer = {
  backgroundColor: string
  brands: CarBrand[]
  createdAt: string
  description: string
  discount: number
  discountType: DiscountTypeEnum
  from: string
  id: string
  label: string
  labelColor: string
  logo: string | null
  maxPrice: number
  maxYear: number
  minPrice: number
  minYear: number
  models: CarModel[]
  pictures: string[]
  priority: OfferPriorityEnum
  status: ExtendedStatus
  textColor: string
  to: string
  type: OfferTypes
  hasChangedLogo?: boolean
  hasChangedPicture?: boolean
  logoState?: File | null
  pictureState?: File | null
}

export type OfferProps = {
  data: Offer
  handleSave: (data: Offer) => Promise<boolean>
  submitLoading: boolean
}

export type OfferCar = {
  id: string
  slug: string
  mainPicture: string
  brand: BaseNameEntity
  carModel: BaseNameEntity
  year: number
  priceInfo: {
    basePrice: number
  }
  status: ExtendedStatus
}

export type ListCarData = {
  offer: Offer
} & GenericListData<OfferCar[]>

export type ListCarByOffer = {
  getListingCarsByOffer: ListCarData
}

export type FilterOfferInput = {
  offer: string
} & FilterInput

export type FilterOfferInputVariable = GenericInputVariable<FilterOfferInput>

export type UpdateOfferPayload = {
  backgroundColor?: string
  description?: string
  discount?: number
  from?: string
  label?: string
  labelColor?: string
  logo?: string
  pictures?: string[]
  priority?: OfferPriorityEnum
  status?: OfferStatusEnum
  textColor?: string
  to?: string
}

export type UpdateOfferInput = {
  where: BaseIdEntity
  data: UpdateOfferPayload
}
