import { NotificationDetailType } from 'models/services/notification/detail'

export const defaultNotificationData: NotificationDetailType = {
  title: 'Are you already driving your dream car?',
  description:
    "🙌🏻 Take advantage of our father's day promotion on all Honda vehicles! 🙌🏻",
  name: 'Dream car on Honda',
  picture: '',
  date: new Date(),
}
