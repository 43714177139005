import React from 'react'
import { Typography } from '@mui/material'
import { Box } from 'library/components/Common'
import { BASE_STEP_WIDTH } from 'library/constants/style'
import { colors } from 'library/styles/theme'

import { StyledSlider } from './style'

export type StepProgressBarProps = {
  currentStep: number
  totalSteps: number
  active?: boolean
  label?: string
  height?: string | number
  width?: string | number
}

export const StepProgressBar = ({
  currentStep,
  totalSteps,
  active,
  label,
  height = '10px',
  width = BASE_STEP_WIDTH,
}: StepProgressBarProps) => {
  return (
    <Box>
      {label ? (
        <Typography
          color={active ? colors.orange : colors.subtitleGray}
          variant="body2"
        >
          {label}
        </Typography>
      ) : null}

      <StyledSlider
        aria-label="step-progress-bar"
        valueLabelDisplay="on"
        disabled
        value={parseFloat(((currentStep / totalSteps) * 100).toFixed(0))}
        valueLabelFormat={(x) => `${x}%`}
        max={100}
        sx={{
          width,
          height,
        }}
      />
    </Box>
  )
}
