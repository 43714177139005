import React from 'react'
import { Typography } from '@mui/material'
import { Box } from 'library'

import { NameInformation } from 'models/car'

import { colors, FONT_WEIGHT } from 'styles/theme'

import TextItem from './Items'

export type ItemProps = {
  item: NameInformation
  handleInfo: React.Dispatch<React.SetStateAction<NameInformation[]>>
  edit: boolean
  index: number
}

const ItemContainer = ({ item, handleInfo, edit, index }: ItemProps) => {
  return (
    <Box
      width="270px"
      sx={{
        display: 'grid',
        gridTemplateColumns: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <TextItem item={item} handleInfo={handleInfo} edit={edit} index={index} />
      <Typography
        variant={item.inverted ? 'h5' : 'body1'}
        fontWeight={item.inverted ? FONT_WEIGHT.BOLD : FONT_WEIGHT.MEDIUM}
        color={item.inverted ? colors.black : colors.gray}
        sx={{
          gridColumnStart: item.inverted ? 1 : 'unset',
          gridRowStart: item.inverted ? 1 : 'unset',
        }}
      >
        {item.label}
      </Typography>
    </Box>
  )
}

export default ItemContainer
