import React, { useState } from 'react'
import { useFormik } from 'formik'
import { ButtonNavigator, ErrorMessage, sleep } from 'library'
import * as yup from 'yup'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import { TranslationContainer } from 'components/CarSettings/style'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { FUEL_TYPE_CREATION_OPTION } from 'constants/fuelType'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { FuelType } from 'models/car'
import { PublicationStatus } from 'models/status'
import { TranslationChange } from 'models/translations'
import { FuelTypeCreationProps } from 'utils/CarSettings/FuelType/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const FuelTypeInfo = ({
  handleContinue,
  fuelTypeData,
  updateFuelTypeData,
}: FuelTypeCreationProps) => {
  const { fuelTypeInformation } = fuelTypeData
  const [fuelTypeStatus, setFuelTypeStatus] = useState<boolean>(
    fuelTypeInformation.status === PublicationStatus.PUBLISHED
  )
  const [stepInfo] = useState<FuelType>(fuelTypeInformation)
  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >(fuelTypeInformation.translations)

  const { text: translation } = useTranslation(textFiles.FUEL_TYPE_CREATION)
  const { information } = translation

  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const handleChangeTranslation = (
    translations: TranslationItemType[],
    type: TranslationChange
  ) => {
    sessionStorage.setItem('type', type)
    setAddedTranslations(translations)
  }

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<FuelType>>({
    initialValues: {
      name: fuelTypeInformation.name,
    },
    validationSchema,
    onSubmit: async (values) => {
      await sleep(1000)
      updateFuelTypeData({
        type: FUEL_TYPE_CREATION_OPTION.UPDATE_FUEL_TYPE_INFORMATION,
        payload: {
          ...stepInfo,
          name: values.name as string,
          translations: addedTranslations,
          status: fuelTypeStatus
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      })

      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={information.isPublished}
            description={information.isPublishedDescription}
            status={fuelTypeStatus}
            handleStatus={setFuelTypeStatus}
            testId="published"
          />
          <StyledTextFieldContainer
            title={information.name}
            description={information.nameDescription}
          >
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={information.placeholder}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <TranslationContainer>
            <Translation
              addedTranslations={addedTranslations}
              handleChangeTranslation={handleChangeTranslation}
              translationDescription={information.translationsDescription}
            />
          </TranslationContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default FuelTypeInfo
