import {
  Menu,
  menuClasses,
  MenuItem,
  menuItemClasses,
  styled,
} from '@mui/material'
import { boxShadow } from 'library/styles/theme'

export const StyledMenu = styled(Menu)({
  [`& .${menuClasses.paper}`]: {
    width: '250px',
    borderRadius: '4px',
    boxShadow,
    marginTop: '4px',
  },
})

export const StyledMenuItem = styled(MenuItem)({
  [`&.${menuItemClasses.root}`]: {
    padding: '2px 12px',
    width: '100%',
  },
})

export const StyledLink = styled('a')({
  display: 'flex',
  textDecoration: 'none',
  alignItems: 'center',
  width: '100%',
})
