import { styled, textFieldClasses } from '@mui/material'
import { Box, Container } from 'library'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  width: '100%',
  display: 'flex',
  marginBottom: '0.5rem',
  flexDirection: 'column',
  justifyContent: 'flex-start',

  [`& .${textFieldClasses.root}`]: {
    height: '33px',
    borderRadius: '4px',
    color: colors.gray,
    input: {
      height: '33px',
      padding: '0',
      fontSize: '14px',
    },
    svg: {
      height: '15px',
      width: '15px',
    },
  },

  '& span': {
    width: '100%',
    fontSize: '14px',
  },
})

export const StyledContainer = styled(Container)({
  width: 'auto',
  height: '748px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& > p, > div:first-of-type': {
    width: '358px',
    textAlign: 'left',
  },
})

export const StyledButton = styled('label')({
  width: '36px',
  height: '37px',
  position: 'absolute',
  bottom: '5%',
  right: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    color: colors.gray,
  },
})
