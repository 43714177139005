import gql from 'graphql-tag'

export const CREATE_INSPECTION = gql`
  mutation createInspection($input: CreateInspectionInput!) {
    data: createInspection(input: $input) {
      id
    }
  }
`

export const CREATE_INSPECTION_WITH_EXISTING_CAR = gql`
  mutation createInspectionWithExistingCar(
    $input: CreateInspectionWithExistingCar!
  ) {
    data: createInspectionWithExistingCar(input: $input) {
      id
    }
  }
`
