import CategoryInfo from 'components/CarSettings/Category/Creation/CategoryInfo'
import Summary from 'components/CarSettings/Category/Creation/Summary'

import {
  CategoryCreationAction,
  CategoryCreationModel,
} from 'models/categoryCreation'

export type CategoryCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  categoryData: CategoryCreationModel
  updateCategoryData: (action: CategoryCreationAction) => void
}

export const INITIAL_CATEGORY_CREATION_STEPS: Array<
  React.FC<CategoryCreationProps>
> = [CategoryInfo, Summary]
