import {
  formControlClasses,
  sliderClasses,
  styled,
  TextField as MUITextField,
} from '@mui/material'
import { Box, Button, Container } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledNumber = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  alignSelf: 'flex-start',
  backgroundColor: colors.orange,
  color: colors.commonWhite,
  fontSize: 25,
  height: '30px',
  width: '30px',
  fontWeight: FONT_WEIGHT.BOLD,
  borderRadius: '4px',
})

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  borderRadius: '0px',
  flex: '1 1 auto',
  height: ' 100%',
})

export const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
})

export const StepTitle = styled(Box)({
  position: 'relative',
  width: '100%',
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  marginBottom: 20,
  width: '100%',

  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
  '> div:nth-of-type(2)': {
    position: 'static',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [`.${sliderClasses.rail}`]: {
      height: 10,
      color: colors.gray,
    },
    [`.${sliderClasses.track}`]: {
      height: 10,
    },
  },
})

export const StyledTextField = styled(MUITextField)({
  border: `1px solid ${colors.lightGray}`,
  borderRadius: '4px',
  opacity: 1,
  height: '38px',
  '& div': {
    color: colors.placeholderGray,
    fontWeight: FONT_WEIGHT.MEDIUM,
    height: '100%',
    fontSize: '12px',
  },
  '& div fieldset': {
    border: 'none',
  },
  input: {
    height: '5px',
    borderRadius: '4px',
  },
})

export const StyledButton = styled(Button)({
  height: '30px !important',
  fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
})
