/* eslint-disable import/no-duplicates */
import React, { useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiPopper from '@mui/material/Popper'
import { Box } from 'library/components/Common/Box'
import { Button } from 'library/components/Common/Button'
import { TabObjects } from 'library/components/Common/Tabs'
import { Title } from 'library/components/Common/Title'
import {
  DateRangeCalendarTextModel,
  ExtendedCalendarRange,
  OmitFieldsType,
} from 'library/components/General/ExtendedCalendarRange'
import {
  CalendarRangeType,
  DateRangeCalendarTabType,
  DateRangeTitleTabType,
} from 'library/models/date'
import { Language } from 'library/models/language'
import {
  formatDateTitle,
  getStartOfCurrentMonth,
  getStartOfCurrentWeek,
  getStartOfCurrentYear,
} from 'library/utils/date'

import { StyledContainer } from './style'

export type TitleTabsTextModel = {
  dayLabel: string
  weekLabel: string
  monthLabel: string
  yearLabel: string
  allLabel: string
  setLabel: string
  allEntriesLabel: string
}

export type TimeFilterProps = {
  title?: string
  handleChangeDateRange: (newDateRange: CalendarRangeType) => void
  dateRange: CalendarRangeType
  dateRangeCalendarText?: DateRangeCalendarTextModel
  /**
   * When enabled the tabs `today`, `this month` and `all`
   */
  extendedFields?: boolean
  /**
   * Object that decides which tabs to omit.
   * You have to set true the ones you wish to omit:  ALL | TODAY | YESTERDAY | LAST_SEVEN_DAYS | LAST_THIRTY_DAYS | MONTH | LAST_MONTH | CUSTOM
   */
  omitFields?: OmitFieldsType

  /**
   * Optional Maximum allowed date for the calendar to be clicked, this will be passed to the to calendar. If undefined defaults to new Date().
   * If null it will have no limit
   */
  maxDate?: Date | null
  /**
   * Minimum allowed date for the calendar to be clicked, this will be passed to the from calendar
   */
  minDate?: Date
  titleTabs?: TitleTabsTextModel
  language?: Language
}

export const TimeFilter = ({
  title = '',
  titleTabs,
  dateRange,
  handleChangeDateRange,
  language,
  dateRangeCalendarText,
  extendedFields,
  omitFields,
  maxDate,
  minDate,
}: TimeFilterProps) => {
  const [titleTabValue, setTitleTabValue] = useState<
    DateRangeTitleTabType | boolean
  >(DateRangeTitleTabType.TODAY)

  const [calendarTabValue, setCalendarTabValue] = useState<
    DateRangeCalendarTabType | boolean
  >(false)

  // manage popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const maintainPopover = () => {
    setAnchorEl(anchorEl)
  }

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopperClose = () => {
    setAnchorEl(null)
  }

  // functions to manage tabs change
  const handleTitleTabChange = (newValue: string | number) => {
    const currentValue = String(newValue) as DateRangeTitleTabType
    setTitleTabValue(currentValue)
    setCalendarTabValue(false)
  }

  const handleCalendarTabChange = (newValue: DateRangeCalendarTabType) => {
    setCalendarTabValue(newValue)
    setTitleTabValue(false)
  }

  //  manage date and tabs
  const handleTodayClick = () => {
    handleChangeDateRange({ fromDate: new Date(), toDate: new Date() })
  }

  const handleWeekClick = () => {
    const startWeek = getStartOfCurrentWeek()
    handleChangeDateRange({ fromDate: startWeek, toDate: new Date() })
  }

  const handleMonthClick = () => {
    const startMonth = getStartOfCurrentMonth()
    handleChangeDateRange({ fromDate: startMonth, toDate: new Date() })
  }

  const handleClickYear = () => {
    const startMonth = getStartOfCurrentYear()
    handleChangeDateRange({ fromDate: startMonth, toDate: new Date() })
  }

  const handleAllClick = () => {
    handleChangeDateRange({ fromDate: null, toDate: null })
  }

  const items: TabObjects[] = [
    {
      id: 1,
      item: {
        label: titleTabs?.dayLabel || 'Day',
        value: DateRangeTitleTabType.TODAY,
        onClick: handleTodayClick,
      },
    },
    {
      id: 2,
      item: {
        label: titleTabs?.weekLabel || 'Week',
        value: DateRangeTitleTabType.WEEK,
        onClick: handleWeekClick,
      },
    },
    {
      id: 3,
      item: {
        label: titleTabs?.monthLabel || 'Month',
        value: DateRangeTitleTabType.MONTH,
        onClick: handleMonthClick,
      },
    },
    {
      id: 4,
      item: {
        label: titleTabs?.yearLabel || 'Year',
        value: DateRangeTitleTabType.YEAR,
        onClick: handleClickYear,
      },
    },
    {
      id: 5,
      item: {
        label: titleTabs?.allLabel || 'All',
        value: DateRangeTitleTabType.ALL,
        onClick: handleAllClick,
      },
    },
  ]

  return (
    <StyledContainer>
      <Title
        title={title}
        subtitle={formatDateTitle(
          dateRange,
          titleTabs?.allEntriesLabel || 'All entries',
          language?.code || 'en'
        )}
        tabs={items}
        icon={<DateRangeIcon />}
        tabValue={titleTabValue}
        handleTabChange={handleTitleTabChange}
        disableDivider
      />
      <Button
        id="date-range-button"
        endIcon={<ExpandMoreIcon />}
        size="small"
        style={{
          borderRadius: '5px',
          height: '24px',
          padding: '0px 8px',
          marginBottom: '4px',
        }}
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
        data-cy="date-range-button"
      >
        {titleTabs?.setLabel || 'Set'}
      </Button>
      <MuiPopper
        id="date-range-popover"
        open={open}
        onMouseEnter={maintainPopover}
        onMouseLeave={handlePopperClose}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{
          zIndex: 1500,
        }}
      >
        <Box>
          <ExtendedCalendarRange
            tabValue={calendarTabValue}
            handleTabChange={handleCalendarTabChange}
            dateRange={dateRange}
            handleChangeDateRange={handleChangeDateRange}
            extendedFields={extendedFields}
            omitFields={omitFields}
            maxDate={maxDate}
            minDate={minDate}
            translation={dateRangeCalendarText}
          />
        </Box>
      </MuiPopper>
    </StyledContainer>
  )
}
