import React from 'react'
import { CSSObject, SelectChangeEvent } from '@mui/material'
import { Box, CurboSpot } from 'library'

import DownloadModal from 'components/General/DownloadModal'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'

import { testReport } from 'constants/inspection'
import { textFiles } from 'constants/textFiles'
import { CalendarRangeType } from 'models/date'
import { ExtendedFilter } from 'models/listingReport'
import { SelectItem } from 'models/select'

import { colors } from 'styles/theme'

import { RowFooterSelect } from './RowFooterSelect'
import { StyledFooterContainer, StyledPagination } from './style'

export type TableFooterProps = {
  /**
   * Styles passed to the container component
   */
  containerStyles?: CSSObject
  /**
   * Pair of dates signaling a range in the calendar
   */
  dateRange?: CalendarRangeType
  /**
   * Text Input passed to the search bar
   */
  searchTerm?: string
  /**
   * Page currently being displayed
   */
  currentPage: number
  /**
   * Function to manage the change of the page
   */
  onPageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void
  /**
   * Number of elements(entries) of the table
   */
  pageCount: number
  /**
   * Rows per page
   */
  pageSize: number
  /**
   * Array of items being used for the select
   */
  selectItems: SelectItem[]
  /**
   ** Function to manage the change of the rowsPerPage
   */
  onSelectChange?: (event: SelectChangeEvent<number>) => void
  /**
   ** Text to display next to select row size select
   */
  rowLabelText: string
  /**
   * Array containing each of the filters selected by the user
   */
  filtersList: Filter[]

  /**
   * Fields list as selected in the inspection listing bar
   */
  fields: string[]

  /**
   * Array containing the fields Labels
   */

  fieldLabels: Record<string, string>
  /**
   * Optional string for different text Filters by criteria translations file
   */
  filtersFile?: string
  /**
   * If `true` it will hide the button that shows the download report modal
   */
  hideDownloadModal?: boolean
  /**
   * CurboSpots
   */
  curboSpots?: CurboSpot[]

  /**
   * Name that the generated report will have
   */
  reportName?: string

  /**
   * Total of entries in the listing page
   */

  inspectionCount?: number

  /**
   * Url to the endpoint in which the report is located
   */
  url?: string

  /**
   * Used as additional filters when you need to use a filter that has an array
   * as a value to compare in the request
   */
  extendedFilters?: ExtendedFilter[]
  /**
   * Background color for the footer
   */
  footerBackgroundColor?: string

  /**
   * Background color for row footer and pagination
   */
  componentsBackgroundColor?: string
}

const TableFooter = ({
  currentPage,
  onPageChange,
  onSelectChange,
  pageCount,
  pageSize,
  dateRange,
  searchTerm,
  selectItems,
  rowLabelText,
  containerStyles,
  filtersList,
  fields,
  fieldLabels,
  filtersFile = textFiles.INSPECTION_LISTING,
  hideDownloadModal,
  curboSpots,
  reportName,
  inspectionCount,
  url,
  extendedFilters,
  footerBackgroundColor = colors.altGray,
  componentsBackgroundColor = colors.background,
}: TableFooterProps) => {
  return (
    <StyledFooterContainer
      sx={{
        backgroundColor: footerBackgroundColor,
        ...containerStyles,
      }}
    >
      <RowFooterSelect
        pageSize={pageSize}
        onSelectChange={onSelectChange}
        selectItems={selectItems}
        rowLabelText={rowLabelText}
        backgroundColor={componentsBackgroundColor}
      />

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        position="sticky"
      >
        <StyledPagination
          count={pageCount}
          onChange={onPageChange}
          page={currentPage}
          variant="text"
          size="small"
          shape="rounded"
          data-cy="listing-pagination"
          backgroundColor={componentsBackgroundColor}
        />
        {!hideDownloadModal ? (
          <DownloadModal
            entries={inspectionCount || testReport.entries}
            reportName={reportName || testReport.reportName}
            searchTerm={searchTerm}
            dateRange={dateRange}
            filters={filtersList}
            fields={fields.filter((field) => field !== 'actions')}
            fieldLabels={fieldLabels}
            file={filtersFile}
            curboSpots={curboSpots}
            // reportType={REPORT_TYPE_ENUM.CSV}
            url={url}
            extendedFilters={extendedFilters}
          />
        ) : null}
      </Box>
    </StyledFooterContainer>
  )
}

export default TableFooter
