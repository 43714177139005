import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CheckIcon from '@mui/icons-material/Check'
import EmailIcon from '@mui/icons-material/Email'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import {
  Avatar,
  Button,
  ErrorMessage,
  maxAllowedSizePerFileInKb,
  TextField,
  useNotification,
} from 'library'
import { uploadImageService } from 'services/uploadImage'

import { cypressSaveButton } from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { Role } from 'models/role'
import { GenericInputVariable } from 'models/services/base'
import { UpdateSelfData } from 'models/services/user'

import { UPDATE_USER } from 'graphQL/User/mutations'

import { StyledBox, StyledButton, StyledContainer } from './style'

export type FieldBoxProps = {
  /**
   * Label of FieldBox
   */
  label: string
  /**
   * Icon of FieldBox
   */
  icon: React.ReactNode
  /**
   * Value of FieldBox
   */
  value: string
  /**
   * Is loading
   */
  loading: boolean
  /**
   * Error message to display
   */
  errorText: string
  /**
   * On change handler
   */
  handleChange?: (field: string, newValue: string) => void

  /**
   * Input name
   */
  name?: string
}

const FieldBox: React.FC<FieldBoxProps> = ({
  label,
  icon,
  value,
  errorText,
  loading,
  handleChange,
  name,
}: FieldBoxProps): JSX.Element => {
  return (
    <StyledBox>
      <span>{label}</span>
      <TextField
        icon={icon}
        value={value}
        disabled={loading || !handleChange}
        onChange={(e) => {
          const { value: inputValue } = e.target
          if (handleChange) {
            handleChange(label, inputValue)
          }
        }}
        name={name}
      />
      {value.length < 3 ? (
        <ErrorMessage text={errorText} sx={{ margin: '0.5em 0 0 0' }} />
      ) : null}
    </StyledBox>
  )
}

type UserDataState = {
  name: string
  lastName: string
  email: string
  [key: string]: string
}

type UpdateUserVariables = {
  name: string
  lastName: string
  profilePicture?: string
}

export type PersonalInfoProps = {
  /**
   * Name of user of profile
   */
  name: string
  /**
   * Last name of user of profile
   */
  lastName: string
  /**
   * Email of user of profile
   */
  email: string
  /**
   * Role of user of profile
   */
  roles: Role[]
  /**
   * User picture
   */
  picture: string
  /**
   * Is data loading?
   */
  loading: boolean
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  name,
  email,
  lastName,
  roles,
  picture = '',
  loading,
}: PersonalInfoProps) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [pictureFile, setPictureFile] = useState<File | null>(null)
  const [userData, setUserData] = useState<UserDataState>(() => {
    return {
      name,
      lastName,
      email,
    }
  })
  const [profile, setProfile] = useState<string>(picture)
  const [pictureSizeErrorStatus, setPictureSizeErrorStatus] =
    useState<boolean>(false)
  const { handleUpdateUser } = useUser()
  const { show } = useNotification()
  const [updateUser, { loading: mutationLoading }] = useMutation<
    UpdateSelfData,
    GenericInputVariable<UpdateUserVariables>
  >(UPDATE_USER, {
    onCompleted({ updateSelf }) {
      setEditMode(false)
      handleUpdateUser(updateSelf)
      show({
        updatedSeverity: 'success',
      })
    },
    onError(error) {
      const errorText: string =
        error.graphQLErrors && error.graphQLErrors.length > 0
          ? error.graphQLErrors[0].message
          : error.message
      console.error('Something went wrong', errorText)
      show({
        updatedSeverity: 'error',
      })
    },
  })
  const [disableEdit, setDisableEdit] = useState<boolean>(false)
  const { text } = useTranslation(textFiles.ACCOUNT)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const {
    settings: { personalInfo: translation },
  } = text

  const handleDataChange = (field: string, value: string) => {
    if (field === translation.name) {
      setUserData((prevData) => {
        return {
          ...prevData,
          name: value,
        }
      })
    } else {
      setUserData((prevData) => {
        return {
          ...prevData,
          lastName: value,
        }
      })
    }
  }

  const handleEdit = async () => {
    if (pictureFile) {
      try {
        const response = await uploadImageService(pictureFile)
        updateUser({
          variables: {
            input: {
              name: userData.name,
              lastName: userData.lastName,
              profilePicture: response.data,
            },
          },
        })
        setPictureFile(null)
      } catch (e) {
        console.error('Failed to upload image', e)
      }
    } else {
      updateUser({
        variables: {
          input: {
            name: userData.name,
            lastName: userData.lastName,
          },
        },
      })
    }
  }

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const { files } = e.target
    const file = files && files.length > 0 ? files[0] : null

    if (file && file.size <= maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(false)
      reader.onload = () => {
        if (reader.readyState === 2) {
          setEditMode(true)
          setProfile(String(reader.result))
        }
      }
      if (file) {
        setPictureFile(file)
        reader.readAsDataURL(file)
      }
    } else if (file && file.size > maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(true)
    }
  }

  useEffect(() => {
    setUserData({
      name,
      lastName,
      email,
    })
    setProfile(picture)
  }, [name, email, lastName, picture, loading])

  useEffect(() => {
    if (
      name !== userData.name ||
      lastName !== userData.lastName ||
      picture !== profile
    ) {
      setEditMode(true)
      if (userData.name.length < 3 || userData.lastName.length < 3) {
        setDisableEdit(true)
      } else {
        setDisableEdit(false)
      }
    } else {
      setEditMode(false)
    }
  }, [name, lastName, picture, profile, setDisableEdit, setEditMode, userData])

  const shouldBeLoading = mutationLoading || loading
  const capitalizeRoles = (role: Role) =>
    `${role.name[0].toLocaleUpperCase()}${role.name.slice(1)}`

  return (
    <StyledContainer
      title={translation.title}
      description={translation.subtitle}
    >
      <section style={{ position: 'relative', marginBottom: '2rem' }}>
        <Avatar height={322} width={338} variant="rounded" image={profile} />
        <input
          disabled={shouldBeLoading}
          type="file"
          name="input"
          id="profile-picture-input"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={imageHandler}
          data-cy="profile-picture-input"
        />
        <StyledButton htmlFor="profile-picture-input">
          <CameraAltIcon />
        </StyledButton>
      </section>
      {pictureSizeErrorStatus && (
        <ErrorMessage
          text={generalText.fileSize.sizeError.replace('%d', 3)}
          sx={{ marginBottom: '5px' }}
        />
      )}
      <FieldBox
        errorText={translation.errorLength}
        label={translation.name}
        icon={<PersonIcon />}
        value={!shouldBeLoading ? userData.name : translation.loading}
        loading={shouldBeLoading}
        handleChange={handleDataChange}
        name="name"
      />
      <FieldBox
        errorText={translation.errorLength}
        label={translation.lastName}
        icon={<PersonIcon />}
        value={!shouldBeLoading ? userData.lastName : translation.loading}
        loading={shouldBeLoading}
        handleChange={handleDataChange}
        name="lastName"
      />
      <FieldBox
        errorText={translation.errorLength}
        label={translation.email}
        icon={<EmailIcon />}
        value={!shouldBeLoading ? userData.email : translation.loading}
        loading={shouldBeLoading}
      />
      <FieldBox
        errorText={translation.errorLength}
        label={translation.role}
        icon={<PeopleIcon />}
        value={
          !shouldBeLoading
            ? roles.map((role) => capitalizeRoles(role)).toString()
            : translation.loading
        }
        loading={shouldBeLoading}
      />
      {editMode ? (
        <Button
          startIcon={<CheckIcon />}
          fullWidth
          onClick={handleEdit}
          disabled={disableEdit}
          data-cy={cypressSaveButton}
        >
          {translation.editProfileButton}
        </Button>
      ) : null}
    </StyledContainer>
  )
}

export default PersonalInfo
