import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import {
  formControlClasses,
  formLabelClasses,
  styled,
  Typography,
} from '@mui/material'
import { Box, colors, FONT_WEIGHT, smallBoxShadow } from 'library'

export const CaseContainer = styled(Box)({
  backgroundColor: colors.commonWhite,
  margin: '0 0 8px 0',
  borderRadius: '8px',
  width: '302px',
  height: '210px',
})

export const SelectContainer = styled(Box)({
  [`.${formControlClasses.root}`]: {
    width: '100%',
    height: '27px',
  },
  [`.${formLabelClasses.root}`]: {
    top: '-10px',
    left: '20px',
  },
  marginTop: '0.5rem',
})

export const SmallSubtitleText = styled(Typography)({
  color: colors.gray,
  fontWeight: FONT_WEIGHT.REGULAR,
})

export const SubtitleText = styled(Typography)({
  color: colors.black,
  fontWeight: FONT_WEIGHT.MEDIUM,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})

export const TooltipPopper = styled(Box)({
  backgroundColor: colors.commonWhite,
  borderRadius: '4px',
  boxShadow: smallBoxShadow,
  padding: '0.25rem',
})

export const StyledLocalPhoneIcon = styled(LocalPhoneIcon)({
  color: colors.gray,
  transition: '0.3s',
  fontSize: '16px',
  ':hover': { color: colors.commonBlack },
})
