import {
  buttonBaseClasses,
  styled,
  tabClasses,
  tabsClasses,
} from '@mui/material'
import { Box } from 'library/components/Common/Box'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

export const StyledTabs = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '50%',

  [`.${tabsClasses.flexContainer}`]: {
    textAlign: 'center',
    alignItems: 'baseline',
    marginBottom: '6px',
  },

  [`.${tabsClasses.scrollButtons}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: '6px',
  },

  [`.${buttonBaseClasses.root}`]: {
    color: colors.gray,
    fontWeight: FONT_WEIGHT.MEDIUM,
    margin: '0 10px',
    width: 'auto',
  },

  [`.${tabClasses.textColorPrimary}`]: {
    color: colors.gray,
  },

  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 16px',
  },
})
