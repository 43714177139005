import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ErrorIcon from '@mui/icons-material/Error'
import { Typography } from '@mui/material'
import { Box, Button, Modal } from 'library'

import { CustomErrorStructure } from 'graphQL/client'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { BottomContainer, StyledBox, StyledList } from './style'

export type InventoryErrorModalProps = {
  open: boolean
  onConfirmHandler: () => void
  errorList: CustomErrorStructure[]
  header: string
  confirmButtonText: string
}

const InventoryErrorModal = ({
  open,
  onConfirmHandler,
  errorList,
  header,
  confirmButtonText,
}: InventoryErrorModalProps) => {
  return open ? (
    <Modal
      open={open}
      size="customDimension"
      customDimension={{
        width: 562,
        height: 382,
      }}
    >
      <StyledBox>
        <ErrorIcon />
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          width="90%"
          marginTop="1rem"
        >
          <Typography
            color={colors.black}
            fontWeight={FONT_WEIGHT.SEMI_BOLD}
            variant="body2"
            width="348px"
            textAlign="center"
          >
            {header}
          </Typography>
          <StyledList>
            {errorList.map((error, index) => (
              // fields are static so index are ok
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                <Typography
                  color={colors.black}
                  margin="0.3rem"
                  variant="body2"
                >
                  <strong>{error.field}:</strong> {error.message}
                </Typography>
              </li>
            ))}
          </StyledList>
        </Box>
        <BottomContainer>
          <Button
            size="small"
            style={{
              width: 'auto',
              minWidth: 159,
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
            }}
            onClick={onConfirmHandler}
          >
            <ArrowBackIosIcon />
            {confirmButtonText}
          </Button>
        </BottomContainer>
      </StyledBox>
    </Modal>
  ) : null
}

export default InventoryErrorModal
