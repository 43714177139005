import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, TextField } from '@mui/material'
import { Box } from 'library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { FONT_WEIGHT } from 'styles/theme'

import { NoteContainer, StyledNoteCard, StyledText } from '../style'
import { NoteType } from '..'

type Props = {
  note: NoteType
  isDisabled: boolean | undefined
  handleOpenModal: (index: number) => void
  handleEditData: (noteData: NoteType) => void
}

const Note = ({ note, isDisabled, handleOpenModal, handleEditData }: Props) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [newNote, setNewNote] = useState<NoteType>({
    index: note.index,
    text: note.text,
  })

  const { text } = useTranslation(textFiles.GENERAL)
  const { notesComponentText } = text

  const handleNotes = () => {
    if (!edit) {
      setEdit(true)
    } else {
      handleEditData(newNote)
      setEdit(false)
    }
  }

  useEffect(() => {
    setNewNote(note)
  }, [edit, note])

  return (
    <NoteContainer>
      {edit ? (
        <>
          <StyledNoteCard>
            <TextField
              id="note-creator"
              placeholder={notesComponentText.placeholder}
              aria-label="note-content"
              value={newNote.text}
              onChange={(e) => setNewNote({ ...newNote, text: e.target.value })}
              minRows={3}
              variant="standard"
              inputProps={{
                maxLength: 102,
                style: {
                  fontSize: '12px',
                  fontWeight: FONT_WEIGHT.MEDIUM,
                },
              }}
              multiline
              fullWidth
              autoFocus
            />
          </StyledNoteCard>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box>
              <IconButton onClick={handleNotes}>
                <CheckIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setEdit(false)
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <StyledNoteCard>
            <StyledText variant="subtitle1">{note.text}</StyledText>
          </StyledNoteCard>
          {!isDisabled && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <IconButton disabled={isDisabled} onClick={handleNotes}>
                <EditIcon />
              </IconButton>
              <IconButton
                disabled={isDisabled}
                onClick={() => handleOpenModal(note.index)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </>
      )}
    </NoteContainer>
  )
}

export default Note
