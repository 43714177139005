/* eslint-disable func-names */
import React, { useState } from 'react'
import { SelectChangeEvent, Typography } from '@mui/material'
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik'
import { Box, Button, ButtonNavigator, ErrorMessage, Select } from 'library'
import * as yup from 'yup'

import {
  Divider,
  RepresentativeNumberBox,
} from 'components/Operations/Dealer/Creation/DealerInfo/style'

import { cypressAddButton } from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import { USER_CREATION_OPTION } from 'constants/UserManagement/creation'
import useTranslation from 'hooks/useTranslation'
import { Role, RolesOption } from 'models/userCreation'
import { UserCreationProps } from 'utils/User/creation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import {
  RoleContent,
  StyledContainer,
  StyledTextFieldContainer,
} from '../Information/style'

import { StyledForm } from './style'

declare module 'yup' {
  interface ArraySchema<T> {
    unique(
      message: string,
      mapper?: (value: T, index?: number, list?: T[]) => T[]
    ): ArraySchema<T>
  }
}

const Roles = ({
  roleOptions,
  areaOptions,
  userData,
  handleContinue,
  handleBack,
  updateUserData,
}: UserCreationProps) => {
  const {
    userRoles: { roles, options },
  } = userData

  const { text } = useTranslation(textFiles.USER_MANAGEMENT_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const [roleOps, setRoleOps] = useState<RolesOption[][]>(options)

  const {
    roles: {
      area: areaLabel,
      role: roleLabel,
      addButton,
      deleteButton,
      duplicateRole,
    },
  } = text

  yup.addMethod(
    yup.array,
    'unique',
    function (message, mapper = (a: unknown) => a) {
      // eslint-disable-next-line react/no-this-in-sfc
      return this.test('unique', message, function (list) {
        return list!.length === new Set(list!.map(mapper)).size
      })
    }
  )

  const validationSchema = yup.object({
    roles: yup
      .array()
      .of(
        yup.object().shape({
          area: yup.string().required(validationText.fieldRequired),
          role: yup.string().required(validationText.fieldRequired),
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .unique('duplicate role', (a: any) => a.role),
  })

  const formik = useFormik<{ roles: Role[] }>({
    initialValues: {
      roles,
    },
    validationSchema,
    onSubmit: (values) => {
      updateUserData({
        type: USER_CREATION_OPTION.UPDATE_USER_ROLES,
        payload: {
          roles: [...values.roles],
          options: roleOps,
        },
      })
      handleContinue()
    },
  })

  const onSelectChange = (
    event: SelectChangeEvent<unknown>,
    index: number,
    type: string
  ) => {
    const {
      target: { value },
    } = event
    formik.setFieldValue(`roles[${index}].${type}`, value as string)
    formik.setFieldTouched(`roles[${index}].${type}`, false)
    if (type === 'area') {
      const filteredRoles = roleOptions.filter(
        (role) => role.area.value === value
      )
      setRoleOps((prevState) => {
        const newRoles: RolesOption[][] = [...prevState]
        newRoles[index] = filteredRoles
        return newRoles
      })
      if (filteredRoles) {
        formik.setFieldValue(`roles[${index}].role`, filteredRoles[0].value)
        formik.setFieldTouched(`roles[${index}].role`, false)
      }
    }
  }

  const userRolesErrorExists = (index: number, key: string) => {
    if (
      getIn(formik.touched.roles?.[index], key) &&
      Boolean(getIn(formik.errors.roles?.[index], key))
    )
      return true

    return false
  }

  return (
    <FormikProvider value={formik}>
      <StyledContainer>
        <StyledForm onSubmit={formik.handleSubmit}>
          <FieldArray
            name="roles"
            render={(arrayHelpers) => (
              <div>
                {formik.values.roles.map((_, index) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    width="100%"
                  >
                    <RepresentativeNumberBox>
                      <Typography
                        color={colors.altBlack}
                        fontWeight={FONT_WEIGHT.MEDIUM}
                        variant="subtitle1"
                      >
                        {`${roleLabel} #${index + 1}`}
                      </Typography>
                      <Divider />
                      {index > 0 ? (
                        <Button
                          size="small"
                          variant="text"
                          onClick={() => {
                            arrayHelpers.remove(index)
                            setRoleOps((prevState) => {
                              const newRoles: RolesOption[][] = [...prevState]
                              newRoles.splice(index, 1)
                              return newRoles
                            })
                          }}
                          testId={`delete-role-${index}-button`}
                        >
                          {deleteButton}
                        </Button>
                      ) : null}
                    </RepresentativeNumberBox>
                    <RoleContent>
                      <StyledTextFieldContainer title={`${areaLabel}*`}>
                        <Select
                          options={areaOptions}
                          variant="outlined"
                          name={`roles[${index}].area`}
                          value={formik.values.roles[index].area}
                          onChange={(e) => onSelectChange(e, index, 'area')}
                          sx={{ fontSize: 12, height: 40 }}
                          label={areaLabel}
                          error={userRolesErrorExists(index, 'area')}
                        />
                        {userRolesErrorExists(index, 'area') ? (
                          <ErrorMessage
                            sx={{
                              alignSelf: 'flex-start',
                              position: 'static',
                              marginTop: '0.5rem',
                            }}
                            text={
                              getIn(formik.errors.roles?.[index], 'area') || ''
                            }
                          />
                        ) : null}
                      </StyledTextFieldContainer>
                      <StyledTextFieldContainer title={`${roleLabel}*`}>
                        <Select
                          options={roleOps[index]}
                          variant="outlined"
                          name={`roles[${index}].role`}
                          value={formik.values.roles[index].role}
                          onChange={(e) => onSelectChange(e, index, 'role')}
                          sx={{ fontSize: 12, height: 40 }}
                          label={roleLabel}
                          error={userRolesErrorExists(index, 'role')}
                          disabled={
                            formik.values.roles[index].area.length === 0
                          }
                        />
                        {userRolesErrorExists(index, 'role') ? (
                          <ErrorMessage
                            sx={{
                              alignSelf: 'flex-start',
                              position: 'static',
                              marginTop: '0.5rem',
                            }}
                            text={
                              getIn(formik.errors.roles?.[index], 'role') || ''
                            }
                          />
                        ) : null}
                      </StyledTextFieldContainer>
                    </RoleContent>
                  </Box>
                ))}
                <Box>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      arrayHelpers.push({
                        area: '',
                        role: '',
                      })
                      setRoleOps((prevState) => {
                        const newRoles: RolesOption[][] = [...prevState]
                        newRoles.push([...roleOptions])
                        return newRoles
                      })
                    }}
                    sx={{
                      padding: '0!important',
                      marginBottom: '0.5rem',
                      height: '24px !important',
                    }}
                    testId={cypressAddButton}
                  >
                    {addButton}
                  </Button>
                  {formik.touched.roles &&
                  formik.errors.roles === 'duplicate role' ? (
                    <ErrorMessage
                      sx={{
                        alignSelf: 'flex-start',
                        position: 'static',
                        paddingLeft: '0',
                      }}
                      text={duplicateRole}
                    />
                  ) : null}
                </Box>
              </div>
            )}
          />
        </StyledForm>
        <ButtonNavigator
          previousFunction={handleBack}
          nextFunction={formik.handleSubmit}
        />
      </StyledContainer>
    </FormikProvider>
  )
}

export default Roles
