import React, { useReducer, useState } from 'react'
import { useQuery } from '@apollo/client'
import { BodyContainerWithHeader, CurboSpot, Stepper } from 'library'

import { BackContainer } from 'components/Common/BackContainer'

import { DAY_ENUM } from 'constants/date'
import { meridiamOptions } from 'constants/inspection'
import { INSPECTION_CREATION_OPTION } from 'constants/Inspection/creation'
import { mapViewConstant } from 'constants/map'
import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import {
  DealerInformationModel,
  SchedulingModel,
  VehicleInformationModel,
} from 'models/inspection'
import {
  InspectionCreationAction,
  InspectionCreationModel,
} from 'models/inspectionCreation'
import { FilterInputVariable, GenericData } from 'models/services/base'
import { Brand, Color } from 'models/services/car'
import { Dealer } from 'models/services/curboSpot'
import inspectionCreationReducer from 'reducers/Inpection/inspectionCreationReducer'
import {
  createInspectionStepperItems,
  INITIAL_INSPECTION_CREATION_STEPS as steps,
} from 'utils/Inspection/creation'

import {
  GET_INSPECTION_BRANDS,
  GET_INSPECTION_COLORS,
} from 'graphQL/Common/CarFeature/queries'
import { GET_CURBO_SPOTS, GET_DEALERS } from 'graphQL/Common/Dealer/queries'

const initialDealerInformationState: DealerInformationModel = {
  multipleInspections: false,
  inspectionAmount: 1,
  dealer: {
    name: '',
    value: '',
    address: '',
    dealerContacts: [],
    telephoneNumber: '',
  },
}

const initialVehicleInformationState: VehicleInformationModel[] = []

const initialSchedulingState: SchedulingModel = {
  address: undefined,
  appointmentDate: null,
  inspector: null,
  meridiam: meridiamOptions[0].value,
  time: '',
  weekCalendar: null,
  currentDayKey: DAY_ENUM.MONDAY,
}

const initialData: InspectionCreationModel = {
  vehicleInformation: initialVehicleInformationState,
  dealerInformation: initialDealerInformationState,
  schedulingInformation: initialSchedulingState,
}
const InspectionCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentSecondaryStep, setCurrentSecondaryStep] = useState<number>(0)
  const [currentCountry] = useSetting()

  const [inspectionData, dispatch] = useReducer(inspectionCreationReducer, {
    ...initialData,
    schedulingInformation: {
      ...initialData.schedulingInformation,
      address: mapViewConstant[currentCountry?.countryCode || 'COP'],
    },
  })
  const [dealers, setDealers] = useState<Dealer[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  const [colorList, setColorList] = useState<Color[]>([])
  const [curboSpots, setCurboSpots] = useState<CurboSpot[]>([])

  const { text } = useTranslation(textFiles.INSPECTION_CREATION)

  const stepperItems = createInspectionStepperItems(text.stepper)

  useQuery<GenericData<Dealer[]>, FilterInputVariable>(GET_DEALERS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setDealers(response.data)
    },
  })

  useQuery<GenericData<Brand[]>, FilterInputVariable>(GET_INSPECTION_BRANDS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setBrands(response.data)
    },
  })

  useQuery<GenericData<Color[]>, FilterInputVariable>(GET_INSPECTION_COLORS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setColorList(response.data)
    },
  })

  useQuery<GenericData<CurboSpot[]>, FilterInputVariable>(GET_CURBO_SPOTS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCurboSpots(response.data)
    },
  })

  const updateInspectionData = (action: InspectionCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleSecondaryContinue = (
    vehicleInformationData: VehicleInformationModel
  ) => {
    updateInspectionData({
      type: INSPECTION_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
      payload: vehicleInformationData,
      index: currentSecondaryStep,
    })

    if (
      currentSecondaryStep + 1 <
      inspectionData.dealerInformation.inspectionAmount
    )
      setCurrentSecondaryStep(currentSecondaryStep + 1)
    else setCurrentStep((step) => step + 1)
  }

  const handleSecondaryBack = (
    vehicleInformationData: VehicleInformationModel
  ) => {
    updateInspectionData({
      type: INSPECTION_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
      payload: vehicleInformationData,
      index: currentSecondaryStep,
    })

    if (currentSecondaryStep - 1 >= 0)
      setCurrentSecondaryStep((step) => step - 1)
    else setCurrentStep((step) => step - 1)
  }

  return (
    <>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
      >
        <Stepper
          currentStep={currentStep}
          currentSecondaryStep={currentSecondaryStep}
          secondaryStepAmount={
            inspectionData.dealerInformation.inspectionAmount
          }
          stepperItems={stepperItems}
          topSx={{
            justifyContent: 'flex-start',
          }}
        />
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          handleSecondaryContinue,
          handleSecondaryBack,
          currentSecondaryStep,
          inspectionData,
          updateInspectionData,
          dealers,
          brands,
          colorList,
          curboSpots,
        })}
      </BodyContainerWithHeader>
    </>
  )
}

export default InspectionCreationPage
