import React from 'react'

const NoDataSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68.256"
      height="66.626"
      viewBox="0 0 68.256 66.626"
    >
      <g id="undraw_no_data_re_kwbl" transform="translate(-0.001 -0.004)">
        <path
          id="Path_261"
          data-name="Path 261"
          d="M354.533,174.126H336.141a1.583,1.583,0,0,0-1.581,1.581v40.877l-.211.064-4.512,1.382a.844.844,0,0,1-1.053-.56l-13.421-43.838a.843.843,0,0,1,.56-1.053l6.953-2.129,20.156-6.17,6.953-2.129a.842.842,0,0,1,1.053.559l3.431,11.205Z"
          transform="translate(-311.2 -159.138)"
          fill="#f2f2f2"
        />
        <path
          id="Path_262"
          data-name="Path 262"
          d="M323.528,148.69l-4.135-13.505A1.791,1.791,0,0,0,317.156,134l-9.775,2.992-20.155,6.171-9.775,2.993a1.793,1.793,0,0,0-1.189,2.238l14.131,46.155a1.8,1.8,0,0,0,1.714,1.268,1.77,1.77,0,0,0,.524-.079l6.7-2.051.211-.065v-.22l-.211.064-6.763,2.071a1.582,1.582,0,0,1-1.974-1.049l-14.13-46.156a1.579,1.579,0,0,1,1.049-1.974l9.775-2.993,20.155-6.17,9.775-2.993a1.579,1.579,0,0,1,1.974,1.049l4.116,13.444.065.211h.219Z"
          transform="translate(-276.182 -133.913)"
          fill="#3f3d56"
        />
        <path
          id="Path_263"
          data-name="Path 263"
          d="M379.281,159.805a.95.95,0,0,1-.908-.671l-1.357-4.434a.948.948,0,0,1,.629-1.185l18.543-5.677a.949.949,0,0,1,1.185.629l1.358,4.434a.95.95,0,0,1-.629,1.185l-18.543,5.677A.946.946,0,0,1,379.281,159.805Z"
          transform="translate(-366.351 -146.33)"
          fill="#07c8e8"
        />
        <circle
          id="Ellipse_230"
          data-name="Ellipse 230"
          cx="2.108"
          cy="2.108"
          r="2.108"
          transform="translate(17.934 0.526)"
          fill="#07c8e8"
        />
        <circle
          id="Ellipse_231"
          data-name="Ellipse 231"
          cx="1.335"
          cy="1.335"
          r="1.335"
          transform="translate(18.707 1.299)"
          fill="#fff"
        />
        <path
          id="Path_264"
          data-name="Path 264"
          d="M568.837,338.563H533.214a.9.9,0,0,1-.9-.9V294.983a.9.9,0,0,1,.9-.9h35.623a.9.9,0,0,1,.9.9v42.684A.9.9,0,0,1,568.837,338.563Z"
          transform="translate(-505.322 -277.202)"
          fill="#e6e6e6"
        />
        <path
          id="Path_265"
          data-name="Path 265"
          d="M519.795,274.087H497.61a1.794,1.794,0,0,0-1.792,1.792v42.979l.211-.064V275.879a1.583,1.583,0,0,1,1.581-1.581H519.86Zm19.34,0H497.61a1.794,1.794,0,0,0-1.792,1.792v48.27a1.794,1.794,0,0,0,1.792,1.792h41.525a1.794,1.794,0,0,0,1.792-1.792v-48.27A1.794,1.794,0,0,0,539.135,274.087Zm1.581,50.062a1.583,1.583,0,0,1-1.581,1.581H497.61a1.583,1.583,0,0,1-1.581-1.581v-48.27a1.583,1.583,0,0,1,1.581-1.581h41.525a1.583,1.583,0,0,1,1.581,1.581Z"
          transform="translate(-472.669 -259.31)"
          fill="#3f3d56"
        />
        <path
          id="Path_266"
          data-name="Path 266"
          d="M629.159,262.621H609.766a.95.95,0,0,1-.949-.949v-4.637a.95.95,0,0,1,.949-.949h19.392a.95.95,0,0,1,.949.949v4.637A.95.95,0,0,1,629.159,262.621Z"
          transform="translate(-573.76 -243.207)"
          fill="#07c8e8"
        />
        <circle
          id="Ellipse_232"
          data-name="Ellipse 232"
          cx="2.108"
          cy="2.108"
          r="2.108"
          transform="translate(43.595 8.98)"
          fill="#07c8e8"
        />
        <circle
          id="Ellipse_233"
          data-name="Ellipse 233"
          cx="1.284"
          cy="1.284"
          r="1.284"
          transform="translate(44.419 9.804)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export { NoDataSVG }
