import { styled, Typography } from '@mui/material'
import { Box } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  paddingLeft: '22px',
  borderLeft: `1px solid ${colors.border}`,
})

export const StyledTitle = styled(Typography)({
  color: colors.altBlack,
  fontWeight: FONT_WEIGHT.MEDIUM,
})

export const ColorItem = styled(Box)({
  display: 'flex',
  marginTop: '6px',
  alignItems: 'center',
})

export const ColorBox = styled(Box)({
  width: '21px',
  height: '21px',
  borderRadius: '4px',
  border: `1px solid ${colors.background}`,
  cursor: 'pointer',
})

export const StyledColorText = styled(Typography)({
  color: colors.altBlack,
  fontWeight: FONT_WEIGHT.REGULAR,
  marginLeft: '10px',
})
