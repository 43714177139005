import {
  IconButton,
  inputBaseClasses,
  selectClasses,
  styled,
  TextField,
} from '@mui/material'
import { Container } from 'library'

import { boxShadow, colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled(Container)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  boxShadow,
  marginTop: 10,
})

export const StyledTextField = styled(TextField)({
  width: '100%',
  minHeight: 32,
  borderRadius: 4,
  border: 'none',
  div: {
    height: '100%',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: 32,
    width: '100%',
    fontSize: '14px',
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:-internal-autofill-selected': {
      backgroundColor: colors.background,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const StyledIcon = styled(IconButton)({
  backgroundColor: colors.orange,
  borderRadius: '4px',
  textAlign: 'left',
  alignSelf: 'center',
  position: 'initial',
  cursor: 'pointer',
  height: '20px',
  width: '20px',
  ':hover': {
    backgroundColor: colors.orange,
  },
})
