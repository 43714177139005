import React from 'react'
import NumberFormat from 'react-number-format'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone'
import EmailIcon from '@mui/icons-material/Email'
import MapIcon from '@mui/icons-material/Map'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import { Box, SummaryItem } from 'library'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import { StyledItem } from 'components/CarSettings/Common/Creation/Summary/style'

import { textFiles } from 'constants/textFiles'
import { UserTypeEnum } from 'constants/UserManagement/creation'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/select'
import { UserCreationProps } from 'utils/User/creation'

const Summary = ({
  handleBack,
  userData,
  handleSubmit,
  submitLoading,
  areaOptions,
  roleOptions,
  organizations,
}: UserCreationProps) => {
  const { text } = useTranslation(textFiles.USER_MANAGEMENT_CREATION)
  const { summary } = text
  const { userInformation, userRoles } = userData

  const renderValue = (options: Option[], value: string) => {
    return options.find((option) => option.value === value)
  }

  const chosenDealer = organizations.find(
    (organization) => organization.value === userInformation.organization
  )

  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      title={summary.title}
      description={summary.description}
      submitLoading={submitLoading}
      gridContainerStyles={{
        gridTemplateColumns: '100% !important',
        width: '100%',
      }}
    >
      <Box
        sx={{
          marginBottom: '5px',
          marginTop: '10px',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          columnGap: '1.5rem',
          width: '100%',
          '> div': {
            width: 'fit-content',
            minWidth: '200px',
          },
        }}
      >
        <SummaryItem
          title={summary.fullName}
          text={`${userInformation.name} ${userInformation.lastName}`}
          icon={<PersonIcon />}
        />
        <SummaryItem
          title={summary.email}
          text={userInformation.email}
          icon={<EmailIcon />}
        />
        {userInformation.phoneNumber.length > 0 ? (
          <SummaryItem
            title={summary.userPhone}
            text={
              <NumberFormat
                value={userInformation.phoneNumber}
                format="+57 (###) ### ####"
                isNumericString
                displayType="text"
              />
            }
            icon={<PhoneIcon />}
          />
        ) : null}
        {userInformation.address.length > 0 ? (
          <SummaryItem
            title={summary.address}
            text={userInformation.address}
            icon={<MapIcon />}
          />
        ) : null}
        <SummaryItem
          title={text.information.organizationTypeLabel}
          text={userInformation.type}
          icon={<CorporateFareIcon />}
        />
        {userInformation.type === UserTypeEnum.DEALER && chosenDealer ? (
          <SummaryItem
            title={text.information.organizationLabel}
            text={chosenDealer.name}
            icon={<CorporateFareTwoToneIcon />}
          />
        ) : null}
      </Box>
      <StyledItem title={summary.roles} maxHeight="238px !important">
        <ul style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {userRoles.roles.map((role) => (
            <li key={role.role}>
              <span>{renderValue(areaOptions, role.area)!.name}</span>
              {` - ${renderValue(roleOptions, role.role)!.name}`}
            </li>
          ))}
        </ul>
      </StyledItem>
    </Skeleton>
  )
}

export default Summary
