import React from 'react'
import { Fade, Typography } from '@mui/material'
import Popper from '@mui/material/Popper'
import { Box } from 'library'

import { colorOptions } from 'constants/Marketing/offerCreation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CarIdPopperStatus } from 'models/offer'

import { colors } from 'styles/theme'

import { CardContainer, StyledCard, StyledTriangleIcon } from './style'

type Props = {
  children: JSX.Element | string
  open: boolean
  anchorEl: HTMLElement | null
  id: string
  popperStatus: CarIdPopperStatus
}

const CarIdPopper = ({ children, open, anchorEl, id, popperStatus }: Props) => {
  const { text } = useTranslation(textFiles.OFFER_CREATION)

  const {
    information: { carIdStatus },
  } = text

  return (
    <>
      <Box>{children}</Box>
      <Popper
        id={id}
        open={open}
        transition
        anchorEl={anchorEl}
        placement="left-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [70, -30],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <CardContainer>
              <StyledTriangleIcon
                sx={{
                  borderColor: `transparent transparent ${colorOptions[popperStatus]} transparent`,
                }}
              />
              <StyledCard
                sx={{
                  backgroundColor: colorOptions[popperStatus],
                }}
              >
                <Typography variant="body2" sx={{ color: colors.commonWhite }}>
                  {carIdStatus[popperStatus]}
                </Typography>
              </StyledCard>
            </CardContainer>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default CarIdPopper
