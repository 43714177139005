import {
  formControlClasses,
  inputBaseClasses,
  selectClasses,
  styled,
  TextField as MUITextField,
} from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import { Box, Container, TextField } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  borderRadius: '0px',
  height: ' 100%',
})

export const ContentBox = styled(Box)({
  display: 'flex',
  width: '100%',
  marginBottom: 20,
  flexWrap: 'wrap',
  gap: '2rem 1.5rem',
  minHeight: '305px',
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  margin: '8px 0',
  width: '290px',
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledTextField = styled(TextField)({
  width: '100%',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '12px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '12px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
  },
})

export const StyledCounterField = styled(MUITextField)({
  width: '100%',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '12px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '12px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const StyledToggleButton = styled(ToggleButton)({
  width: '76px',
  height: '38px',
  color: colors.placeholderGray,
  '&.Mui-selected': {
    backgroundColor: colors.orange,
    color: colors.commonWhite,
    ':hover': {
      backgroundColor: colors.orange,
    },
  },
})

export const CounterDiv = styled('div')({
  width: '30px',
  height: '38px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  position: 'relative',
  right: 31,
})

export const CounterButton = styled('div')({
  width: '100%',
  height: '19px',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: colors.placeholderGray,
  borderLeft: `1px solid ${colors.lightGray}`,
  cursor: 'pointer',
})
