import React, { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { ClickAwayListener, IconButton } from '@mui/material'
import { Box } from 'library'

import { StyledSquareIcon, StyledStripe } from 'components/Motives/Stripe/style'

import { OfferTypes } from 'models/offer'

import { colors } from 'styles/theme'

import DiscountBox from '../DiscountBox'

type OfferStripeProps = {
  name: string
  id: string
  discountValue?: number
  individualDiscount?: boolean
  enableRemove?: boolean
  valueHandler?: (value: number) => void
  type?: OfferTypes
  index: number
  handleEntityRemove?: (index: number, entityType: OfferTypes) => void
  disableEditing?: boolean
}
export const OfferStripe = ({
  name,
  id,
  discountValue,
  individualDiscount,
  enableRemove = false,
  valueHandler,
  type,
  index,
  handleEntityRemove,
  disableEditing,
}: OfferStripeProps) => {
  const [editMode, setEditMode] = useState(false)
  const [discountField, setDiscountField] = useState(discountValue || 0)

  const handleEditMode = (mode: boolean) => {
    setEditMode(mode)
  }

  const handleDiscountValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^\d+$/

    if (reg.test(e.target.value)) {
      setDiscountField(Number(e.target.value))
      if (valueHandler) {
        valueHandler(Number(e.target.value))
      }
    }
  }

  return (
    <ClickAwayListener disableReactTree onClickAway={() => setEditMode(false)}>
      <StyledStripe
        sx={{
          justifyContent: 'start',
        }}
      >
        <section>
          <StyledSquareIcon />
          <Box display="flex" flexDirection="row" minWidth="fit-content">
            <Box>{name}</Box>
            <Box marginX="5px">-</Box>
            <Box color={colors.orange}>{id}</Box>
            {individualDiscount && <Box marginX="5px">-</Box>}
          </Box>
          {individualDiscount ? (
            <DiscountBox
              editMode={editMode}
              id={id}
              name={name}
              discountValue={discountField}
              handleDiscountValue={handleDiscountValue}
              handleEditMode={handleEditMode}
              disableEditing={disableEditing}
              discountField={discountField}
            />
          ) : null}
        </section>
        <section>
          {individualDiscount && (
            <Box
              marginRight={enableRemove ? '25px' : '0px'}
              onClick={!disableEditing ? () => setEditMode(true) : undefined}
            >
              US$
            </Box>
          )}

          {enableRemove && (
            <IconButton
              data-cy={`delete-${id}-button`}
              onClick={() => {
                if (handleEntityRemove && type) handleEntityRemove(index, type)
              }}
            >
              <ClearIcon sx={{ color: colors.placeholderGray, fontSize: 14 }} />
            </IconButton>
          )}
        </section>
      </StyledStripe>
    </ClickAwayListener>
  )
}
