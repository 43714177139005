import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Container } from 'library/components/Common'
import { TimeSensitivePopper } from 'library/components/General'
import { FONT_WEIGHT } from 'library/styles/theme'

import { Inspector, InspectorProps } from './Inspector'

export type InspectorStatusTextModel = {
  title: string
  description: string
  tableInspector: string
  tableGoal: string
  tableProgress: string
}

export type InspectorStatusProps = {
  inspectorStatuses: InspectorProps[]
  translation?: InspectorStatusTextModel
  isEmpty?: boolean
}

export const InspectorStatus = ({
  inspectorStatuses,
  translation,
  isEmpty = false,
}: InspectorStatusProps) => {
  return (
    <Container
      height={437}
      width="44%"
      contentStyle={{
        width: 'auto',
        height: 'auto',
      }}
      title={translation?.title || 'Inspector leadership board'}
      description={`${
        translation?.description ||
        'Check your inspectors current standing based on their goals'
      }`}
      extraText={<TimeSensitivePopper />}
      isEmpty={isEmpty}
    >
      <Grid alignItems="flex-start" container marginBottom="1rem" width="100%">
        <Grid item xs={6}>
          <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
            {translation?.tableInspector || 'Inspector'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
            #
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
            {translation?.tableGoal || 'Goal'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
            {translation?.tableProgress || 'Progress'}
          </Typography>
        </Grid>
      </Grid>
      <section
        style={{
          width: 'unset',
          overflowY: 'auto',
          height: '335px',
          paddingLeft: '0.25rem',
        }}
      >
        {inspectorStatuses.map((inspectorStatusItem, index) => (
          <Inspector
            // eslint-disable-next-line react/no-array-index-key
            key={`${inspectorStatusItem.name} ${index}`}
            {...inspectorStatusItem}
          />
        ))}
      </section>
    </Container>
  )
}
