import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import { FormControlLabel, styled, typographyClasses } from '@mui/material'
import { colors } from 'library/styles/theme'

export const StyledLabel = styled(FormControlLabel)({
  alignSelf: 'flex-start',
  [typographyClasses.root]: {
    lineHeight: '0.5rem !important',
  },
})

export const StyledCheckboxIcon = styled(CheckBoxOutlineBlankOutlinedIcon)({
  color: colors.gray,
})
