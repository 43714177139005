import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'
import { endOfDay, startOfDay } from 'date-fns'
import {
  Box,
  buildDetailRoute,
  Button,
  cleanFilters,
  FilterByCriteria,
  generateFilterInput,
  getIsoDate,
  Title,
  useNotification,
} from 'library'

import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import DateFilter from 'components/Inspection/Listing/DateFilter'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'

import { cypressAddButton } from 'constants/cypress'
import { FilterEntryVariableType } from 'constants/Inspection/filterByCriteria'
import { routes } from 'constants/routes'
import { StateColumnField } from 'constants/state'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { CalendarRangeType } from 'models/date'
import { FiltersByCriteriaTranslationType } from 'models/filtersByCriteria'
import { UserRoles } from 'models/role'
import {
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  ListingFilterType,
} from 'models/services/base'
import { ListState } from 'models/services/operations/state'
import { PublicationStatusMap } from 'models/status'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSortModel,
} from 'utils/serializers'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/State/listing'
import { getCarSettingsStatusList } from 'utils/status'
import { verifyParam } from 'utils/verifyUrlData'

import { DELETE_STATE } from 'graphQL/Operations/State/Listing/mutations'
import { LIST_STATES } from 'graphQL/Operations/State/Listing/queries'

// import { StyledTextField } from 'styles/inspection/listing'

const StateListingPage = () => {
  const { show } = useNotification()
  const { validateAllowedRoles } = useUser()
  const location = useLocation()
  const { search } = location
  const defaultSortedFields = [...defaultFields].sort()

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [selectedLanguage] = useLocale()
  const history = useHistory()

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const { text } = useTranslation(textFiles.STATE_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const {
    filtersByCriteria,
  }: { filtersByCriteria: FiltersByCriteriaTranslationType } = text
  const { fieldOrEntryOptions: filterOptionText } = filtersByCriteria
  const [data, setData] = useState<GridRowsProp>([])
  const [stateCount, setStateCount] = useState<number>(0)

  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(
        dateRange.fromDate ? startOfDay(dateRange.fromDate) : null
      ),
      toDate: getIsoDate(dateRange.toDate ? endOfDay(dateRange.toDate) : null),
    }
  }, [dateRange])

  const fieldOrEntryOptions: Record<string, FilterEntryVariableType> = {
    id: {
      name: filterOptionText.id,
      type: 'string',
    },
    name: {
      name: filterOptionText.name,
      type: 'string',
    },
    status: {
      name: filterOptionText.status,
      type: 'string',
    },
  }

  const statusList = getCarSettingsStatusList(generalText)

  const { loading: stateLoading, refetch: refetchState } = useQuery<
    ListState,
    FilterInputVariable
  >(LIST_STATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          createdAt_gte: fromDate,
          createdAt_lte: toDate,
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    onCompleted(res) {
      setData(res.listStates.data)
      setStateCount(res.listStates.count)
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: generalText.notificationText.deleteFail,
    })
  }

  const handleRefetchStates = async () => {
    try {
      const response = await refetchState()
      if (response) {
        const states = response.data.listStates
        if (states.data.length === 0 && states.count > 0) {
          setPage(1)
        }
      }
      show({
        updatedSeverity: 'success',
        message: generalText.notificationText.deleteSuccess,
      })
    } catch {
      handleShowError()
    }
  }

  const [removeState] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(DELETE_STATE, {
    onCompleted() {
      handleRefetchStates()
    },
    onError() {
      handleShowError()
    },
  })

  const deleteState = useCallback((id: GridRowId) => {
    setDeleteItem(id)
  }, [])

  const editState = useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.STATE_DETAIL))
    },
    [history]
  )

  const createState = () => {
    history.push(routes.STATE_CREATION)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  const handleRemoveState = (id: string) => {
    removeState({
      variables: {
        input: id,
      },
    })
  }

  const {
    id: idColumn,
    isoDate: dateColumn,
    status: statusColumn,
    standard: standardColumn,
    actions: actionsColumn,
  } = commonListColumns(
    PublicationStatusMap,
    selectedLanguage,
    generalText,
    editState,
    validateAllowedRoles([UserRoles.ADMIN, UserRoles.OPERATION_SUPERVISOR])
      ? deleteState
      : undefined,
    statusList
  )
  const [columns, setColumns] = useState<GridColDef[]>([
    { ...idColumn, hide: !selectedFields.includes(idColumn.field) },
    {
      ...standardColumn,
      field: StateColumnField.NAME,
      hide: !selectedFields.includes(StateColumnField.NAME),
      headerName: text.table.name,
      renderCell: (params: GridRenderCellParams) => {
        const { name } = params.row
        return <Tooltip>{name}</Tooltip>
      },
    },
    {
      ...dateColumn,
      field: StateColumnField.CREATED_AT,
      hide: !selectedFields.includes(StateColumnField.CREATED_AT),
      headerName: text.table.createdAt,
    },
    {
      ...statusColumn,
      sortable: true,
      hide: !selectedFields.includes(statusColumn.field),
    },
    { ...actionsColumn, hide: !selectedFields.includes(actionsColumn.field) },
  ])

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const getPageCount = () => {
    return Math.ceil(stateCount / pageSize)
  }

  // const handleRemoveItem = (id: string) => {
  //   // eslint-disable-next-line no-console
  //   console.log('id', id)
  // }

  // const handleRemoveMultipleItems = async (ids: string[]) => {
  //   // eslint-disable-next-line no-console
  //   console.log('IDS', ids)
  // }

  return (
    <Box width="100%">
      <Title
        icon={<PlaylistAddCheckIcon />}
        subtitle={`${stateCount} ${text.description}`}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginTop="2rem"
        width="100%"
      >
        <Button
          startIcon={<AddIcon />}
          size="medium"
          onClick={createState}
          data-cy={cypressAddButton}
        >
          {text.addButton}
        </Button>

        <Box display="flex">
          {/* <Box marginRight="1rem" width="370px">
            <StyledTextField
              icon={<SearchIcon fontSize="small" />}
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchValue}
              onChange={handleSearchChange}
            />
          </Box> */}
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterInput={filterInput}
              statusList={statusList}
              filterCardText={filtersByCriteria}
              filtersByCriteriaText={{
                ...filtersByCriteria,
                fieldOrEntryOptions,
              }}
              labels={generalText.filterByCriteria}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
          <Box>
            <DateFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              maxDate={null}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        dateRange={dateRange}
        // searchValue={searchValue}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        route={routes.STATE_DETAIL}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={stateLoading}
        handleRemoveItem={handleRemoveState}
        hideDownloadModal
        checkboxSelection={false}
      />
    </Box>
  )
}
export default StateListingPage
