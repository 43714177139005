export enum PreInventoryColumnField {
  ID = 'id',
  SLUG = 'slug',
  PICTURE = 'picture',
  VEHICLE = 'brand',
  TRIM = 'trimLevel',
  PRICE = 'listingPrice',
  MILEAGE = 'mileage',
  PROVIDER = 'provider',
  CREATED_AT = 'createdAt',
  CERTIFIED = 'certified',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export const preInventoryStaticFields = [
  'brand',
  'carModel',
  'countryVersion',
  'bodyStyle',
  'categories',
  'transmission',
  'fuelType',
  'driveTrain',
  'features',
  'exteriorColor',
  'interiorColor',
  'dealer',
  'city',
  'curboSpot',
  'provider',
  'certified',
]

export const packagingHash = '#packaging'
