import React from 'react'
import { Typography } from '@mui/material'
import { Box } from 'library'

import { FONT_WEIGHT } from 'styles/theme'

import Tooltip from '.'

type BiggerFontTooltipProps = {
  text: string
  color?: string
}

const BiggerFontTooltip = ({ text, color }: BiggerFontTooltipProps) => (
  <Tooltip>
    <Box display="flex" alignItems="center" height="100%">
      <Typography
        width="auto"
        fontWeight={FONT_WEIGHT.REGULAR}
        fontSize="14px"
        color={color}
      >
        {text}
      </Typography>
    </Box>
  </Tooltip>
)

export default BiggerFontTooltip
