import { styled } from '@mui/material'
import { Autocomplete } from 'library/components/Common/Autocomplete'
import { TextField } from 'library/components/Common/TextField'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

export const StyledSelectorContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  border: `6px solid ${colors.background}`,
  borderRadius: '4px',
  backgroundColor: colors.background,
  width: 'fit-content',
  marginBottom: '20px',

  '& > div:nth-of-type(2) > div': {
    width: '140px',
    backgroundColor: colors.commonWhite,
    border: 'none',
    color: colors.commonBlack,
    margin: '0px 4px',
  },
  '& > div:nth-of-type(1) > div': {
    width: '200px',
    height: '40px',
    color: colors.commonBlack,
    backgroundColor: colors.commonWhite,
    borderColor: 'transparent',
    margin: '0px 3px',
  },
  '& > div:nth-of-type(3)': {
    paddingRight: '5px',
    '& > div': {
      width: '100%',
      height: '40px',
      color: colors.commonBlack,
      backgroundColor: colors.commonWhite,
      borderColor: 'transparent',
      margin: '0px 3px',
    },
  },
})

export const StyledTextField = styled(TextField)({
  width: '200px',
  height: '36px',
  border: '1px solid transparent',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  '& div input': {
    color: colors.commonBlack,
    fontWeight: FONT_WEIGHT.MEDIUM,
    padding: '8px 14px',
    backgroundColor: colors.commonWhite,
    fontSize: '12px',
  },
  padding: '0px',
})

export const StyledFiltersBackground = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px',
  borderRadius: '4px',
  marginBottom: '10px',
})

export const StyledForm = styled('form')({
  width: '600px',
  margin: '20px 0px',
})

export const StyledButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& > button:nth-of-type(1)': {
    marginRight: '12px',
  },
  '& > button:nth-of-type(2)': {
    padding: '22px',
    backgroundColor: colors.background,
  },
})

export const StyledFiltersContainer = styled('div')({
  marginTop: '20px',
})

export const StyledSelectAutoComplete = styled(Autocomplete)({
  width: '100%',
})
