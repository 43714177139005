import React, { useCallback, useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ClickAwayListener, SelectChangeEvent, Typography } from '@mui/material'
import Popper from '@mui/material/Popper'
import {
  formatDistanceToNowStrict,
  getDay,
  getHours,
  getMinutes,
} from 'date-fns'
import { useFormik } from 'formik'
import {
  Box,
  ButtonNavigator,
  DatePicker,
  ErrorMessage,
  formatDateLocalizedTime,
  Select,
} from 'library'
import * as yup from 'yup'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'

import { DAY_ENUM, weekDay } from 'constants/date'
import { NOTIFICATION_CREATION_OPTION, statusOps } from 'constants/notification'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Hour } from 'models/hours'
import { NotificationInformationModel } from 'models/notification'
import { convertToPm } from 'utils/date'
import { NotificationCreationProps } from 'utils/Marketing/Notification/creation'

import { colors } from 'styles/theme'

import NotificationCard from '../../Detail/NotificationCard'

import HourCreation from './HourCreation'
import {
  MessageCard,
  PreviewContainer,
  SetButton,
  StyledContainer,
  StyledForm,
  StyledSchedule,
  StyledTextField,
  StyledTextFieldContainer,
} from './style'

const Information = ({
  notificationData,
  updateNotificationData,
  handleContinue,
}: NotificationCreationProps) => {
  const { notificationInformation } = notificationData

  const [singleHour, setSingleHour] = useState<Hour>(
    notificationInformation.time as Hour
  )

  const [appointmentDate, setAppointmentDate] = useState<Date | null>(
    notificationInformation.date as Date
  )
  const [dateKey, setDateKey] = useState<DAY_ENUM>(
    notificationInformation.currentDayKey
  )
  const [pictureState, setPictureState] = useState<string>(
    notificationInformation.picture
  )
  const [fileState, setFileState] = useState<File | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: translation } = useTranslation(textFiles.NOTIFICATION_CREATION)
  const { information, status: statusLabel } = translation

  const options = statusOps(statusLabel)

  const validationSchema = yup.object({
    title: yup.string().required(validationText.fieldRequired),
    description: yup.string().required(validationText.fieldRequired),
    name: yup.string(),
    date: yup.string().required(validationText.fieldRequired),
    time: yup.string().required(validationText.fieldRequired),
    meridiam: yup.string(),
    status: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<NotificationInformationModel>>({
    initialValues: {
      title: notificationInformation.title,
      description: notificationInformation.description,
      name: notificationInformation.name,
      date: formatDateLocalizedTime(appointmentDate as Date, 'PPP'),
      time: (singleHour as Hour).slug,
      meridiam: notificationInformation.meridiam,
      status: notificationInformation.status,
    },
    validationSchema,
    onSubmit: async (values) => {
      updateNotificationData({
        type: NOTIFICATION_CREATION_OPTION.UPDATE_NOTIFICATION_INFORMATION,
        payload: {
          title: values.title as string,
          name: values.name as string,
          description: values.description as string,
          status: values.status as string,
          picture: pictureState,
          pictureFile: fileState,
          time: { ...singleHour },
          meridiam: singleHour.am ? 'am' : 'pm',
          date: appointmentDate,
          currentDayKey: dateKey,
        },
      })

      handleContinue()
    },
  })

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleSchedulingDateChange = (date: Date | null) => {
    if (date) {
      const dateNumber = getDay(date)
      const dayKey = weekDay[dateNumber]
      setDateKey(dayKey)
      const storedDate = new Date(date.setHours(0, 0, 0, 0))
      setAppointmentDate(storedDate)
      formik.setFieldValue('date', 'date')
      formik.setFieldTouched('date', false)
    }
  }

  const handleChangeSingleHour = useCallback((value: Hour) => {
    setSingleHour(value)
    formik.setFieldValue('time', value.slug)
    formik.setFieldTouched('time', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    formik.setFieldValue('status', value as string)
    formik.setFieldTouched('status', false)
    if (value === 'COMPLETED') {
      handleSchedulingDateChange(new Date())
      const hours = getHours(new Date())
      const minutes = getMinutes(new Date())
      const newHour: Hour = {
        id: singleHour.id,
        am: parseInt(`${hours}${minutes}`, 10) < 1200,
        name: `${hours - 12}:${minutes}`,
        slug: `${hours - 12}${minutes}`,
        visible: singleHour.visible,
        continentalTime: 0,
      }
      handleChangeSingleHour(newHour)
    }
  }

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <section>
            <StyledTextFieldContainer title={information.title}>
              <StyledTextField
                variant="outlined"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder={information.title}
                error={formik.touched.title && Boolean(formik.errors.title)}
                inputProps={{
                  maxLength: 60,
                }}
              />
              {formik.touched.title && formik.errors.title ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.title}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={information.description}>
              <StyledTextField
                variant="outlined"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                sx={{ height: 90 }}
                rows={3}
                multiline
                placeholder={information.description}
                inputProps={{
                  maxLength: 178,
                }}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
              />
              {formik.touched.description && formik.errors.description ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.description}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={information.name}>
              <StyledTextField
                variant="outlined"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder={information.name}
              />
            </StyledTextFieldContainer>
            <AvatarContainer
              title={information.image}
              picture={pictureState}
              handlePictureChange={setPictureState}
              handleFileChange={setFileState}
            />
            <StyledSchedule>
              <StyledTextFieldContainer title={information.date}>
                <SetButton
                  buttonType="secondary"
                  endIcon={<KeyboardArrowDownIcon />}
                  startIcon={<DateRangeIcon />}
                  onClick={handlePopperOpen}
                  testId="set-date-button"
                >
                  <Typography variant="body2">
                    {formatDateLocalizedTime(appointmentDate as Date, 'PP') ||
                      information.setButton}
                  </Typography>
                </SetButton>
                <>
                  <Popper
                    id="picker-popper"
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                  >
                    <ClickAwayListener
                      onClickAway={() => {
                        setAnchorEl(null)
                        setOpen(false)
                      }}
                    >
                      <Box sx={{ backgroundColor: colors.commonWhite }}>
                        <DatePicker
                          value={appointmentDate}
                          onChange={handleSchedulingDateChange}
                          startSameDay
                        />
                      </Box>
                    </ClickAwayListener>
                  </Popper>
                </>
                {formik.touched.date && formik.errors.date ? (
                  <ErrorMessage
                    sx={{
                      alignSelf: 'flex-start',
                      position: 'static',
                      marginTop: '0.5rem',
                    }}
                    text={formik.errors.date}
                  />
                ) : null}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={information.time}>
                <Box>
                  <HourCreation
                    singleHour={singleHour}
                    handleChangeSingleHour={handleChangeSingleHour}
                  />
                </Box>
                {formik.touched.time && formik.errors.time ? (
                  <ErrorMessage
                    sx={{
                      alignSelf: 'flex-start',
                      position: 'static',
                      marginTop: '0.5rem',
                    }}
                    text={formik.errors.time}
                  />
                ) : null}
              </StyledTextFieldContainer>
            </StyledSchedule>
          </section>
          <section>
            <StyledTextFieldContainer title={information.status}>
              <Select
                options={options}
                variant="outlined"
                name="status"
                value={formik.values.status}
                label={information.type}
                onChange={(e) => handleSelect(e)}
                sx={{ fontSize: 14, height: 40 }}
              />
              {formik.touched.status && formik.errors.status ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.status}
                />
              ) : null}
            </StyledTextFieldContainer>
            <PreviewContainer title={information.preview}>
              <NotificationCard
                title={formik.values.title as string}
                description={formik.values.description as string}
                time={formatDistanceToNowStrict(
                  convertToPm(
                    new Date(
                      (appointmentDate as Date).setHours(
                        Number(singleHour.name.split(':')[0]),
                        Number(singleHour.name.split(':')[1])
                      )
                    ),
                    singleHour.am
                  ),
                  {
                    addSuffix: true,
                  }
                )}
              />
              <NotificationCard
                title={formik.values.title as string}
                description={formik.values.description as string}
                isAndroid
                picture={pictureState}
                time={formatDistanceToNowStrict(
                  convertToPm(
                    new Date(
                      (appointmentDate as Date).setHours(
                        Number(singleHour.name.split(':')[0]),
                        Number(singleHour.name.split(':')[1])
                      )
                    ),
                    singleHour.am
                  )
                )}
              />
              <MessageCard>
                <p style={{ margin: '11px' }}>{information.paragraph}</p>
              </MessageCard>
            </PreviewContainer>
          </section>
        </div>
        <ButtonNavigator isFirstStep confirmButtonType="submit" />
      </StyledForm>
    </StyledContainer>
  )
}

export default Information
