import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { Typography } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import {
  BaseIdEntity,
  colors,
  CurboSpot,
  ErrorMessage,
  GenericInputVariable,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'
import * as yup from 'yup'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import EditButtons from 'components/CarSettings/Common/Detail/NameContainer/Items/EditButtons'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { GenericData } from 'models/services/base'
import { UpdateCurboSpotInput } from 'models/services/operations/curboSpot'
import { DealerDetailDataType } from 'models/services/operations/dealer'

import { GET_PUBLISHED_CURBO_SPOTS } from 'graphQL/Common/Dealer/queries'
import { UPDATE_CURBO_SPOT } from 'graphQL/Operations/CurboSpot/Detail/mutations'
import { UPGRADE_DEALER_TO_SPOT } from 'graphQL/Operations/Dealer/Detail/mutations'
import { GET_DEALER_BY_ID } from 'graphQL/Operations/Dealer/Detail/queries'

import { StyledTextField, StyledTextFieldContainer } from 'styles/creation'

import { DetailsHeader, StyledButtonContainer, StyledContainer } from '../style'

import {
  DealerInformationContainer,
  StyledForm,
  StyledParagraph,
} from './style'

type UpdateDealerData = {
  isWorkshop?: boolean
  isSpot?: boolean
  spotName?: string
  linkedSpot?: string
}

type UpgradeMutationInput = {
  id: string
}

type DealerDetailsProps = {
  dealer: DealerDetailDataType
}

const DealerDetails = ({ dealer }: DealerDetailsProps) => {
  const { dealerId } = useParams<{ dealerId: string }>()
  const history = useHistory()
  const { show } = useNotification()
  const [editMode, setEditMode] = useState<boolean>(false)
  const setCurboSpots = useState<CurboSpot[]>([])[1]
  const [dealerData, setDealerData] = useState<UpdateDealerData>({
    isWorkshop: false,
    isSpot: false,
    spotName: '',
    linkedSpot: dealer.curboSpot?.id || '',
  })
  const { text } = useTranslation(textFiles.DEALER_DETAIL)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const { dealerInformation: translation } = text

  useQuery<GenericData<CurboSpot[]>>(GET_PUBLISHED_CURBO_SPOTS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCurboSpots(response.data)
      setDealerData((prevData) => {
        return {
          ...prevData,
        }
      })
    },
  })

  const [updateCurboSpot, { loading: updateSpotLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateCurboSpotInput>
  >(UPDATE_CURBO_SPOT, {
    onCompleted() {
      show({ updatedSeverity: 'success', message: translation.updateSuccess })
      setEditMode(false)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: translation.updateFail,
        })
    },
    refetchQueries: [GET_DEALER_BY_ID],
  })

  const [upgradeDealer, { loading: upgradingLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    UpgradeMutationInput
  >(UPGRADE_DEALER_TO_SPOT, {
    onCompleted(result) {
      show({
        updatedSeverity: 'success',
        message: `${translation.successFulNotificationOne} CurboSpot ${translation.successFulNotificationTwo}`,
      })
      updateCurboSpot({
        variables: {
          input: {
            where: {
              id: result.data.id,
            },
            data: {
              supportsMaintenance: dealerData.isWorkshop,
              name: dealerData.spotName,
            },
          },
        },
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const validationSchema = yup.object({
    isSpot: yup.boolean().required(validationText.fieldRequired),
    spotName: yup.string().when('isSpot', {
      is: true,
      then: yup.string().required(validationText.fieldRequired),
    }),
    isWorkshop: yup.boolean(),
    linkedSpot: yup.string(),
  })
  const formik = useFormik<UpdateDealerData>({
    initialValues: {
      isSpot: dealer.type === 'CurboSpot',
      spotName: dealer.type !== 'CurboSpot' ? '' : dealer.curboSpot?.name,
      isWorkshop: dealer.curboSpot?.supportsMaintenance,
      linkedSpot: dealer.curboSpot?.id || '',
    },
    validationSchema,
    onSubmit: (values) => {
      setDealerData(values)
      if (values.isSpot === true && dealer.type !== 'CurboSpot') {
        upgradeDealer({
          variables: {
            id: dealerId,
          },
        })
      } else if (values.isWorkshop && !dealer.curboSpot?.supportsMaintenance) {
        if (dealer.curboSpot?.id) {
          updateCurboSpot({
            variables: {
              input: {
                where: {
                  id: dealer.curboSpot?.id,
                },
                data: {
                  supportsMaintenance: true,
                },
              },
            },
          })
        }
      }
    },
  })

  const handleCancelEdit = () => {
    setEditMode(false)
  }

  const handleEdit = () => {
    if (!editMode) {
      setEditMode(true)
    } else {
      formik.handleSubmit()
    }
  }

  const handleApplySpotChange = (newValue: boolean) => {
    formik.setFieldValue('isSpot', newValue)
    formik.setFieldTouched('isSpot', false)
  }

  const handleApplyWorkshopChange = (newValue: boolean) => {
    formik.setFieldValue('isWorkshop', newValue)
    formik.setFieldTouched('isWorkshop', false)
  }

  const navigateLinkedSpot = () => {
    const URL = routes.CURBO_SPOT_DETAIL.replace(
      ':curboSpotId',
      dealer.curboSpot!.id
    ).replace('/:section', '')
    history.push(URL)
  }

  return (
    <section>
      <DetailsHeader>
        <div>
          <Typography variant="body1">{translation.detailsTitle}</Typography>
          <Typography variant="body2">
            {translation.detailsDescription}
          </Typography>
        </div>

        <StyledButtonContainer display="flex">
          <EditButtons
            edit={editMode}
            handleEdit={handleEdit}
            disabled={upgradingLoading || updateSpotLoading}
            editDisabled={
              dealer.type === 'CurboSpot' &&
              (dealer.curboSpot?.supportsMaintenance || false)
            }
            handleCancelEdit={handleCancelEdit}
          />
        </StyledButtonContainer>
      </DetailsHeader>

      <FormikProvider value={formik}>
        {editMode && (
          <StyledContainer>
            <StyledForm onSubmit={formik.handleSubmit} id="dealer-form">
              {dealer.type !== 'CurboSpot' && (
                <ToggleContainer
                  title={translation.dealerSpot}
                  status={formik.values.isSpot!}
                  handleStatus={handleApplySpotChange}
                  testId="isCurboSpotToggle"
                  width="280px !important"
                />
              )}
              {formik.values.isSpot && dealer.type !== 'CurboSpot' && (
                <>
                  <StyledTextFieldContainer title={translation.spotName}>
                    <StyledTextField
                      variant="outlined"
                      name="spotName"
                      value={formik.values.spotName}
                      onChange={formik.handleChange}
                      placeholder={translation.spotNamePlaceholder}
                      error={
                        formik.touched.spotName &&
                        Boolean(formik.errors.spotName)
                      }
                      inputProps={{ maxLength: 40 }}
                    />
                    {formik.touched.spotName && formik.errors.spotName ? (
                      <ErrorMessage
                        sx={{
                          alignSelf: 'flex-start',
                          position: 'static',
                          marginTop: '0.5rem',
                        }}
                        text={formik.errors.spotName}
                      />
                    ) : null}
                  </StyledTextFieldContainer>
                </>
              )}
              {formik.values.isSpot &&
                !dealer.curboSpot?.supportsMaintenance && (
                  <ToggleContainer
                    title={translation.workshopCapabilities}
                    status={formik.values.isWorkshop!}
                    handleStatus={handleApplyWorkshopChange}
                    testId="supportsMaintenanceToggle"
                    width="280px !important"
                  />
                )}
            </StyledForm>
          </StyledContainer>
        )}
        <Typography variant="body1" marginTop="0.5em">
          {translation.linkedSpot}
        </Typography>
        <DealerInformationContainer>
          <DirectionsCarIcon />{' '}
          <StyledParagraph
            onClick={navigateLinkedSpot}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            {dealer.curboSpot?.name || ''}
          </StyledParagraph>
        </DealerInformationContainer>
        {!editMode && (
          <>
            <Typography variant="body1" marginTop="0.5em">
              {translation.workshopCapabilities}
            </Typography>
            <DealerInformationContainer>
              <DirectionsCarIcon
                sx={{
                  color: colors.orange,
                  marginRight: '0.5rem',
                }}
              />
              <StyledParagraph>
                {dealer.curboSpot?.supportsMaintenance
                  ? generalText.status.active
                  : generalText.status.inactive}
              </StyledParagraph>
            </DealerInformationContainer>
          </>
        )}
      </FormikProvider>
    </section>
  )
}

export default DealerDetails
