import React, { useState } from 'react'
import { DirectionsCar } from '@mui/icons-material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { ErrorMessage, maxAllowedSizePerFileInKb } from 'library'

import ImageBox from 'components/CarSettings/Common/Detail/ImageBox'
import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import PaginationList from 'components/General/PaginationList'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { BaseEntity } from 'models/services/base'
import { BrandDetailDataType } from 'models/services/CarSettings/detail'

import placeholderImage from 'images/blueDefaultAvatar.png'

import { GridBox, StyledButton, StyledContainer } from './style'

type Props = {
  brandData: BrandDetailDataType
  submitLoading: boolean
  handleSave: (
    name: string | undefined,
    fileState: File | null,
    hasChangedPicture: boolean
  ) => Promise<boolean>
  handlePageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void
  pageCount: number
  totalCount: number
  modelList: BaseEntity[]
  modelsLoading: boolean
  actualPage: number
  pageSize: number
}

const BrandInformation = ({
  actualPage,
  brandData,
  pageCount,
  totalCount,
  submitLoading,
  handlePageChange,
  handleSave,
  modelList,
  modelsLoading,
  pageSize,
}: Props) => {
  const { name, icon } = brandData

  const { text } = useTranslation(textFiles.BRAND_DETAIL)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [pictureSizeErrorStatus, setPictureSizeErrorStatus] =
    useState<boolean>(false)

  const { brandInformation: translation } = text

  const [picture, setPicture] = useState(icon || placeholderImage)
  const [fileState, setFileState] = useState<File | null>(null)

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [hasPictureChanges, setHasPictureChanges] = useState<boolean>(false)

  const initialNameInformation = [
    {
      id: 1,
      icon: <DirectionsCar />,
      label: translation.brandLabel,
      name,
    },
  ]

  const [informationRows, setInformationRows] = useState<NameInformation[]>(
    initialNameInformation
  )

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const { files } = e.target
    const file = files && files.length > 0 ? files[0] : null

    if (file && file.size <= maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(false)
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPicture(String(reader.result))
        }
      }
      if (file) {
        setFileState(file)
        reader.readAsDataURL(file)
      }

      setHasUnsavedChanges(true)
      setHasPictureChanges(true)
    } else if (file && file.size > maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(true)
    }
  }

  const resetState = () => {
    setHasUnsavedChanges(false)
    setHasPictureChanges(false)
    setPicture(icon || placeholderImage)
    setInformationRows(initialNameInformation)
  }

  const handleSaveChanges = async () => {
    const isUpdateSuccessful = await handleSave(
      informationRows[0].name as string,
      fileState,
      hasPictureChanges
    )

    if (isUpdateSuccessful) {
      setHasPictureChanges(false)
      setHasUnsavedChanges(false)
    }
  }

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setInformationRows(newInformation)
    setHasUnsavedChanges(true)
  }

  return (
    <StyledContainer>
      <GridBox>
        <section>
          <ImageBox
            height="100%"
            width="100%"
            variant="rounded"
            image={picture}
            containerSx={{
              height: '363px',
              width: '363px',
              marginTop: '1rem',
              padding: '1rem',
              position: 'relative',
            }}
          >
            <input
              disabled={false}
              type="file"
              name="input"
              id="brand-picture-input"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={imageHandler}
            />
            <StyledButton htmlFor="brand-picture-input">
              <CameraAltIcon />
            </StyledButton>
          </ImageBox>
          {pictureSizeErrorStatus && (
            <ErrorMessage
              text={generalText.fileSize.sizeError.replace('%d', 3)}
              sx={{ marginTop: '15px' }}
            />
          )}
        </section>

        <NameContainer
          title={translation.title}
          description={translation.description}
          width={399}
          containerSx={{
            padding: '1rem 0 0 !important',
          }}
          handleSetInformation={handleChangeInformation}
          information={informationRows}
          loading={submitLoading}
        />
        <PaginationList
          actualPage={actualPage}
          handlePageChange={handlePageChange}
          items={modelList}
          pageCount={pageCount}
          totalCount={totalCount}
          loading={modelsLoading}
          pageSize={pageSize}
          title={translation.modelTitle}
          description={translation.modelDescription}
          countText={translation.countModels}
        />
      </GridBox>
      <SaveChangesBlock
        handleSaveChanges={handleSaveChanges}
        resetState={resetState}
        submitLoading={submitLoading}
        shouldRender={hasUnsavedChanges}
      />
    </StyledContainer>
  )
}

export default BrandInformation
