import { inputBaseClasses, selectClasses, styled } from '@mui/material'
import { TextField } from 'library/components/Common/TextField'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

export const StyledTextField = styled(TextField)({
  width: '100%',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '12px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '12px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
  },
})
