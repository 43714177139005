import { gql } from '@apollo/client'

export const GET_EMPLOYEE_BY_ID = gql`
  query getEmployeeById($input: ID!) {
    data: getEmployeeById(id: $input) {
      profilePicture
      id
      name
      lastName
      email
      telephoneNumber
      address
      roles {
        area {
          value: slug
          name
        }
        value: slug
        name
      }
    }
  }
`
