import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import InputAdornment from '@mui/material/InputAdornment'
import { useNotification, validateGraphQLErrorCode } from 'library'

import Stripe from 'components/Motives/Stripe'
import {
  AddSection,
  ContentBox,
  StripeContainer,
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledTextField,
} from 'components/Motives/VehicleMotives/style'

import { cypressAddButton } from 'constants/cypress'
import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  BaseEntity,
  BaseIdEntity,
  BaseNameEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { UpdateGenericInput } from 'models/services/CarSettings/detail'

import {
  CREATE_ABSENCE_REASON,
  DELETE_ABSENCE_REASON,
  UPDATE_ABSENCE_REASON,
} from 'graphQL/Motives/mutations'
import { GET_ABSENCE_REASONS } from 'graphQL/Motives/queries'

import { colors } from 'styles/theme'

type Motive = {
  name: string
}

type AbsenceProps = {
  absenceMotives: BaseEntity[]
}

const AbsenceMotives = ({ absenceMotives }: AbsenceProps) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [absenceArr, setAbsenceArr] = useState<BaseEntity[]>(absenceMotives)

  const [singleMotive, setSingleMotive] = useState<Motive>({
    name: '',
  })

  const {
    text: { motives },
  } = useTranslation(textFiles.GENERAL)
  const { absence } = motives

  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const [createAbsence] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<BaseNameEntity>
  >(CREATE_ABSENCE_REASON, {
    onCompleted(response) {
      show({
        updatedSeverity: 'success',
        message: motives.createSuccess,
      })
      setAbsenceArr((prevState) => {
        const newAbsences = [...prevState]
        newAbsences.push({
          name: singleMotive.name,
          id: response.data.id as string,
        })
        return newAbsences
      })
      setSingleMotive({ name: '' })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: motives.createFail,
        })
    },
    refetchQueries: [GET_ABSENCE_REASONS],
  })

  const [updateAbsence] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateGenericInput<BaseNameEntity>>
  >(UPDATE_ABSENCE_REASON, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: motives.updateSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: motives.updateFail,
      })
    },
    refetchQueries: [GET_ABSENCE_REASONS],
  })

  const [deleteAbsence] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(DELETE_ABSENCE_REASON, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: motives.deleteSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: motives.deleteFail,
      })
    },
    refetchQueries: [GET_ABSENCE_REASONS],
  })

  const handleAdding = () => {
    if (singleMotive.name !== '') {
      createAbsence({
        variables: {
          input: {
            name: singleMotive.name,
          },
        },
      })
    }
  }

  const handleEditItem = (id: string, newName: string) => {
    const neededAbsence = absenceArr.find((abs) => abs.id === id)
    if (neededAbsence?.name !== newName)
      updateAbsence({
        variables: {
          input: {
            where: {
              id,
            },
            data: {
              name: newName,
            },
          },
        },
      })

    setAbsenceArr((prevState) => {
      const newAbsences = prevState.map((abs) => {
        if (abs.id === id) {
          return { ...abs, name: newName }
        }
        return abs
      })
      return newAbsences
    })
  }

  const handleConfirmRemove = (id: string) => {
    setAbsenceArr((prevState) => {
      const newAbsences = prevState.filter((abs) => abs.id !== id)
      return newAbsences
    })
    deleteAbsence({
      variables: {
        input: id,
      },
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSingleMotive({ ...singleMotive, name: e.target.value })
  }

  return (
    <StyledContainer>
      <StyledForm>
        <ContentBox>
          <StripeContainer>
            {absenceArr.map((absenceMotive) => (
              <Stripe
                key={absenceMotive.id}
                id={absenceMotive.id as string}
                name={absenceMotive.name}
                handleRemove={handleConfirmRemove}
                handleEditItem={handleEditItem}
                handleEditMode={setEditMode}
              />
            ))}
          </StripeContainer>
          <AddSection>
            <StyledTextField
              variant="outlined"
              name="name"
              placeholder={absence.typeInAbsencePlaceholder}
              value={singleMotive.name}
              sx={{
                width: '75% !important',
                input: {
                  paddingLeft: '0px',
                },
              }}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaylistAddIcon
                      sx={{ color: colors.placeholderGray, fontSize: 18 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <StyledButton
              startIcon={<AddIcon />}
              size="small"
              role="button"
              variant="text"
              disabled={editMode}
              onClick={handleAdding}
              sx={{
                border: `2px solid ${
                  editMode ? 'unset' : colors.orange
                } !important`,
                backgroundColor: editMode ? colors.lightGray : 'unset',
              }}
              testId={cypressAddButton}
            >
              {motives.addButton}
            </StyledButton>
          </AddSection>
        </ContentBox>
      </StyledForm>
    </StyledContainer>
  )
}

export default AbsenceMotives
