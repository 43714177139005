/*
 * Function to format names with names and last names
 */
export const formatFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  if (firstName && lastName) return `${firstName} ${lastName}`
  if (!firstName && lastName) return `${lastName}`
  if (firstName && !lastName) return `${firstName}`
  return 'N/A'
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

/*
 * Function to remove duplicates from array of objects
 */
export const uniqByReduce = <T>(array: T[]): T[] => {
  return array.reduce((acc: T[], cur: T) => {
    if (!acc.includes(cur)) {
      acc.push(cur)
    }
    return acc
  }, [])
}
