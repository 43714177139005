import {
  styled,
  TextField as MUITextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'
import { colors } from 'library/styles/theme'

export const StyledTextField = styled(MUITextField)<MuiTextFieldProps>(
  ({ error }) => ({
    border: `1px solid ${error ? colors.lightRed : colors.lightGray}`,
    borderRadius: '10px',
    opacity: 1,
    '& div': {
      color: colors.placeholderGray,
    },
    '& div fieldset': {
      border: 'none',
    },
  })
)
