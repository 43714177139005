import React, { useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { useFormik } from 'formik'
import { ButtonNavigator, ErrorMessage, Select, sleep } from 'library'
import * as yup from 'yup'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Model } from 'models/car'
import { PublicationStatus } from 'models/status'
import { ModelCreationProps } from 'utils/CarSettings/Model/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const ModelInfo = ({
  handleContinue,
  modelData,
  updateModelData,
  brands,
}: ModelCreationProps) => {
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text } = useTranslation(textFiles.MODEL_CREATION)
  const {
    information: {
      isPublishedDescription,
      name,
      nameDescription,
      brand,
      brandDescription,
      isPublished,
    },
  } = text

  const { modelInformation } = modelData

  const [modelInfo, setModelInfo] = useState<Model>(modelData.modelInformation)

  const [modelStatus, setModelStatus] = useState<boolean>(
    modelInformation.status === PublicationStatus.PUBLISHED
  )

  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    setModelInfo({
      ...modelInfo,
      brandName: event.target.value as string,
    })
  }

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<Model>>({
    initialValues: {
      name: modelInformation.name,
    },
    validationSchema,
    onSubmit: async (values) => {
      await sleep(1000)
      const customModelInfo = {
        modelInformation: {
          ...modelInfo,
          name: values.name as string,
          status: modelStatus
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      }
      updateModelData(customModelInfo)

      handleContinue()
    },
  })

  useEffect(() => {
    if (brands.length) {
      setModelInfo({
        ...modelInfo,
        brandName: brands[0].value as string,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands])

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={isPublished}
            description={isPublishedDescription}
            status={modelStatus}
            handleStatus={setModelStatus}
            testId="published"
          />
          <StyledTextFieldContainer title={name} description={nameDescription}>
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="Corolla"
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={brand}
            description={brandDescription}
          >
            <Select
              options={brands}
              variant="outlined"
              name="brandName"
              value={modelInfo.brandName}
              onChange={handleSelect}
              sx={{ fontSize: 12, height: 40 }}
            />
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default ModelInfo
