import { styled } from '@mui/material'
import { Box, colors } from 'library'

export const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  padding: '0 0.5rem',
})

export const Circle = styled(Box)({
  alignItems: 'center',
  backgroundColor: colors.commonWhite,
  borderRadius: '50%',
  display: 'flex',
  height: '15px',
  justifyContent: 'center',
  width: '15px',
})
