import { styled } from '@mui/material'
import { TextField } from 'library'

import { colors } from 'styles/theme'

export const StyledTextField = styled(TextField)({
  width: 150,
  height: 25,
  borderRadius: 4,
  border: 'none',
  div: {
    height: '100%',
  },

  input: {
    height: 25,
    width: 150,
    fontSize: '24px',
    padding: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:-internal-autofill-selected': {
      backgroundColor: colors.background,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})
