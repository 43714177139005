import { CurboSpot, StepperModel } from 'library'

import DealerInformation from 'components/Inspection/Creation/DealerInfo'
import InspectionScheduling from 'components/Inspection/Creation/Scheduling'
import InspectionSummary from 'components/Inspection/Creation/Summary'
import VehicleInformation from 'components/Inspection/Creation/VehicleInfo'

import { VehicleInformationModel } from 'models/inspection'
import {
  InspectionCreationAction,
  InspectionCreationModel,
} from 'models/inspectionCreation'
import { Brand, Color } from 'models/services/car'
import { Dealer } from 'models/services/curboSpot'
import { StepperTextType } from 'models/text/General'

export type InspectionCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSecondaryBack: (secondaryStepData: VehicleInformationModel) => void
  handleSecondaryContinue: (secondaryStepData: VehicleInformationModel) => void
  currentSecondaryStep: number
  dealers: Dealer[]
  brands: Brand[]
  colorList: Color[]
  curboSpots: CurboSpot[]
  inspectionData: InspectionCreationModel
  updateInspectionData: (action: InspectionCreationAction) => void
}

export const INITIAL_INSPECTION_CREATION_STEPS: Array<
  React.FC<InspectionCreationProps>
> = [
  DealerInformation,
  VehicleInformation,
  InspectionScheduling,
  InspectionSummary,
]

export const createInspectionStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep, isDynamicStep: true },
    {
      label: text.thirdStep,
    },
    { label: text.fourthStep },
  ]

  return stepperItems
}
