import React, { FunctionComponent, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import DateRangeIcon from '@mui/icons-material/DateRange'
import InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Checkbox,
  ClickAwayListener,
  InputAdornment,
  InputBaseComponentProps,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import Popper from '@mui/material/Popper'
import { useFormik } from 'formik'
import { Box, ButtonNavigator, ErrorMessage, Select } from 'library'
import * as yup from 'yup'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import NumberValueInput from 'components/Inventory/Common/NumberValueInput'

import { cypressAddButton } from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import { INFORMATION_OPTION, TRIM_CREATION_OPTION } from 'constants/trim'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/select'
import { PublicationStatus } from 'models/status'
import { BasicInformationModel } from 'models/trimCreation'
import {
  getRangesAsString,
  TrimCreationProps,
} from 'utils/CarSettings/Trim/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'
import { colors } from 'styles/theme'

import { AddButton, InfoBox, SetButton, StyledItem } from '../Measurement/style'

import TimePicker from './TimePicker'

const BasicInfo = ({
  handleBack,
  handleContinue,
  trimData,
  brands,
  updateTrimData,
}: TrimCreationProps) => {
  const { basicInformation } = trimData
  const [info, setInfo] = useState<BasicInformationModel>(basicInformation)
  const [modelOps, setModelOps] = useState<Option[]>([])
  const [status, setStatus] = useState<boolean>(
    basicInformation.status === PublicationStatus.PUBLISHED
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [infoAnchorEl, setInfoAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [infoOpen, setInfoOpen] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [range, setRange] = useState<number[]>([])
  const [basePrices, setBasePrices] = useState<number[]>([
    ...basicInformation.prices,
  ])
  const [yearList, setYearList] = useState<number[]>([])
  const [normalization, setNormalization] = useState<boolean>(
    basicInformation.normalization
  )

  const { text: translation } = useTranslation(textFiles.TRIM_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { information } = translation

  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''

  const validationSchema = yup.object({
    trim: yup.string().required(validationText.fieldRequired),
    alias: yup.string().required(validationText.fieldRequired),
    brand: yup.string().required(validationText.fieldRequired),
    model: yup.string().required(validationText.fieldRequired),
    datage: yup.array().min(1, validationText.fieldRequired),
    prices: yup
      .array()
      .min(1, validationText.fieldRequired)
      .of(
        yup
          .number()
          .positive(validationText.positiveNumber)
          .typeError(validationText.errorMessageOnlyNumbers)
      ),
  })

  const formik = useFormik<Partial<BasicInformationModel>>({
    initialValues: {
      trim: info.trim,
      alias: info.alias,
      brand: info.brand,
      model: info.model,
      datage: info.datage,
      prices: info.prices,
    },
    validationSchema,
    onSubmit: (values) => {
      setInfo({
        ...info,
        trim: values.trim as string,
      })
      updateTrimData({
        type: TRIM_CREATION_OPTION.UPDATE_BASIC_INFORMATION,
        payload: {
          status: status
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
          trim: values.trim as string,
          alias: values.alias as string,
          brand: info.brand,
          model: info.model,
          normalization,
          datage: info.datage,
          prices: normalization ? [basePrices[0]] : basePrices,
        },
      })
      const isThereAnEmptyPrice = basePrices.some((price) => price === 0)
      if (isThereAnEmptyPrice && !normalization) return
      handleContinue()
    },
  })

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    formik.setFieldTouched('datage', true)
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleInfoOpen = (event: React.MouseEvent<HTMLElement>) => {
    setInfoAnchorEl(event.currentTarget)
    setInfoOpen(true)
  }

  const handleInfoClose = () => {
    setInfoAnchorEl(null)
    setInfoOpen(false)
  }

  const handleCheck = () => {
    formik.setFieldTouched('datage', false)
    if (range[0] !== undefined && info.datage.length < 8) {
      setInfo((prevState) => {
        const items = [...prevState.datage]
        if (range.length > 1) {
          items.push([range[0], range[1]])
        } else items.push([range[0]])
        return { ...prevState, datage: items }
      })
      setYearList([])
      setError('')
    }
  }

  const handleClear = () => {
    setInfo((prevState) => {
      return { ...prevState, datage: [] }
    })
    setBasePrices([])
    setYearList([])
    setNormalization(false)
    setError('')
    formik.setFieldValue('datage', [])
    formik.setFieldValue('prices', [])
  }

  const handleSelect = (
    event: SelectChangeEvent<unknown>,
    map: INFORMATION_OPTION
  ) => {
    const {
      target: { value },
    } = event
    formik.setFieldValue(map, value as string)
    formik.setFieldTouched(map, false)
    if (map === 'brand') {
      formik.setFieldValue('model', value as string)
      formik.setFieldTouched('model', false)
    }
    setInfo({
      ...info,
      [map]: value,
    })
  }

  const handlePriceChange = (value: number, index: number) => {
    setBasePrices((prevState) => {
      const arr = [...prevState]
      arr[index] = value
      return arr
    })
  }

  useEffect(() => {
    const currentBrand = brands.find((brand) => brand.value === info.brand)
    if (currentBrand) {
      setModelOps(currentBrand.models)
      setInfo((prevState) => {
        const newInfo = {
          ...prevState,
          model: currentBrand.models[0].value as string,
        }
        return newInfo
      })
    }
  }, [brands, info.brand])

  useEffect(() => {
    let counter = 0
    for (const values of Object.values(info.datage)) {
      for (let i = values[0]; i <= values[values.length - 1]; i += 1) {
        if (basePrices[counter] === undefined) {
          basePrices.push(0)
        }
        yearList.push(i)
        counter += 1
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.datage])

  useEffect(() => {
    if (!open) {
      setRange([])
      setError('')
    }
  }, [open])

  useEffect(() => {
    if (error.length > 1) setRange([])
  }, [error])

  useEffect(() => {
    if (formik.values.datage !== info.datage) {
      formik.setFieldValue('datage', info.datage)
    }
  }, [formik, info.datage])

  useEffect(() => {
    if (formik.values.prices !== basePrices) {
      formik.setFieldValue('prices', basePrices)
    }
  }, [formik, basePrices])

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={information.isPublished}
            description={information.isPublishedDescription}
            status={status}
            handleStatus={setStatus}
            testId="published"
          />
          <StyledTextFieldContainer
            title={information.trim}
            description={information.trimDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <StyledTextField
              variant="outlined"
              name="trim"
              value={formik.values.trim}
              onChange={formik.handleChange}
              placeholder={information.trim}
              inputProps={{ maxLength: 25 }}
              error={formik.touched.trim && Boolean(formik.errors.trim)}
            />
            {formik.touched.trim && formik.errors.trim ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.trim}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.alias}
            description={information.aliasDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <StyledTextField
              variant="outlined"
              name="alias"
              value={formik.values.alias}
              onChange={formik.handleChange}
              placeholder={information.alias}
              inputProps={{ maxLength: 25 }}
              error={formik.touched.alias && Boolean(formik.errors.alias)}
            />
            {formik.touched.alias && formik.errors.alias ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.alias}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.brand}
            description={information.brandDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={brands}
              variant="outlined"
              name="brand"
              value={info.brand}
              label={information.brand}
              onChange={(e) => handleSelect(e, INFORMATION_OPTION.BRAND)}
              sx={{ fontSize: 12, height: 40 }}
            />
            {formik.touched.brand && formik.errors.brand ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.brand}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.model}
            description={information.modelDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={modelOps}
              variant="outlined"
              name="model"
              value={info.model}
              label={information.model}
              onChange={(e) => handleSelect(e, INFORMATION_OPTION.MODEL)}
              sx={{ fontSize: 12, height: 40 }}
            />
            {formik.touched.model && formik.errors.model ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.model}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.datage}
            description={information.datageDescription}
            contentStyle={{ display: 'flex', flexDirection: 'column' }}
          >
            <Box
              display="flex"
              marginBottom="15px"
              width="100%"
              justifyContent="space-between"
            >
              <SetButton
                buttonType="secondary"
                endIcon={<KeyboardArrowDownIcon />}
                startIcon={<DateRangeIcon />}
                onClick={handlePopperOpen}
                testId="set-date-button"
              >
                <Typography variant="body2">
                  {open ? (
                    <>
                      {range.length > 1
                        ? `${range[0]} - ${range[1]}`
                        : range[0]}
                    </>
                  ) : (
                    information.setButton
                  )}
                </Typography>
              </SetButton>
              <AddButton
                buttonType="secondary"
                startIcon={
                  open || !info.datage.length ? <AddIcon /> : <ClearIcon />
                }
                onClick={open ? handleCheck : handleClear}
                onMouseEnter={(e) => {
                  handleInfoClose()
                  handleInfoOpen(e)
                }}
                onMouseLeave={() => {
                  handleInfoClose()
                }}
                testId={cypressAddButton}
              >
                {open || !info.datage.length
                  ? information.addButton
                  : information.clearButton}
              </AddButton>
            </Box>
            {info.datage.length > 0 && (
              <StyledItem title={information.addedYears} titleSize="12px">
                <ul>
                  <li>
                    <span>{getRangesAsString(info.datage)}</span>
                  </li>
                </ul>
              </StyledItem>
            )}
            <>
              <Popper
                id="picker-popper"
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setAnchorEl(null)
                    setOpen(false)
                  }}
                >
                  <Box>
                    <TimePicker
                      data={info.datage}
                      errorMessage={error}
                      handleSetRange={setRange}
                      handleSetError={setError}
                    />
                  </Box>
                </ClickAwayListener>
              </Popper>
            </>
            <Popper
              id="info-popper"
              open={infoOpen}
              anchorEl={infoAnchorEl}
              placement="top-end"
            >
              <InfoBox>
                <InfoIcon sx={{ color: colors.orange, marginLeft: '15px' }} />
                <Box margin="0 15px">
                  {open || !info.datage.length
                    ? information.timePicker.info
                    : information.timePicker.clear}
                </Box>
              </InfoBox>
            </Popper>
            {formik.touched.datage && formik.errors.datage ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.datage}
              />
            ) : null}
          </StyledTextFieldContainer>
          {info.datage.length > 0 ? (
            <>
              {info.datage.length > 1 || info.datage[0].length > 1 ? (
                <StyledTextFieldContainer
                  title={information.normalization}
                  contentStyle={{
                    flexDirection: 'column',
                  }}
                >
                  <span>
                    <Checkbox
                      checked={normalization}
                      inputProps={{ 'aria-label': 'controlled' }}
                      name="normalization"
                      sx={{ padding: '0 9px' }}
                      onChange={(e) => setNormalization(e.target.checked)}
                    />
                    <Typography variant="button" color={colors.gray}>
                      {information.normalizationLabel}
                    </Typography>
                  </span>
                </StyledTextFieldContainer>
              ) : null}
              <StyledTextFieldContainer
                title={
                  (info.datage.length > 1 || info.datage[0].length > 1) &&
                  !normalization
                    ? information.priceSetup
                    : information.price
                }
                contentStyle={{
                  flexDirection: 'column',
                  maxWidth: '290px !important',
                }}
              >
                {(info.datage.length > 1 || info.datage[0].length > 1) &&
                !normalization ? (
                  <>
                    {basePrices.map((_, index) => {
                      return (
                        <>
                          <StyledTextField
                            variant="outlined"
                            name={`basePrice.${index}`}
                            value={basePrices[index]}
                            InputProps={{
                              inputComponent:
                                NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="body2">
                                    {priceCurrency}
                                  </Typography>
                                </InputAdornment>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography
                                    variant="body2"
                                    color={colors.black}
                                  >
                                    {yearList[index]}
                                  </Typography>
                                </InputAdornment>
                              ),
                              inputProps: {
                                maxLength: 10,
                              },
                            }}
                            onChange={(e) =>
                              handlePriceChange(Number(e.target.value), index)
                            }
                          />
                          {basePrices[index] === 0 ||
                          Number.isNaN(basePrices[index]) ? (
                            <ErrorMessage
                              sx={{
                                alignSelf: 'flex-start',
                                position: 'static',
                                marginTop: '0.5rem',
                              }}
                              text={validationText.fieldRequired}
                            />
                          ) : null}
                        </>
                      )
                    })}
                  </>
                ) : (
                  <>
                    <StyledTextField
                      variant="outlined"
                      name="basePrice"
                      value={basePrices[0]}
                      InputProps={{
                        inputComponent:
                          NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2">
                              {priceCurrency}
                            </Typography>
                          </InputAdornment>
                        ),
                        inputProps: {
                          maxLength: 10,
                        },
                      }}
                      onChange={(e) =>
                        handlePriceChange(Number(e.target.value), 0)
                      }
                    />

                    {formik.errors.prices ? (
                      <ErrorMessage
                        sx={{
                          alignSelf: 'flex-start',
                          position: 'static',
                          marginTop: '0.5rem',
                        }}
                        text={formik.errors.prices}
                      />
                    ) : null}
                  </>
                )}
              </StyledTextFieldContainer>
            </>
          ) : null}
        </div>
      </StyledForm>
      <ButtonNavigator
        previousFunction={handleBack}
        confirmButtonType="submit"
        isFirstStep
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default BasicInfo
