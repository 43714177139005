import React from 'react'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { SummaryItem } from 'library'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import TranslationItem from 'components/CarSettings/Common/Creation/Summary/TranslationItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { DriveTrainCreationProps } from 'utils/CarSettings/DriveTrain/creation'

import { SectionContainer } from 'styles/creation'

const Summary = ({
  handleBack,
  driveTrainData,
  handleSubmit,
  submitLoading,
}: DriveTrainCreationProps) => {
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { text } = useTranslation(textFiles.DRIVE_TRAIN_CREATION)
  const { summary } = text
  const { driveTrainInformation } = driveTrainData
  const { translations } = driveTrainInformation

  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      title={summary.title}
      description={summary.description}
      submitLoading={submitLoading}
    >
      <SectionContainer>
        <SummaryItem
          title={summary.name}
          text={driveTrainInformation.name}
          icon={<CarRepairIcon />}
        />
        <SummaryItem
          title={summary.status}
          text={generalText.status[driveTrainInformation.status]}
          icon={<CheckCircleIcon />}
        />
        {translations.length > 0 ? (
          <TranslationItem translations={driveTrainInformation.translations} />
        ) : null}
      </SectionContainer>
    </Skeleton>
  )
}

export default Summary
