import React from 'react'
import { Typography } from '@mui/material'
import { Box, DetailStatusTag, GenericStatusChangePopper } from 'library'

import GoBackButton from 'components/Common/GoBackButton'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { ExtendedStatus, Status } from 'models/services/status'

import { colors } from 'styles/theme'

type DetailHeaderProps = {
  title: string
  backButtonText: string
  status?: ExtendedStatus | Status
  editable: boolean
  statusList?: ExtendedStatus[]
  loading?: boolean
  handleStatusChange?: (newStatus: ExtendedStatus) => void
}
export const DetailHeader = ({
  title,
  backButtonText,
  status,
  statusList,
  editable,
  loading = false,
  handleStatusChange,
}: DetailHeaderProps) => {
  const { text } = useTranslation(textFiles.GENERAL)

  return (
    <>
      <GoBackButton>{`< ${backButtonText}`}</GoBackButton>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {title}
        </Typography>
        {status && (
          <DetailStatusTag
            backgroundColor={status.backgroundColor}
            color={status.textColor}
            text={status.name}
          />
        )}
        {editable && handleStatusChange && statusList && (
          <GenericStatusChangePopper
            status={status as ExtendedStatus}
            handleSaveStatus={handleStatusChange}
            statusList={statusList}
            loading={loading}
            text={{
              saveButton: text.buttons.save,
              cancelButton: text.buttons.cancel,
              ...text.statusChangePopper,
            }}
          />
        )}
      </Box>
    </>
  )
}
