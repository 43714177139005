import React from 'react'
import { Tabs as MuiTabs, tabsClasses } from '@mui/material'
import { Tab } from 'library/components/Common/Tab'

import { StyledTabs } from './style'

export type TabItem = {
  label: string
  value?: number | string
  badge?: number
  onClick?: () => void
}

export type TabObjects = {
  id: string | number
  item: TabItem | React.ReactElement
}

export type TabsProps = {
  /**
   * Array of tab labels
   */
  tabs: TabObjects[]
  /**
   * value of tabs
   */
  value: number | string | boolean
  /**
   * Function to change tabs
   */
  onChange: (value: number | string) => void
}
export const CustomTab = ({ ...props }) => {
  return <div>{props.item}</div>
}

export const Tabs = ({ value, onChange, tabs }: TabsProps) => {
  return (
    <StyledTabs>
      <MuiTabs
        value={value}
        variant="scrollable"
        component="nav"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            width: 'auto',
          },
        }}
      >
        {tabs.map((tab: TabObjects) => {
          const { item } = tab
          const { label, value: tabValue, badge, onClick } = item as TabItem
          if (React.isValidElement(item) !== true) {
            return (
              <Tab
                key={tab.id}
                value={tabValue!}
                label={label}
                badge={badge}
                handleTabChange={onChange}
                onClick={onClick}
              />
            )
          }
          return <CustomTab key={tab.id} item={item} />
        })}
      </MuiTabs>
    </StyledTabs>
  )
}
