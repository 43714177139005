import React from 'react'
import { Typography } from '@mui/material'
import {
  Autocomplete,
  AutocompleteItem,
  Box,
  ExtendedAvatar,
} from 'library/components/Common'
import { InspectorEntity } from 'library/models/services/inspectorEntity'

export type InspectorPickerTextModel = {
  inspectorTitle: string
  inspectorSubtitle: string
  loading: string
  noInspectors: string
}

export type InspectorPickerProps = {
  translation?: InspectorPickerTextModel
  onChange: (value: AutocompleteItem) => void
  value: InspectorEntity | null
  inspectors: InspectorEntity[]
  disabled?: boolean
  loading?: boolean
}

export const InspectorPicker = ({
  translation,
  onChange,
  value,
  inspectors,
  disabled,
  loading,
}: InspectorPickerProps) => {
  const title = translation?.inspectorTitle || 'Inspector assignment'
  const loadingText = translation?.loading || 'Loading inspectors...'

  return (
    <Box marginBottom="1em">
      <Typography variant="body1">{title}</Typography>
      <Typography variant="subtitle1" marginBottom="1em">
        {translation?.inspectorSubtitle ||
          'This technician will be in charge of the vehicle'}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          '& > div:nth-of-type(2)': {
            flexGrow: '2',
            width: '80%',
            marginLeft: '5%',
          },
        }}
      >
        <ExtendedAvatar
          alt="InspectorPicker-avatar"
          variant="rounded"
          height={44}
          width={44}
          image={value?.profilePicture as string}
          sx={{
            width: 'unset',
            height: 'unset',
            marginRight: '10px',
          }}
        />
        <Autocomplete
          options={inspectors}
          onChangeHandler={onChange}
          placeholder={!loading ? title : loadingText}
          value={value}
          disabled={disabled || loading}
          noOptionsText={
            translation?.noInspectors || 'No inspectors for this time or place'
          }
          testId="inspectors-autocomplete"
        />
      </Box>
    </Box>
  )
}
