import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import { CSSObject } from '@mui/material'
import { Button, Modal } from 'library'

import { BottomContainer, StyledDiv, StyledSpan } from './style'

export type ModalText = {
  header: string
  confirmButton: string
  cancelButton: string
}

export type ConfirmationModalProps = {
  open: boolean
  headerIcon?: React.ReactNode
  onConfirmHandler: () => void
  onCancelHandler: () => void
  text: ModalText
  descriptionSx?: CSSObject
  confirmIcon?: React.ReactNode
}

export const ConfirmationModal = ({
  open,
  headerIcon = <DeleteIcon />,
  onConfirmHandler,
  onCancelHandler,
  text,
  descriptionSx,
  confirmIcon = <CheckIcon />,
}: ConfirmationModalProps) => {
  return open ? (
    <Modal
      open={open}
      size="customDimension"
      customDimension={{
        width: 384,
        height: 230,
      }}
    >
      <StyledDiv>
        {headerIcon}
        <StyledSpan sx={descriptionSx}>{text.header}</StyledSpan>
        <BottomContainer>
          <Button
            size="small"
            style={{
              width: 'auto',
              minWidth: 159,
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
            }}
            onClick={onConfirmHandler}
          >
            {confirmIcon}
            {text.confirmButton}
          </Button>
          <Button
            size="small"
            style={{ width: 122, fontWeight: 'bold' }}
            buttonType="info"
            onClick={onCancelHandler}
          >
            {text.cancelButton}
          </Button>
        </BottomContainer>
      </StyledDiv>
    </Modal>
  ) : null
}

export default ConfirmationModal
