import React from 'react'
import { Container } from 'library/components/Common/Container'
import {
  BarChart,
  BarStructureType,
} from 'library/components/Dashboard/BarChart'
import { TimeSensitivePopper } from 'library/components/General/TimeSensitivePopper'
import { GraphApprovementType } from 'library/models/dashboard'
import { BREAK_POINTS } from 'library/styles/breakpoints'

export type InspectionsBasedOnSourceGraphProps = {
  structure: BarStructureType[]
  data: GraphApprovementType[]
  loading?: boolean
  containerHeight?: number
  height?: number
  width?: string
  basedOnSourceTitle?: string
  basedOnSourceDescription?: string
}

export const InspectionsBasedOnSourceGraph = ({
  structure,
  data,
  loading,
  containerHeight,
  height,
  width,
  basedOnSourceTitle,
  basedOnSourceDescription,
}: InspectionsBasedOnSourceGraphProps) => {
  return (
    <Container
      height={containerHeight || 437}
      width="44%"
      title={basedOnSourceTitle || 'Inspections distribution based on source'}
      description={
        basedOnSourceDescription ||
        'Discover where the inspections are coming from'
      }
      extraText={<TimeSensitivePopper />}
      sx={{
        marginTop: '1rem',
        [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
          marginTop: '0rem',
        },
      }}
      loading={loading}
      isEmpty={!data.length}
    >
      <BarChart
        data={data}
        barsStructure={structure}
        layout="horizontal"
        size={{ width: width || '80%', height: height || 390 }}
        hasLegend
      />
    </Container>
  )
}
