import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import {
  BodyContainerWithHeader,
  Box,
  createSimpleStepperItems,
  LoadingAnimation,
  Stepper,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'

import { initialSelectOptions } from 'pages/Inventory/Creation'

import ErrorModal from 'components/CarSettings/Trim/Creation/Common/ErrorModal'
import { BackContainer } from 'components/Common/BackContainer'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateTrimInputType } from 'models/services/CarSettings/creation'
import { VehicleInformationSelectOptions } from 'models/services/inventory/detail'
import { PublicationStatus } from 'models/status'
import { PictureOption } from 'models/trim'
import {
  BasicInformationModel,
  MeasurementInformationModel,
  MileageInformationModel,
  SettingsInformationModel,
  SpacingInformationModel,
  TrimCreationAction,
  TrimCreationModel,
} from 'models/trimCreation'
import trimCreationReducer from 'reducers/trimCreationReducer'
import { INITIAL_TRIM_CREATION_STEPS as steps } from 'utils/CarSettings/Trim/creation'

import { GET_VEHICLE_INFORMATION_SELECT_OPTIONS } from 'graphQL/Common/Vehicle/queries'
import { GET_BRANDS } from 'graphQL/Operations/Model/Creation/queries'
import { CREATE_TRIM_LEVEL } from 'graphQL/Operations/Trim/Creation/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialBasicInformationState: BasicInformationModel = {
  status: PublicationStatus.PUBLISHED,
  trim: '',
  alias: '',
  brand: '',
  model: '',
  normalization: false,
  datage: [],
  prices: [],
}

const initialSettingsInformationState: SettingsInformationModel = {
  transmission: '',
  bodyStyle: '',
  driveTrain: '',
  fuelType: '',
  features: [],
}

const initialSpacingInformationState: SpacingInformationModel = {
  frontLegRoom: 100,
  backLegRoom: 100,
  frontHeadRoom: 100,
  backHeadRoom: 100,
  engineDisplacement: 5,
  cargoCapacity: 5,
  cargoWeightCapacity: 100,
}

const initialMeasurementInformationState: MeasurementInformationModel = {
  length: 10,
  width: 10,
  height: 10,
  seats: 6,
  doors: 5,
}

const initialMileageInformationState: MileageInformationModel = {
  mpg: 1000,
  cityMpg: 1000,
  highwayMpg: 1000,
  fuelCapacity: 100,
  cylinders: 10,
  torque: 100,
  torqueRpm: 100,
  horsePower: 100,
  horsePowerRpm: 100,
}

const initialData: TrimCreationModel = {
  basicInformation: initialBasicInformationState,
  settingsInformation: initialSettingsInformationState,
  spacingInformation: initialSpacingInformationState,
  measurementInformation: initialMeasurementInformationState,
  mileageInformation: initialMileageInformationState,
}

const TrimCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [brands, setBrands] = useState<PictureOption[]>([])
  const [vehicleSelectOptions, setVehicleSelectOptions] =
    useState<VehicleInformationSelectOptions>(initialSelectOptions)
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [trimData, dispatch] = useReducer(trimCreationReducer, initialData)

  const { text } = useTranslation(textFiles.TRIM_CREATION)
  const stepperItems = createSimpleStepperItems(text.stepper)
  const history = useHistory()
  const { show } = useNotification()

  const updateTrimData = (action: TrimCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const { loading: brandLoading } = useQuery<GenericData<PictureOption[]>>(
    GET_BRANDS,
    {
      onCompleted(response) {
        setBrands(response.data.filter((brand) => brand.models.length > 0))
      },
    }
  )

  const { loading: vehicleSelectOptionsLoading } = useQuery<
    VehicleInformationSelectOptions,
    FilterInputVariable
  >(GET_VEHICLE_INFORMATION_SELECT_OPTIONS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setVehicleSelectOptions(response)
    },
  })

  const [createTrim, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateTrimInputType>
  >(CREATE_TRIM_LEVEL, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CAR_SETTINGS_SUB_ROUTES.TRIM_LISTING)
    },
    onError(error: ApolloError) {
      const { errorExists, filteredErrors } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        setErrorModalOpen(true)
        setErrorMessage(filteredErrors[0].message)
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(() => {
    const {
      basicInformation,
      settingsInformation,
      spacingInformation,
      measurementInformation,
      mileageInformation,
    } = trimData

    const yearSet = new Set<number>()
    basicInformation.datage.forEach((datageItem) => {
      if (datageItem.length > 1) {
        for (let i = datageItem[0]; i <= datageItem[1]; i += 1) {
          yearSet.add(i)
        }
      } else yearSet.add(datageItem[0])
    })
    const yearArray = Array.from(yearSet)

    createTrim({
      variables: {
        input: {
          alias: basicInformation.alias,
          backHeadRoom: spacingInformation.backHeadRoom,
          backLegRoom: spacingInformation.backLegRoom,
          bodyStyle: settingsInformation.bodyStyle,
          carModel: basicInformation.model,
          cargo: spacingInformation.cargoWeightCapacity,
          cargoCapacity: spacingInformation.cargoCapacity,
          cylinders: mileageInformation.cylinders,
          doors: measurementInformation.doors,
          driveTrain: settingsInformation.driveTrain,
          engineDisplacement: spacingInformation.engineDisplacement,
          features: settingsInformation.features,
          frontHeadRoom: spacingInformation.frontHeadRoom,
          frontLegRoom: spacingInformation.frontLegRoom,
          fuelCapacity: mileageInformation.fuelCapacity,
          fuelType: settingsInformation.fuelType,
          height: measurementInformation.height,
          horsePower: mileageInformation.horsePower,
          horsePowerRpm: mileageInformation.horsePowerRpm,
          length: measurementInformation.length,
          mpg: mileageInformation.mpg,
          mpgCity: mileageInformation.cityMpg,
          mpgHgw: mileageInformation.highwayMpg,
          name: basicInformation.trim,
          seats: measurementInformation.seats,
          torque: mileageInformation.torque,
          torqueRpm: mileageInformation.horsePowerRpm,
          transmission: settingsInformation.transmission,
          width: measurementInformation.width,
          years: yearArray,
          status: basicInformation.status,
          price: basicInformation.normalization
            ? basicInformation.prices[0]
            : undefined,
          prices: basicInformation.normalization
            ? undefined
            : basicInformation.prices,
        },
      },
    })
  }, [trimData, createTrim])

  const isLoading = brandLoading || vehicleSelectOptionsLoading

  return (
    <>
      <StyledBox>
        <BackContainer />
        <BodyContainerWithHeader
          title={text.title}
          subtitle={text.processDescription}
        >
          <Box
            width="100%"
            sx={{
              display: 'flex',
              '> div': {
                flexWrap: 'wrap',
              },
            }}
          >
            <Stepper
              currentStep={currentStep}
              stepperItems={stepperItems}
              optionalWidth="160px"
              topSx={{
                justifyContent: 'flex-start',
                alignItems: 'end',
                rowGap: '10px',
              }}
            />
          </Box>
          <>
            {isLoading ? (
              <LoadingAnimation showAnimation={isLoading} />
            ) : (
              React.createElement(steps[currentStep], {
                handleContinue,
                handleSubmit,
                handleBack,
                trimData,
                vehicleSelectOptions,
                brands,
                submitLoading,
                updateTrimData,
              })
            )}
          </>
        </BodyContainerWithHeader>
      </StyledBox>
      <ErrorModal
        open={errorModalOpen}
        onConfirmHandler={() => {
          setErrorModalOpen(false)
        }}
        trim={trimData.basicInformation}
        brands={brands}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default TrimCreationPage
