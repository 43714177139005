import React, { FunctionComponent, useState } from 'react'
import {
  InputAdornment,
  InputBaseComponentProps,
  Typography,
} from '@mui/material'

import BiggerFontTooltip from 'components/Common/Table/Tooltip/BiggerFontTooltip'
import { StyledTextField } from 'components/Inventory/Common/InformationCell/styles'
import NumberValueInput from 'components/Inventory/Common/NumberValueInput'

type Props = {
  label: string
  value: number
  startAdornment: string
  edit: boolean
  name: string
  color: string
}

const PriceTextfield = ({
  label,
  value,
  startAdornment,
  edit,
  name,
  color,
}: Props) => {
  const [discount, setDiscount] = useState<number>(value)

  const handleBlur = () => {
    if (!discount || discount === 0) {
      setDiscount(1)
    }
  }

  return (
    <>
      {!edit ? (
        <BiggerFontTooltip text={label} color={color} />
      ) : (
        <StyledTextField
          name={name}
          value={discount}
          onChange={(e) => setDiscount(Number(e.target.value))}
          onBlur={handleBlur}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Typography variant="body2">{startAdornment}</Typography>
              </InputAdornment>
            ),
            inputComponent:
              NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
            inputProps: {
              maxLength: 7,
            },
          }}
        />
      )}
    </>
  )
}

export default PriceTextfield
