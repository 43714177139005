import React, { useState } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { Divider, SelectChangeEvent, Typography } from '@mui/material'
import { Box, colors, FONT_WEIGHT, Option, Select, Tooltip } from 'library'

import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import {
  LeadData,
  UpdateCaseInput,
} from 'models/services/CustomerRelationship/board'

import {
  CaseContainer,
  SelectContainer,
  SmallSubtitleText,
  StyledLocalPhoneIcon,
  SubtitleText,
  TooltipPopper,
} from './style'

type CaseBoxProps = {
  borderColor: string
  draggableProvided: DraggableProvided
  draggableSnapshot: DraggableStateSnapshot
  item: LeadData
  options: Option[]
  handleUpdateLead: (id: string, input: UpdateCaseInput) => void
}

const CaseCard = ({
  borderColor,
  draggableProvided,
  draggableSnapshot,
  item,
  options,
  handleUpdateLead,
}: CaseBoxProps) => {
  const {
    text: { caseCard: translation },
  } = useTranslation(textFiles.LEAD_BOARD)
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''

  const {
    car,
    customer,
    customerCareRepresentative,
    caseId,
    estimatedValue,
    origin,
    type,
    id,
  } = item

  const [representative, setRepresentative] = useState<string>(
    customerCareRepresentative ? customerCareRepresentative.id : ''
  )

  const handleSelectRepresentative = (event: SelectChangeEvent<unknown>) => {
    const selectedRepresentative = event.target.value as string
    setRepresentative(selectedRepresentative)
    handleUpdateLead(id, {
      customerCareRepresentative: selectedRepresentative,
    })
  }

  return (
    <CaseContainer
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
      sx={{
        border: `1px solid ${
          draggableSnapshot.isDragging ? borderColor : colors.faintGray
        }`,
        '&:hover': {
          border: `1px solid ${borderColor}`,
        },
      }}
    >
      <Box padding="1rem">
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box display="flex" alignItems="center">
              <Typography
                fontWeight={FONT_WEIGHT.SEMI_BOLD}
                marginRight="0.25rem"
                variant="body1"
                maxWidth="160px"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {`${customer.name} ${customer.lastName}`}
              </Typography>
              {customer.telephoneNumber && (
                <Tooltip
                  customPopper={
                    <TooltipPopper>
                      <Typography color={colors.black} variant="body2">
                        {customer.telephoneNumber}
                      </Typography>
                    </TooltipPopper>
                  }
                  boxSx={{ display: 'flex', width: 'auto' }}
                >
                  <StyledLocalPhoneIcon />
                </Tooltip>
              )}
            </Box>
            <SmallSubtitleText variant="subtitle2">{caseId}</SmallSubtitleText>
          </Box>
          {estimatedValue !== null ? (
            <Box>
              <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body1">
                {`${priceCurrency}${estimatedValue.toLocaleString('en-US')}`}
              </Typography>
              <SmallSubtitleText variant="subtitle2">
                {translation.estimatedValueLabel}
              </SmallSubtitleText>
            </Box>
          ) : null}
        </Box>
        <Typography color={colors.orange} variant="body2" margin="0.5rem 0">
          {type}
        </Typography>
        <Divider
          sx={{
            borderColor: colors.faintGray,
            margin: '0.75rem 0',
          }}
        />
        <SubtitleText variant="subtitle1">
          {car ? car.title : translation.vehicle}
        </SubtitleText>
        <SubtitleText variant="subtitle1">{`${translation.sourceLabel}: ${origin}`}</SubtitleText>
        <SelectContainer>
          <Select
            icon={<ManageAccountsIcon />}
            label={translation.assign}
            onChange={handleSelectRepresentative}
            options={options}
            value={representative}
            variant="outlined"
            sx={{
              fontSize: '12px',
              width: '100%',
            }}
          />
        </SelectContainer>
      </Box>
    </CaseContainer>
  )
}

export default CaseCard
