import React, { useEffect, useState } from 'react'
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled'
import ReplyIcon from '@mui/icons-material/Reply'
import { CSSObject, Typography } from '@mui/material'
import AutoComplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import { Box, CurboSpot, ErrorMessage } from 'library'

import PlacesAutocomplete from 'components/Common/MapAutocomplete'
import Map from 'components/General/Map'

import { GOOGLE_SHARE_BASE_URL } from 'constants/map'
import { Address, Coordinate } from 'models/map'

import { colors, FONT_WEIGHT } from 'styles/theme'

import CopyToClipboard from '../CopyToClipboard'
import MapCoordinates from '../MapCoordinates'

import { StyledTextField } from './style'

type LocationProps = {
  translation: Record<string, string>
  address: Address | undefined
  handleAddressChange: (address: Address | undefined) => void
  containerStyling?: CSSObject
  showError: boolean
  curboSpots: CurboSpot[]
  hasCoordinatePicker?: boolean
  disabled?: boolean
  circleCenter?: Coordinate
  mapHeight?: number | string
}

const Location = ({
  translation,
  address,
  showError,
  handleAddressChange,
  containerStyling,
  curboSpots,
  hasCoordinatePicker,
  disabled,
  circleCenter,
  mapHeight = '70%',
}: LocationProps) => {
  const [apiLoaded, setApiLoaded] = useState<boolean>(false)
  const [curboSpot, selectCurboSpot] = useState<CurboSpot | null>(null)
  const [shareURL, setShareURL] = useState<string>('')

  useEffect(() => {
    if (address) {
      const QUERY = `${address?.address}`
      const ENCODED_URI = `${GOOGLE_SHARE_BASE_URL}${encodeURIComponent(QUERY)}`
      setShareURL(ENCODED_URI)

      if (!address.originFromSpot) {
        selectCurboSpot(null)
      } else {
        selectCurboSpot(curboSpots.filter((cs) => cs.name === address.name)[0])
      }
    }
  }, [address, setShareURL, curboSpots])

  return (
    <Box sx={containerStyling}>
      {translation.locationTitle ? (
        <Typography variant="body1">{translation.locationTitle}</Typography>
      ) : null}
      {translation.locationSubtitle ? (
        <Typography
          variant="subtitle1"
          color={colors.placeholderGray}
          fontWeight={FONT_WEIGHT.MEDIUM}
          marginBottom="8px"
        >
          {translation.locationSubtitle}
        </Typography>
      ) : null}

      <Box
        border={`1px solid ${colors.border}`}
        padding="0.5em"
        sx={{
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <PlacesAutocomplete
            isLoaded={apiLoaded}
            setAddress={handleAddressChange}
            address={address}
            translation={translation}
            disabled={disabled}
          />
          {hasCoordinatePicker ? (
            <MapCoordinates
              setAddress={handleAddressChange}
              address={address}
            />
          ) : (
            <>
              <Typography variant="body2" fontSize="12px" margin="0 0.5em">
                {translation.orLabel}
              </Typography>
              <AutoComplete
                disablePortal
                disabled={disabled}
                id="curboSpotSelect"
                getOptionLabel={(spot: CurboSpot) => spot.name}
                options={curboSpots}
                value={curboSpot}
                noOptionsText={translation.noOptionsText}
                onChange={(
                  event: React.SyntheticEvent,
                  newValue: CurboSpot | null,
                  reason: AutocompleteChangeReason
                ) => {
                  if (reason === 'clear') {
                    handleAddressChange(undefined)
                    selectCurboSpot(null)
                  } else if (newValue) {
                    const {
                      id,
                      name,
                      address: spotAddress,
                      latitude: lat,
                      longitude: lng,
                    } = newValue
                    selectCurboSpot(newValue)
                    handleAddressChange({
                      id,
                      lat,
                      lng,
                      address: spotAddress,
                      name,
                      originFromSpot: true,
                    })
                  }
                }}
                sx={{ width: 'max(300px, 100%)' }}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder={translation.curboSpotPlaceholder}
                    inputProps={{
                      ...params.inputProps,
                      'data-cy': 'curbo-spots-autocomplete',
                    }}
                  />
                )}
              />
            </>
          )}
        </Box>
        {showError ? (
          <ErrorMessage
            text={translation.errorMessage}
            sx={{ margin: '0.5em 0' }}
          />
        ) : null}
      </Box>
      <Box width="100%" height={mapHeight}>
        <Map
          setIsLoaded={setApiLoaded}
          curboSpots={curboSpots}
          address={address}
          setAddress={handleAddressChange}
          disabled={disabled}
          circleCenter={circleCenter}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="1rem 0.5rem"
      >
        <DirectionsCarFilledIcon
          sx={{ marginRight: '0.5rem', color: colors.orange }}
        />
        <Typography
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          color="primary"
          sx={{
            marginRight: '1rem',
            textAlign: 'left',
            display: '-webkit-box !important',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {address?.address || ''}
        </Typography>
        {shareURL.length ? (
          <Typography
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            color="primary"
            marginLeft="auto"
            whiteSpace="nowrap"
          >
            <CopyToClipboard
              textToCopy={shareURL}
              placeholderText={translation.shareButton}
              color={colors.orange}
            />
            <ReplyIcon
              sx={{ transform: 'scale(-1, 1)', marginLeft: '0.25em' }}
            />
          </Typography>
        ) : null}
      </Box>
    </Box>
  )
}

export default Location
