import React, {
  forwardRef,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import NumberFormat from 'react-number-format'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Grid, InputBaseComponentProps, InputLabel } from '@mui/material'
import { Box } from 'library'

import { initialCreationHour } from 'constants/hour'
import { textFiles } from 'constants/textFiles'
import { CustomProps } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { Hour } from 'models/hours'
import { getContinentalTime } from 'utils/Hour'

import { colors } from 'styles/theme'

import { StyledBox } from '../Stripe/style'
import { MeridiemButton, StyledTextField } from '../style'

export type HourModelType = {
  hour: string
  minute: string
  am: boolean
}

const NumberFormatCustom = React.forwardRef<NumberFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        format="##"
        isNumericString
      />
    )
  }
)

type HourInputFieldProps = {
  time?: Hour
  handleTimeChange: (value: Hour) => void
}

const HourInputField = forwardRef(
  (
    { time = initialCreationHour, handleTimeChange }: HourInputFieldProps,
    ref
  ) => {
    const { text } = useTranslation(textFiles.HOURS_CREATION)
    const { general: translation } = text
    const timeSplit = time?.name.split(':')
    const timeInput: HourModelType = {
      hour: timeSplit[0] || '',
      minute: timeSplit[1].split(' ')[0] || '',
      am: time.am,
    }

    const [hour, setHour] = useState<string>(timeInput.hour)
    const [minute, setMinute] = useState<string>(timeInput.minute)
    const [am, setAm] = useState<boolean>(timeInput.am)

    const handleChangeHour = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10)
      if (value > 12) {
        setHour('12')
      } else if (value < 1) {
        setHour('1')
      } else {
        setHour(e.target.value)
      }
    }

    const handleChangeMinute = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10)
      if (value >= 60) {
        setMinute('59')
      } else {
        setMinute(e.target.value)
      }
    }

    const handleChangeAm = (value: boolean) => {
      setAm(value)
    }

    useEffect(() => {
      handleTimeChange({
        am,
        continentalTime: getContinentalTime(
          parseInt(hour, 10),
          parseInt(minute, 10),
          am
        ),
        slug: `${hour}${minute}`,
        name: `${hour}:${minute}`,
        id: time.id,
        visible: time.visible,
      })
    }, [hour, minute, am, handleTimeChange, time.id, time.visible])

    return (
      <StyledBox
        ref={ref}
        width="332px !important"
        height="105px !important"
        paddingTop="17px !important"
        boxShadow={5}
        marginTop="10px !important"
      >
        <Box display="flex">
          <Grid container>
            <Grid item xs={6}>
              <InputLabel>{translation.hours}</InputLabel>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{translation.minutes}</InputLabel>
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                name="hours"
                value={hour}
                onChange={handleChangeHour}
                InputProps={{
                  inputComponent:
                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '100% !important',
                  input: {
                    height: '36px',
                    backgroundColor: colors.grayLight,
                    color: colors.placeholderGray,
                    fontSize: '24px',
                    fontWeight: 'bold !important',
                    textAlign: 'center',
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
              alignItems="center"
              marginLeft="6px"
              marginRight="6px"
            >
              <FiberManualRecordIcon
                color="primary"
                sx={{ width: 8, height: 8 }}
              />
              <FiberManualRecordIcon
                color="primary"
                sx={{ width: 8, height: 8 }}
              />
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                name="minutes"
                value={minute}
                onChange={handleChangeMinute}
                InputProps={{
                  inputComponent:
                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  input: {
                    height: '36px',
                    backgroundColor: colors.grayLight,
                    color: colors.placeholderGray,
                    fontSize: '24px',
                    fontWeight: 'bold !important',
                    textAlign: 'center',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container marginLeft={3}>
            <Grid item xs={12}>
              <InputLabel>{`${translation.am}/${translation.pm}`}</InputLabel>
            </Grid>
            <Grid item xs={6}>
              <MeridiemButton
                variant={`${am ? 'contained' : 'text'}`}
                onClick={() => handleChangeAm(true)}
              >
                {translation.am}
              </MeridiemButton>
            </Grid>
            <Grid item xs={6}>
              <MeridiemButton
                variant={`${am ? 'text' : 'contained'}`}
                onClick={() => handleChangeAm(false)}
              >
                {translation.pm}
              </MeridiemButton>
            </Grid>
          </Grid>
        </Box>
      </StyledBox>
    )
  }
)

export default HourInputField
