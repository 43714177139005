import React from 'react'
import { Typography } from '@mui/material'

import { InspectorStatsBox, StyledBoxContainer } from './style'

/**
 ** General data between Statistics Containers.
 */
export type StatisticsTypeProps = {
  value: string | number
  color: string
  label?: string
}

export const InspectionsTotal = ({
  value,
  color,
  label,
}: StatisticsTypeProps) => (
  <StyledBoxContainer>
    <InspectorStatsBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="80%"
      paddingTop="0px"
      flexDirection="column"
    >
      <Typography textAlign="center" variant="h1" color={color} fontSize="54px">
        {value}
      </Typography>

      {label && (
        <Typography variant="body2" marginTop="-5px" marginBottom="0px">
          {label}
        </Typography>
      )}
    </InspectorStatsBox>
  </StyledBoxContainer>
)
