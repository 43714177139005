import React, { useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Fade, Popper, Typography } from '@mui/material'
import { Box } from 'library/components/Common'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

import { StyledContainer } from './style'

export type TimeSensitivePopperTextModel = {
  firstPart: string
  secondPart: string
  colorText: string
}

export type PopperProps = {
  translation?: TimeSensitivePopperTextModel
}

export const TimeSensitivePopper = ({ translation }: PopperProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const open = Boolean(anchorEl)
  const maintainPopover = () => {
    setAnchorEl(anchorEl)
  }
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopperClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
        height="24px"
        width="24px"
      >
        <DateRangeIcon sx={{ color: colors.gray }} />
      </Box>

      <Popper
        open={open}
        onMouseEnter={maintainPopover}
        onMouseLeave={handlePopperClose}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        style={{
          zIndex: 1500,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <StyledContainer>
              <Typography
                variant="body1"
                sx={{
                  color: colors.gray,
                  fontWeight: FONT_WEIGHT.MEDIUM,

                  span: {
                    color: colors.orange,
                  },
                }}
              >
                {translation?.firstPart || 'This module is '}
                <span>{translation?.colorText || 'time sensitive'}</span>{' '}
                {translation?.secondPart ||
                  'meaning its information will change depending on the date frame you set on the time configuration.'}
              </Typography>
            </StyledContainer>
          </Fade>
        )}
      </Popper>
    </>
  )
}
