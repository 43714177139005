import { styled } from '@mui/material'

export const StyledForm = styled('form')({
  justifyContent: 'flex-start',
  alignItems: 'left',
  width: '100%',
  minHeight: '305px',
  marginBottom: '2rem',
  '> div': {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '2rem',
  },
})
