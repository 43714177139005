import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import {
  IconButton,
  InputAdornment,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'

import { StyledTextField } from './style'

// creates a type based on Material UI TextField Props, adds another property
// called icon and submitFunction for extra functionality and then extends
// from MUI TextFile Props
export type TextFieldProps = {
  icon?: React.ReactNode
  submitFunction?: () => void
} & MuiTextFieldProps

export const TextField = ({
  icon,
  name,
  inputProps,
  submitFunction,
  ...props
}: TextFieldProps) => {
  const handleSubmit = () => {
    if (submitFunction) {
      submitFunction()
    }
  }

  return (
    <StyledTextField
      {...props}
      color="info"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSubmit()
        }
      }}
      name={name}
      InputProps={{
        ...props.InputProps,
        ...(icon && {
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        }),

        ...(submitFunction && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit" onClick={handleSubmit}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        ...inputProps,
        'data-cy': name ? `${name}-input` : undefined,
      }}
    />
  )
}
