import React from 'react'
import { Box, Typography } from '@mui/material'

import { Role } from 'models/role'
import { Option } from 'models/select'

import { colors } from 'styles/theme'

import { flexStyle, StyledSquareIcon } from './style'

export type StaticRoleFieldProps = {
  role?: Role
  area?: Option
}
export const StaticRoleField = ({ role, area }: StaticRoleFieldProps) => {
  return (
    <Box sx={flexStyle}>
      <StyledSquareIcon />
      {role && (
        <>
          <Typography variant="body1">{area?.name}</Typography>
          <Typography
            variant="body1"
            marginX="10px"
            color={colors.placeholderGray}
          >
            -
          </Typography>
          <Typography variant="body1" color={colors.placeholderGray}>
            {role.name}
          </Typography>
        </>
      )}
    </Box>
  )
}
