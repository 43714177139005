import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import {
  DetailNavTab,
  generateTabItems,
  LoadingAnimation,
  NavBarItem,
  TabPanel,
  useNotification,
} from 'library'
import { uploadImageService } from 'services/uploadImage'

import { DetailHeader } from 'components/CarSettings/Common/Detail/DetailHeader'
import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import CopyToClipboard from 'components/Common/CopyToClipboard'
import GeneralInformation from 'components/Marketing/Offer/Detail/Information'
import VehicleSet from 'components/Marketing/Offer/Detail/VehicleSet'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { Offer, UpdateOfferInput } from 'models/services/offer/detail'
import { ExtendedStatus } from 'models/services/status'
import { OfferStatus as OfferStatusEnum } from 'models/status'

import { UPDATE_OFFER } from 'graphQL/Offer/Detail/mutations'
import {
  GET_OFFER_BY_ID,
  GET_OFFER_STATUSES,
} from 'graphQL/Offer/Detail/queries'

import { ContentContainer, Layout } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const OfferDetailPage = () => {
  const { offerId } = useParams<{ offerId: string }>()
  const { text } = useTranslation(textFiles.OFFER_DETAIL)
  const { general: translation, notificationText } = text
  const { show } = useNotification()
  const history = useHistory()
  const [tab, setTab] = useState<number>(0)
  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })
  const [offerData, setOfferData] = useState<Offer | null>(null)
  const [status, setStatus] = useState<ExtendedStatus | null>(null)
  const [statusList, setStatusList] = useState<ExtendedStatus[]>([])

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const { loading: statusLoading } = useQuery<GenericData<ExtendedStatus[]>>(
    GET_OFFER_STATUSES,
    {
      onCompleted(response) {
        setStatusList(response.data)
      },
    }
  )

  const { loading: offerLoading } = useQuery<
    GenericData<Offer>,
    GenericInputVariable<string>
  >(GET_OFFER_BY_ID, {
    variables: {
      input: offerId,
    },
    onError(error) {
      setApolloError(error)
    },
    onCompleted(response) {
      const { data } = response
      setOfferData(data)
      setStatus(data.status)
    },
  })

  const [updateOffer, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateOfferInput>
  >(UPDATE_OFFER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: notificationText.updateSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: notificationText.updateFail,
      })
    },
    refetchQueries: [GET_OFFER_BY_ID],
  })

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleUpdateStatus = (newStatus: ExtendedStatus) => {
    updateOffer({
      variables: {
        input: {
          where: {
            id: offerId,
          },
          data: {
            status: newStatus.slug as OfferStatusEnum,
          },
        },
      },
      onError() {
        show({ updatedSeverity: 'error', message: translation.updateFail })
      },
      onCompleted() {
        show({
          updatedSeverity: 'success',
          message: translation.updateSuccess,
        })
        setStatus(newStatus)
      },
    })
  }

  const handleStatusChange = (newStatus: ExtendedStatus) => {
    if (newStatus !== status) {
      handleUpdateStatus(newStatus)
    }
  }

  const handleSave = async (offer: Offer) => {
    try {
      const logo =
        offer.hasChangedLogo && offer.logoState
          ? await uploadImageService(offer.logoState)
          : undefined
      const picture =
        offer.hasChangedPicture && offer.pictureState
          ? await uploadImageService(offer.pictureState)
          : undefined

      const backgroundColor = offer.backgroundColor.toUpperCase()
      const labelColor = offer.labelColor.toUpperCase()
      const textColor = offer.textColor.toUpperCase()

      const includesHash = (value: string) => value.includes('#')
      const response = await updateOffer({
        variables: {
          input: {
            where: {
              id: offerId,
            },
            data: {
              backgroundColor: includesHash(backgroundColor)
                ? backgroundColor
                : `#${backgroundColor}`,
              description: offer.description,
              discount: offer.discount,
              from: offer.from,
              label: offer.label,
              labelColor: includesHash(labelColor)
                ? labelColor
                : `#${labelColor}`,
              logo: logo && logo.data,
              pictures: picture && picture.data,
              textColor: includesHash(textColor) ? textColor : `#${textColor}`,
              to: offer.to,
              priority: offer.priority,
              status: offer.status.slug as OfferStatusEnum,
            },
          },
        },
      })

      if (response.errors) return false
      return true
    } catch {
      show({
        updatedSeverity: 'error',
        message: notificationText.updateFail,
      })
      return false
    }
  }

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })

      setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs])

  const loading = offerLoading || statusLoading

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return (
    offerData &&
    status && (
      <Layout>
        <DetailHeader
          editable
          title={offerData.label}
          backButtonText={text.general.backButton}
          status={offerData.status}
          handleStatusChange={handleStatusChange}
          statusList={statusList}
          loading={submitLoading}
        />
        <DetailSubHeader
          component={
            <Typography
              variant="body2"
              color={colors.orange}
              marginLeft="0.5rem"
            >
              {'ID: '} <CopyToClipboard textToCopy={`${offerId}`} />
            </Typography>
          }
        />

        <DetailNavTab
          tab={tab}
          handleTabChange={handleTabChange}
          items={initialItems}
        />
        <ContentContainer>
          {loading ? (
            <LoadingAnimation showAnimation={loading} />
          ) : (
            <>
              <TabPanel value={tab} index={0}>
                <GeneralInformation
                  data={offerData}
                  submitLoading={submitLoading}
                  handleSave={handleSave}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <VehicleSet
                  data={offerData}
                  submitLoading={submitLoading}
                  handleSave={handleSave}
                />
              </TabPanel>
            </>
          )}
        </ContentContainer>
      </Layout>
    )
  )
}

export default OfferDetailPage
