import React from 'react'
import { Box } from 'library'

import { UserRoles } from 'models/role'

import ModuleCard from './ModuleCard'

export type PointerType = {
  id: number
  pointer: string
}

export type ModuleInfoType = {
  id: number
  name: string
  icon: JSX.Element
  subtitle: string
  pointers: PointerType[]
  route: string
  allowedRoles: UserRoles[]
  hideInProduction?: boolean
}

export type GeneralViewProps = {
  modules: ModuleInfoType[]
}

export const GeneralViewContainer = ({ modules }: GeneralViewProps) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      gap="24px 21px"
      flexWrap="wrap"
    >
      {modules.map((module) => (
        <ModuleCard key={module.id} moduleInfo={module} />
      ))}
    </Box>
  )
}
