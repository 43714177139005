import React from 'react'
import { Typography } from '@mui/material'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Role } from 'models/role'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledContainer, StyledContent, StyledIcon, StyledList } from './style'

type RolesInfoProps = {
  roles: Role[]
}

const RolesInfo = ({ roles }: RolesInfoProps) => {
  const { text } = useTranslation(textFiles.ACCOUNT)
  const {
    settings: { rolesInfo: translation },
  } = text
  return (
    <StyledContainer>
      <StyledContent>
        <StyledIcon color="primary" fontSize="large" />
        <Typography
          color={colors.gray}
          fontWeight={FONT_WEIGHT.REGULAR}
          variant="body1"
        >
          {translation.currentRolesText}
        </Typography>
        {roles.map((role) => (
          <React.Fragment key={role.slug}>
            <Typography
              variant="h6"
              sx={{
                textTransform: 'capitalize',
                fontWeight: FONT_WEIGHT.BOLD,
                color: colors.orange,
              }}
            >
              {role.name}
            </Typography>
            <StyledList>
              <Typography
                component="li"
                fontWeight={FONT_WEIGHT.MEDIUM}
                variant="body1"
              >
                {role.description}
              </Typography>
            </StyledList>
          </React.Fragment>
        ))}
      </StyledContent>
    </StyledContainer>
  )
}

export default RolesInfo
