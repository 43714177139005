import React, { useState } from 'react'
import { useFormik } from 'formik'
import { ButtonNavigator, ErrorMessage } from 'library'
import * as yup from 'yup'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { State } from 'models/stateCreation'
import { PublicationStatus } from 'models/status'
import { StateCreationProps } from 'utils/State/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const StateInfo = ({
  stateData,
  handleContinue,
  updateStateData,
}: StateCreationProps) => {
  const { text } = useTranslation(textFiles.STATE_CREATION)

  const {
    information: {
      name,
      nameDescription,
      newYork,
      fieldRequired,
      isPublished,
      isPublishedDescription,
    },
  } = text

  const { stateInformation } = stateData

  const initialStateInformation: State = {
    ...stateInformation,
    name: stateInformation.name || '',
    status: stateInformation.status || PublicationStatus.PUBLISHED,
  }

  const [stateStatus, setStateStatus] = useState<boolean>(
    stateInformation.status === PublicationStatus.PUBLISHED
  )
  const validationSchema = yup.object({
    name: yup.string().required(fieldRequired),
  })

  const formik = useFormik<Partial<State>>({
    initialValues: {
      name: stateInformation.name,
    },
    validationSchema,
    onSubmit: () => {
      if (formik.values.name !== undefined) {
        const customStateInfo = {
          stateInformation: {
            ...initialStateInformation,
            name: formik.values.name,
            status: stateStatus
              ? PublicationStatus.PUBLISHED
              : PublicationStatus.UNPUBLISHED,
          },
        }

        updateStateData(customStateInfo)
      }

      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm
        onSubmit={formik.handleSubmit}
        sx={{
          '> div': {
            columnGap: '3rem',
          },
        }}
      >
        <div>
          <StyledTextFieldContainer title={name} description={nameDescription}>
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={newYork}
              error={formik.touched.name && Boolean(formik.errors.name)}
              inputProps={{ maxLength: 40 }}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>

          <ToggleContainer
            title={isPublished}
            description={isPublishedDescription}
            status={stateStatus}
            handleStatus={setStateStatus}
            testId="published"
          />
        </div>
      </StyledForm>
      <ButtonNavigator
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default StateInfo
