import { styled } from '@mui/material'
import { Box, colors, Container, FONT_WEIGHT } from 'library'

import Link from 'components/Common/Link'

import { BREAK_POINTS } from 'styles/breakpoints'

export const ImgContainer = styled(Container)({
  height: '100%',
  width: '100%',
  padding: '1rem 0 0 !important',
})

export const VehicleBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '35% 35% 25%',
  columnGap: '30px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 45%',
  },
})

export const StyledButton = styled('label')({
  width: '36px',
  height: '37px',
  position: 'absolute',
  bottom: '5%',
  right: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    color: colors.gray,
  },
})

export const StyledLink = styled(Link)({
  color: colors.orange,
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  textDecoration: 'underline',
})

export const ContentBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

export const ParentBox = styled(Box)({
  padding: '0.5rem 0',
  borderBottom: `1px solid ${colors.border}`,
})
