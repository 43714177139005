import { HuePicker, SketchPicker } from 'react-color'
import { formControlClasses, styled } from '@mui/material'
import { Box, Container } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors, FONT_WEIGHT } from 'styles/theme'

export const FormContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  marginBottom: '20px',
  '> p': {
    fontSize: '14px',
  },
  [`.${formControlClasses.root}`]: {
    width: '325px',
  },
})

export const StyledSketchPicker = styled(SketchPicker)({
  width: 'fit-content !important',
  display: 'flex',
  height: '200px !important',
  boxShadow: 'none !important',
  borderRadius: 'unset !important',
  marginBottom: '20px',

  '> div:nth-child(1)': {
    // Color picker box
    width: '230px !important',
    borderRadius: '10px',
    paddingBottom: 'unset !important',
    '& > div div div:nth-of-type(2) div': {
      // Color slider circle
      width: '50px !important',
      height: '50px !important',
      transform: 'translate(-25px, -25px) !important',
      boxShadow: 'unset !important',
      border: `3px solid ${colors.commonWhite}`,
    },
  },
  '> div:nth-child(2)': {
    display: 'none !important',
  },
  '> div:nth-child(3)': {
    // RGBA Column
    flexDirection: 'column',
    width: '60px',
    marginLeft: '25px',
    paddingRight: '21px',

    '> div': {
      paddingLeft: '0px !important',
      borderRadius: '4px !important',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& label': {
        fontWeight: FONT_WEIGHT.BOLD,
        order: '-1',
      },
    },

    '> div input': {
      border: `1px solid ${colors.border} !important`,
      boxShadow: 'unset !important',
      borderRadius: '4px',
      textAlign: 'center',
    },
    '> div:nth-child(1)': {
      order: 2,
    },
  },
  '> div:nth-child(4)': {
    display: 'none !important',
  },
})

export const StyledHuePicker = styled(HuePicker)({
  backgroundColor: 'blue',
  marginLeft: '10px',
  borderRadius: '4px',

  '> div': {
    borderRadius: '4px',
  },
})

export const ColorPickerContainer = styled(Box)({
  display: 'flex',
  marginBottom: '20px',
  border: `1px solid ${colors.border}`,
  backgroundColor: colors.commonWhite,
  boxSizing: 'border-box',
  padding: '15px !important',
  borderRadius: '10px',
  width: '70% !important',
  minWidth: 600,
  [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
    width: '90% !important',
  },
})
