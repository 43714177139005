import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Checkbox,
  ClickAwayListener,
  CSSObject,
  ListItemText,
  MenuList,
  PopperPlacementType,
  Typography,
} from '@mui/material'
import MuiPopper from '@mui/material/Popper'
import { Button } from 'library'

import FilterButton from 'components/Common/FilterButton'

import { inspectionTextFile } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { SelectItem } from 'models/select'

import { colors } from 'styles/theme'

import { StyledContainer, StyledMenuItem } from './style'

export type FieldFilterProps = {
  handleResetDefault: () => void
  handleSelectItem: (item: string) => void
  items: SelectItem[]
  selectedValues: string[]
  name?: string
  icon?: JSX.Element
  placement?: PopperPlacementType
  sx?: CSSObject
  labelSx?: CSSObject
  file?: string
}

const FieldFilter = ({
  handleResetDefault,
  handleSelectItem,
  items,
  selectedValues,
  name,
  icon,
  sx,
  labelSx,
  placement = 'bottom-end',
  file = inspectionTextFile.INSPECTION_LISTING,
}: FieldFilterProps) => {
  const { text } = useTranslation(file)
  const { fieldFilter: translation } = text
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <FilterButton
        id="fields-filter-popper"
        onClick={handleClick}
        text={name || translation.openButton}
        icon={icon || <KeyboardArrowDownIcon />}
        sx={sx}
        labelSx={labelSx}
        testId="fields-button"
      />
      <MuiPopper
        id="fields-filter-popper"
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        style={{ zIndex: 2 }}
      >
        <Box marginTop="1.5rem">
          <ClickAwayListener onClickAway={handleClose}>
            <StyledContainer>
              <Typography color={colors.black} variant="body2">
                {translation.title}
              </Typography>
              <MenuList
                sx={{ height: 'auto', maxHeight: '325px', overflow: 'auto' }}
              >
                {items.map((item) => (
                  <StyledMenuItem
                    key={item.value}
                    value={item.value}
                    onClick={() => {
                      handleSelectItem(item.value as string)
                    }}
                  >
                    <Checkbox
                      checked={
                        selectedValues.indexOf(item.value as string) > -1
                      }
                      sx={{ color: colors.gray, padding: '0px' }}
                    />
                    <ListItemText color={colors.gray} primary={item.label} />
                  </StyledMenuItem>
                ))}
              </MenuList>
              <Button
                disableRipple
                onClick={handleResetDefault}
                variant="text"
                size="small"
                sx={{
                  textDecoration: 'underline',
                  ':hover': { textDecoration: 'underline' },
                }}
              >
                {translation.resetButton}
              </Button>
            </StyledContainer>
          </ClickAwayListener>
        </Box>
      </MuiPopper>
    </>
  )
}
export default FieldFilter
