import { InspectionColumnField } from 'constants/inspection'
import { SelectItem } from 'models/select'
import { FieldSelectTextType } from 'models/text/InspectionListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: InspectionColumnField.ID,
    },
    {
      label: text.inspectionDate,
      value: InspectionColumnField.DATE,
    },
    {
      label: text.provider,
      value: InspectionColumnField.PROVIDER,
    },
    {
      label: text.providerTelephoneNumber,
      value: InspectionColumnField.PHONE,
    },
    {
      label: text.createdAt,
      value: InspectionColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: InspectionColumnField.STATUS,
    },
    {
      label: text.actions,
      value: InspectionColumnField.ACTIONS,
    },
    {
      label: text.carName,
      value: InspectionColumnField.CAR_NAME,
    },
    {
      label: text.inspectionSource,
      value: InspectionColumnField.SOURCE,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    date: text.inspectionDate,
    providerName: text.provider,
    providerType: text.providerTelephoneNumber,
    provider: text.provider,
    providerTelephoneNumber: text.providerTelephoneNumber,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    carName: text.carName,
    source: text.inspectionSource,
  }
}

export const defaultFields = [
  InspectionColumnField.ID,
  InspectionColumnField.DATE,
  InspectionColumnField.PROVIDER,
  InspectionColumnField.PHONE,
  InspectionColumnField.CREATED_AT,
  InspectionColumnField.STATUS,
  InspectionColumnField.SOURCE,
  InspectionColumnField.ACTIONS,
]
