import InspectionHours from 'components/Hours/InspectionHours'
import TestDriveHours from 'components/Hours/TestDriveHours'

import { HourCreationModel } from 'models/hours'

export type HourCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  hoursData: HourCreationModel
}

export const getContinentalTime = (
  hour: number,
  minute: number,
  am: boolean
) => {
  const minuteSide = minute < 10 ? `0${minute}` : `${minute}`
  if (!am) {
    const hourSide = hour === 12 ? 12 : hour + 12
    return parseInt(`${hourSide}${minuteSide}`, 10)
  }
  if (hour === 12) {
    return parseInt(`0${minuteSide}`, 10)
  }
  return parseInt(`${hour}${minuteSide}`, 10)
}

export const INITIAL_HOURS_CREATION_STEPS: Array<React.FC<HourCreationProps>> =
  [InspectionHours, TestDriveHours]
