import { gql } from '@apollo/client'

export const CREATE_DEALER = gql`
  mutation createDealer($input: CreateDealerInput!) {
    data: createDealer(input: $input) {
      id
    }
  }
`

export const CREATE_CURBOSPOT_DEALER = gql`
  mutation createCurboSpotDealer($input: CreateCurboSpotDealerInput!) {
    data: createCurboSpotDealer(input: $input) {
      id
    }
  }
`
