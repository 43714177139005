import { buttonBaseClasses, styled, Tab, tabClasses, Tabs } from '@mui/material'
import { colors } from 'library/styles/theme'

export const StyledTab = styled(Tab)({
  [`&.${tabClasses.root}`]: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    width: 'auto',
    padding: '0.25rem 1rem',
    marginRight: '0.5rem',
  },
  [`.${buttonBaseClasses.root}`]: {
    border: '1px solid transparent',
    borderRadius: '4px 4px 0 0',
  },
  [`&.${tabClasses.root}.${tabClasses.selected}`]: {
    backgroundColor: colors.commonWhite,
    border: `1px solid ${colors.commonWhite}`,
    borderRadius: '5px 5px 0 0',
  },
})

export const TabContainer = styled(Tabs)({
  borderRadius: '2px',
  marginTop: '1rem',
})
