import { inputBaseClasses, selectClasses, styled } from '@mui/material'
import { Button, TextField } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const MeridiemButton = styled(Button)({
  fontWeight: FONT_WEIGHT.EXTRA_BOLD,
  fontSize: '18px !important',
  width: '55px',
  height: '36px',
})

export const StyledTextField = styled(TextField)({
  width: '100%',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.BOLD,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '14px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.BOLD,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.BOLD,
  },

  input: {
    height: '38px',
    padding: '0',
    textAlign: 'left',
    fontSize: '14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const AddSection = styled('section')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 8,
})

export const StyledButton = styled(Button)({
  fontSize: `14px !important`,
  height: `38px !important`,
  fontWeight: FONT_WEIGHT.MEDIUM,
})
