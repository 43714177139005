import React, { useEffect, useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Grid } from '@mui/material'
import { formatDateLocalizedTime, Title } from 'library'

import LanguageSettings from 'components/Account/LanguageSettings'
import PersonalInfo from 'components/Account/PersonalInfo'
import RolesInfo from 'components/Account/RolesInfo'
import SecuritySettings from 'components/Account/SecuritySettings'

import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { Role } from 'models/role'

type PersonalInfoData = {
  picture: string
  name: string
  lastName: string
  email: string
  roles: Role[]
}

const Settings = () => {
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoData>({
    name: '',
    lastName: '',
    picture: '',
    email: '',
    roles: [],
  })
  const { user } = useUser()
  const [selectedLanguage] = useLocale()
  const currentDate = formatDateLocalizedTime(
    new Date(),
    'PPPP',
    selectedLanguage.code
  )
  const { text } = useTranslation(textFiles.ACCOUNT)
  const {
    settings: { general: translation },
  } = text

  useEffect(() => {
    if (user) {
      setPersonalInfo({
        name: user.name,
        email: user.email,
        lastName: user.lastName,
        roles: user.roles,
        picture: user.profilePicture,
      })
    }
  }, [user])

  return (
    <>
      <Title
        icon={<DateRangeIcon />}
        subtitle={`${translation.welcomeText} ${
          personalInfo?.name.split(' ')[0] || ''
        }! ${translation.dateText} ${currentDate}`}
        title="Dashboard"
      />
      <Grid spacing={2} container marginTop="2rem">
        <Grid item xl={4} xs={5}>
          <PersonalInfo loading={!user} {...personalInfo} />
        </Grid>
        <Grid item xl={8} xs={7}>
          <Grid container spacing={2}>
            <Grid item xl={6} xs={6}>
              <SecuritySettings />
            </Grid>
            <Grid item xl={6} xs={6}>
              <LanguageSettings />
            </Grid>
            <Grid item xl={12} xs={12}>
              <RolesInfo roles={personalInfo ? personalInfo.roles : []} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Settings
