import { Button, styled, Typography } from '@mui/material'
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledCheckbox = styled(Checkbox)({
  [checkboxClasses.disabled]: {
    color: `${colors.orange} !important`,
  },
})

export const StyledText = styled(Typography)({
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.black,
})

export const StyledButton = styled(Button)({
  height: '30px !important',
  fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
})
