import React from 'react'
import { SxProps, Typography } from '@mui/material'
import { NoDataSVG } from 'library/assets/NoDataSvg'
import { Box } from 'library/components/Common/Box'
import { colors, FONT_WEIGHT } from 'library/styles/theme'

export type NoDataBoxProps = {
  /**
   * Height of the container
   */
  height?: number | string

  /**
   * Width of the container
   */
  width?: number | string

  /**
   * Style for the box
   */
  boxSx?: SxProps
  /**
   * Displayed text's first part
   */
  firstPart?: string
  /**
   * Displayed text's second part
   */
  secondPart?: string
}

/**
 * Component that displays whenever no data is shown in a dataset
 */
export const NoDataBox = ({
  height = 162,
  width = 162,
  boxSx,
  firstPart,
  secondPart,
}: NoDataBoxProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      sx={boxSx}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height={height}
        width={width}
        sx={{
          backgroundColor: colors.background,
          border: `2px solid ${colors.border}`,
          borderRadius: '8px',
        }}
      >
        <NoDataSVG />
        <Typography
          color={colors.altBlack2}
          fontWeight={FONT_WEIGHT.BOLD}
          marginTop="7px"
          variant="body2"
          textAlign="center"
        >
          {firstPart || 'Not enough data'}
        </Typography>
        <Typography fontWeight={FONT_WEIGHT.MEDIUM} variant="subtitle1">
          {secondPart || 'to display'}
        </Typography>
      </Box>
    </Box>
  )
}
