import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  BodyContainerWithHeader,
  createSimpleStepperItems,
  LoadingAnimation,
  useNotification,
  validateGraphQLErrorCode,
} from 'library'
import { uploadImageService } from 'services/uploadImage'

import { BackContainer } from 'components/Common/BackContainer'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import {
  arrayOfNamedDays,
  emptyInputWeekCalendar,
  initialOutOfSpotState,
} from 'constants/Operation/outOfSpot'
import { CURBO_SPOT_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  CityOption,
  CreateCurboSpotWeekCalendarInput,
  CurboSpotInputType,
  SpotCreationAction,
  SpotCreationModel,
  SpotInformation,
  SpotLocation,
  SpotSchedule,
} from 'models/services/operations/curboSpot'
import { PublicationStatus } from 'models/status'
import curboSpotCreationReducer from 'reducers/Operation/curboSpotCreationReducer'
import { CURBO_SPOT_CREATION_STEPS as steps } from 'utils/CurboSpot/creation'

import { GET_CITIES } from 'graphQL/Common/City/queries'
import { CREATE_CURBO_SPOT } from 'graphQL/Operations/CurboSpot/Creation/mutation'
import {
  CREATE_INSPECTIONS_WEEK_CALENDAR,
  CREATE_TEST_DRIVE_WEEK_CALENDAR,
} from 'graphQL/Operations/CurboSpot/Detail/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialSpotInformation: SpotInformation = {
  status: PublicationStatus.PUBLISHED,
  city: '',
  state: '',
  picture: '',
  pictureFile: null,
  name: '',
  telephoneNumber: '',
}

const initialSpotLocation: SpotLocation = {
  address: undefined,
}

const initialSpotSchedule: SpotSchedule = {
  supportsTestDrive: false,
  supportsInspection: false,
  supportsOffSiteTestDrive: false,
  supportsOffSiteInspection: false,
  schedules: {
    testDrive: initialOutOfSpotState.testDrive,
    inspections: initialOutOfSpotState.inspections,
  },
}

const initialData: SpotCreationModel = {
  spotInformation: initialSpotInformation,
  spotLocation: initialSpotLocation,
  spotSchedule: initialSpotSchedule,
}

const SpotCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [spotData, dispatch] = useReducer(curboSpotCreationReducer, initialData)
  const [cities, setCities] = useState<CityOption[]>([])
  const history = useHistory()

  const { text } = useTranslation(textFiles.CURBO_SPOT_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const stepperItems = createSimpleStepperItems(text.stepper)

  const { loading: citiesLoading } = useQuery<GenericData<CityOption[]>>(
    GET_CITIES,
    {
      onCompleted(response) {
        setCities(response.data)
      },
    }
  )

  const [createTestDriveWeekCalendar, { loading: createTestDriveLoading }] =
    useMutation<
      GenericData<string>,
      GenericInputVariable<CreateCurboSpotWeekCalendarInput>
    >(CREATE_TEST_DRIVE_WEEK_CALENDAR, {
      onCompleted() {
        show({
          updatedSeverity: 'success',
        })
      },
      onError() {
        show({
          updatedSeverity: 'error',
        })
      },
    })

  const [createInspectionWeekCalendar, { loading: createInspectionLoading }] =
    useMutation<
      GenericData<string>,
      GenericInputVariable<CreateCurboSpotWeekCalendarInput>
    >(CREATE_INSPECTIONS_WEEK_CALENDAR, {
      onCompleted() {
        show({
          updatedSeverity: 'success',
        })
      },
      onError() {
        show({
          updatedSeverity: 'error',
        })
      },
    })

  const createCalendars = useCallback(
    async (id: string) => {
      const {
        spotSchedule: { schedules },
      } = spotData
      const { inspections, testDrive } = schedules

      const testDriveSchedule = { ...emptyInputWeekCalendar }
      const inspectionsSchedule = { ...emptyInputWeekCalendar }

      arrayOfNamedDays.forEach((day) => {
        inspectionsSchedule[day] = inspections[day].map((h) => h.value)
        testDriveSchedule[day] = testDrive[day].map((h) => h.value)
      })
      try {
        const responseI = await createInspectionWeekCalendar({
          variables: {
            input: {
              curboSpot: id,
              ...inspectionsSchedule,
            },
          },
        })
        const responseT = await createTestDriveWeekCalendar({
          variables: {
            input: {
              curboSpot: id,
              ...testDriveSchedule,
            },
          },
        })
        if (responseT.data && responseI.data) return true
        return false
      } catch (error) {
        console.error(error)
        return false
      }
    },
    [createInspectionWeekCalendar, createTestDriveWeekCalendar, spotData]
  )

  const [createSpot, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CurboSpotInputType>
  >(CREATE_CURBO_SPOT, {
    onCompleted(response) {
      if (response.data.id) {
        const manageCalendars = async (id: string) => {
          const res = await createCalendars(id)
          if (res) history.push(CURBO_SPOT_SUB_ROUTES.CURBO_SPOT_LISTING)
        }
        manageCalendars(response.data.id)
      }
      show({
        updatedSeverity: 'success',
      })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const updateSpotData = (action: SpotCreationAction) => {
    dispatch(action)
  }

  const handleSubmit = useCallback(async () => {
    const { spotInformation, spotLocation, spotSchedule } = spotData

    const { city, status, telephoneNumber, pictureFile, name } = spotInformation
    const { address } = spotLocation
    const {
      supportsTestDrive,
      supportsInspection,
      supportsOffSiteTestDrive,
      supportsOffSiteInspection,
    } = spotSchedule

    if (address) {
      try {
        const pictureResponse = pictureFile
          ? await uploadImageService(pictureFile)
          : undefined
        createSpot({
          variables: {
            input: {
              address: address.address,
              city,
              latitude: address.lat,
              longitude: address.lng,
              name,
              telephoneNumber,
              mainPicture: pictureResponse ? pictureResponse.data : undefined,
              status,
              supportsTestDrive,
              supportsInspection,
              supportsOffSiteTestDrive,
              supportsOffSiteInspection,
            },
          },
        })
      } catch (e) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.uploadError,
        })
      }
    }
  }, [createSpot, spotData, generalText, show])

  const isLoading =
    submitLoading || createInspectionLoading || createTestDriveLoading

  if (citiesLoading) return <LoadingAnimation showAnimation={citiesLoading} />

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
        currentStep={currentStep}
        stepperItems={stepperItems}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          updateSpotData,
          handleSubmit,
          spotData,
          submitLoading: isLoading,
          cities,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default SpotCreationPage
