import React from 'react'
import { Divider } from '@mui/material'
import { Title } from 'library/components/Common/Title'
import { Stepper, StepperModel } from 'library/components/General/Stepper'
import { colors } from 'library/styles/theme'

import { StyledBody } from './style'

export type ContainerWithHeaderProps = {
  title: string
  subtitle: string
  children?: React.ReactNode
  currentStep?: number
  currentSecondaryStep?: number
  secondaryStepAmount?: number
  stepperItems?: StepperModel[]
  additionalContainerStyles?: React.CSSProperties
}

export const BodyContainerWithHeader = ({
  title,
  subtitle,
  children,
  currentStep,
  currentSecondaryStep,
  secondaryStepAmount,
  stepperItems,
  additionalContainerStyles,
}: ContainerWithHeaderProps) => {
  return (
    <StyledBody sx={{ ...additionalContainerStyles }}>
      <Title
        title={title}
        containerSx={{
          backgroundColor: colors.commonWhite,
        }}
        subtitle={subtitle}
        disableDivider
      />
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
          marginTop: '30px',
          width: '100%',
        }}
      />
      {stepperItems ? (
        <Stepper
          currentStep={currentStep || 0}
          stepperItems={stepperItems || []}
          currentSecondaryStep={currentSecondaryStep}
          secondaryStepAmount={secondaryStepAmount}
          topSx={{
            justifyContent: 'flex-start',
            alignItems: 'end',
          }}
        />
      ) : null}
      {children}
    </StyledBody>
  )
}
