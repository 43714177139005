import React from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LabelIcon from '@mui/icons-material/Label'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import NotesIcon from '@mui/icons-material/Notes'
import { Typography } from '@mui/material'
import {
  Avatar,
  ColorBox,
  Container,
  getDateRangeString,
  SummaryItem,
} from 'library'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { DiscountTypes, OfferTypes, PriorityTypes } from 'models/offer'
import { OfferCreationProps } from 'utils/Marketing/Offer/creation'

import { colors } from 'styles/theme'

import { OfferStripe } from '../OfferData/OfferStripe'

import { StyledElement, StyledItem } from './style'

const Summary = ({
  offerData,
  handleBack,
  brands,
  models,
  handleSubmit,
}: OfferCreationProps) => {
  const { offerInformation } = offerData
  const { text } = useTranslation(textFiles.OFFER_CREATION)

  const {
    summary: {
      title,
      description,
      true: trueText,
      false: falseText,
      modelsSelected,
      brandsSelected,
      carsSelected,
      priceSelected,
      yearsSelected,
    },
  } = text

  const selectionOptions = {
    [OfferTypes.MODEL]: modelsSelected,
    [OfferTypes.BRAND]: brandsSelected,
    [OfferTypes.CAR]: carsSelected,
    [OfferTypes.PRICE]: priceSelected,
    [OfferTypes.YEAR]: yearsSelected,
  }

  return (
    <Skeleton
      previousFunction={handleBack}
      title={title}
      description={description}
      nextFunction={handleSubmit}
      gridContainerStyles={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '20px',
      }}
      containerStyles={{ height: '100%' }}
      formStyles={{
        height: '100%',
      }}
      bodyStyles={{
        height: '100%',
        overflowY: 'inherit',
      }}
    >
      <SummaryItem
        title={text.information.name}
        text={offerInformation.label}
        icon={<LabelIcon />}
      />
      <SummaryItem
        title={text.information.description}
        text={offerInformation.description}
        icon={<NotesIcon />}
      />
      <SummaryItem
        title={text.information.discountType}
        text={offerInformation.discountType}
        icon={<MoneyOffIcon />}
      />
      <SummaryItem
        title={text.information.discount}
        text={offerInformation.discount}
        icon={<MoneyOffIcon />}
      />
      <SummaryItem
        title={text.information.backgroundColor}
        text={offerInformation.backgroundColor}
        elementStyle={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '10px',
        }}
        icon={
          <ColorBox
            hexCode={offerInformation.backgroundColor}
            styles={{ marginRight: '9px' }}
          />
        }
      />
      <SummaryItem
        title={text.information.textColor}
        text={offerInformation.textColor}
        elementStyle={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '10px',
        }}
        icon={
          <ColorBox
            hexCode={offerInformation.textColor}
            styles={{ marginRight: '9px' }}
          />
        }
      />

      <SummaryItem
        title={text.information.labelColor}
        text={offerInformation.labelColor}
        elementStyle={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '10px',
        }}
        icon={
          <ColorBox
            hexCode={offerInformation.labelColor}
            styles={{ marginRight: '9px' }}
          />
        }
      />
      {offerInformation.from && offerInformation.to ? (
        <SummaryItem
          title={text.information.range}
          text={getDateRangeString({
            fromDate: offerInformation.from,
            toDate: offerInformation.to,
          })}
          icon={<CalendarMonthIcon />}
        />
      ) : null}

      <SummaryItem
        title={text.information.type}
        text={text.information.typeOptions[offerInformation.type]}
        icon={<LocalOfferIcon />}
      />

      <SummaryItem
        title={text.information.priorized}
        text={
          offerInformation.priority === PriorityTypes.PRIMARY
            ? trueText
            : falseText
        }
        icon={<CheckCircleIcon />}
      />

      <SummaryItem
        title={text.information.isActive}
        text={offerInformation.isActive ? trueText : falseText}
        icon={offerInformation.isActive ? <CheckCircleIcon /> : <CancelIcon />}
      />

      {offerInformation.logo ? (
        <StyledItem title={text.information.logo} width={315}>
          <StyledElement
            sx={{
              padding: '0px',
              marginTop: '0.6rem',
            }}
          >
            <Avatar
              image={offerInformation.logo}
              height={104}
              width={121}
              variant="rounded"
            />
          </StyledElement>
        </StyledItem>
      ) : null}

      <StyledItem title={text.information.picture} width={315}>
        <StyledElement
          sx={{
            padding: '0px',
            marginTop: '0.6rem',
          }}
        >
          <Avatar
            image={offerInformation.picture}
            height={104}
            width={121}
            variant="rounded"
          />
        </StyledElement>
      </StyledItem>

      <Container
        title={selectionOptions[offerInformation.type]}
        sx={{
          padding: '0',
          '> div': {
            marginTop: '0.5',
          },
        }}
      >
        {offerInformation.type === OfferTypes.PRICE ? (
          <>
            <Typography variant="body2">
              <span style={{ color: colors.orange, marginRight: '10px' }}>
                ●
              </span>
              {offerInformation.minPrice?.toLocaleString()} US$ -{' '}
              {offerInformation.maxPrice?.toLocaleString()} US$
            </Typography>

            <Typography variant="body2">
              <span style={{ color: colors.orange, marginRight: '10px' }}>
                ●
              </span>
              {`${offerInformation.carsInInterval} ${text.information.vehiclesInRange}`}
            </Typography>
          </>
        ) : null}

        {offerInformation.type === OfferTypes.YEAR ? (
          <>
            <Typography variant="body2">
              <span style={{ color: colors.orange, marginRight: '10px' }}>
                ●
              </span>
              {offerInformation.minYear} - {offerInformation.maxYear}
            </Typography>

            <Typography variant="body2">
              <span style={{ color: colors.orange, marginRight: '10px' }}>
                ●
              </span>
              {`${offerInformation.carsInInterval} ${text.information.vehiclesInRange}`}
            </Typography>
          </>
        ) : null}

        {offerInformation.brands.length > 0 &&
        offerInformation.type === OfferTypes.BRAND
          ? offerInformation.brands.map((brandId, idx) => {
              return (
                <OfferStripe
                  key={brandId}
                  name={brands.find((br) => br.id === brandId)?.name || ''}
                  id={brandId}
                  type={OfferTypes.BRAND}
                  index={idx}
                />
              )
            })
          : null}

        {offerInformation.models.length > 0 &&
        offerInformation.type === OfferTypes.MODEL
          ? offerInformation.models.map((modelId, idx) => {
              return (
                <OfferStripe
                  key={modelId}
                  name={models.find((mod) => mod.id === modelId)?.name || ''}
                  id={modelId}
                  type={OfferTypes.MODEL}
                  index={idx}
                />
              )
            })
          : null}

        {offerInformation.cars.length > 0 &&
        offerInformation.type === OfferTypes.CAR
          ? offerInformation.cars.map((car, idx) => {
              return (
                <OfferStripe
                  key={car.id}
                  name={car.title}
                  id={car.id}
                  individualDiscount={
                    offerInformation.discountType === DiscountTypes.INDIVIDUAL
                  }
                  discountValue={offerInformation.carDiscounts[idx] || 0}
                  disableEditing
                  index={idx}
                />
              )
            })
          : null}
      </Container>
    </Skeleton>
  )
}

export default Summary
