import CityInfo from 'components/Operations/City/Creation/CityInfo'
import CityLocation from 'components/Operations/City/Creation/CityLocation'
import CitySummary from 'components/Operations/City/Creation/Summary'

import { CityCreationModel } from 'models/cityCreation'
import { Option } from 'models/select'

export type CityCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  cityData: CityCreationModel
  updateCityData: (newCity: CityCreationModel) => void
  handleSubmit: () => void
  submitLoading: boolean
  states: Option[]
}

export const CITY_CREATION_STEPS: Array<React.FC<CityCreationProps>> = [
  CityInfo,
  CityLocation,
  CitySummary,
]
