import React, { useState } from 'react'
import { ButtonNavigator } from 'library'

import LocationSection from 'components/Common/Location'
import {
  ContainerStyles,
  StyledBox,
  StyledContainer,
} from 'components/Operations/Dealer/Creation/DealerLocation/style'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { StateCreationProps } from 'utils/State/creation'

const StateLocation = ({
  stateData,
  handleContinue,
  handleBack,
  updateStateData,
}: StateCreationProps) => {
  const { stateInformation } = stateData

  const [error, setError] = useState<boolean>(false)

  const [address, setAddress] = useState<Address | undefined>(
    stateInformation.address
  )
  const { text } = useTranslation(textFiles.STATE_CREATION)

  const { location: translation } = text

  const handleSubmit = () => {
    if (address === undefined) {
      setError(true)
    } else {
      updateStateData({
        stateInformation: {
          ...stateInformation,
          address,
        },
      })
      handleContinue()
    }
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    setError(false)
  }

  return (
    <StyledBox>
      <StyledContainer>
        <LocationSection
          translation={translation}
          address={address}
          showError={error}
          handleAddressChange={handleAddressChange}
          containerStyling={ContainerStyles}
          hasCoordinatePicker
          curboSpots={[]}
          circleCenter={
            address ? { lat: address.lat, lng: address.lng } : undefined
          }
          mapHeight="90%"
        />
      </StyledContainer>
      <ButtonNavigator
        previousFunction={handleBack}
        nextFunction={handleSubmit}
        confirmButtonType="button"
      />
    </StyledBox>
  )
}

export default StateLocation
