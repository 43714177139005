import EntityInfo from 'components/CarSettings/BlackList/Creation/EntityInfo'
import Summary from 'components/CarSettings/BlackList/Creation/Summary'

import {
  EntityCreationAction,
  EntityCreationModel,
} from 'models/blackListCreation'

export type EntityCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  entityData: EntityCreationModel
  submitLoading: boolean
  updateEntityData: (action: EntityCreationAction) => void
}

export const INITIAL_ENTITY_CREATION_STEPS: Array<
  React.FC<EntityCreationProps>
> = [EntityInfo, Summary]
