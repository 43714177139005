import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import CallIcon from '@mui/icons-material/Call'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MapIcon from '@mui/icons-material/Map'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import { Typography } from '@mui/material'
import { Box, SummaryItem } from 'library'

import AvatarItem from 'components/CarSettings/Common/Creation/Summary/AvatarItem'
import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import Map from 'components/General/Map'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { isScheduleEmpty, SpotCreationProps } from 'utils/CurboSpot/creation'

import DayCollapse from './DayCollapse'

export const elementStyle: React.CSSProperties = {
  alignItems: 'flex-start',
  marginTop: '0rem',
}

const Summary = ({
  spotData,
  handleBack,
  submitLoading,
  handleSubmit,
  cities,
}: SpotCreationProps) => {
  const {
    spotInformation,
    spotLocation,
    spotSchedule: { schedules },
  } = spotData
  const { text } = useTranslation(textFiles.CURBO_SPOT_CREATION)
  const {
    information: { spotName, spotPhone, cityName, stateName, thumbnail },
    summary: {
      addressLabel,
      title,
      description,
      status,
      map,
      inspectionSchedule,
      testdriveSchedule,
      inspectionOsSchedule,
      testdriveOsSchedule,
    },
  } = text

  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [, setApiLoaded] = useState<boolean>(false)
  const [address, setAddress] = useState<Address | undefined>(
    spotLocation.address
  )

  const cityOption = cities.find((city) => city.value === spotInformation.city)
  const city = cityOption ? cityOption.name : spotInformation.city

  return (
    <Skeleton
      previousFunction={handleBack}
      title={title}
      description={description}
      nextFunction={handleSubmit}
      submitLoading={submitLoading}
      gridContainerStyles={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '20px',
      }}
      containerStyles={{ height: '100%' }}
      formStyles={{
        height: '100%',
      }}
      bodyStyles={{
        height: '100%',
        overflowY: 'inherit',
      }}
    >
      <SummaryItem
        title={spotName}
        text={spotInformation.name}
        icon={<DirectionsCarIcon />}
        width={200}
        elementStyle={elementStyle}
      />
      <SummaryItem
        title={stateName}
        text={spotInformation.state}
        icon={<MapIcon />}
        width={200}
        elementStyle={elementStyle}
      />
      {isScheduleEmpty(schedules.testDrive) ? (
        <DayCollapse title={testdriveSchedule} elements={schedules.testDrive} />
      ) : null}
      <SummaryItem
        title={spotPhone}
        text={
          <NumberFormat
            value={spotInformation.telephoneNumber}
            format="+57 (###) ### ####"
            isNumericString
            displayType="text"
          />
        }
        icon={<CallIcon />}
        width={200}
        elementStyle={elementStyle}
      />
      <SummaryItem
        title={cityName}
        text={city}
        icon={<MapOutlinedIcon />}
        width={200}
        elementStyle={elementStyle}
      />
      {isScheduleEmpty(schedules.inspections) ? (
        <DayCollapse
          title={inspectionOsSchedule}
          elements={schedules.inspections}
        />
      ) : null}
      <SummaryItem
        title={addressLabel}
        text={address ? address.address : ''}
        icon={<LocationOnIcon />}
        width={200}
        elementStyle={elementStyle}
      />
      {isScheduleEmpty(schedules.inspections) ? (
        <DayCollapse
          title={inspectionSchedule}
          elements={schedules.inspections}
        />
      ) : null}
      {isScheduleEmpty(schedules.testDrive) ? (
        <DayCollapse
          title={testdriveOsSchedule}
          elements={schedules.testDrive}
        />
      ) : null}
      <SummaryItem
        title={status}
        text={generalText.status[spotInformation.status]}
        icon={<CheckCircleIcon />}
        width={200}
        elementStyle={elementStyle}
      />
      {spotInformation.picture ? (
        <AvatarItem
          title={thumbnail}
          picture={spotInformation.picture}
          width={200}
          containerStyles={{
            gridColumnStart: 3,
            gridRowStart: 2,
            gridRowEnd: 4,
          }}
          imageHeight={145}
          imageWidth={145}
        />
      ) : null}
      <Box width="100%" height="353px" sx={{ marginY: '2rem' }}>
        <Typography variant="body1" marginBottom="12px">
          {map}
        </Typography>
        <Map
          disabled
          setIsLoaded={setApiLoaded}
          curboSpots={[]}
          address={address}
          setAddress={setAddress}
          circleCenter={
            address ? { lat: address.lat, lng: address.lng } : undefined
          }
        />
      </Box>
    </Skeleton>
  )
}

export default Summary
