import React, { useState } from 'react'
import { DirectionsCar, Map as MapIcon } from '@mui/icons-material'
import { Container } from 'library'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import { GridBox } from 'components/CarSettings/Trim/Detail/Settings/style'
import LocationSection from 'components/Common/Location'

// import HistoryLog from 'components/Common/HistoryLog'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { Address } from 'models/map'
import { StateDetailDataType } from 'models/services/operations/state'

import { StyledContainer } from './style'

type Props = {
  information: StateDetailDataType
  handleSave: (
    name: string | undefined,
    latitude: number | undefined,
    longitude: number | undefined
  ) => Promise<boolean>
  loading: boolean
}

const StateInformation = ({ information, handleSave, loading }: Props) => {
  const { id, name, latitude, longitude, curboSpotCount, dealerCount } =
    information

  const { text } = useTranslation(textFiles.STATE_DETAIL)
  const { brandInformation: translation } = text
  const { appointment } = translation

  const initialNameInformation = [
    {
      id: 1,
      icon: <MapIcon />,
      label: translation.stateLabel,
      name,
    },
    {
      id: 2,
      icon: <DirectionsCar />,
      label: translation.dealersLabel,
      name: dealerCount,
      disabled: true,
    },
    {
      id: 3,
      icon: <DirectionsCar />,
      label: translation.spotsLabel,
      name: curboSpotCount,
      disabled: true,
    },
  ]

  const initialAddress = {
    id: String(id),
    name,
    lat: latitude,
    lng: longitude,
    address: name,
  }
  const [error, setError] = useState<boolean>(false)
  const [info, setInfo] = useState<NameInformation[]>(initialNameInformation)
  const [address, setAddress] = useState<Address | undefined>(initialAddress)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const resetState = () => {
    setInfo(initialNameInformation)
    setAddress(initialAddress)
    setHasUnsavedChanges(false)
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    setHasUnsavedChanges(true)
  }

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setInfo(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    const nameInformation = info[0].name !== name ? info[0] : undefined

    if (address === undefined) {
      setError(true)
    } else {
      const isUpdateSuccesful = await handleSave(
        nameInformation && String(nameInformation.name),
        address?.lat,
        address?.lng
      )
      if (isUpdateSuccesful) {
        setHasUnsavedChanges(false)
      }
    }
  }

  return (
    <StyledContainer>
      <GridBox gridTemplateColumns="65% 35% !important">
        <Container
          title={translation.map}
          description={translation.mapDescription}
          paddingX="0px !important"
        >
          <LocationSection
            translation={appointment}
            showError={error}
            address={address}
            handleAddressChange={handleAddressChange}
            containerStyling={{
              width: '95%',
            }}
            mapHeight="300px"
            hasCoordinatePicker
            curboSpots={[]}
            circleCenter={
              address ? { lat: address.lat, lng: address.lng } : undefined
            }
          />
        </Container>
        <NameContainer
          title={translation.title}
          description={translation.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          handleSetInformation={handleChangeInformation}
          information={info}
          loading={loading}
        />
      </GridBox>
      <SaveChangesBlock
        handleSaveChanges={handleSaveChanges}
        resetState={resetState}
        submitLoading={loading}
        shouldRender={hasUnsavedChanges}
      />
      {/* <Box width="834px">
        <HistoryLog
          height="200px"
          dateFormat="time"
          hasPadding={!!(width && width <= BREAK_POINTS.XL)}
          sx={{ padding: '0px !important', paddingY: '1rem !important' }}
        />
      </Box> */}
    </StyledContainer>
  )
}

export default StateInformation
