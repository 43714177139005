import React, { FunctionComponent } from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import EmailIcon from '@mui/icons-material/Email'
import GroupsIcon from '@mui/icons-material/Groups'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MapIcon from '@mui/icons-material/Map'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import { InputBaseComponentProps } from '@mui/material'

import { NumberFormatCustom } from 'components/General/PhoneNumberFormat'

import { DealerDetailData } from 'models/dealer'
import {
  CityDetail,
  DealerDetailDataType,
} from 'models/services/operations/dealer'

export const placeholderPhone = '+57 (829) 505 1041'

export enum DealerColumnField {
  ID = 'id',
  NAME = 'name',
  CONTACT_NAME = 'dealerContacts',
  DEALER_PHONE = 'telephoneNumber',
  STATE_NAME = 'stateName',
  CITY_NAME = 'city',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export const initialDealerData = (
  dealerInformationText: Record<string, string>,
  dealer: DealerDetailDataType,
  cities: CityDetail[],
  openModalFunction: () => void
): DealerDetailData => {
  const contact =
    dealer.dealerContacts.length > 0
      ? [
          {
            id: 1,
            icon: <PhoneIcon />,
            label: dealerInformationText.phoneLabel,
            name:
              dealer.dealerContacts.length === 0
                ? ''
                : dealer.dealerContacts[0].phone.replace(/-/g, ''),
            inputProps: {
              inputComponent:
                NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
            },
          },
          {
            id: 2,
            icon: <PersonIcon />,
            label: dealerInformationText.nameLabel,
            name:
              dealer.dealerContacts.length === 0
                ? ''
                : dealer.dealerContacts[0].name,
          },
          {
            id: 3,
            icon: <EmailIcon />,
            label: dealerInformationText.emailLabel,
            name:
              dealer.dealerContacts.length > 0 && dealer.dealerContacts[0].email
                ? dealer.dealerContacts[0].email
                : 'N/A',
          },
          {
            id: 4,
            icon: <GroupsIcon />,
            label: dealerInformationText.additionalLabel,
            name:
              dealer.dealerContacts.length > 0
                ? dealerInformationText.seeMoreLabel
                : 'N/A',
            onClick: openModalFunction,
            disabled: true,
          },
        ]
      : [
          {
            id: 4,
            icon: <GroupsIcon />,
            label: dealerInformationText.additionalLabel,
            name: dealerInformationText.addRepresentativeLabel,

            onClick: openModalFunction,
            disabled: true,
          },
        ]

  return {
    dealerInfo: {
      curboSpot: dealer.curboSpot?.id || '',
      picture: dealer.mainPicture ? dealer.mainPicture : '',
      specifications: [
        {
          id: 1,
          icon: <DirectionsCarIcon />,
          label: dealerInformationText.nameLabel,
          name: dealer.name,
        },
        {
          id: 2,
          icon: <PhoneIcon />,
          label: dealerInformationText.phoneLabel,
          name: dealer.phone.replace(/-/g, ''),
          inputProps: {
            inputComponent:
              NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
          },
        },
        {
          id: 3,
          icon: <MapIcon />,
          label: dealerInformationText.cityLabel,
          name: dealer.city.id || '',
          options: cities,
          emptyOptionsText: dealerInformationText.noOptionsAvailable,
        },
        {
          id: 4,
          icon: <LocationCityIcon />,
          label: dealerInformationText.stateLabel,
          name: dealer.city.state.name,
          disabled: true,
        },
        {
          id: 5,
          icon: <LocationOnIcon />,
          label: dealerInformationText.addressLabel,
          name: dealer.address,
          inverted: true,
          disabled: true,
        },
      ],
      contact,
      vehicles: dealer.carsCount,
    },
    dealerLocation: {
      id: dealer.id,
      lat: dealer.latitude,
      lng: dealer.longitude,
      address: dealer.address,
      name: dealer.address,
    },
  }
}
