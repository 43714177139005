import { Address } from 'models/map'

export const libraries: (
  | 'core'
  | 'maps'
  | 'places'
  | 'geocoding'
  | 'routes'
  | 'marker'
  | 'geometry'
  | 'elevation'
  | 'streetView'
  | 'journeySharing'
  | 'drawing'
  | 'visualization'
)[] = ['places']

export const GOOGLE_SHARE_BASE_URL =
  'https://www.google.com/maps/search/?api=1&query='

type MapConstantsType = {
  [key: string]: Address
}

export const mapViewConstant: MapConstantsType = {
  DOM: {
    name: 'Santo Domingo',
    address: 'Vista Panoramica',
    lat: 18.491389,
    lng: -69.9489658,
    id: 'DOM',
  },
  COP: {
    name: 'Colombia',
    address: 'Vista Panoramica',
    lat: 3.3764016,
    lng: -77.0214149,
    id: 'COP',
  },
  MXN: {
    name: 'Mexico',
    address: 'Vista Panoramica',
    lat: 19.0284259,
    lng: -100.6749789,
    id: 'MXN',
  },
}
