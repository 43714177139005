import React from 'react'
import { BoxProps, Typography } from '@mui/material'
import { Box } from 'library/components/Common'
import { BASE_STEP_WIDTH } from 'library/constants/style'
import { colors } from 'library/styles/theme'

export type StepProps = {
  /**
   * If active the bar will have a blue color otherwise it will be gray
   */
  active?: boolean
  /**
   * The height of the bar
   */
  height?: string | number
  /**
   * Text at the top of the step, if undefined it will not appear
   */
  label?: string
  /**
   * The width of the bar
   */
  width?: string | number
} & BoxProps

/**
 * A single rectangular bar that makes the stepper
 */
export const Step = ({
  active,
  label,
  height = '10px',
  width = BASE_STEP_WIDTH,
  ...props
}: StepProps) => {
  return (
    <Box {...props}>
      {label ? (
        <Typography
          color={active ? colors.orange : colors.subtitleGray}
          variant="body2"
        >
          {label}
        </Typography>
      ) : null}
      <Box
        borderRadius="2px"
        height={height}
        width={width}
        sx={{ backgroundColor: active ? colors.orange : colors.subtitleGray }}
      />
    </Box>
  )
}
