import { ExtendedStatus } from 'library'

import { LeadColumnField } from 'constants/Lead/listing'
import { SelectItem } from 'models/select'
import { Lead } from 'models/services/CustomerRelationship/listing'
import { FieldSelectTextType } from 'models/text/LeadListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: LeadColumnField.ID,
    },
    {
      label: text.client,
      value: LeadColumnField.CLIENT,
    },
    {
      label: text.customerEmail,
      value: LeadColumnField.EMAIL,
    },
    {
      label: text.phone,
      value: LeadColumnField.PHONE,
    },
    {
      label: text.vehicle,
      value: LeadColumnField.VEHICLE,
    },
    {
      label: text.cta,
      value: LeadColumnField.CTA,
    },
    {
      label: text.source,
      value: LeadColumnField.SOURCE,
    },
    {
      label: text.createdAt,
      value: LeadColumnField.CREATED_AT,
    },
    {
      label: text.price,
      value: LeadColumnField.PRICE,
    },
    {
      label: text.step,
      value: LeadColumnField.STEP,
    },
    {
      label: text.specialist,
      value: LeadColumnField.SPECIALIST,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    caseId: text.id,
    customer: text.client,
    customerEmail: text.customerEmail,
    telephoneNumber: text.phone,
    car: text.vehicle,
    type: text.cta,
    origin: text.source,
    createdAt: text.createdAt,
    estimatedValue: text.price,
    leadStep: text.step,
    customerCareRepresentative: text.specialist,
  }
}

export const defaultFields = [
  LeadColumnField.ID,
  LeadColumnField.CLIENT,
  LeadColumnField.EMAIL,
  LeadColumnField.PHONE,
  LeadColumnField.VEHICLE,
  LeadColumnField.CTA,
  LeadColumnField.SOURCE,
  LeadColumnField.CREATED_AT,
  LeadColumnField.PRICE,
  LeadColumnField.STEP,
  LeadColumnField.SPECIALIST,
]

export const addCommas = (number: number) => number.toLocaleString('en-US')

export const formatLeads = (leads: Lead[]) =>
  leads.map((lead) => {
    const specialistFirstName =
      lead.customerCareRepresentative && lead.customerCareRepresentative.name
    const specialistLastName =
      lead.customerCareRepresentative &&
      lead.customerCareRepresentative.lastName

    return {
      id: lead.id,
      caseId: lead.caseId,
      client: `${lead.customer.name} ${lead.customer.lastName}`,
      customerEmail: lead.customerEmail,
      phone: lead.customer.telephoneNumber,
      vehicle: lead.car ? lead.car.name : 'N/A',
      cta: lead.type,
      source: lead.origin,
      createdAt: lead.createdAt,
      price: Number(lead.estimatedValue),
      step: {
        ...lead.leadStep,
        name: lead.leadStep.name.split(' - ').pop(),
      } as ExtendedStatus,
      specialist: {
        name:
          specialistFirstName && specialistLastName
            ? `${specialistFirstName} ${specialistLastName}`
            : '',
        value: lead.customerCareRepresentative
          ? (lead.customerCareRepresentative.value as string)
          : '',
      },
    }
  })

export const formatStep = (steps: ExtendedStatus[]): ExtendedStatus[] =>
  steps.map((step) => {
    return {
      backgroundColor: step.backgroundColor as string,
      textColor: step?.textColor as string,
      id: step.id,
      name: step.name.split(' - ').pop() as string,
      description: step.description as string,
      slug: step.slug as string,
    }
  })
