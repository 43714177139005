import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import { Button } from 'library'

import { cypressBackButton } from 'constants/cypress'

import { colors } from 'styles/theme'

const GoBackButton: FC = ({ ...props }) => {
  const history = useHistory()
  const goBack2Steps = () => {
    if (history.location.pathname.includes('#')) {
      history.go(-2)
    } else {
      history.goBack()
    }
  }

  return (
    <Button
      variant="text"
      onClick={() => goBack2Steps()}
      data-cy={cypressBackButton}
    >
      <Typography
        variant="h3"
        color={colors.orange}
        sx={{
          width: 'fit-content',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {props.children}
      </Typography>
    </Button>
  )
}

export default GoBackButton
