import React from 'react'
import { CSSObject, Typography } from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledCard } from './style'

type CardProps = {
  count: number
  name: string
  boxStyle?: CSSObject
}

const CountCard = ({ count, name, boxStyle }: CardProps): JSX.Element => {
  return (
    <StyledCard sx={boxStyle}>
      <Typography variant="h1" style={{ color: colors.orange }}>
        {count}
      </Typography>
      <Typography
        fontWeight={FONT_WEIGHT.MEDIUM}
        style={{ color: colors.gray, fontSize: 15 }}
      >
        {name}
      </Typography>
    </StyledCard>
  )
}

export default CountCard
