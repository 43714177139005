import { Image } from 'library/models/carousel'
import { nanoid } from 'nanoid'

export const createPictureList = (pictureList: string[]): Image[] => {
  return pictureList.map((picture) => {
    return {
      url: picture,
      id: nanoid(),
    }
  })
}
