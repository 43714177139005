import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'

export type CustomErrorStructure = {
  field: string
  message: string
}

export type CustomGraphQLError = GraphQLError & {
  errors: Array<CustomErrorStructure>
  code: string
}

/**
 * Function that takes the apollo error and checks if the given code exists in the error. Returns a boolean
 * describing if the error exists and the filterer error with the given error code
 */
export const validateGraphQLErrorCode = (
  error: ApolloError,
  errorCode: string
) => {
  const { graphQLErrors } = error
  const customGraphQLErrors = graphQLErrors as CustomGraphQLError[]
  const errorItem = customGraphQLErrors.filter(
    (item: CustomGraphQLError) => item.code === errorCode
  )

  return {
    errorExists: errorItem.length > 0,
    filteredErrors: errorItem,
    message: customGraphQLErrors[0].message,
  }
}
