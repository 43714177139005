import { Option } from 'library'

export enum USER_CREATION_OPTION {
  UPDATE_USER_INFORMATION = 'updateUserInformation',
  UPDATE_USER_ROLES = 'updateUserRoles',
}

export enum UserTypeEnum {
  CURBO = 'CURBO',
  DEALER = 'DEALER',
}

export const userTypeOptions: Option[] = [
  {
    name: 'Massy',
    value: UserTypeEnum.CURBO,
  },
  {
    name: 'Dealer',
    value: UserTypeEnum.DEALER,
  },
]
