import * as React from 'react'
import { Autocomplete as AutoComplete, inputBaseClasses } from '@mui/material'
import { colors, FONT_WEIGHT } from 'library/styles'

import {
  ArrowIcon,
  InputContainer,
  StyledMenuItem,
  StyledTextField,
} from './style'

export type AutocompleteItem = {
  name: string
  id: number | string
  disabled?: boolean
}

type AutocompleteProps = {
  /**
   * Is the autocomplete disabled
   */
  disabled?: boolean
  /**
   * Callback to run when option changes
   */
  onChangeHandler: (value: AutocompleteItem) => void
  /**
   *  Appears before the value of autocomplete
   */
  icon?: React.ReactNode
  /**
   * Specify if the user can clear the selected option
   */
  disableClear?: boolean
  /**
   * Autocomplete width
   */
  width?: number | string
  /**
   * Options to show the user
   */
  options: AutocompleteItem[]
  /**
   * Index of the starting option
   */
  startingOption?: number
  /**
   * Text to display as a placeholder for the textfield
   */
  placeholder?: string
  /**
   * The value of the item
   */
  value?: AutocompleteItem | null
  /**
   *If true, the Popper content will be under the DOM hierarchy of the parent component.
   *@default
   *true
   */
  disablePortal?: boolean

  /**
   * Styling for the autocomplete component
   */
  sx?: React.CSSProperties
  /**
   * Styling for input container in Autocomplete
   */
  inputSx?: React.CSSProperties

  /**
   * Text to display when there are no options.
   * @default
   * 'No options'
   */
  noOptionsText?: string

  /**
   * If input contains error or not
   *
   */
  error?: boolean

  /**
   * Cypress test id
   */
  testId?: string

  /**
   * Custom option to compare equality between a selected option and the rest of options
   */
  isOptionEqualToValue?: (
    option: AutocompleteItem,
    value: AutocompleteItem
  ) => boolean
}

export const Autocomplete = ({
  disabled = false,
  icon,
  onChangeHandler,
  options,
  disableClear = true,
  placeholder,
  value = null,
  disablePortal = true,
  noOptionsText,
  error,
  width = '100%',
  sx,
  inputSx,
  testId,
  isOptionEqualToValue,
}: AutocompleteProps) => {
  return (
    <AutoComplete
      isOptionEqualToValue={isOptionEqualToValue}
      disabled={disabled}
      onChange={(_, selectedValue) => {
        onChangeHandler(selectedValue as AutocompleteItem)
      }}
      disablePortal={disablePortal}
      id="autocompleteBar"
      value={value}
      getOptionLabel={(option) => (option as AutocompleteItem).name}
      options={options}
      disableClearable={disableClear}
      sx={{
        color: colors.gray,
        backgroundColor: colors.transparent,
        height: '38px',
        fontSize: '12px',
        fontWeight: FONT_WEIGHT.MEDIUM,
        letterSpacing: '0.14px',

        '#autocompleteBar': {
          padding: '0 0 0 15px',
          height: '100%',
        },
        width,
        ...sx,
      }}
      popupIcon={<ArrowIcon />}
      noOptionsText={noOptionsText}
      renderOption={(props, option) => (
        <StyledMenuItem
          disabled={(option as AutocompleteItem).disabled}
          key={(option as AutocompleteItem).name}
          value={(option as AutocompleteItem).name}
          {...props}
        >
          {(option as AutocompleteItem).name}
        </StyledMenuItem>
      )}
      renderInput={(params) => {
        return (
          <InputContainer sx={inputSx}>
            {icon || null}
            <StyledTextField
              {...params}
              placeholder={placeholder}
              sx={{
                [`& .${inputBaseClasses.root}`]: {
                  paddingLeft: `${icon ? '10px' : '0px'}`,
                },
                ...inputSx,
              }}
              error={error}
              inputProps={{
                ...params.inputProps,
                'data-cy': testId,
              }}
            />
          </InputContainer>
        )
      }}
    />
  )
}
