import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  Box,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material'
import { addDays, getDate } from 'date-fns'
import {
  formatDateLocalizedTime,
  getIsoDate,
  LoadingAnimation,
  useNotification,
} from 'library'

import { TableNative } from 'components/Common/TableNative'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'

import { inspectorItems } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { Inspector } from 'models/calendar'
import { CalendarRangeType, WeeklyRangeCalendarTabType } from 'models/date'
import { SelectItem } from 'models/select'
import {
  BaseSlugType,
  FilterInputVariable,
  GenericData,
} from 'models/services/base'
import { DistributionCalendarWeek } from 'models/services/inspector'
import { createCalendarFieldLabelList } from 'utils/Inspector/calendar'

import { GET_CURBO_SPOTS } from 'graphQL/Common/Dealer/queries'
import { GET_INSPECTORS_CALENDAR } from 'graphQL/Inspector/Calendar/queries'

import { colors } from 'styles/theme'

import { CalendarTableBody } from './CalendarTableBody/CalendarTableBody'
import { Footer } from './Footer'
import { ButtonsBox, StyledRowBody, StyledRowHeader } from './style'
import WeeklyRangeFilter from './WeeklyDateFilter'

type DayCellProps = { day: Date }
const DayCell = ({ day }: DayCellProps) => {
  const [currentLanguage] = useLocale()
  return (
    <TableCell align="center" sx={{ maxWidth: '120px', minWidth: '120px' }}>
      <Typography variant="subtitle1" color={colors.gray}>
        {formatDateLocalizedTime(
          day,
          'eeee',
          currentLanguage.code
        ).toUpperCase()}
      </Typography>
      <Typography variant="h3">{getDate(day)}</Typography>
    </TableCell>
  )
}

const DistributionCalendar = () => {
  const { show } = useNotification()

  const { text: translation } = useTranslation(textFiles.INSPECTOR_CALENDAR)
  const { general } = translation
  const {
    filter: filterText,
    dateRange: rangeText,
    nullLabel: noCurboSpot,
  } = general

  const [fields, setFields] = useState<SelectItem[]>([])
  const [selectedFields, setSelectedFields] = useState<string[]>([])
  const [dateRange, setDateRange] = useState<CalendarRangeType>({
    fromDate: new Date(),
    toDate: addDays(new Date(), 6),
  })
  const [data, setData] = useState<Inspector[]>([])

  const [filteredData, setFilteredData] = useState<Inspector[]>(data)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    Number(inspectorItems[0].value)
  )
  const [calendarTabValue, setCalendarTabValue] = useState<
    WeeklyRangeCalendarTabType | boolean
  >(WeeklyRangeCalendarTabType.THIS_WEEK)

  const fromDate = useMemo(() => {
    return getIsoDate(dateRange.fromDate)
  }, [dateRange.fromDate])

  const firstDay = dateRange.fromDate!

  const dayInNumbers = [
    { name: 'day1', date: firstDay },
    { name: 'day2', date: addDays(firstDay, 1) },
    { name: 'day3', date: addDays(firstDay, 2) },
    { name: 'day4', date: addDays(firstDay, 3) },
    { name: 'day5', date: addDays(firstDay, 4) },
    { name: 'day6', date: addDays(firstDay, 5) },
    { name: 'day7', date: addDays(firstDay, 6) },
  ]

  const { loading: calendarLoading } = useQuery<
    GenericData<DistributionCalendarWeek>,
    FilterInputVariable
  >(GET_INSPECTORS_CALENDAR, {
    variables: {
      input: {
        where: { from: fromDate! },
      },
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
    onCompleted(response) {
      if (response) {
        setData(
          response.data.inspectors.map((row) => {
            return {
              data: row.tags.map((tag) => {
                return {
                  isAbsent: tag.isAbsent,
                  data: tag.info,
                  date: new Date(tag.date),
                }
              }),
              curboSpot: row.curboSpot,
              id: row.id,
              name: row.name,
              profilePicture: row.profilePicture,
            }
          })
        )
      }
    },
  })

  const { loading: fieldsLoading } = useQuery<GenericData<BaseSlugType[]>>(
    GET_CURBO_SPOTS,
    {
      onError() {
        show({
          updatedSeverity: 'error',
        })
      },
      onCompleted(response) {
        if (response) {
          setFields([
            ...response.data.map((field) => {
              return { value: field.slug, label: field.name }
            }),
            {
              label: noCurboSpot,
              value: 'null',
            },
          ])
          setSelectedFields([
            ...response.data.map((field) => {
              return field.slug
            }),
            'null',
          ])
        }
      },
    }
  )

  const handleSelectItem = (valueInput: string) => {
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(fields.map((singleField) => singleField.value.toString()))
  }
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(event.target.value as number)
  }

  const handleCalendarTabChange = (newValue: WeeklyRangeCalendarTabType) => {
    setCalendarTabValue(newValue)
  }

  useEffect(() => {
    setFilteredData(
      data.filter((row) => {
        if (row.curboSpot === null) return selectedFields.includes('null')
        return selectedFields.includes(row.curboSpot.slug)
      })
    )
    setPage(1)
  }, [data, selectedFields])

  if (fieldsLoading) return <LoadingAnimation showAnimation={fieldsLoading} />

  return (
    <Box width="100%" display="grid">
      <TableNative
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          height: '645px',
        }}
        tableProps={{
          stickyHeader: true,
          size: 'small',
          sx: {
            marginLeft: '0px !important',
            marginRight: '0px !important',
            borderSpacing: '0px',
            width: '100%',
            height: calendarLoading ? '100%' : undefined,
          },
        }}
        maxHeight="645px"
      >
        <TableHead sx={{ overflow: 'auto' }}>
          <StyledRowHeader>
            <TableCell
              align="center"
              sx={{
                paddingX: 'auto',
                paddingY: 'auto',
                width: '304px',
                maxWidth: '304px',
                minWidth: '304px',
                overflow: 'hidden',
                position:
                  filteredData.length === 0 || calendarLoading
                    ? 'inherit'
                    : 'absolute',
                top: 'auto',
                zIndex: '3',
              }}
            >
              <ButtonsBox>
                <FieldFilter
                  handleSelectItem={handleSelectItem}
                  items={fields}
                  selectedValues={selectedFields}
                  handleResetDefault={handleResetDefault}
                  name={filterText}
                  icon={<FilterListIcon />}
                  placement="bottom-start"
                  sx={{
                    boxShadow: 'none',
                    border: 'none',
                    marginRight: '10px',
                  }}
                  labelSx={{ margin: '0 22px 0 16px' }}
                  file={textFiles.INSPECTOR_CALENDAR}
                />
                <WeeklyRangeFilter
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  title={rangeText}
                  sx={{
                    boxShadow: 'none',
                    border: 'none',
                    width: '100%',
                  }}
                  labelSx={{
                    fontSize: '13px',
                    marginY: 'auto',
                    marginX: 'auto',
                  }}
                  calendarTabValue={calendarTabValue}
                  handleCalendarTabChange={handleCalendarTabChange}
                />
              </ButtonsBox>
            </TableCell>
            {dayInNumbers.map((day) => (
              <DayCell key={`day_${day.date}`} day={day.date} />
            ))}
          </StyledRowHeader>
        </TableHead>
        {calendarLoading ? (
          <TableBody>
            <StyledRowBody>
              <TableCell colSpan={8}>
                <LoadingAnimation showAnimation={calendarLoading} />
              </TableCell>
            </StyledRowBody>
          </TableBody>
        ) : (
          <CalendarTableBody
            filteredData={filteredData}
            rowsPerPage={rowsPerPage}
            page={page}
            dayInNumbers={dayInNumbers}
          />
        )}
      </TableNative>

      {fieldsLoading || calendarLoading || filteredData.length === 0 ? null : (
        <Footer
          currentPage={page}
          rowLabelText={general.entriesLabel}
          selectItems={inspectorItems}
          pageSize={rowsPerPage}
          pageCount={Math.ceil(filteredData.length / rowsPerPage)}
          onPageChange={handleChangePage}
          onSelectChange={handleChangeRowsPerPage}
          entries={filteredData.length}
          dateRange={dateRange}
          fields={selectedFields}
          fieldLabels={createCalendarFieldLabelList(fields)}
          buttonLabel={general.download}
          hideDownloadButton
        />
      )}
    </Box>
  )
}

export default DistributionCalendar
