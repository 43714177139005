import { styled } from '@mui/material'
import { Box } from 'library'

import { colors } from 'styles/theme'

export const NavContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
})

export const CheckpointList = styled('ul')({
  paddingLeft: '0',
  margin: '1em auto 0 auto',
  width: '90%',
})

export const CheckpointItem = styled('li')({
  color: colors.aquaBlue,
  marginBottom: '2em',
})
