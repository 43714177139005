import React from 'react'
import { Typography } from '@mui/material'
import { Box, Calendar } from 'library/components/Common'
import { Language } from 'library/models/language'
import { colors, FONT_WEIGHT } from 'library/styles/theme'
import { formatDateLocalizedTime } from 'library/utils/date'

export type DatePickerTextModel = {
  dateTitle: string
  dateSubtitle: string
}

export type DatePickerProps = {
  translation?: DatePickerTextModel
  // Necessary for it to run, but should cast the event target value when being used
  onChange: (date: Date | null) => void
  value: Date | null
  /**
   * If `true`, user will not be allowed to interact with the Calendar
   */
  disabled?: boolean
  /**
   * Disable specific dates
   */
  shouldDisableDate?: (date: Date) => boolean
  /**
   * Callback fired when date is accepted
   */
  onAccept?: () => void
  /**
   * Tells the calendar if the minDate should be in the current day, otherwise starts the next day
   */
  startSameDay?: boolean
  language?: Language
}

export const DatePicker = ({
  disabled = false,
  translation,
  startSameDay = false,
  onChange,
  value,
  shouldDisableDate,
  onAccept,
  language = { name: 'English', code: 'en' },
}: DatePickerProps) => {
  const minimumDate = !startSameDay
    ? new Date(Date.now() + 24 * 60 * 60 * 1000)
    : new Date()
  return (
    <Box marginBottom="1em">
      <Typography variant="body1">
        {translation?.dateTitle || 'Date scheduling'}
      </Typography>
      <Typography variant="subtitle1" marginBottom="1em">
        {translation?.dateSubtitle || 'Set a date for the appointment'}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="1.25rem 0 0 0"
        border={`1px solid ${colors.border}`}
        borderRadius="10px"
        width="320px"
        position="relative"
        maxHeight="340px"
      >
        <Typography
          position="absolute"
          variant="body2"
          color="primary"
          fontWeight={FONT_WEIGHT.BOLD}
          height="20px"
          top="5px"
        >
          {value ? formatDateLocalizedTime(value, 'P', language.code) : ''}
        </Typography>
        <Calendar
          date={value}
          handleCalendarDate={onChange}
          minDate={disabled ? undefined : minimumDate}
          disabled={disabled}
          shouldDisableDate={shouldDisableDate}
          onAccept={onAccept}
        />
      </Box>
    </Box>
  )
}
