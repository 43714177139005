import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  ClickAwayListener,
  inputBaseClasses,
  outlinedInputClasses,
  Typography,
} from '@mui/material'
import MuiPopper from '@mui/material/Popper'
import { SelectChangeEvent } from '@mui/material/Select'
import {
  Box,
  Button,
  Container,
  ErrorMessage,
  FilterButton,
  Select,
} from 'library/components/Common'
import {
  Autocomplete,
  AutocompleteItem,
} from 'library/components/Common/Autocomplete'
import {
  FilterCard,
  FilterCardTextModel,
} from 'library/components/General/FilterCard'
import { cypressCancelButton } from 'library/constants/cypress'
import {
  FilteringOptionType,
  FiltersByCriteriaType,
  getFilteringOptions,
} from 'library/constants/Listing/filters'
import { CurboSpot } from 'library/models/curboSpot'
import { Filter } from 'library/models/filter'
import { FiltersByCriteriaCategoryType } from 'library/models/filtersByCriteria'
import { ListingFilterType } from 'library/models/services/base'
import { ProviderEntity } from 'library/models/services/providerEntity'
import { ExtendedStatus } from 'library/models/services/statusEntity'
import { boxShadow, colors } from 'library/styles/theme'
import { formatFiltersTypes } from 'library/utils/filters'

import {
  StyledButtonsContainer,
  StyledFiltersBackground,
  StyledFiltersContainer,
  StyledForm,
  StyledSelectorContainer,
  StyledTextField,
} from './style'

export type staticFieldsType = {
  /**
   * This type refers to the fields that have values we already know, such as Massy Spot, Province, Status (pending, approved, rejected).
   * This type serves with keyof operator to specify the type for fieldOrEntry on a dinamic array value below
   */
  province: string
  curboSpot: string
  status: string
  provider: string
}

export type FilterByCriteriaProps = {
  /**
   * Array containing each of the filters selected by the user
   */
  filtersList: Filter[]
  /**
   * Function to update the value of the filtersList stored in state
   */
  handleFiltersList: (
    newFiltersList: Filter[],
    filterInput: ListingFilterType
  ) => void
  /*
   * Text file to get filters
   */
  filterTypes?: FiltersByCriteriaCategoryType
  /**
   *  Object containing the variable to send to the query
   */
  filterInput: ListingFilterType

  statusList?: ExtendedStatus[]

  providerList?: ProviderEntity[]

  curboSpots?: CurboSpot[]

  options?: Record<string, FilteringOptionType[]>

  staticFields?: string[]

  handleFieldEntrySelect?: (fieldEntry: string) => void

  loadingSelect?: boolean

  filtersByCriteriaText: FiltersByCriteriaType

  filterCardText: FilterCardTextModel

  labels: {
    field: string
    operatorLabel: string
    loading: string
    valueLabel: string
    fieldLabel: string
    missingValue: string
    titles: {
      addFilter: string
      currentFilters: string
    }
    buttons: {
      addFilter: string
      clear: string
      cancel: string
      applyFilters: string
    }
  }
}

export const FilterByCriteria = ({
  filtersList,
  handleFiltersList,
  labels,
  filterTypes,
  filterInput,
  statusList,
  providerList,
  curboSpots,
  options,
  loadingSelect = false,
  handleFieldEntrySelect,
  staticFields = ['province', 'curboSpot', 'status', 'provider'],
  filtersByCriteriaText,
  filterCardText,
}: FilterByCriteriaProps) => {
  const [fieldOrEntry, setFieldOrEntry] = useState<string>('')
  const [newFiltersList, setNewFiltersList] = useState<Filter[]>([
    ...filtersList,
  ])
  const [newFilterInput, setNewFilterInput] = useState<ListingFilterType>({
    ...filterInput,
  })
  const [operator, setOperator] = useState<string>('')
  const [valueToCompare, setValueToCompare] = useState<string | number>('')
  const [selectAutoComplete, setSelectAutoComplete] =
    useState<AutocompleteItem>()
  const [hasError, setHasError] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)

  const {
    fieldOrEntryOptions,
    operatorOptions,
    provinceOptions,
    curboSpotOptions,
    providerOptions,
    statusOptions,
    filtersButton,
  } = getFilteringOptions(
    formatFiltersTypes(filtersByCriteriaText, filterTypes),
    statusList,
    curboSpots,
    providerList
  )

  const staticFieldsMap = {
    province: provinceOptions,
    curboSpot: curboSpotOptions,
    provider: providerOptions,
    status: statusOptions,
    ...options,
  }
  // manage popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setNewFiltersList(filtersList)
    setAnchorEl(anchorEl || event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setNewFilterInput({ ...filterInput })
  }

  const handleStartEditing = () => {
    setEditing(true)
  }

  const handleStopEditing = () => {
    setEditing(false)
  }

  const handleFieldChange = (event: SelectChangeEvent<unknown>) => {
    if (handleFieldEntrySelect) {
      handleFieldEntrySelect(event.target.value as string)
    }
    setFieldOrEntry(event.target.value as string)
  }
  const handleOperatorChange = (event: SelectChangeEvent<unknown>) => {
    setOperator(event.target.value as string)
  }

  const handleAutoCompleteChange = (value: AutocompleteItem) => {
    setSelectAutoComplete(value)
    setValueToCompare(value.id as string)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueToCompare(event.target.value)
  }
  const handleNewFiltersList = (
    newFilters: Filter[],
    changedInputFilter: ListingFilterType
  ) => {
    setNewFiltersList(newFilters)
    setNewFilterInput(changedInputFilter)
  }

  /**
   * Function called to add Filters.
   * First checks for duplicates inside the filtersList
   * Then checks if all of the fields are filled before pushing
   */
  const handleAddFilter = () => {
    const formattedValueToCompare =
      fieldOrEntryOptions.find((field) => field.value === fieldOrEntry)
        ?.type === 'number'
        ? parseInt(valueToCompare as string, 10)
        : valueToCompare

    const newFilter = {
      fieldOrEntry,
      operator,
      valueToCompare: formattedValueToCompare,
    } as Filter

    if (
      !newFiltersList.find(
        (oldFilter) =>
          oldFilter.fieldOrEntry === newFilter.fieldOrEntry &&
          oldFilter.operator === newFilter.operator &&
          oldFilter.valueToCompare === newFilter.valueToCompare
      ) &&
      newFilter.fieldOrEntry &&
      newFilter.operator &&
      newFilter.valueToCompare !== null
    ) {
      const newState = [...newFiltersList, newFilter]

      setNewFiltersList(newState)

      setHasError(false)

      const key = `${fieldOrEntry}_${operator}`

      setNewFilterInput((prevFilters) => {
        if (key in prevFilters) {
          const value = [...prevFilters[key], formattedValueToCompare]
          return {
            ...prevFilters,
            [key]: value,
          }
        }
        return {
          ...prevFilters,
          [key]: [formattedValueToCompare],
        }
      })

      setFieldOrEntry('')
      setOperator('')
      setValueToCompare('')
    } else setHasError(true)
  }

  const handleClear = () => {
    setFieldOrEntry('')
    setOperator('')
    setValueToCompare('')
    setSelectAutoComplete(undefined)
  }

  const handleApplyFilters = () => {
    handleFiltersList(newFiltersList, newFilterInput)
    setOpen(false)
  }

  useEffect(() => {
    setNewFilterInput(filterInput)
    setNewFiltersList(filtersList)
    setSelectAutoComplete(undefined)
  }, [filterInput, filtersList])

  return (
    <>
      <FilterButton
        id="fields-filter-popper"
        onClick={handleClick}
        text={filtersButton}
        icon={<FilterListIcon />}
        testId="filters-button"
      />
      <MuiPopper
        id="fields-filter-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          zIndex: 1201,
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (editing) return
            handleClose()
          }}
        >
          <Box marginTop="5px">
            <Container sx={{ width: '600px', boxShadow }}>
              <Typography variant="body1">{labels.titles.addFilter}</Typography>
              <StyledForm>
                <StyledSelectorContainer>
                  <Select
                    options={fieldOrEntryOptions}
                    label={labels.fieldLabel}
                    sx={{ width: '400px' }}
                    name="fieldOrEntry"
                    value={fieldOrEntry}
                    onChange={handleFieldChange}
                    MenuProps={{
                      disableScrollLock: true,
                      TransitionProps: {
                        onExited: handleStopEditing,
                      },
                    }}
                    onOpen={handleStartEditing}
                  />
                  <Select
                    options={operatorOptions}
                    label={labels.operatorLabel}
                    sx={{ width: '400px' }}
                    name="operator"
                    value={operator}
                    onChange={handleOperatorChange}
                    MenuProps={{
                      disableScrollLock: true,
                      TransitionProps: {
                        onExited: handleStopEditing,
                      },
                    }}
                    onOpen={handleStartEditing}
                  />
                  {staticFields.find(
                    (staticField) => staticField === fieldOrEntry
                  ) ? (
                    <Autocomplete
                      width="230px"
                      inputSx={{
                        paddingLeft: '0px',
                        [`& .${inputBaseClasses.root}`]: {
                          color: colors.commonBlack,
                        },
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                          border: 'none',
                        },
                      }}
                      options={staticFieldsMap[
                        fieldOrEntry as keyof staticFieldsType
                      ].map((option) => {
                        return {
                          name: option.name,
                          id: option.value,
                        }
                      })}
                      value={selectAutoComplete}
                      placeholder={
                        loadingSelect ? labels.loading : labels.valueLabel
                      }
                      onChangeHandler={handleAutoCompleteChange}
                    />
                  ) : (
                    <StyledTextField
                      placeholder={labels.valueLabel}
                      name="valueToCompare"
                      type={
                        fieldOrEntryOptions.find(
                          (field) => field.value === fieldOrEntry
                        )?.type === 'number'
                          ? 'number'
                          : undefined
                      }
                      value={valueToCompare}
                      onChange={handleInputChange}
                    />
                  )}
                </StyledSelectorContainer>
                {hasError ? (
                  <ErrorMessage
                    text={labels.missingValue}
                    sx={{ margin: '20px 0px' }}
                  />
                ) : null}
                <StyledButtonsContainer>
                  <Button
                    disabled={
                      fieldOrEntry === '' ||
                      operator === '' ||
                      valueToCompare === ''
                    }
                    startIcon={<AddIcon sx={{ fontSize: '24px !important' }} />}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleAddFilter()
                    }}
                    testId="add-filter-button"
                  >
                    {labels.buttons.addFilter}
                  </Button>
                  <Button
                    buttonType="info"
                    type="reset"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClear()
                    }}
                    testId="restart-filter-button"
                  >
                    {labels.buttons.clear}
                  </Button>
                </StyledButtonsContainer>
              </StyledForm>

              {/* Filters */}
              <StyledFiltersContainer>
                <Typography variant="body1">
                  {labels.titles.currentFilters}
                </Typography>
                <StyledFiltersBackground>
                  {newFiltersList
                    ? newFiltersList.map((filter, filterIdx) => (
                        <FilterCard
                          key={`${filter.fieldOrEntry}_${filter.operator}:${filter.valueToCompare}`}
                          filterKey={`${filter.fieldOrEntry}_${filter.operator}:${filter.valueToCompare}`}
                          filter={filter}
                          filterIdx={filterIdx}
                          newFiltersList={newFiltersList}
                          handleNewFiltersList={handleNewFiltersList}
                          filterInput={newFilterInput}
                          statusList={statusList}
                          curboSpots={curboSpots}
                          providerList={providerList}
                          options={options}
                          filterCardText={filterCardText}
                        />
                      ))
                    : null}
                </StyledFiltersBackground>
                <StyledButtonsContainer>
                  <Button
                    startIcon={
                      <CheckIcon sx={{ fontSize: '24px !important' }} />
                    }
                    onClick={handleApplyFilters}
                    testId="apply-filter-button"
                  >
                    {labels.buttons.applyFilters}
                  </Button>
                  <Button
                    buttonType="info"
                    onClick={handleClose}
                    testId={cypressCancelButton}
                  >
                    {labels.buttons.cancel}
                  </Button>
                </StyledButtonsContainer>
              </StyledFiltersContainer>
            </Container>
          </Box>
        </ClickAwayListener>
      </MuiPopper>
    </>
  )
}
