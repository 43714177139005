import React, { useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { ClickAwayListener, CSSObject } from '@mui/material'
import MuiPopper from '@mui/material/Popper'
import { Box, ExtendedCalendarRange } from 'library'

import FilterButton from 'components/Common/FilterButton'

import { CalendarRangeType, DateRangeCalendarTabType } from 'models/date'

export type DateRangeFilterProps = {
  /**
   * handleChangeDateRange is a function that takes a new CalendarRangeType as an argument
   * and replaces the last dateRange that was selected
   */
  handleChangeDateRange: (newDateRange: CalendarRangeType) => void

  dateRange: CalendarRangeType
  title: string
  maxDate?: Date | null
  sx?: CSSObject
  labelSx?: CSSObject
  popperSx?: CSSObject
}

const DateRangeFilter = ({
  dateRange,
  handleChangeDateRange,
  title,
  maxDate,
  sx,
  labelSx,
  popperSx,
}: DateRangeFilterProps) => {
  const [calendarTabValue, setCalendarTabValue] = useState<
    DateRangeCalendarTabType | boolean
  >(
    dateRange.fromDate || dateRange.toDate
      ? DateRangeCalendarTabType.CUSTOM
      : DateRangeCalendarTabType.ALL
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCalendarTabChange = (newValue: DateRangeCalendarTabType) => {
    setCalendarTabValue(newValue)
  }

  return (
    <>
      <FilterButton
        id="fields-filter-popper"
        onClick={handleClick}
        text={title}
        icon={<DateRangeIcon fontSize="small" />}
        sx={sx}
        labelSx={labelSx}
        testId="date-range-button"
      />
      <MuiPopper
        id="fields-filter-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={popperSx}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box marginTop="0.5rem">
            <ExtendedCalendarRange
              tabValue={calendarTabValue}
              handleTabChange={handleCalendarTabChange}
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              extendedFields
              maxDate={maxDate}
            />
          </Box>
        </ClickAwayListener>
      </MuiPopper>
    </>
  )
}
export default DateRangeFilter
