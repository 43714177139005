import React from 'react'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { ButtonNavigator, SummaryItem } from 'library'

import { textFiles } from 'constants/textFiles'
import { summaryItems } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import {
  getRangesAsString,
  TrimCreationProps,
} from 'utils/CarSettings/Trim/creation'

import Collapse from './Collapse'
import {
  FeatureContainer,
  StyledBorder,
  StyledContainer,
  StyledForm,
  StyledItem,
} from './style'

const Summary = ({
  handleBack,
  handleSubmit,
  trimData,
  brands,
  vehicleSelectOptions,
  submitLoading,
}: TrimCreationProps) => {
  const { basicInformation, settingsInformation } = trimData
  const { text: translation } = useTranslation(textFiles.TRIM_CREATION)
  const { information, settings, spacing, measurement, mileage, summary } =
    translation

  const options = {
    brands,
    vehicleSelectOptions,
  }
  const { infoArr, settArr, meArr, mileArr, spArr } = summaryItems(
    trimData,
    translation,
    options
  )

  return (
    <StyledBorder>
      <StyledForm>
        <StyledContainer
          title={summary.title}
          description={summary.description}
          contentStyle={{ width: '100%' }}
        >
          <Collapse title={information.title} defaultExpanded>
            <>
              {infoArr.map((item) => {
                const { id, ...rest } = item
                return <SummaryItem key={id} {...rest} />
              })}
              {basicInformation.datage.length > 0 && (
                <SummaryItem
                  title={information.datage}
                  text={getRangesAsString(basicInformation.datage)}
                  icon={<CalendarTodayIcon />}
                />
              )}
            </>
          </Collapse>
          <Collapse title={settings.title}>
            <>
              {settArr.map((item) => {
                const { id, ...rest } = item
                return <SummaryItem key={id} {...rest} />
              })}
              {settingsInformation.features.length > 0 && (
                <StyledItem title={settings.features}>
                  <FeatureContainer>
                    <ul>
                      {settingsInformation.features.map((feature) => (
                        <li key={feature}>
                          <span>
                            {
                              vehicleSelectOptions.features.find(
                                (feat) => feat.value === feature
                              )!.name
                            }
                          </span>
                        </li>
                      ))}
                    </ul>
                  </FeatureContainer>
                </StyledItem>
              )}
            </>
          </Collapse>
          <Collapse title={spacing.title}>
            <>
              {spArr.map((item) => {
                const { id, ...rest } = item
                return <SummaryItem key={id} {...rest} />
              })}
            </>
          </Collapse>
          <Collapse title={measurement.title}>
            <>
              {meArr.map((item) => {
                const { id, ...rest } = item
                return <SummaryItem key={id} {...rest} />
              })}
            </>
          </Collapse>
          <Collapse title={mileage.title}>
            <>
              {mileArr.map((item) => {
                const { id, ...rest } = item
                return <SummaryItem key={id} {...rest} />
              })}
            </>
          </Collapse>
        </StyledContainer>
        <ButtonNavigator
          previousFunction={handleBack}
          nextFunction={handleSubmit}
          loading={submitLoading}
          isLastStep
        />
      </StyledForm>
    </StyledBorder>
  )
}

export default Summary
