import { gql } from '@apollo/client'

export const GET_LISTING_CAR_BY_ID = gql`
  query getListingCarById($input: ID!) {
    data: getListingCarById(id: $input) {
      id
      certified
      listingPrice
      mainPicture
      slug
      title
      year
    }
  }
`

export const GET_FILTER_CONFIG = gql`
  query getFilterConfig {
    data: getFilterConfig {
      maxYear
      minYear
    }
  }
`

export const GET_INSPECTION_BRANDS = gql`
  query getInspectionBrands($input: FilterInput) {
    data: getInspectionBrands(input: $input) {
      name
      id
    }
  }
`

export const GET_LISTING_CARS_COUNT = gql`
  query getListingCars($input: FilterInput) {
    data: getListingCars(input: $input) {
      count
    }
  }
`
