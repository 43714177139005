import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from 'library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import noDataSvg from 'images/no-data.svg'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledContainer } from './style'

const NoResults = () => {
  const { text } = useTranslation(textFiles.GENERAL)
  const { noRecords } = text
  return (
    <StyledContainer>
      <Box width="108px" sx={{ marginRight: '20px' }}>
        <img alt="no data" height="96px" width="100%" src={noDataSvg} />
      </Box>
      <Box width="80%">
        <Typography
          variant="h5"
          color={colors.orange}
          fontWeight={FONT_WEIGHT.BOLD}
        >
          {noRecords.title}
        </Typography>
        <Box display="flex" flexWrap="wrap">
          <Typography
            variant="body1"
            color={colors.black}
            fontWeight={FONT_WEIGHT.REGULAR}
          >
            {noRecords.subtitle}
            <span style={{ color: colors.orange }}> {noRecords.contact}</span>
          </Typography>
        </Box>
      </Box>
    </StyledContainer>
  )
}

export default NoResults
