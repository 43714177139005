export enum InspectionStatus {
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  IN_PROCESS = 'in-process',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  FOR_REPAIR = 'for-repair',
  ABORTED = 'aborted',
  PATCHING_UP = 'patching-up',
}

export enum InspectionSlugStatus {
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  IN_PROCESS = 'IN_PROCESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FOR_REPAIR = 'FOR_REPAIR',
  ABORTED = 'ABORTED',
  PATCHING_UP = 'PATCHING_UP',
}

export enum InspectorStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum TemplateStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

export enum InventoryStatus {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not-available',
  SOLD = 'sold',
  PENDING = 'pending',
  COMING_SOON = 'coming-soon',
  TO_REVIEW = 'to-review',
}

export enum InventorySlugStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  SOLD = 'SOLD',
  PENDING = 'PENDING',
  COMING_SOON = 'COMING_SOON',
  TO_REVIEW = 'TO_REVIEW',
}

export enum backendTemplateStatus {
  published = 'PUBLISHED',
  draft = 'DRAFT',
}

export enum PublicationStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum OfferStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum NotificationStatus {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
}

export enum UserStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}

export const InspectorStatusMap: Record<string, InspectorStatus> = {
  active: InspectorStatus.ACTIVE,
  inactive: InspectorStatus.INACTIVE,
}

export const TemplateStatusMap: Record<string, TemplateStatus> = {
  published: TemplateStatus.PUBLISHED,
  draft: TemplateStatus.DRAFT,
}

export const InventoryStatusMap: Record<string, InventoryStatus> = {
  available: InventoryStatus.AVAILABLE,
  not_available: InventoryStatus.NOT_AVAILABLE,
  sold: InventoryStatus.SOLD,
  pending: InventoryStatus.PENDING,
  coming_soon: InventoryStatus.COMING_SOON,
  to_review: InventoryStatus.TO_REVIEW,
}

export const InspectionStatusMap: Record<string, InspectionStatus> = {
  pending: InspectionStatus.PENDING,
  schedule: InspectionStatus.SCHEDULED,
  inProcess: InspectionStatus.IN_PROCESS,
  approved: InspectionStatus.APPROVED,
  rejected: InspectionStatus.REJECTED,
  forRepair: InspectionStatus.FOR_REPAIR,
  aborted: InspectionStatus.ABORTED,
}

export const PublicationStatusMap: Record<string, PublicationStatus> = {
  [PublicationStatus.PUBLISHED]: PublicationStatus.PUBLISHED,
  [PublicationStatus.UNPUBLISHED]: PublicationStatus.UNPUBLISHED,
}

export type StatusKey =
  | InspectionStatus.PENDING
  | InspectionStatus.SCHEDULED
  | InspectionStatus.IN_PROCESS
  | InspectionStatus.APPROVED
  | InspectionStatus.REJECTED
  | InspectionStatus.FOR_REPAIR
  | InspectionStatus.ABORTED
  | InspectionStatus.PATCHING_UP
  | InspectorStatus.INACTIVE
  | InspectorStatus.ACTIVE
  | TemplateStatus.PUBLISHED
  | TemplateStatus.DRAFT
  | OfferStatus.ACTIVE
  | OfferStatus.INACTIVE
  | PublicationStatus.PUBLISHED
  | PublicationStatus.UNPUBLISHED
  | InventoryStatus.AVAILABLE
  | InventoryStatus.NOT_AVAILABLE
  | InventoryStatus.SOLD
  | InventoryStatus.PENDING
  | InventoryStatus.COMING_SOON
  | InventoryStatus.TO_REVIEW
  | UserStatus.CANCELLED

export type StatusCSSObject = {
  color: string
  backgroundColor: string
}

export type StatusStyling = {
  [key in StatusKey]: StatusCSSObject
}
