import { gql } from '@apollo/client'

export const GET_CURBO_SPOT_BY_ID = gql`
  query getCurboSpotById($input: ID!) {
    data: getCurboSpotById(id: $input) {
      address
      city {
        id
        name
        state {
          id
          name
        }
      }
      latitude
      dealerParent {
        name
        id
      }
      longitude
      mainPicture
      name
      status
      telephoneNumber
      supportsTestDrive
      supportsInspection
      supportsMaintenance
      supportsOffSiteTestDrive
      supportsOffSiteInspection
    }
  }
`
