import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'
import { endOfDay, startOfDay } from 'date-fns'
import {
  Box,
  buildDetailRoute,
  Button,
  checkSearchEmptiness,
  cleanFilters,
  FilterByCriteria,
  formatOptionsIntoFilteringOptions,
  formatPhoneNumber,
  generateFilterInput,
  getIsoDate,
  LoadingAnimation,
  Title,
  useNotification,
} from 'library'

import { CityOption } from 'pages/Operation/Dealer/Creation'

import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import DateFilter from 'components/Inspection/Listing/DateFilter'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'

import { cypressAddButton } from 'constants/cypress'
import {
  FilterEntryVariableType,
  FilteringOption,
} from 'constants/Inspection/filterByCriteria'
import { CURBO_SPOT_LISTING_REPORT_URL } from 'constants/listingReport'
import { CurboSpotColumnField } from 'constants/Operation/curboSpot'
import { routes } from 'constants/routes'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import { CalendarRangeType } from 'models/date'
import { FiltersByCriteriaTranslationType } from 'models/filtersByCriteria'
import {
  FilterInputVariable,
  GenericData,
  ListingFilterType,
} from 'models/services/base'
import {
  ListingCurboSpot,
  ListSpots,
} from 'models/services/operations/curboSpot'
import { PublicationStatusMap } from 'models/status'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/CurboSpot/listing'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSearch,
  serializeSortModel,
} from 'utils/serializers'
import { getCarSettingsStatusList } from 'utils/status'
import { verifyParam } from 'utils/verifyUrlData'

import { GET_CITIES } from 'graphQL/Common/City/queries'
import { LIST_CURBO_SPOTS } from 'graphQL/Operations/CurboSpot/Listing/queries'

import { StyledTextField } from 'styles/inspection/listing'

const formatSpot = (
  spots: ListingCurboSpot[],
  table: Record<string, string>
) => {
  return spots.map((spot) => {
    return {
      id: spot.id,
      name: spot.name,
      telephoneNumber:
        spot.phone.length < 10
          ? table.notRegistered
          : formatPhoneNumber(spot.phone),
      state: spot.city.state.name,
      city: spot.city.name,
      createdAt: spot.createdAt,
      status: spot.status,
    }
  })
}

type CustomFilterOption = {
  city: FilteringOption[]
}

const CurboSpotListingPage = () => {
  const { show } = useNotification()
  const location = useLocation()
  const { search } = location
  const defaultSortedFields = [...defaultFields].sort()

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )

  const [searchInput, setSearchInput] = useState<string>(searchValue || '')

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [spotCount, setSpotCount] = useState<number>(0)
  const [selectedLanguage] = useLocale()
  const history = useHistory()

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const { text } = useTranslation(textFiles.CURBO_SPOT_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const {
    filtersByCriteria,
  }: { filtersByCriteria: FiltersByCriteriaTranslationType } = text
  const { fieldOrEntryOptions: filterOptionText } = filtersByCriteria
  const [data, setData] = useState<GridRowsProp>([])
  const [customFilterOptions, setCustomFilterOptions] =
    useState<CustomFilterOption>({ city: [] })
  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(
        dateRange.fromDate ? startOfDay(dateRange.fromDate) : null
      ),
      toDate: getIsoDate(dateRange.toDate ? endOfDay(dateRange.toDate) : null),
    }
  }, [dateRange])

  const statusList = getCarSettingsStatusList(generalText)

  const fieldOrEntryOptions: Record<string, FilterEntryVariableType> = {
    id: {
      name: filterOptionText.id,
      type: 'string',
    },
    name: {
      name: filterOptionText.name,
      type: 'string',
    },
    city: {
      name: filterOptionText.city,
      type: 'string',
    },
    status: {
      name: filterOptionText.status,
      type: 'string',
    },
  }

  const { loading: spotLoading } = useQuery<ListSpots, FilterInputVariable>(
    LIST_CURBO_SPOTS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          limit: pageSize,
          start: (page - 1) * pageSize,
          where: {
            text_search: searchValue,
            createdAt_gte: fromDate,
            createdAt_lte: toDate,
            ...cleanFilters(filterInput),
          },
          sort:
            sortModel.length > 0 && sortModel[0].sort
              ? {
                  [sortModel[0].field]: sortModel[0].sort,
                }
              : undefined,
        },
      },
      onCompleted(res) {
        setData(formatSpot(res.listCurboSpots.data, generalText.table))
        setSpotCount(res.listCurboSpots.count)
      },
      onError() {
        show({
          updatedSeverity: 'error',
        })
      },
    }
  )

  const { loading: citiesLoading } = useQuery<GenericData<CityOption[]>>(
    GET_CITIES,
    {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setCustomFilterOptions((prevCustomFilter) => {
          return {
            ...prevCustomFilter,
            city: formatOptionsIntoFilteringOptions(response.data),
          }
        })
      },
    }
  )

  const editCurboSpot = useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.CURBO_SPOT_DETAIL))
    },
    [history]
  )

  const createCurboSpot = () => {
    history.push(routes.CURBO_SPOT_CREATION)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const {
    id: idColumn,
    isoDate: dateColumn,
    status: statusColumn,
    standard: standardColumn,
    actions: actionsColumn,
  } = commonListColumns(
    PublicationStatusMap,
    selectedLanguage,
    generalText,
    editCurboSpot,
    undefined,
    statusList
  )
  const [columns, setColumns] = useState<GridColDef[]>([
    { ...idColumn, hide: !selectedFields.includes(idColumn.field) },
    {
      ...standardColumn,
      field: CurboSpotColumnField.NAME,
      hide: !selectedFields.includes(CurboSpotColumnField.NAME),
      headerName: text.table.name,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip>{params.row.name}</Tooltip>
      },
    },
    {
      ...standardColumn,
      field: CurboSpotColumnField.STATE,
      hide: !selectedFields.includes(CurboSpotColumnField.STATE),
      headerName: text.table.state,
      sortable: false,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip>{params.row.state}</Tooltip>
      },
    },
    {
      ...standardColumn,
      field: CurboSpotColumnField.CITY,
      hide: !selectedFields.includes(CurboSpotColumnField.CITY),
      headerName: text.table.city,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip>{params.row.city}</Tooltip>
      },
    },
    {
      ...standardColumn,
      field: CurboSpotColumnField.TELEPHONE_NUMBER,
      hide: !selectedFields.includes(CurboSpotColumnField.TELEPHONE_NUMBER),
      headerName: text.table.telephoneNumber,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip>{params.row.telephoneNumber}</Tooltip>
      },
    },
    {
      ...statusColumn,
      sortable: true,
      hide: !selectedFields.includes(statusColumn.field),
    },
    {
      ...dateColumn,
      field: CurboSpotColumnField.CREATED_AT,
      hide: !selectedFields.includes(CurboSpotColumnField.CREATED_AT),
      headerName: text.table.createdAt,
    },
    { ...actionsColumn, hide: !selectedFields.includes(actionsColumn.field) },
  ])

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  const getPageCount = () => {
    return Math.ceil(spotCount / pageSize)
  }

  const submitSearchValue = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  if (citiesLoading) return <LoadingAnimation showAnimation={citiesLoading} />

  return (
    <Box width="100%">
      <Title
        icon={<PlaylistAddCheckIcon />}
        subtitle={`${spotCount} ${text.description}`}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginTop="2rem"
        width="100%"
      >
        <Button
          startIcon={<AddIcon />}
          size="medium"
          onClick={createCurboSpot}
          style={{ minWidth: '160px', marginRight: '20px' }}
          data-cy={cypressAddButton}
        >
          {text.addButton}
        </Button>

        <Box display="flex" flex="1 0.8 auto" justifyContent="flex-end">
          <Box marginRight="1rem" flex="0 1 430px">
            <StyledTextField
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterInput={filterInput}
              statusList={statusList}
              options={customFilterOptions}
              staticFields={['status', 'city']}
              filterCardText={filtersByCriteria}
              filtersByCriteriaText={{
                ...filtersByCriteria,
                fieldOrEntryOptions,
              }}
              labels={generalText.filterByCriteria}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
          <Box>
            <DateFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              maxDate={null}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        dateRange={dateRange}
        searchValue={searchValue}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        route={routes.CURBO_SPOT_DETAIL}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={spotLoading}
        checkboxSelection={false}
        reportName={text.processName}
        inspectionCount={spotCount}
        url={CURBO_SPOT_LISTING_REPORT_URL}
      />
    </Box>
  )
}
export default CurboSpotListingPage
