import { inputBaseClasses, selectClasses, styled } from '@mui/material'
import { Button, TextField } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '778px',
  height: '444px',
  border: `1px solid ${colors.border}`,
  borderRadius: '8px',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '394px',
  width: '693px',
})

export const ContentBox = styled('div')({
  height: 368,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

export const StripeContainer = styled('div')({
  height: 332,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  overflowY: 'auto',
  marginBottom: 10,
})

export const StyledTextField = styled(TextField)({
  width: '100%',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.gray,
  div: {
    height: '100%',
    fontSize: '14px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '14px',
    padding: '0 0 0 12px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const AddSection = styled('section')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const StyledButton = styled(Button)({
  fontSize: `14px !important`,
  height: `38px !important`,
  fontWeight: FONT_WEIGHT.MEDIUM,
})
