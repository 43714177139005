import React from 'react'
import { tabsClasses } from '@mui/material'
import { addDays } from 'date-fns'
import { Box, CalendarRange, getSubDaysFromDate } from 'library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CalendarRangeType, WeeklyRangeCalendarTabType } from 'models/date'

import { StyledContainer, StyledTab, StyledTabs } from './style'

type OmitFieldsType = {
  THIS_WEEK?: boolean
  LAST_WEEK?: boolean
  NEXT_WEEK?: boolean
  CUSTOM?: boolean
}

export type WeeklyCalendarRangeProps = {
  tabValue: WeeklyRangeCalendarTabType | boolean
  handleTabChange: (value: WeeklyRangeCalendarTabType) => void
  /**
   * Object containing the date range of the extended calendar
   */
  dateRange: CalendarRangeType
  /**
   * setState function for the dateRange, reason why this is a
   * setState instead of a normal function it is because it's
   * better to set the range of the dates directly
   * inside this component
   */
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRangeType>>

  /**
   * Object that decides which tabs to omit.
   * You have to set true the ones you wish to omit: THIS_WEEK | LAST_WEEK | NEXT_WEEK | CUSTOM
   */
  omitFields?: OmitFieldsType

  /**
   * Optional Maximum allowed date for the calendar to be clicked, this will be passed to the to calendar. If undefined defaults to new Date()
   */
  maxDate?: Date
}

/**
 * This is an augmented and opinionated version of the `<CalendarRange />`
 * where you can set  predefined range of dates in tab, if `value` is different
 * than `custom`, the `<CalendarRange />` will be disabled
 */
const WeeklyCalendarRange = ({
  handleTabChange,
  tabValue,
  dateRange,
  setDateRange,
  omitFields,
  maxDate,
}: WeeklyCalendarRangeProps) => {
  const { text } = useTranslation(textFiles.INSPECTOR_CALENDAR)
  const { WeeklyRangeCalendarText: translation } = text

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    if (dateRange.fromDate !== newDateRange.fromDate) {
      setDateRange({
        fromDate: newDateRange.fromDate,
        toDate: addDays(newDateRange.fromDate!, 6),
      })
    } else {
      setDateRange({
        fromDate: getSubDaysFromDate(6, newDateRange.toDate!),
        toDate: newDateRange.toDate,
      })
    }
  }

  const handleClickThisWeek = () => {
    const today = new Date()
    setDateRange({ fromDate: today, toDate: addDays(today, 6) })
  }

  const handleClickLastWeek = () => {
    const weekAway = getSubDaysFromDate(7, new Date())
    setDateRange({ fromDate: weekAway, toDate: addDays(weekAway, 6) })
  }

  const handleClickNextWeek = () => {
    const nextWeek = addDays(new Date(), 7)
    setDateRange({ fromDate: nextWeek, toDate: addDays(nextWeek, 6) })
  }

  return (
    <StyledContainer>
      <StyledTabs
        orientation="vertical"
        onChange={(e, newValue) => {
          handleTabChange(newValue)
        }}
        value={tabValue}
        sx={{
          [`& .${tabsClasses.flexContainerVertical}`]: {
            paddingTop: '20px',
            height: '80%',
            justifyContent: 'flex-start',
          },
        }}
      >
        {!omitFields?.THIS_WEEK ? (
          <StyledTab
            label={translation.thisWeek}
            onClick={handleClickThisWeek}
            value={WeeklyRangeCalendarTabType.THIS_WEEK}
          />
        ) : null}
        {!omitFields?.LAST_WEEK ? (
          <StyledTab
            label={translation.lastWeek}
            onClick={handleClickLastWeek}
            value={WeeklyRangeCalendarTabType.LAST_WEEK}
          />
        ) : null}
        {!omitFields?.NEXT_WEEK ? (
          <StyledTab
            label={translation.nextWeek}
            onClick={handleClickNextWeek}
            value={WeeklyRangeCalendarTabType.NEXT_WEEK}
          />
        ) : null}
        {!omitFields?.CUSTOM ? (
          <StyledTab
            label={translation.customLabel}
            value={WeeklyRangeCalendarTabType.CUSTOM}
          />
        ) : null}
      </StyledTabs>
      <Box padding="2rem 2rem 0rem 2rem">
        <CalendarRange
          dateRange={dateRange}
          handleChangeCalendarRange={handleChangeDateRange}
          maxDate={maxDate}
          disableRangeCalendar={tabValue !== WeeklyRangeCalendarTabType.CUSTOM}
          fromLabel={translation.fromLabel}
          toLabel={translation.toLabel}
        />
      </Box>
    </StyledContainer>
  )
}

export default WeeklyCalendarRange
