import ModelInfo from 'components/CarSettings/Model/Creation/ModelInfo'
import ModelSummary from 'components/CarSettings/Model/Creation/Summary'

import { ModelCreationModel } from 'models/modelCreation'
import { Option } from 'models/select'

export type ModelCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  modelData: ModelCreationModel
  submitLoading: boolean
  brands: Option[]
  updateModelData: (newModel: ModelCreationModel) => void
}

export const MODEL_CREATION_STEPS: Array<React.FC<ModelCreationProps>> = [
  ModelInfo,
  ModelSummary,
]
