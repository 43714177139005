import { Pagination, paginationClasses, styled } from '@mui/material'
import { Box } from 'library'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  height: '330px',
  textAlign: 'left',
  padding: '18px',
  width: 'auto',
})

export const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-icon': {
    fontSize: '1.5rem!important',
  },
  [`& .${paginationClasses.ul}`]: {
    borderRadius: '4px',
    height: '35px',
    width: '100%',
    justifyContent: 'space-between',
    'li:first-of-type': {
      color: colors.background,
      borderRight: `1px solid ${colors.border}`,
      height: '50%',
    },

    'li:last-child': {
      color: colors.background,
      borderLeft: `1px solid ${colors.border}`,
      height: '50%',
    },

    '.Mui-selected': {
      flexDirection: 'column',
      paddingTop: '2px',
      backgroundColor: 'transparent',
      color: colors.orange,
    },

    '.MuiPaginationItem-previousNext': {
      color: colors.placeholderGray,
      marginTop: '-5px',
    },
  },
})
