import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import ClearIcon from '@mui/icons-material/Clear'
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'
import {
  addCommas,
  Avatar,
  Box,
  checkSearchEmptiness,
  cleanFilters,
  FilterByCriteria,
  FilterCardTextModel,
  generateFilterInput,
  useNotification,
} from 'library'

import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import BiggerFontTooltip from 'components/Common/Table/Tooltip/BiggerFontTooltip'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'

import { cypressCancelButton } from 'constants/cypress'
import { FilterEntryVariableType } from 'constants/Inspection/filterByCriteria'
import { OfferDetailColumnField } from 'constants/offer'
import { commonListColumns, defaultStatusSortModel } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { FiltersByCriteriaTranslationType } from 'models/filtersByCriteria'
import { GenericData, ListingFilterType } from 'models/services/base'
import {
  FilterOfferInputVariable,
  ListCarByOffer,
  OfferProps,
  OfferTypes,
} from 'models/services/offer/detail'
import { ExtendedStatus } from 'models/services/status'
import { InventoryStatusMap } from 'models/status'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
  formatOfferCars,
} from 'utils/offer'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeSearch,
  serializeSortModel,
} from 'utils/serializers'
import { verifyParam } from 'utils/verifyUrlData'

import { GET_CAR_STATUSES } from 'graphQL/Inventory/Listing/queries'
import { GET_CARS_BY_OFFER } from 'graphQL/Offer/Detail/queries'

import { StyledTextField } from 'styles/inspection/listing'
import { colors } from 'styles/theme'

import { StyledContainer } from '../Information/style'

import { StyledIcon } from './AddButton/style'
import PriceTextField from './PriceTextfield'

const VehicleSet = ({ data }: OfferProps) => {
  const { show } = useNotification()
  const [selectedLanguage] = useLocale()
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''
  const { text } = useTranslation(textFiles.OFFER_DETAIL)
  const { vehicleSet: translation } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const {
    filtersByCriteria,
  }: { filtersByCriteria: FiltersByCriteriaTranslationType } = text
  const { fieldOrEntryOptions: filterOptionText } = filtersByCriteria

  const fieldOrEntryOptions: Record<string, FilterEntryVariableType> = {
    id: {
      name: filterOptionText.id,
      type: 'string',
    },
    slug: {
      name: filterOptionText.slug,
      type: 'string',
    },
    price: {
      name: filterOptionText.price,
      type: 'number',
    },
    status: {
      name: filterOptionText.status,
      type: 'string',
    },
  }

  const location = useLocation()
  const { search } = location

  const defaultSortedFields = [...defaultFields].sort()

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )
  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )
  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )
  const [pageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 5,
    serializePageSize,
    5
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )
  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultStatusSortModel,
    serializeSortModel,
    defaultStatusSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )
  const [searchInput, setSearchInput] = useState<string>(searchValue || '')
  const [offerQuantity, setOfferQuantity] = useState<number>(0)
  const [offerData, setOfferData] = useState<GridRowsProp>([])
  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const [statusList, setStatusList] = useState<ExtendedStatus[]>([])
  // const [edit, setEdit] = useState<boolean>(false)

  const fieldSelectItems = createFieldSelectItems(translation.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(translation.fieldSelect)

  const getPageCount = () => {
    return Math.ceil(offerQuantity / pageSize)
  }

  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: generalText.notificationText.error,
    })
  }

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const { loading: statusLoading, data: statusResponse } =
    useQuery<GenericData<ExtendedStatus[]>>(GET_CAR_STATUSES)

  const { loading: carsLoading } = useQuery<
    ListCarByOffer,
    FilterOfferInputVariable
  >(GET_CARS_BY_OFFER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        offer: data.id,
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          text_search: searchValue,
          priceInfo: {
            basePrice_gte:
              data.type === OfferTypes.PRICE ? data.minPrice : undefined,
            basePrice_lte:
              data.type === OfferTypes.PRICE ? data.maxPrice : undefined,
          },
          year_gte: data.type === OfferTypes.YEAR ? data.minYear : undefined,
          year_lte: data.type === OfferTypes.YEAR ? data.maxYear : undefined,
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    onCompleted(response) {
      const {
        data: listingData,
        count,
        offer: listingOffer,
      } = response.getListingCarsByOffer
      setOfferQuantity(count)
      setOfferData(formatOfferCars(listingData, listingOffer))
    },
    onError() {
      handleShowError()
    },
  })

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  // const handleEditDiscount = (newDiscount: number, id: GridRowId) => {
  //   setOfferData((prevData) => {
  //     const newArr = [...prevData]
  //     const newData = newArr.map((item) => {
  //       if (item.id === id) {
  //         return { ...item, discount: newDiscount }
  //       }
  //       return item
  //     })
  //     return newData
  //   })
  // }

  const handleDelete = (id: GridRowId) => {
    setDeleteItem(id)
  }

  const createColumns = useCallback(
    (statuses?: ExtendedStatus[], fields?: string[]) => {
      const hiddenField = (field: string) => {
        return fields && !fields.includes(field)
      }
      const {
        id: idColumn,
        picture: pictureColumn,
        status: statusColumn,
        standard: standardColumn,
        actions: actionsColumn,
      } = commonListColumns(
        InventoryStatusMap,
        selectedLanguage,
        translation,
        undefined,
        handleDelete,
        statuses
      )
      return [
        { ...idColumn, hide: hiddenField(OfferDetailColumnField.ID), flex: 1 },
        {
          ...standardColumn,
          field: OfferDetailColumnField.SLUG,
          headerName: translation.table.slug,
          hide: hiddenField(OfferDetailColumnField.SLUG),
          flex: 1,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>{params.row.carSlug}</Tooltip>
          ),
        },
        {
          ...pictureColumn,
          hide: hiddenField(pictureColumn.field),
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Avatar
                image={params.row.picture}
                alt="car-picture"
                height={88}
                width={88}
                style={{
                  width: '88px',
                  height: '88px',
                }}
                variant="rounded"
              />
            )
          },
        },
        {
          ...standardColumn,
          field: OfferDetailColumnField.VEHICLE,
          headerName: translation.table.vehicle,
          hide: hiddenField(OfferDetailColumnField.VEHICLE),
          flex: 2,
          width: 200,
          sortable: true,
          renderCell: (params: GridRenderCellParams) => (
            <BiggerFontTooltip text={params.row.slug} />
          ),
        },
        {
          ...standardColumn,
          field: OfferDetailColumnField.PRICE,
          headerName: translation.table.price,
          hide: hiddenField(OfferDetailColumnField.PRICE),
          flex: 1,
          sortable: true,
          renderCell: (params: GridRenderCellParams) => (
            <BiggerFontTooltip
              text={`${priceCurrency} ${addCommas(
                parseInt(params.row.price, 10)
              )}`}
            />
          ),
        },
        {
          ...standardColumn,
          field: OfferDetailColumnField.DISCOUNT,
          headerName: translation.table.discount,
          hide: hiddenField(OfferDetailColumnField.DISCOUNT),
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => (
            <PriceTextField
              label={`${priceCurrency} ${addCommas(
                parseInt(params.row.discount, 10)
              )}`}
              value={params.row.discount}
              startAdornment={priceCurrency}
              edit={false}
              // edit={edit && data.discountType === DiscountTypeEnum.INDIVIDUAL}
              name={OfferDetailColumnField.DISCOUNT}
              color={colors.red}
            />
          ),
        },
        {
          ...standardColumn,
          field: OfferDetailColumnField.NET_PRICE,
          headerName: translation.table.netPrice,
          hide: hiddenField(OfferDetailColumnField.NET_PRICE),
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => (
            <BiggerFontTooltip
              text={`${priceCurrency} ${addCommas(
                parseInt(params.row.netPrice, 10)
              )}`}
              color={colors.green}
            />
          ),
        },
        {
          ...statusColumn,
          hide: hiddenField(statusColumn.field),
        },
        {
          ...actionsColumn,
          hide: true,
          // hide: !edit,
          renderCell: (params: GridRenderCellParams) => (
            <div
              key={params.row.id}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <StyledIcon
                onClick={(e) => {
                  handleDelete(params.row.id)
                  e.stopPropagation()
                }}
                sx={{
                  backgroundColor: colors.commonWhite,
                  height: '40px',
                  width: '40px',
                  ':hover': {
                    backgroundColor: colors.commonWhite,
                  },
                }}
                data-cy={cypressCancelButton}
              >
                <ClearIcon
                  sx={{ color: colors.placeholderGray, fontSize: 30 }}
                />
              </StyledIcon>
            </div>
          ),
        },
      ]
    },
    [selectedLanguage, translation, priceCurrency]
  )
  const [columns, setColumns] = useState<GridColDef[]>(
    createColumns(statusList)
  )

  const handleSelectItem = (valueInput: string) => {
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }
  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const submitSearchValue = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }
  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  // const createCar = (id: string) => {
  //   console.log(id)
  // }

  useEffect(() => {
    if (statusResponse?.data) {
      const formattedStatuses = statusResponse.data.map((status) => ({
        ...status,
        slug: status.slug.toLowerCase().replace('_', '-'),
      }))
      // Set columns but only change status header
      setColumns(createColumns(formattedStatuses, selectedFields))
      setStatusList(formattedStatuses)
    }
  }, [statusResponse, createColumns, selectedFields])

  return (
    <StyledContainer
      title={translation.title}
      description={translation.description.replace('%d', offerQuantity)}
    >
      {/* <EditContainer
        edit={edit}
        handleCancelEdit={() => setEdit(false)}
        handleSaveEdit={() => setEdit(false)}
        handleEdit={() => setEdit(true)}
        loading={submitLoading}
        isEditable
        boxStyles={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      /> */}
      <Box
        alignItems="center"
        display="flex"
        justifyContent="flex-end"
        // justifyContent={
        //   data.type === OfferTypes.CAR ? 'space-between' : 'flex-end'
        // }
        alignContent="center"
        margin="2rem 0"
        width="100%"
      >
        {/* {data.type === OfferTypes.CAR ? (
          <AddButton
            label={translation.addButton}
            popperTitle={translation.popperTitle}
            handleCreateCar={createCar}
            placeholder={data.id}
          />
        ) : null} */}
        <Box display="flex">
          <Box marginRight="1rem" width="450px">
            <StyledTextField
              placeholder={translation.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterCardText={
                filtersByCriteria as unknown as FilterCardTextModel
              }
              filtersByCriteriaText={{
                ...filtersByCriteria,
                fieldOrEntryOptions,
              }}
              labels={generalText.filterByCriteria}
              filterInput={filterInput}
              staticFields={['status']}
              statusList={statusList}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
        </Box>
      </Box>
      <Table
        rowHeight={110}
        columns={columns}
        data={offerData}
        currentPage={page}
        onPageChange={handleChangePage}
        pageSize={pageSize}
        selectItems={[
          {
            label: '5',
            value: 5,
          },
        ]}
        pageCount={getPageCount()}
        loading={carsLoading || statusLoading}
        filtersList={filtersList}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        file={textFiles.INVENTORY_LISTING}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        hideDownloadModal
        checkboxSelection={false}
      />
    </StyledContainer>
  )
}

export default VehicleSet
