import {
  MenuItem,
  menuItemClasses,
  Pagination,
  paginationClasses,
  PaginationProps,
  styled,
} from '@mui/material'
import { Box } from 'library'

import { boxShadow, colors } from 'styles/theme'

type CustomPaginationProps = {
  backgroundColor?: string
} & PaginationProps

export const StyledMenuItem = styled(MenuItem)({
  [`&.${menuItemClasses.root}`]: {
    color: colors.gray,
    fontSize: '12px',
    lineHeight: '16px',
    paddingLeft: '8px',
    paddingRight: '24px',
  },
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: `${colors.lightOrange}`,
    color: `${colors.orange}`,
  },
})

export const StyledFooterContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'sticky',
  height: '35px',
  bottom: '4px',
  padding: '11px',
})

export const StyledPagination = styled(Pagination)<CustomPaginationProps>(
  ({ backgroundColor = colors.background }) => ({
    '& .MuiPaginationItem-icon': {
      fontSize: '1.5rem!important',
    },
    [`& .${paginationClasses.ul}`]: {
      backgroundColor,
      borderRadius: '4px',
      boxShadow,
      height: '35px',
      'li:first-of-type': {
        color: colors.background,
        borderRight: `1px solid ${colors.border}`,
        height: '50%',
      },

      'li:last-child': {
        color: colors.background,
        borderLeft: `1px solid ${colors.border}`,
        height: '50%',
      },
      '.Mui-selected::after': {
        content: '""',
        display: 'block',
        width: ' 14px',
        height: '2px',
        borderRadius: ' 6px',
        background: colors.orange,
      },

      '.Mui-selected': {
        flexDirection: 'column',
        paddingTop: '2px',
        backgroundColor: 'transparent',
      },

      '.MuiPaginationItem-previousNext': {
        color: colors.placeholderGray,
        marginTop: '-5px',
      },
    },
  })
)
