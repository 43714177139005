import React from 'react'
import { CSSObject } from '@mui/material'
import { Avatar, AvatarProps } from 'library'

import { StyledContainer } from './style'

export type ClientInfo = {
  name: string
  address: string
  phone: string
  email: string
}

type ImageBoxProps = {
  containerSx?: CSSObject
  children?: React.ReactNode
} & AvatarProps

const ImageBox = ({ containerSx, children, ...props }: ImageBoxProps) => {
  return (
    <StyledContainer sx={containerSx}>
      <Avatar {...props} />
      {children}
    </StyledContainer>
  )
}

export default ImageBox
