import React, { FunctionComponent, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AddIcon from '@mui/icons-material/Add'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import { IconButton, InputBaseComponentProps, Typography } from '@mui/material'
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik'
import { Box, Button, formatPhoneNumber } from 'library'
import * as yup from 'yup'

import EditButtons from 'components/CarSettings/Common/Detail/NameContainer/Items/EditButtons'
import EditableText from 'components/General/EditableText'
import ErrorTooltip from 'components/General/ErrorTooltip'
import { NumberFormatCustom } from 'components/General/PhoneNumberFormat'

import { cypressAddButton } from 'constants/cypress'
import { placeholderPhone } from 'constants/dealer'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { DealerContact } from 'models/services/operations/dealer'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { BoxContainer, FormContainer, StyledForm } from '../style'

type DealerFormProps = {
  dealerContacts: DealerContact[]
  header: string
  handleSubmit: (dealerContact: DealerContact[]) => Promise<boolean>
}

const DealerForm = ({
  dealerContacts,
  header,
  handleSubmit,
}: DealerFormProps) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text } = useTranslation(textFiles.DEALER_DETAIL)
  const phoneValidation = validationText.errorMessagePhoneNumberDigits.replace(
    '%d',
    '10'
  )

  const validationSchema = yup.object({
    dealerContacts: yup.array().of(
      yup.object().shape({
        phone: yup
          .string()
          .length(10, phoneValidation)
          .required(validationText.fieldRequired),
        name: yup.string().required(validationText.fieldRequired),
        email: yup
          .string()
          .required(validationText.fieldRequired)
          .email(validationText.invalidEmail),
      })
    ),
  })

  const formik = useFormik<{ dealerContacts: DealerContact[] }>({
    initialValues: {
      dealerContacts,
    },
    validationSchema,
    onSubmit: async (values) => {
      setSubmitLoading(true)
      const isSuccesful = await handleSubmit(values.dealerContacts)
      if (isSuccesful) {
        setEdit(false)
        setSubmitLoading(false)
      }
    },
  })

  const handleEdit = () => {
    if (!edit) setEdit(true)
    else {
      formik.handleSubmit()
    }
  }

  const handleCancelEdit = () => {
    formik.setValues({ dealerContacts })
    setEdit(false)
  }

  const dealerContactsErrorExists = (index: number, key: string) => {
    if (
      getIn(formik.touched.dealerContacts?.[index], key) &&
      Boolean(getIn(formik.errors.dealerContacts?.[index], key))
    )
      return true

    return false
  }

  return (
    <>
      <BoxContainer justifyContent="center" position="relative" width="90%">
        <ErrorIcon
          sx={{ color: colors.orange, width: '31px', height: '35px' }}
        />

        <EditButtons
          edit={edit}
          handleCancelEdit={handleCancelEdit}
          handleEdit={handleEdit}
          sx={{
            right: 0,
            top: 0,
          }}
          disabled={submitLoading}
        />
      </BoxContainer>
      <BoxContainer flexDirection="column" width="85%" marginTop="1rem">
        <Typography
          color={colors.black}
          fontWeight={FONT_WEIGHT.SEMI_BOLD}
          variant="body2"
          width="348px"
          textAlign="center"
        >
          {header}
        </Typography>
        <FormikProvider value={formik}>
          <StyledForm onSubmit={formik.handleSubmit} id="edit-dealer-form">
            <FieldArray
              name="dealerContacts"
              render={(arrayHelpers) => (
                <>
                  <FormContainer>
                    {formik.values.dealerContacts.map((_, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <BoxContainer key={index} marginBottom="1rem">
                        <AccountCircleIcon
                          sx={{ color: colors.orange, fontSize: '2.5rem' }}
                        />
                        <Box marginLeft="0.5rem">
                          <EditableText
                            value={formik.values.dealerContacts[index].name}
                            edit={edit}
                            name={`dealerContacts[${index}].name`}
                            onChange={formik.handleChange}
                            placeholder="John Doe"
                            id={`dealerContacts[${index}].name`}
                            error={dealerContactsErrorExists(index, 'name')}
                            InputProps={{
                              ...(dealerContactsErrorExists(index, 'name') && {
                                endAdornment: (
                                  <ErrorTooltip
                                    errorMessage={
                                      getIn(
                                        formik.errors.dealerContacts?.[index],
                                        'name'
                                      ) || ''
                                    }
                                  />
                                ),
                              }),
                            }}
                          />
                          <BoxContainer>
                            <Box>
                              <EditableText
                                value={
                                  formik.values.dealerContacts[index].email
                                }
                                edit={edit}
                                editType="normal"
                                name={`dealerContacts[${index}].email`}
                                id={`dealerContacts[${index}].email`}
                                onChange={formik.handleChange}
                                placeholder="john_doe@dealer.com"
                                error={dealerContactsErrorExists(
                                  index,
                                  'email'
                                )}
                                InputProps={{
                                  ...(dealerContactsErrorExists(
                                    index,
                                    'email'
                                  ) && {
                                    endAdornment: (
                                      <ErrorTooltip
                                        errorMessage={
                                          getIn(
                                            formik.errors.dealerContacts?.[
                                              index
                                            ],
                                            'email'
                                          ) || ''
                                        }
                                      />
                                    ),
                                  }),
                                }}
                              />
                            </Box>

                            <ArrowRightAltIcon
                              sx={{ color: colors.gray, fontSize: '12' }}
                            />
                            <Box>
                              <EditableText
                                value={
                                  formik.values.dealerContacts[index].phone
                                }
                                edit={edit}
                                editType="normal"
                                name={`dealerContacts[${index}].phone`}
                                id={`dealerContacts[${index}].phone`}
                                onChange={formik.handleChange}
                                placeholder={placeholderPhone}
                                formatFunction={formatPhoneNumber}
                                InputProps={{
                                  inputComponent:
                                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                                  ...(dealerContactsErrorExists(
                                    index,
                                    'phone'
                                  ) && {
                                    endAdornment: (
                                      <ErrorTooltip
                                        errorMessage={
                                          getIn(
                                            formik.errors.dealerContacts?.[
                                              index
                                            ],
                                            'phone'
                                          ) || ''
                                        }
                                      />
                                    ),
                                  }),
                                }}
                                error={dealerContactsErrorExists(
                                  index,
                                  'phone'
                                )}
                                sx={{
                                  marginLeft: '0.4rem',
                                }}
                              />
                            </Box>
                          </BoxContainer>
                        </Box>
                        {edit && (
                          <IconButton
                            onClick={() => {
                              arrayHelpers.remove(index)
                            }}
                          >
                            <CloseIcon
                              fontSize="small"
                              sx={{ color: colors.gray }}
                            />
                          </IconButton>
                        )}
                      </BoxContainer>
                    ))}
                  </FormContainer>
                  {edit && (
                    <Button
                      testId={cypressAddButton}
                      variant="text"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        arrayHelpers.push({
                          name: '',
                          phone: '',
                          email: '',
                        })
                      }}
                    >
                      {text.dealerInformation.addRepresentativeLabel}
                    </Button>
                  )}
                </>
              )}
            />
          </StyledForm>
        </FormikProvider>
      </BoxContainer>
    </>
  )
}

export default DealerForm
