import { Box, styled, Typography } from '@mui/material'
import { boxShadow, colors, FONT_WEIGHT } from 'library/styles/theme'

/**
 ** This box is different for the summary on the first row last column of the Dashboard
 */
export const SummaryBox = styled(Box)({
  backgroundColor: colors.background,
  paddingLeft: '1.5rem',
  paddingBottom: '1rem',
  paddingTop: '0.8rem',
  marginBottom: '0.5rem',
  boxShadow,
  borderRadius: '8px',
  height: '30px',
  width: '155px',
  display: 'flex',
})

/**
 ** Typography for the Numbers of the summary container.
 */
export const SummaryNumber = styled(Typography)({
  fontWeight: FONT_WEIGHT.BOLD,
  color: colors.orange,
  marginTop: '-0.1rem',
  marginRight: '1rem',
})

/**
 ** Typography for the Titles/labels of the summary container.
 */
export const SummaryLabel = styled(Typography)({
  width: '100px',
  color: colors.black,
  lineHeight: '15px',
})
