import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Snackbar, Typography } from '@mui/material'
import { AlertColor } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { colors } from 'library/styles/theme'

import {
  StyledCloseButton,
  StyledIconContainer,
  StyledMessage,
  StyledSnackbarContent,
} from './style'

export type CloseButtonType = {
  handleClose: () => void
}

const CloseButton = ({ handleClose }: CloseButtonType) => (
  <StyledCloseButton aria-label="Close" onClick={handleClose}>
    <CloseIcon sx={{ color: colors.gray }} />
  </StyledCloseButton>
)

type ConfirmationMessageProps = {
  /**
   * Text for the secondary label
   */
  description: string
  /**
   * Icon to render next to the Toast message
   */
  icon?: React.ReactNode
  /**
   * Text for the primary label
   */
  title?: string
  /**
   * Function to handle the close function
   */
  handleClose: () => void
}

const ConfirmationMessage = ({
  description,
  icon,
  title,
  handleClose,
}: ConfirmationMessageProps) => (
  <>
    <CloseButton handleClose={handleClose} />

    {icon ? <StyledIconContainer>{icon}</StyledIconContainer> : null}
    <StyledMessage>
      {title ? (
        <Typography variant="body1" sx={{ marginBottom: '5px' }}>
          {title}
        </Typography>
      ) : null}
      <Typography variant="body2">{description}</Typography>
    </StyledMessage>
  </>
)

export type ToastProps = {
  /**
   * State describing whether or not should toast be open
   */
  showToast: boolean
  /*
   * Variant of snackbar
   */
  variant?: 'confirmation' | 'alert'
  /*
   * After how many ms should the alert disappear
   */
  autoHide?: number

  /*
   * Severity of the snackbar if using alert variant
   */
  severity?: AlertColor
} & ConfirmationMessageProps

const Toast = ({
  showToast,
  handleClose,
  icon,
  title,
  description,
  variant = 'alert',
  autoHide = 2500,
  severity = 'success',
}: ToastProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={showToast}
      onClose={handleClose}
      autoHideDuration={autoHide}
      key="bottom right"
    >
      {variant === 'confirmation' ? (
        <StyledSnackbarContent
          sx={{
            height: `${description.length * 1}px`,
          }}
          message={
            <ConfirmationMessage
              handleClose={handleClose}
              icon={icon}
              title={title}
              description={description}
            />
          }
        />
      ) : (
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ maxWidth: '340px', height: 'auto' }}
        >
          <AlertTitle>{title}</AlertTitle>
          {description.length > 0 ? (
            <Typography variant="body2">{description}</Typography>
          ) : null}
        </Alert>
      )}
    </Snackbar>
  )
}

export { Toast, CloseButton }
