import React from 'react'
import { SxProps, Typography } from '@mui/material'
import { Box } from 'library'

import { StatusStyles as InspectionStatusStyles } from 'constants/status'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  InspectionStatus,
  InspectorStatus,
  OfferStatus,
  PublicationStatus,
  TemplateStatus,
  UserStatus,
} from 'models/status'

import { colors } from 'styles/theme'

type TagProps = {
  status?:
    | InspectionStatus
    | InspectorStatus
    | TemplateStatus
    | OfferStatus
    | PublicationStatus
    | UserStatus
  backgroundColor?: string
  color?: string
  text?: string
  boxStyle?: SxProps
  textStyle?: SxProps
}

const Tag = ({
  status = InspectionStatus.APPROVED,
  backgroundColor,
  color,
  text,
  boxStyle,
  textStyle,
}: TagProps) => {
  const { text: translation } = useTranslation(textFiles.GENERAL)

  return (
    <Box sx={boxStyle}>
      <Typography
        variant="body2"
        sx={{
          width: '120px',
          height: '37px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          userSelect: 'none',
          border: `2px solid ${colors.commonWhite}`,
          color: color || InspectionStatusStyles[status].color,
          backgroundColor:
            backgroundColor || InspectionStatusStyles[status].backgroundColor,
          textAlign: 'center',
          ...textStyle,
        }}
      >
        {text || translation.status[status]}
      </Typography>
    </Box>
  )
}

export default Tag
