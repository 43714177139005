import {
  formControlClasses,
  sliderClasses,
  styled,
  TextField as MUITextField,
} from '@mui/material'
import { Box, Container } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledBorder = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  border: 'unset',
  borderRadius: '8px',
  backgroundColor: colors.commonWhite,
})

export const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minHeight: '330px',
  width: '100%',
  padding: '0 0 0 13px',
  overflowX: 'hidden',
})

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '384px',
  width: '100%',
})

export const GridContainer = styled(Box)({
  height: '75%',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '48% 48%',
  columnGap: '20px',
  rowGap: '20px',
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
  '> div:nth-of-type(2)': {
    position: 'static',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [`.${sliderClasses.rail}`]: {
      height: 10,
      color: colors.gray,
    },
    [`.${sliderClasses.track}`]: {
      height: 10,
    },
  },
})

export const StyledTextField = styled(MUITextField)({
  border: `1px solid ${colors.lightGray}`,
  borderRadius: '4px',
  opacity: 1,
  height: '38px',
  '& div': {
    color: colors.placeholderGray,
    fontWeight: FONT_WEIGHT.MEDIUM,
    height: '100%',
    fontSize: '12px',
  },
  '& div fieldset': {
    border: 'none',
  },
})

export const StyledItem = styled(Container)({
  width: 315,
  maxHeight: 145,
  color: colors.placeholderGray,
  borderRadius: 0,
  padding: 0,
  '& > div:nth-of-type(2)': {
    margin: 0,
  },
  ul: {
    paddingLeft: '35px',
    fontSize: 14,
    fontWeight: FONT_WEIGHT.MEDIUM,
    li: {
      '&::marker': {
        fontSize: 16,
        color: colors.orange,
      },
      span: {
        color: colors.placeholderGray,
      },
      marginBottom: '12px',
    },
  },
})

export const FeatureContainer = styled(Box)({
  maxHeight: 105,
  maxWidth: 325,
  overflowY: 'auto',
})
