import { styled } from '@mui/material'
import { Box, colors } from 'library'

export const StyledParagraph = styled('p')({
  textDecoration: 'underline',
  margin: '0 0 0 0.5em',
})

export const DealerInformationContainer = styled(Box)({
  display: 'flex',
  margin: '0.25em 0 1em 0',
  color: colors.orange,
  paddingBottom: '0.5em',
  borderBottom: `2px solid ${colors.altGray}`,
})

export const StyledForm = styled('form')({
  justifyContent: 'flex-start',
  alignItems: 'left',
  width: '100%',
  marginBottom: '0.5rem',
  '> div': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    columnGap: '0.5rem',
    marginBottom: '1em',
    '> div': {
      width: '290px',
      marginTop: '0.5rem',
    },
  },
})
