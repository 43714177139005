import { GridSortModel } from '@mui/x-data-grid'
import { formatISO } from 'date-fns'
import { pageSizes } from 'library/constants/Listing/pageSizes'
import { UrlParamNames } from 'library/constants/urlQuery'
import { CalendarRangeType } from 'library/models/date'
import { Filter } from 'library/models/filter'
import { isEqual } from 'lodash'

/**
 *
 * @param date - date to be serialized on the url
 * @returns a ISO date string
 */
export const serializeDate = (date: Date | null) => {
  if (!(date instanceof Date && !Number.isNaN(date.valueOf())) || !date) {
    return undefined
  }
  return formatISO(date, {
    representation: 'date',
  })
}

export const serializeRange = (dateRange: CalendarRangeType) => {
  const from = dateRange && serializeDate(dateRange.fromDate)
  const to = dateRange && serializeDate(dateRange.toDate)
  const fromString = `"from":"${from}"`
  const toString = `"to":"${to}"`

  if (from && to) {
    return `{${fromString},${toString}}`
  }
  if (from) {
    return `{${fromString}}`
  }
  if (to) {
    return `{${toString}}`
  }
  return undefined
}

/**
 *
 * @param fields - fields to be serialized
 * @returns a string with the fields separated by commas
 */
export const serializeFields = (fields: string[], defaultValue?: string[]) => {
  if (
    Array.isArray(fields) &&
    defaultValue &&
    fields &&
    fields.length > 0 &&
    !isEqual([...fields].sort(), [...defaultValue].sort())
  ) {
    return fields ? `[${fields.join(',')}]` : ''
  }
  return undefined
}

/**
 *
 * @param filters - filters to be serialized
 * @returns a string with the filters separated by commas
 */
export const serializeFilters = (filters: Filter[]) => {
  if (filters && filters.length > 0) {
    return Array.isArray(filters)
      ? `[${filters
          .map(({ fieldOrEntry, operator, valueToCompare }) => {
            const stringOrNumber =
              typeof valueToCompare === 'string'
                ? `"${valueToCompare}"`
                : valueToCompare

            return `{"${fieldOrEntry}_${operator}":${stringOrNumber}}`
          })
          .join(',')}]`
      : ''
  }
  return undefined
}

/**
 *
 * @param sortModel - sort model to be serialized
 * @returns a string with the sortModel separated by commas
 */
export const serializeSortModel = (
  sortModel: GridSortModel,
  defaultValue?: GridSortModel
) => {
  if (
    defaultValue &&
    sortModel &&
    sortModel.length > 0 &&
    sortModel[0] !== defaultValue[0]
  ) {
    return Array.isArray(sortModel)
      ? `[${sortModel
          .map(({ field, sort }) => {
            const fieldString = `"field":"${field}"`
            const sortString = `"sort":"${sort}"`
            return `{${fieldString},${sortString}}`
          })
          .join('|')}]`
      : ''
  }
  return undefined
}

/**
 *
 * @param pageSize - Page size to be serialized
 * @returns a string of the page size if valid
 */
export const serializePageSize = (pageSize: number) => {
  const isNumber = !Number.isNaN(pageSize)

  if (
    pageSizes.includes(pageSize) &&
    pageSize !== 10 &&
    isNumber &&
    typeof pageSize === 'number'
  ) {
    return pageSize
  }
  return undefined
}

/**
 *
 * @param page - Page number to be serialized
 * @returns a number of the page if valid
 */
export const serializePage = (page: number | string) => {
  const isNumber = !Number.isNaN(page)
  if (page !== 1 && isNumber && typeof page === 'number') {
    return page
  }

  return undefined
}

/**
 *
 * @param search - Page number to be serialized
 * @returns a number of the page if valid
 */
export const serializeSearch = (text?: string) => {
  if (text && text.length > 0) {
    return text
  }

  return undefined
}

export const serializeParam = (param: string) => {
  switch (param) {
    case UrlParamNames.LIMIT:
      return serializePageSize
    case UrlParamNames.PAGE:
      return serializePage
    case UrlParamNames.SORT:
      return serializeSortModel
    case UrlParamNames.FIELDS:
      return serializeFields
    case UrlParamNames.FILTERS:
      return serializeFilters
    case UrlParamNames.DATE:
      return serializeDate
    case UrlParamNames.SEARCH:
      return serializeSearch
    default:
      return undefined
  }
}
