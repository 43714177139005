import { styled } from '@mui/material'
import { Box } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const StyledContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  columnGap: '15px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '100%',
  },
})

export const LogContainer = styled(Box)({
  width: '92%',
})

export const StyledButton = styled('label')({
  width: '30px',
  height: '30px',
  position: 'absolute',
  display: 'flex',
  left: '85px',
  bottom: '5%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    color: colors.gray,
  },
})
