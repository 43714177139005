import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { renderWeekPickerDay } from 'library/utils/Calendar'

import { StyledDatePickerContainer } from './style'

export type CalendarProps = {
  /**
   * Date of the calendar
   */
  date: Date | null
  /**
   * If `true`, user will not be allowed to interact with the Calendar
   */
  disabled?: boolean
  /**
   * Function fired when date changes
   */
  handleCalendarDate: (newDate: Date | null) => void
  /**
   * Maximum allowed date for the calendar to be clicked
   */
  maxDate?: Date
  /**
   * Minimum allowed date for the calendar to be clicked
   */
  minDate?: Date
  /**
   * Disable specific date
   */
  shouldDisableDate?: (date: Date) => boolean
  /**
   * Callback fired when date is accepted
   */
  onAccept?: () => void
}

export const Calendar = ({
  date,
  disabled = false,
  handleCalendarDate,
  minDate,
  maxDate,
  shouldDisableDate,
  onAccept,
}: CalendarProps) => {
  return (
    <StyledDatePickerContainer>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={date}
          onChange={handleCalendarDate}
          onAccept={onAccept}
          renderInput={(params: TextFieldProps) => <TextField {...params} />}
          renderDay={renderWeekPickerDay}
          showDaysOutsideCurrentMonth
          minDate={minDate}
          maxDate={maxDate}
          views={['day']}
          disabled={disabled}
          shouldDisableDate={shouldDisableDate}
        />
      </LocalizationProvider>
    </StyledDatePickerContainer>
  )
}
