import { StepperModel } from 'library/components'

export type StepperTextType = {
  firstStep: string
  secondStep: string
  thirdStep: string
  fourthStep: string
  fifthStep: string
  sixthStep: string
}

export const createSimpleStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = []
  if (typeof text !== 'string') {
    for (const value of Object.values(text)) {
      stepperItems.push({ label: value })
    }
  } else {
    stepperItems.push({ label: text })
  }

  return stepperItems
}
