import React, { useState } from 'react'
import HelpIcon from '@mui/icons-material/Help'
import LockIcon from '@mui/icons-material/Lock'
import { Box, Button, Container } from 'library'

import ResetPasswordModal from 'components/Auth/ResetPassword/Modal'
import HelpModal from 'components/General/HelpModal'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

const SecuritySettings = () => {
  const { text } = useTranslation(textFiles.ACCOUNT)
  const {
    settings: { securitySettings: translation },
  } = text
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openHelp, setOpenHelp] = useState<boolean>(false)
  const handleOpenResetPassword = () => {
    setOpenModal(true)
  }

  const handleOpenHelp = () => {
    setOpenHelp(true)
  }

  return (
    <>
      <Container
        title={translation.title}
        description={translation.description}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="252px"
        width="auto"
        paddingBottom="0.75rem!important"
      >
        <Box display="flex" flexDirection="column">
          <Button
            size="medium"
            startIcon={<LockIcon />}
            onClick={handleOpenResetPassword}
            sx={{ marginTop: '1rem', whiteSpace: 'nowrap' }}
            data-cy="change-password-button"
          >
            {translation.passwordButton}
          </Button>
          <Button
            size="medium"
            startIcon={<HelpIcon />}
            onClick={handleOpenHelp}
            sx={{ marginTop: '1rem' }}
            data-cy="request-help-button"
          >
            {translation.helpButton}
          </Button>
        </Box>
      </Container>
      {openModal ? (
        <ResetPasswordModal openModal={openModal} setOpenModal={setOpenModal} />
      ) : null}
      {openHelp ? (
        <HelpModal
          show={openHelp}
          handleClose={() => setOpenHelp(false)}
          originPage="account-settings"
        />
      ) : null}
    </>
  )
}

export default SecuritySettings
