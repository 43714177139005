import React from 'react'
import { Typography } from '@mui/material'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import MassyMotorsLogo from 'images/massyMotorsLogo.png'

import {
  StyledContent,
  StyledLayout,
  StyledLeftContainer,
  StyledRightContainer,
} from './style'

const AuthLayout: React.FC = ({ children }) => {
  const { text } = useTranslation(textFiles.AUTH)

  return (
    <StyledLayout>
      <StyledLeftContainer>
        <StyledContent>
          <img alt="Massy Logo" src={MassyMotorsLogo} />
          <Typography sx={{ fontSize: 80, marginTop: '5rem' }} variant="h1">
            {text.welcomeMessage}
          </Typography>
          <Typography variant="h5">{text.helpMessage}</Typography>
        </StyledContent>
      </StyledLeftContainer>
      <StyledRightContainer>{children}</StyledRightContainer>
    </StyledLayout>
  )
}

export default AuthLayout
