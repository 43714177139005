import { Link } from 'react-router-dom'
import { styled } from '@mui/material'
import { Box } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10%',
  [`@media only screen and (min-width: ${BREAK_POINTS.LG}px)`]: {
    marginLeft: '0%',
  },
})

export const StyledCardsBox = styled(Box)({
  display: 'flex',
  marginTop: '30px',
  justifyContent: 'flex-start',
  gap: '24px 21px',
  flexWrap: 'wrap',
})

export const StyledLink = styled(Link)({
  width: '45%',
  [`@media only screen and (min-width: ${BREAK_POINTS.XXL - 100}px)`]: {
    maxWidth: '440px',
  },
})

export const StyledCard = styled(Box)({
  display: 'flex',
  height: '164px',
})

export const StyledIconContainer = styled(Box)({
  width: '50%',
  backgroundColor: colors.cyan,
  padding: '45px 50px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '> svg': {
    color: colors.orange,
    fontSize: '90px',
  },
})

export const StyledOptionDetail = styled(Box)({
  width: '50%',
  backgroundColor: colors.commonWhite,
  padding: '37px 20px',
  boxSizing: 'border-box',
})
