import React from 'react'
import { ClickAwayListener, Popper, Typography } from '@mui/material'
import { Box } from 'library/components/Common/Box'
import { Button } from 'library/components/Common/Button'
import { colors } from 'library/styles/theme'

import { StyledBox } from './style'

export type AvatarPopperTextType = {
  title: string
  placeholder: string
  popperTitle: string
  confirm: string
  decline: string
}

export type AvatarPopperProps = {
  anchorEl: HTMLElement | null
  handleClosePopper: () => void
  handleConfirmRemove: () => void
  open: boolean
  translation?: AvatarPopperTextType
}

export const AvatarPopper = ({
  anchorEl,
  handleClosePopper,
  handleConfirmRemove,
  open,
  translation,
}: AvatarPopperProps) => {
  return (
    <Popper
      id="translation-popper"
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
    >
      <ClickAwayListener onClickAway={handleClosePopper}>
        <StyledBox>
          <Typography>
            {translation?.popperTitle ||
              'Are you sure you want to delete this?'}
          </Typography>
          <Box display="flex" justifyContent="space-evenly" marginTop="10px">
            <Button onClick={handleConfirmRemove} size="small">
              {translation?.confirm || 'Yes'}
            </Button>
            <Button
              size="small"
              buttonType="info"
              sx={{
                backgroundColor: colors.background,
              }}
              onClick={handleClosePopper}
            >
              {translation?.decline || 'No'}
            </Button>
          </Box>
        </StyledBox>
      </ClickAwayListener>
    </Popper>
  )
}
