import React, { ChangeEvent } from 'react'
import { Box } from '@mui/material'
import { Checkbox } from 'library'

import { CheckHourType, ScheduleType } from 'models/outOfSpot'

type ScheduleCheckboxListProps = {
  schedule: CheckHourType[]
  type: ScheduleType
  loading?: boolean
  updateSchedules: (
    e: ChangeEvent<HTMLInputElement>,
    type: ScheduleType
  ) => void
}

export const ScheduleCheckboxList = ({
  schedule,
  type,
  updateSchedules,
  loading = false,
}: ScheduleCheckboxListProps) => {
  return (
    <Box flex="1 0 50%">
      {schedule.map((hourTime) => (
        <Checkbox
          key={hourTime.value}
          value={hourTime.value}
          label={`${hourTime.name} ${hourTime.am ? 'AM' : 'PM'}`}
          checked={hourTime.checked}
          disabled={loading}
          onChange={(e) => updateSchedules(e, type)}
        />
      ))}
    </Box>
  )
}
