import { styled, typographyClasses } from '@mui/material'
import { Container } from 'library'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  padding: '0rem 0rem 1rem',
  width: '100%',
  minHeight: '300px',
  backgroundColor: colors.commonWhite,
  '> div': {
    width: '100%',
    '> div': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      columnGap: '3rem',
      width: '100%',
      '> div': {
        width: '350px',
      },
    },
  },
  '> div:first-of-type': {
    [`& .${typographyClasses.body1}`]: {
      fontSize: 24,
      fontWeight: FONT_WEIGHT.BOLD,
    },
  },

  '> p': {
    fontSize: 12,
  },
})

export const StyledItem = styled(Container)({
  minHeight: 70,
  color: colors.placeholderGray,
  padding: 0,
  '& > div:nth-of-type(2)': {
    margin: 0,
  },
  ul: {
    fontSize: 14,
    li: {
      margin: '0.25rem 0',
      '&::marker': {
        color: colors.orange,
      },
    },
  },
})
