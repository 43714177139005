export enum OfferDetailColumnField {
  ID = 'ID',
  SLUG = 'carSlug',
  PICTURE = 'picture',
  VEHICLE = 'slug',
  PRICE = 'price',
  DISCOUNT = 'discount',
  NET_PRICE = 'netPrice',
  STATUS = 'status',
  ACTIONS = 'actions',
}
