import { styled } from '@mui/material'
import { calendarPickerClasses } from '@mui/x-date-pickers'
import { Box } from 'library/components/Common/Box'
import { colors } from 'library/styles/theme'

export const StyledDatePickerContainer = styled(Box)({
  width: '100%',

  '& > div': {
    backgroundColor: 'transparent',
  },
  '& > div > div > div': {
    margin: '0 auto',
  },
  [`& .${calendarPickerClasses.viewTransitionContainer}`]: {
    '& > div': {
      '> div:nth-of-type(2)': {
        minHeight: '200px',
      },
    },
  },
  [`& .${calendarPickerClasses.root}`]: {
    margin: 0,
  },
  [`& .${calendarPickerClasses.root} > div:nth-of-type(1)`]: {
    '& > div:nth-of-type(1)': {
      position: 'absolute',
      width: 283,
      display: 'flex',
      justifyContent: 'center',
    },
    '> div:nth-of-type(2)': {
      width: '100%',
      justifyContent: 'space-between',
      paddingRight: '10px',
    },
  },
  '& .Mui-selected:hover': {
    backgroundColor: colors.orange,
    color: `${colors.commonWhite} !important`,
  },
})
