import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { Typography } from '@mui/material'
import { CurboSpot } from 'library/models/curboSpot'
import { Filter, FilteringOption } from 'library/models/filter'
import { ListingFilterType } from 'library/models/services/base'
import { ProviderEntity } from 'library/models/services/providerEntity'
import { ExtendedStatus } from 'library/models/services/statusEntity'
import { TextObject } from 'library/models/text'

import { StyledCard } from './style'

export type FilterCardTextModel = {
  fieldOrEntryOptions: TextObject
  operatorOptions: TextObject
  filtersButton: string
  [key: string]: TextObject | string
}

export type FilterCardType = {
  filter?: Filter | null
  filterIdx?: number
  viewOnly?: boolean
  field?: string | null
  /**
   * Optional string for different text file
   */
  filterCardText: FilterCardTextModel
  /**
   * List of the new filters, stored in state but NOT STILL applied
   */
  newFiltersList?: Filter[]
  /**
   * Function to update the value of the filters list that are NOT STILL applied,
   * so you can remove filters from it before pushing the filters you want applied.
   */
  handleNewFiltersList?: (
    newFiltersList: Filter[],
    filterInput: ListingFilterType
  ) => void

  filterKey?: string

  filterInput?: ListingFilterType

  statusList?: ExtendedStatus[]

  curboSpots?: CurboSpot[]

  providerList?: ProviderEntity[]

  options?: Record<string, FilteringOption[]>
}

export const FilterCard = ({
  filter,
  filterIdx,
  viewOnly = false,
  field,
  filterCardText,
  newFiltersList,
  handleNewFiltersList,
  filterKey,
  filterInput,
  statusList,
  curboSpots,
  providerList,
  options,
}: FilterCardType) => {
  const [isActive, setIsActive] = useState(true)

  const { fieldOrEntryOptions, operatorOptions, provinceOptions } =
    filterCardText

  const removeFilter = () => {
    setIsActive(!isActive)

    /**
     * Remove the filter from the "newFiltersList" array. It contains the temporary filters
     * to apply.
     */
    if (newFiltersList && handleNewFiltersList && filterInput) {
      const testArray: Filter[] = [...newFiltersList]
      if (filterIdx !== undefined) testArray.splice(filterIdx, 1)

      let newFilterInput = { ...filterInput }
      if (filterKey) {
        const filterArray = filterKey.split(':')
        const key = filterArray[0]
        const value = filterArray[1]
        const newArrayValue = newFilterInput[key].filter((input) => {
          return typeof input === 'string'
            ? input !== value
            : input !== Number(value)
        })
        newFilterInput = { ...newFilterInput, [key]: newArrayValue }
      }
      handleNewFiltersList(testArray, newFilterInput)
    }
  }

  const getResultLabel = () => {
    if (filter) {
      const entry = filter?.fieldOrEntry
      const value = filter?.valueToCompare
      if (entry === 'province' && typeof value === 'string') {
        return provinceOptions[value] as unknown as string
      }
      if (entry === 'status') {
        const displayStatus = statusList?.find(
          (status) => status.slug === value
        )

        return displayStatus ? displayStatus.name : value
      }
      if (entry === 'curboSpot' && curboSpots) {
        const displayCurboSpot = curboSpots?.find(
          (curboSpot) => curboSpot.id === value
        )
        return displayCurboSpot ? displayCurboSpot.name : value
      }
      if (entry === 'provider' && providerList) {
        const displayProvider = providerList?.find(
          (provider) => provider.id === value
        )

        return displayProvider ? displayProvider.name : value
      }

      if (options && entry in options) {
        const displayOption = options[entry].find(
          (option) => option.value === value
        )
        return displayOption ? displayOption.name : value
      }
    }

    return filter?.valueToCompare
  }

  return isActive ? (
    <StyledCard>
      {filter ? (
        <Typography variant="subtitle1">{`"${
          fieldOrEntryOptions[filter.fieldOrEntry]
        }" ${
          operatorOptions[filter.operator]
        } "${getResultLabel()}"`}</Typography>
      ) : null}
      {field ? <Typography variant="subtitle1">{field}</Typography> : null}
      {viewOnly ? null : <CancelIcon onClick={removeFilter} />}
    </StyledCard>
  ) : null
}
