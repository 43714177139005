import { styled } from '@mui/material'
import { Box, Container } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const Content = styled(Container)({
  header: {
    backgroundColor: colors.commonWhite,
    '> div': {
      '> div:first-of-type': {
        marginTop: 28,
      },
    },
  },
  section: {
    marginTop: '2rem',
    display: 'grid',
    gridTemplateColumns: '40% 58%',
    columnGap: '20px',
    [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
      gridTemplateColumns: '100%',
      columnGap: 0,
      rowGap: 20,
    },
  },
})

export const CardLayout = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  width: '100%',
  gap: '20px 20px',
  padding: '3%',
  boxSizing: 'border-box',
  [`@media only screen and (max-width: ${BREAK_POINTS.MLG + 100}px)`]: {
    justifyContent: 'space-between',
  },
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    flexDirection: 'column',
    width: '48%',
    flexWrap: 'inherit',
    height: 'fit-content',
    gap: '20px 0px',
  },
})

export const LargeBoxBorder = styled(Box)({
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  width: '100%',
  height: 548,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '> div:first-of-type': {
    marginTop: 0,
  },
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridColumnStart: 1,
    gridRowStart: 1,
  },
})

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  backgroundColor: colors.background,
  boxShadow: 'none',
  width: '94%',
  height: '508px',
})

export const StyledContainer = styled(Container)({
  width: '86%',
  height: '440px',

  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    width: '83%',
  },
})

export const CardContent = {
  width: '174px',
  height: 100,
  marginBottom: '15px',
  [`@media only screen and (max-width: ${BREAK_POINTS.MLG + 100}px)`]: {
    width: '205px',
  },
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    width: '100%',
    marginBottom: 'unset',
  },
}

export const ResponsiveCardContainer = styled(Box)({
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
