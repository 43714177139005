import React, { useState } from 'react'
import { useFormik } from 'formik'
import { ButtonNavigator, ErrorMessage, sleep } from 'library'
import * as yup from 'yup'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'
// import SelectContainer from 'components/CarSettings/Common/Creation/Select'
import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'

import {
  BRAND_CREATION_OPTION,
  // brandCountry
} from 'constants/brand'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Brand } from 'models/car'
import { PublicationStatus } from 'models/status'
import { BrandCreationProps } from 'utils/CarSettings/Brand/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const BrandInfo = ({
  handleContinue,
  brandData,
  updateBrandData,
}: BrandCreationProps) => {
  const { brandInformation } = brandData
  const [brandInfo, setBrandInfo] = useState<Brand>(brandInformation)
  // const [countryState, setCountryState] = useState<string>(
  //   brandInformation.country as string
  // )
  const [pictureState, setPictureState] = useState<string>(
    brandInformation.picture
  )
  const [fileState, setFileState] = useState<File | null>(null)
  const [brandStatus, setBrandStatus] = useState<boolean>(
    brandInformation.status === PublicationStatus.PUBLISHED
  )

  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: translation } = useTranslation(textFiles.BRAND_CREATION)
  const { information } = translation

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<Brand>>({
    initialValues: {
      name: brandData.brandInformation.name,
    },
    validationSchema,
    onSubmit: async (values) => {
      setBrandInfo({
        ...brandInfo,
        name: values.name as string,
      })
      await sleep(1000)
      updateBrandData({
        type: BRAND_CREATION_OPTION.UPDATE_BRAND_INFORMATION,
        payload: {
          ...brandInfo,
          name: values.name as string,
          picture: pictureState,
          pictureFile: fileState,
          status: brandStatus
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      })

      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={information.isPublished}
            description={information.isPublishedDescription}
            status={brandStatus}
            handleStatus={setBrandStatus}
            testId="published"
          />
          <StyledTextFieldContainer
            title={information.name}
            description={information.nameDescription}
          >
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="Toyota"
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          {/* <SelectContainer
              title={information.country}
              description={information.countryDescription}
              options={brandCountry}
              value={countryState}
              handleSelectChange={setCountryState}
            /> */}
          <AvatarContainer
            title={information.logo}
            description={information.logoDescription}
            picture={pictureState}
            handlePictureChange={setPictureState}
            handleFileChange={setFileState}
            additionalButtonStyles={{ height: 'unset' }}
          />
        </div>
      </StyledForm>
      <ButtonNavigator
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default BrandInfo
