import FeatureInfo from 'components/CarSettings/Feature/Creation/FeatureInfo'
import Summary from 'components/CarSettings/Feature/Creation/Summary'

import {
  FeatureCreationAction,
  FeatureCreationModel,
} from 'models/featureCreation'

export type FeatureCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  featureData: FeatureCreationModel
  updateFeatureData: (action: FeatureCreationAction) => void
}

export const INITIAL_FEATURE_CREATION_STEPS: Array<
  React.FC<FeatureCreationProps>
> = [FeatureInfo, Summary]
