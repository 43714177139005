import { gql } from '@apollo/client'

export const GET_ASSIGNABLE_ROLES = gql`
  query getAssignableRoles($input: FilterInput) {
    data: getAssignableRoles(input: $input) {
      area {
        name
        value: slug
      }
      name
      value: slug
    }
  }
`
export const GET_ASSIGNABLE_AREAS = gql`
  query getAssignableRoleAreas {
    data: getAssignableRoleAreas {
      name
      value: slug
    }
  }
`
export const GET_ORGANIZATIONS = gql`
  query getOrganizations($input: FilterInput) {
    data: getOrganizations(input: $input) {
      value: id
      name
      status
      type
    }
  }
`
