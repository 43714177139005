import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { SummaryItem } from 'library'

import AvatarItem from 'components/CarSettings/Common/Creation/Summary/AvatarItem'
import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import TranslationItem from 'components/CarSettings/Common/Creation/Summary/TranslationItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { BodyStyleCreationProps } from 'utils/CarSettings/BodyStyle/creation'

import { SectionContainer } from 'styles/creation'

const Summary = ({
  handleBack,
  bodyStyleData,
  handleSubmit,
  submitLoading,
}: BodyStyleCreationProps) => {
  const { bodyStyleInformation } = bodyStyleData
  const { translations } = bodyStyleInformation
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { text } = useTranslation(textFiles.BODY_STYLE_CREATION)
  const { summary } = text

  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      title={summary.title}
      description={summary.description}
      submitLoading={submitLoading}
    >
      <SectionContainer>
        <SummaryItem
          title={summary.name}
          text={bodyStyleInformation.name}
          icon={<DirectionsCarIcon />}
        />
        <SummaryItem
          title={summary.status}
          text={generalText.status[bodyStyleInformation.status]}
          icon={<CheckCircleIcon />}
        />
        {translations.length > 0 ? (
          <TranslationItem translations={bodyStyleInformation.translations} />
        ) : null}
        <AvatarItem
          title={summary.logo}
          picture={bodyStyleInformation.picture}
        />
      </SectionContainer>
    </Skeleton>
  )
}

export default Summary
