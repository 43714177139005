import React from 'react'
import { colors } from 'library/styles/theme'

import { StyledElement, StyledItem } from './style'

export const defaultStyle: React.CSSProperties = {
  alignItems: 'flex-start',
  marginTop: '1rem',
}

export type SummaryItemProps = {
  title: string
  description?: string
  text: string | React.ReactNode
  icon?: React.ReactNode
  color?: string
  width?: number | string
  elementStyle?: React.CSSProperties
}

export const SummaryItem = ({
  title,
  description,
  text,
  icon,
  color = colors.placeholderGray,
  width = 315,
  elementStyle = defaultStyle,
}: SummaryItemProps): JSX.Element => {
  return (
    <StyledItem title={title} description={description} width={width}>
      <StyledElement
        sx={{
          ...elementStyle,
          color,
        }}
      >
        {icon}
        {text}
      </StyledElement>
    </StyledItem>
  )
}
