import React, { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button } from 'library'

import LocationSection from 'components/Common/Location'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { handleUpdateDealerType } from 'models/services/operations/dealer'

import { contentStyle } from './style'

type Props = {
  address: Address | undefined
  handleUpdateDealer: ({
    newSpecifications,
    newLocation,
  }: handleUpdateDealerType) => void
}

const DealerLocation = ({
  address: addressProp,
  handleUpdateDealer,
}: Props) => {
  const [address, setAddress] = useState<Address | undefined>(addressProp)
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const { text } = useTranslation(textFiles.DEALER_DETAIL)
  const { dealerLocation: translation } = text

  const checkIfAddressChange = (value: Address | undefined) => {
    if (value) {
      const fieldValue = address as Address
      if (fieldValue?.id !== (value as Address).id) {
        setShowSaveButton(true)
      } else {
        setShowSaveButton(false)
      }
    }
  }
  const handleSaveData = () => {
    if (address === undefined) {
      setError(true)
    } else {
      handleUpdateDealer({ newLocation: address })
      setShowSaveButton(false)
      setError(false)
    }
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    checkIfAddressChange(newAddress)
  }
  useEffect(() => {
    if (error && address !== undefined) {
      setError(false)
    }
  }, [error, address])
  return (
    <Box display="flex" height="720px" flexDirection="column">
      <LocationSection
        showError={error}
        translation={translation}
        address={address}
        curboSpots={[]}
        hasCoordinatePicker
        handleAddressChange={handleAddressChange}
        containerStyling={contentStyle}
      />
      {showSaveButton ? (
        <Button
          startIcon={<SaveIcon />}
          buttonType="primary"
          size="small"
          onClick={handleSaveData}
          sx={{
            alignSelf: 'start',
          }}
        >
          {translation.saveButton}
        </Button>
      ) : null}
    </Box>
  )
}
export default DealerLocation
