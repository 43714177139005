import { styled } from '@mui/material'
import { Box } from 'library/components/Common/Box'
import { colors } from 'library/styles/theme'

export const StyledContainer = styled(Box)({
  backgroundColor: colors.background,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '100%',
})

export const StyledContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  textAlign: 'left',
})

export const StyledTitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  margin: '0',
  padding: '0',
})

export const StyledSubTitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',

  '& > svg': {
    height: '15px',
    width: '15px',
    margin: '0px 7px 0 0',
    color: colors.gray,
  },

  '& > p': {
    color: colors.gray,
  },
})
