import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Typography } from '@mui/material'
import { Button, ButtonProps } from 'library'

import HelpModal from 'components/General/HelpModal'

import Logo from 'images/massyLogo.png'

import { FONT_WEIGHT } from 'styles/theme'

import {
  StyledBox,
  StyledButtonBox,
  StyledColumns,
  StyledCurboLogo,
  StyledErrorMessage,
  StyledImage,
  StyledLeftColumn,
  StyledLeftContent,
  StyledRightColumn,
  StyledSocials,
  StyledSubtitle,
  StyledTitle,
} from './style'

export type textType = {
  errorCode: string
  title: string
  subtitle: string
  leftButtonText: string
  rightButtonText: string
}

export type ErrorPageProps = {
  /**
   * Object containing the page's layout text
   */

  text: textType
  /**
   * Props to be passed to the left button, including icons
   */

  leftButtonProps?: ButtonProps

  handleLeftButtonClick: () => void

  /**
   * Props to be passed to the right  button, including icons
   */

  rightButtonProps?: ButtonProps

  handleRightButtonClick: () => void

  imageSrc: string

  /**
   * Function to close modals (if any of them is going to be opened)
   */
  isModalOpen?: boolean

  handleCloseModal?: () => void
}

const ErrorPage = ({
  text: { errorCode, title, subtitle, leftButtonText, rightButtonText },
  leftButtonProps,
  handleLeftButtonClick,
  rightButtonProps,
  handleRightButtonClick,
  imageSrc,
  isModalOpen,
  handleCloseModal,
}: ErrorPageProps) => {
  return (
    <StyledBox>
      <StyledCurboLogo src={Logo} alt="curbo-logo" />
      <StyledColumns>
        <StyledLeftColumn>
          <StyledLeftContent>
            <StyledErrorMessage>
              <Typography variant="h6" fontWeight={FONT_WEIGHT.MEDIUM}>
                {errorCode}
              </Typography>
            </StyledErrorMessage>
            <StyledTitle>{title}</StyledTitle>
            <StyledSubtitle variant="h5">{subtitle}</StyledSubtitle>
            <StyledButtonBox>
              <Button
                size="large"
                onClick={handleLeftButtonClick}
                {...leftButtonProps}
              >
                {leftButtonText}
              </Button>

              <Button
                buttonType="secondary"
                size="large"
                onClick={handleRightButtonClick}
                {...rightButtonProps}
              >
                {rightButtonText}
              </Button>
            </StyledButtonBox>
            <StyledSocials>
              <InstagramIcon />
              <TwitterIcon />
              <FacebookIcon />
            </StyledSocials>
          </StyledLeftContent>
        </StyledLeftColumn>
        <StyledRightColumn>
          <StyledImage src={imageSrc} />
        </StyledRightColumn>
      </StyledColumns>
      {handleCloseModal && isModalOpen ? (
        <HelpModal
          show={isModalOpen}
          handleClose={handleCloseModal}
          originPage="error-page"
        />
      ) : null}
    </StyledBox>
  )
}

export default ErrorPage
