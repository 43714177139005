import { BASE_API_URL } from 'constants/environment'
import { reportFileType } from 'models/listingReport'
/**
 * Source: https://ourtechroom.com/tech/mime-type-for-excel/
 */

export const reportFileTypeMap: Record<reportFileType, string> = {
  csv: 'text/csv',
  pdf: 'application/pdf',
  xlsx: '	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const CARS_LISTING_REPORT_URL = `${BASE_API_URL}/catalog/cars/report`
export const INSPECTIONS_LISTING_REPORT_URL = `${BASE_API_URL}/inspection/inspections/report`
export const INSPECTORS_LISTING_REPORT_URL = `${BASE_API_URL}/inspection/inspectors/report`
export const DEALERS_LISTING_REPORT_URL = `${BASE_API_URL}/catalog/dealers/report`
export const CURBO_SPOT_LISTING_REPORT_URL = `${BASE_API_URL}/catalog/curbo-spots/report`
