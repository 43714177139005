import { Box, styled, Typography } from '@mui/material'
import { Button } from 'library'

import { BREAK_POINTS } from 'styles/breakpoints'
import { boxShadow, colors, FONT_WEIGHT } from 'styles/theme'

export const StyledBox = styled(Box)({
  margin: '0 auto',
  width: '95vw',
  [`@media only screen and (min-width: ${BREAK_POINTS.MD}px)`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '29px',
    paddingTop: '30px',
    paddingBottom: '5px',
    boxSizing: 'border-box',
    overflowY: 'scroll',
    height: 'auto',
    width: '100vw',
  },
})

export const StyledColumns = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',
  gridTemplateAreas: `
  "image"
  "content"
  `,
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginTop: '20px !important',

  [`@media only screen and (min-width: ${BREAK_POINTS.MD}px)`]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: '1fr 1fr',
    gridTemplateAreas: `
    "content content image image"
    "content content image image"
    `,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    marginTop: '50px',
  },
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '100px !important',
  },
})

export const StyledLeftColumn = styled(Box)({
  gridArea: 'content',
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '40px',
  margin: '0 auto 80px auto',

  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    paddingLeft: '39px',
    alignItems: 'stretch',
    width: '90%',
    margin: '0 auto 40px auto',
  },
})

export const StyledLeftContent = styled(Box)({
  width: '100%',
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    marginRight: '41px',
  },
})

export const StyledCurboLogo = styled('img')({
  height: '80px',
  width: '200px',
  marginTop: '82px',
  marginLeft: '34px',
  objectFit: 'contain',
  [`@media only screen and (min-width: ${BREAK_POINTS.MD}px)`]: {
    width: '229px',
    height: '80px',
    marginLeft: '30px',
    marginTop: '74px',
  },
})

export const StyledErrorMessage = styled(Box)({
  backgroundColor: colors.cyan,
  padding: '17px',
  width: 'fit-content',
  marginBottom: '45px',
  boxShadow,
})

export const StyledTitle = styled(Typography)({
  fontSize: '2rem',
  fontWeight: FONT_WEIGHT.EXTRA_BOLD,
  color: colors.altBlack2,
  marginBottom: '7px',

  [`@media only screen and (min-width: ${BREAK_POINTS.SM}px)`]: {
    fontSize: '3rem',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.MD}px)`]: {
    fontSize: '70px',
  },
})

export const StyledSubtitle = styled(Typography)({
  fontWeight: FONT_WEIGHT.MEDIUM,
  marginBottom: '55px',
  fontSize: '20px',
  marginTop: '15px',

  [`@media only screen and (min-width: ${BREAK_POINTS.SM}px)`]: {
    fontSize: '24px',
  },
})

export const StyledBackButton = styled(Button)({
  width: '243px',
  height: '95px',
})

export const StyledHelpButton = styled(Button)({})

export const StyledButtonBox = styled(Box)({
  display: 'flex',
  width: '100%',
  marginBottom: '30px',
  flexDirection: 'row',
  '> button': {
    width: 'auto',
    margin: '0px 10px 20px 0px',
    whiteSpace: 'nowrap',
  },

  '> button:nth-of-type(1)': {
    height: '60px',
    fontSize: '20px',
  },
  '> button:nth-of-type(2)': {
    height: '60px',
    fontSize: '20px',
    justifyContent: 'center !important',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.SM}px)`]: {
    flexDirection: 'row',
    '> button:nth-of-type(1)': {
      width: '35%',
      maxWidth: '200px',
      marginRight: '22px',
      height: '95px',
      fontSize: '25px ',
    },
    '> button:nth-of-type(2)': {
      width: '60%',
      height: '95px',
      maxWidth: '350px',
      justifyContent: 'center',
      fontSize: '25px ',
    },
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.MD}px)`]: {
    marginBottom: '60px',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    marginBottom: '100px',
  },
})

export const StyledSocials = styled(Box)({
  marginTop: '2em',
  width: '150px',
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto',
  '> svg': {
    fontSize: '35px',
    color: colors.altBlack2,
    cursor: 'pointer',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.SM}px)`]: {
    margin: 'auto',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.MD}px)`]: {
    margin: '2em 0 0 0',
  },
})

export const StyledRightColumn = styled(Box)({
  width: '90%',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  [`@media only screen and (min-width: ${BREAK_POINTS.MD}px)`]: {
    gridArea: 'image',
    width: '100%',
    height: '100%',
  },
})

export const StyledImage = styled('img')({
  minWidth: '350px',
  width: '100%',
  minHeight: '350px',
  height: '100%',
  marginBottom: '2rem',
  [`@media only screen and (min-width: ${BREAK_POINTS.MD}px)`]: {
    width: '90%',
    maxHeight: '780px',
  },
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    width: '95%',
    margin: '0 auto',
  },
})
