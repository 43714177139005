import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Check from '@mui/icons-material/Check'
import { Box, InputLabel, styled } from '@mui/material'

import { boxShadow, colors, FONT_WEIGHT } from 'styles/theme'

export const CalendarContainer = styled(Box)({
  backgroundColor: colors.commonWhite,
  boxShadow,
  height: '390px',
  width: 'fit-content',
  display: 'flex',
})

export const CheckIcon = styled(Check)({
  color: `${colors.commonWhite} !important`,
})

export const CalendarIcon = styled(CalendarMonthIcon)({
  color: `${colors.gray} !important`,
})

export const StyledInputLabel = styled(InputLabel)({
  color: `${colors.gray} !important`,
  fontWeight: FONT_WEIGHT.REGULAR,
  fontSize: '14px',
  letterSpacing: '0.12px',
  top: '-5px',
  opacity: '0.5',
  marginLeft: '20px',
})
