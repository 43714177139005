import React, { useState } from 'react'
import { Avatar } from 'library'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'
import { StyledContainer } from 'components/CarSettings/Common/Creation/style'

import defaultAvatarImage from 'images/defaultAvatar.png'

type Props = {
  title: string
  editMode: boolean
  keyCode: string
  handleChange: (value: string | boolean | File | null, keyCode: string) => void
  picture: string | null
}

const Item = ({ title, editMode, keyCode, picture, handleChange }: Props) => {
  const [pictureState, setPictureState] = useState<string>(
    (picture as string) || defaultAvatarImage
  )
  const [fileState, setFileState] = useState<File | null>(null)

  const handleFileState = (file: File | null) => {
    setFileState(file)
    handleChange(fileState, keyCode)
  }

  return (
    <>
      {editMode ? (
        <AvatarContainer
          title={title}
          picture={pictureState}
          handlePictureChange={setPictureState}
          handleFileChange={handleFileState}
        />
      ) : (
        <StyledContainer title={title}>
          <div style={{ marginTop: '1rem' }}>
            <Avatar height={95} width={95} variant="rounded" image={picture} />
          </div>{' '}
        </StyledContainer>
      )}
    </>
  )
}

export default Item
