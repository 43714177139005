import React from 'react'
import { Box } from 'library'

import { StatusStyles } from 'constants/status'
import {
  InspectionStatus,
  InspectorStatus,
  InventoryStatus,
  PublicationStatus,
  TemplateStatus,
} from 'models/status'

import { colors, FONT_WEIGHT } from 'styles/theme'

type StatusProps = {
  variant:
    | InspectionStatus
    | TemplateStatus
    | InspectorStatus
    | PublicationStatus
    | InventoryStatus
}

const Status: React.FC<StatusProps> = ({ variant, children }) => {
  let backgroundColor: string
  let color: string
  try {
    backgroundColor = StatusStyles[variant].backgroundColor
    color = StatusStyles[variant].color
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    backgroundColor = colors.lightPink
    color = colors.red
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor,
        color,
        width: '85px',
        height: '25px',
        borderRadius: '2px',
        opacity: '1',
        fontSize: '12px',
        fontWeight: FONT_WEIGHT.MEDIUM,
      }}
    >
      {children}
    </Box>
  )
}

export default Status
