import React, { FunctionComponent, useState } from 'react'
import NumberFormat from 'react-number-format'
import { InputBaseComponentProps, SelectChangeEvent } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import { ButtonNavigator, ErrorMessage, Select } from 'library'
import * as yup from 'yup'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'
import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'

import { placeholderPhone } from 'constants/dealer'
import { CURBO_SPOT_CREATION_OPTION } from 'constants/Operation/curboSpot'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { SpotInformation } from 'models/services/operations/curboSpot'
import { PublicationStatus } from 'models/status'
import { SpotCreationProps } from 'utils/CurboSpot/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        format="+57 (###) ### ####"
        mask=""
        isNumericString
      />
    )
  }
)

const Information = ({
  cities,
  spotData,
  handleContinue,
  updateSpotData,
}: SpotCreationProps) => {
  const { text } = useTranslation(textFiles.CURBO_SPOT_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const {
    spotInformation: {
      status,
      city,
      state,
      picture,
      name,
      telephoneNumber,
      pictureFile,
    },
  } = spotData

  const [pictureState, setPictureState] = useState<string>(picture)
  const [fileState, setFileState] = useState<File | null>(null)
  const [spotStatus, setSpotStatus] = useState<boolean>(
    status === PublicationStatus.PUBLISHED
  )
  const {
    information: {
      spotName,
      spotPhone,
      cityName,
      stateName,
      thumbnail,
      thumbnailDescription,
      isPublished,
      isPublishedDescription,
    },
  } = text

  const phoneValidation = validationText.errorMessagePhoneNumberDigits.replace(
    '%d',
    '10'
  )

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
    city: yup.string().required(validationText.fieldRequired),
    state: yup.string().required(validationText.fieldRequired),
    telephoneNumber: yup
      .string()
      .required(validationText.fieldRequired)
      .length(10, phoneValidation),
  })

  const formik = useFormik<SpotInformation>({
    initialValues: {
      name,
      city,
      state,
      telephoneNumber,
      picture,
      pictureFile,
      status,
    },
    validationSchema,
    onSubmit: (values) => {
      updateSpotData({
        type: CURBO_SPOT_CREATION_OPTION.UPDATE_CURBO_SPOT_INFORMATION,
        payload: {
          ...values,
          status: spotStatus
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
          picture: pictureState,
          pictureFile: fileState,
        },
      })
      handleContinue()
    },
  })

  const onSelectChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    formik.setFieldValue('city', value as string)
    formik.setFieldTouched('city', false)
    const selectedCity = cities.find(
      (currentCity) => currentCity.value === value
    )
    if (selectedCity) formik.setFieldValue('state', selectedCity.state.name)
  }

  return (
    <FormikProvider value={formik}>
      <StyledContainer>
        <StyledForm onSubmit={formik.handleSubmit} id="curbo-spot-form">
          <div>
            <ToggleContainer
              title={isPublished}
              description={isPublishedDescription}
              status={spotStatus}
              handleStatus={setSpotStatus}
              testId="published"
            />
            <StyledTextFieldContainer title={`${spotName}*`}>
              <StyledTextField
                variant="outlined"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Massy Spot"
                error={formik.touched.name && Boolean(formik.errors.name)}
                inputProps={{ maxLength: 40 }}
              />
              {formik.touched.name && formik.errors.name ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.name}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={`${spotPhone}*`}>
              <StyledTextField
                variant="outlined"
                name="telephoneNumber"
                placeholder={placeholderPhone}
                value={formik.values.telephoneNumber}
                InputProps={{
                  inputComponent:
                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                error={
                  formik.touched.telephoneNumber &&
                  Boolean(formik.errors.telephoneNumber)
                }
                onChange={formik.handleChange}
              />
              {formik.touched.telephoneNumber &&
              formik.errors.telephoneNumber ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.telephoneNumber}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={`${cityName}*`}>
              <Select
                options={cities}
                variant="outlined"
                name="city"
                value={formik.values.city}
                onChange={onSelectChange}
                sx={{ fontSize: 12, height: 40 }}
                label="Bogotá"
                error={formik.touched.city && Boolean(formik.errors.city)}
              />
              {formik.touched.city && formik.errors.city ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.city}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={stateName}>
              <StyledTextField
                variant="outlined"
                name="state"
                value={formik.values.state}
                placeholder="Antio"
                error={formik.touched.state && Boolean(formik.errors.state)}
                disabled
              />
              {formik.touched.state && formik.errors.state ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.state}
                />
              ) : null}
            </StyledTextFieldContainer>
            <AvatarContainer
              title={thumbnail}
              description={thumbnailDescription}
              picture={pictureState}
              handlePictureChange={setPictureState}
              handleFileChange={setFileState}
            />
          </div>
        </StyledForm>
        <ButtonNavigator
          isFirstStep
          confirmButtonType="submit"
          nextFunction={formik.handleSubmit}
        />
      </StyledContainer>
    </FormikProvider>
  )
}

export default Information
