// simulating async submit

import { SidebarItem } from 'components/General/Sidebar'

import { ENVIRONMENT } from 'constants/environment'
import {
  OrganizationStatusEnum,
  OrganizationTypeEnum,
  User,
} from 'context/UserContext'
import { BackOfficeAllowedRoles, UserRoles } from 'models/role'

/**
 * Check if atleast one
 * of the roles of the user is inside
 * the permitted roles passed to it.
 */
export const grantPermissions = (
  clientRoles: UserRoles[],
  permittedRoles: UserRoles[],
  hideInProduction?: boolean
) => {
  if (hideInProduction && ENVIRONMENT === 'production') return false

  return clientRoles.some((role) => permittedRoles.includes(role))
}

/*
 * Check if the user roles are even allowed to access
 * the BackOffice
 */
export const grantAccess = (clientRoles: UserRoles[]) => {
  return clientRoles.some((role) => BackOfficeAllowedRoles.includes(role))
}

export const checkOrganization = (user: User) => {
  const { organization } = user

  if (!organization) return false

  const { status, type } = organization

  if (
    status === OrganizationStatusEnum.ACTIVE &&
    type === OrganizationTypeEnum.CURBO
  )
    return true

  return false
}

/**
 * This is a recursive function which does the following,
 * if the element is 'private' (has allowedRoles prop)
 * it checks wether the current user has permission to
 * access it if it doesn't it goes to the next element
 * of the list otherwise it adds it. We keep repeating
 * the same proccess until we have iterating throught all
 * the structure.
 *
 * Aditioanlly, if the item has hideInProduction as `true` and
 * the current environment is production, it will hide the item
 * even if `allowedRoles` is not defined
 */

export const filterSidebarItems = (
  items: SidebarItem[],
  userRoles: UserRoles[]
): SidebarItem[] => {
  const filteredItems: SidebarItem[] = []
  items.forEach((item: SidebarItem) => {
    if (
      item.allowedRoles ||
      (item.hideInProduction && ENVIRONMENT === 'production')
    ) {
      const itemAllowedRoles = item.allowedRoles || []
      if (
        !grantPermissions(userRoles, itemAllowedRoles, item.hideInProduction)
      ) {
        return
      }
    }
    if (item.children) {
      filteredItems.push({
        ...item,
        children: filterSidebarItems(item.children, userRoles),
      })
    } else {
      filteredItems.push(item)
    }
  })
  return filteredItems
}
