import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ArrowForwardIos } from '@mui/icons-material'
import MuiCollapse from '@mui/material/Collapse'
import List from '@mui/material/List'

import Popper from 'components/Common/Popper'
import { SidebarItem } from 'components/General/Sidebar'

import { isHighlighted } from 'constants/collapse'

import { colors } from 'styles/theme'

import { StyledListItemButton, SubCategory } from './style'

type CollapseProps = {
  expand: boolean
  items: SidebarItem[]
  highlightOnUrl?: boolean
}

/**
 * The Collapse component allows to create a list that can expand  * retract depending on the user input. It combines a normal mui
 * material List with their Collapse component which achieves the * latter efect .
 */
const Collapse: React.FC<CollapseProps> = ({
  expand,
  items,
  highlightOnUrl = false,
}: CollapseProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState(0)
  const [selectedChildren, setSelectedChildren] = useState<SidebarItem[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  const history = useHistory()
  const [open, setOpen] = useState<boolean>(false)
  const location = useLocation()

  const currentUrlPage = location.pathname

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handlePopperClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const maintainPopover = () => {
    setAnchorEl(anchorEl)
    setOpen(true)
  }

  const handleSubElementClick = (index: number) => {
    setSelectedSubCategory(index)
  }
  return (
    <MuiCollapse in={expand} timeout="auto">
      <List sx={{ width: '275px' }}>
        {items.map((item, index) => {
          const { icon, key, children, name, url } = item

          if (!children) {
            return (
              <StyledListItemButton
                key={key}
                onClick={() => {
                  handleSubElementClick(index + 1)
                  if (url) history.push(url)
                }}
                selected={
                  highlightOnUrl
                    ? url === currentUrlPage
                    : selectedSubCategory === index + 1
                }
                onMouseEnter={handlePopperClose}
                data-cy={`${item.key}-collapse-item`}
              >
                <SubCategory
                  sx={{
                    color: 'colors.altBlack',
                  }}
                >
                  {icon}
                  {name}
                </SubCategory>
              </StyledListItemButton>
            )
          }
          return (
            <React.Fragment key={key}>
              <StyledListItemButton
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                disableRipple
                sx={{
                  padding: '0 16px 0 13px',
                  color: 'colors.altBlack',
                }}
                onMouseEnter={(e) => {
                  handlePopperClose()
                  handlePopperOpen(e)
                  setSelectedChildren(children)
                  setSelectedIndex(index)
                }}
                onClick={() => {
                  if (children) {
                    handleSubElementClick(index + 1)
                    history.push(children[0].url as string)
                  }
                }}
                selected={
                  highlightOnUrl
                    ? isHighlighted(children, currentUrlPage)
                    : selectedSubCategory === index + 1
                }
                data-cy={`${item.key}-collapse-item`}
              >
                {icon}
                <SubCategory
                  sx={{
                    paddingLeft: '0.5em',
                    color: isHighlighted(children, currentUrlPage)
                      ? colors.orange
                      : 'colors.altBlack',
                  }}
                >
                  {name}
                </SubCategory>
                <ArrowForwardIos
                  fontSize="small"
                  sx={{
                    color: 'colors.altBlack',
                  }}
                />
              </StyledListItemButton>
            </React.Fragment>
          )
        })}
      </List>
      <Popper
        open={open}
        handlePopperClose={handlePopperClose}
        handlePopperOpen={maintainPopover}
        anchorEl={anchorEl}
        items={selectedChildren || []}
        changeSelectedSubcategory={handleSubElementClick}
        indexToHighlight={selectedIndex + 1}
      />
    </MuiCollapse>
  )
}

export default Collapse
