import ColorInfo from 'components/CarSettings/Color/Creation/ColorInfo'
import ColorSummary from 'components/CarSettings/Color/Creation/Summary'

import { ColorCreationModel } from 'models/colorCreation'

export type ColorCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  colorData: ColorCreationModel
  updateColorData: (newColor: ColorCreationModel) => void
}

export const COLOR_CREATION_STEPS: Array<React.FC<ColorCreationProps>> = [
  ColorInfo,
  ColorSummary,
]
