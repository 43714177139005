import React, { FunctionComponent, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import { Checkbox, InputBaseComponentProps, Typography } from '@mui/material'
import {
  Box,
  ColorBox,
  Container,
  formatDateLocalizedTime,
  getIsoDate,
} from 'library'

import { StyledIcon } from 'components/CarSettings/Common/Detail/NameContainer/style'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import InformationCell from 'components/Inventory/Common/InformationCell'
import NumberValueInput from 'components/Inventory/Common/NumberValueInput'

import { cypressCancelButton, cypressEditButton } from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { CalendarRangeType } from 'models/date'
import {
  DiscountTypeEnum,
  Offer,
  OfferPriorityEnum,
  OfferProps,
  OfferTypes,
} from 'models/services/offer/detail'

import { colors } from 'styles/theme'

import ColorButton from '../Common/ColorButton'
import ImageItem from '../Common/ImageItem'
import RangeButton from '../Common/RangeButton'

import { GridBox, StyledContainer } from './style'

const GeneralInformation = ({
  data,
  submitLoading,
  handleSave,
}: OfferProps) => {
  const { text } = useTranslation(textFiles.OFFER_DETAIL)
  const { generalInformation: translation } = text
  const [selectedLanguage] = useLocale()
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''
  const [edit, setEdit] = useState<boolean>(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<CalendarRangeType>({
    fromDate: new Date(data.from),
    toDate: new Date(data.to),
  })

  const priorityStatus = data.priority === OfferPriorityEnum.PRIMARY
  const [priority, setPriority] = useState<boolean>(priorityStatus)

  const [hasLogoChanges, setHasLogoChanges] = useState<boolean>(false)
  const [hasPictureChanges, setHasPictureChanges] = useState<boolean>(false)

  const [information, setInformation] = useState<Offer>(data)

  const range = `${formatDateLocalizedTime(
    edit ? (dateRange.fromDate as Date) : new Date(data.from),
    'P',
    selectedLanguage.code
  )} - ${formatDateLocalizedTime(
    edit ? (dateRange.toDate as Date) : new Date(data.to),
    'P',
    selectedLanguage.code
  )}`

  const handleSaveChanges = async () => {
    const isUpdateSuccesful = await handleSave({
      ...information,
      backgroundColor: information.backgroundColor,
      description: information.description,
      discount: information.discount,
      from: getIsoDate(dateRange.fromDate, 'date') as string,
      label: information.label,
      labelColor: information.labelColor,
      logo: information.logo,
      pictures: information.pictures,
      textColor: information.textColor,
      to: getIsoDate(dateRange.toDate, 'date') as string,
      priority: priority
        ? OfferPriorityEnum.PRIMARY
        : OfferPriorityEnum.SECONDARY,
      hasChangedLogo: hasLogoChanges,
      hasChangedPicture: hasPictureChanges,
      logoState: information.logoState,
      pictureState: information.pictureState,
    })
    if (isUpdateSuccesful) {
      setHasUnsavedChanges(false)
      setEdit(false)
    }
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setHasUnsavedChanges(true)
  }

  const handleChangeInformation = (
    value: string | number | boolean | File | null,
    keyCode: string
  ) => {
    setInformation({ ...information, [keyCode]: value })
    if (keyCode === 'logoState') {
      setHasLogoChanges(true)
    }
    if (keyCode === 'pictureState') {
      setHasPictureChanges(true)
    }
    setHasUnsavedChanges(true)
  }

  const handlePriority = (checked: boolean) => {
    setPriority(checked)
    setHasUnsavedChanges(true)
  }

  return (
    <StyledContainer
      title={translation.title}
      description={translation.description}
    >
      {!edit ? (
        <StyledIcon
          disabled={submitLoading}
          onClick={() => setEdit(true)}
          data-cy={cypressEditButton}
        >
          <EditIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
        </StyledIcon>
      ) : (
        <>
          <StyledIcon
            onClick={() => setEdit(false)}
            data-cy={cypressCancelButton}
          >
            <ClearIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
          </StyledIcon>
        </>
      )}
      <GridBox>
        <InformationCell
          label={translation.nameLabel}
          value={edit ? information.label : data.label}
          edit={edit}
          name="label"
          onChange={(e) => handleChangeInformation(e.target.value, 'label')}
        />
        <InformationCell
          label={translation.descriptionLabel}
          value={edit ? information.description : data.description}
          edit={edit}
          name="description"
          onChange={(e) =>
            handleChangeInformation(e.target.value, 'description')
          }
          inputSx={{ height: 113 }}
          rowsNumber={5}
          multiline
        />
        <InformationCell
          label={translation.discountTypeLabel}
          value={translation.discount[data.discountType.toLowerCase()]}
        />
        <InformationCell
          label={translation.discountLabel}
          value={edit ? String(information.discount) : String(data.discount)}
          edit={edit && data.discountType === DiscountTypeEnum.GENERAL}
          name="discount"
          onChange={(e) => handleChangeInformation(e.target.value, 'discount')}
          inputComponent={
            NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>
          }
          thousandSeparator
          endAdornment={priceCurrency}
        />
        <InformationCell
          label={translation.backgroundLabel}
          value={edit ? information.backgroundColor : data.backgroundColor}
          edit={edit}
          name="backgroundColor"
          startAdornment={
            <ColorBox
              height={15}
              width={15}
              styles={{
                border: `1px solid ${colors.gray}`,
                boxShadow: 'none',
                marginRight: '0.5rem',
              }}
              hexCode={information.backgroundColor}
            />
          }
        >
          <ColorButton
            text={information.backgroundColor}
            keyCode="backgroundColor"
            handleChange={handleChangeInformation}
          />
        </InformationCell>
        <InformationCell
          label={translation.textLabel}
          value={edit ? information.textColor : data.textColor}
          edit={edit}
          name="textColor"
          startAdornment={
            <ColorBox
              height={15}
              width={15}
              styles={{
                border: `1px solid ${colors.gray}`,
                boxShadow: 'none',
                marginRight: '0.5rem',
              }}
              hexCode={information.textColor}
            />
          }
        >
          <ColorButton
            text={information.textColor}
            keyCode="textColor"
            handleChange={handleChangeInformation}
          />
        </InformationCell>
        <InformationCell
          label={translation.labelColor}
          value={edit ? information.labelColor : data.labelColor}
          edit={edit}
          name="labelColor"
          startAdornment={
            <ColorBox
              height={15}
              width={15}
              styles={{
                border: `1px solid ${colors.gray}`,
                boxShadow: 'none',
                marginRight: '0.5rem',
              }}
              hexCode={information.labelColor}
            />
          }
        >
          <ColorButton
            text={information.labelColor}
            keyCode="labelColor"
            handleChange={handleChangeInformation}
          />
        </InformationCell>
        <InformationCell
          label={translation.DateRangeLabel}
          value={range}
          edit={
            edit &&
            data.type !== OfferTypes.YEAR &&
            data.type !== OfferTypes.PRICE
          }
          name="DateRangeLabel"
        >
          <RangeButton
            text={range}
            dateRange={dateRange}
            handleChange={handleChangeDateRange}
          />
        </InformationCell>

        <InformationCell
          label={translation.offerTypeLabel}
          value={translation.offer[data.type.toLowerCase()]}
        />

        <InformationCell
          label={translation.prioritizeLabel}
          value={
            edit
              ? translation.bool[priority.toString()]
              : translation.bool[priorityStatus.toString()]
          }
          edit={edit}
          name="priority"
        >
          <Box alignItems="center" display="flex">
            <Checkbox
              checked={edit ? priority : priorityStatus}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ padding: '0 9px' }}
              onChange={(e) =>
                edit ? handlePriority(e.target.checked) : () => undefined
              }
            />
            {edit
              ? translation.bool[priority.toString()]
              : translation.bool[priorityStatus.toString()]}
          </Box>
        </InformationCell>
      </GridBox>
      <GridBox>
        <ImageItem
          title={translation.logoLabel}
          editMode={edit}
          keyCode="logoState"
          handleChange={handleChangeInformation}
          picture={edit ? information.logo : data.logo}
        />
        {/* <ImageItem
          title={translation.pictureLabel}
          editMode={edit}
          keyCode="pictureState"
          handleChange={handleChangeInformation}
          picture={edit ? information.pictures[0] : data.pictures[0]}
        /> */}
      </GridBox>
      <Box>
        {data.brands && data.type === OfferTypes.BRAND ? (
          <Container title={translation.modelLabel} padding="0px !important">
            {data.brands.map((brand) => (
              <Box display="flex">
                <Typography
                  variant="body1"
                  marginRight="4px"
                >{`${brand.name} - `}</Typography>
                <Typography
                  variant="body2"
                  color={colors.placeholderGray}
                  lineHeight="1.5"
                  fontSize="16px"
                >
                  {brand.id}
                </Typography>
              </Box>
            ))}
          </Container>
        ) : null}
        {data.models && data.type === OfferTypes.MODEL ? (
          <Container title={translation.modelLabel} padding="0px !important">
            {data.models.map((model) => (
              <Box display="flex">
                <Typography
                  variant="body1"
                  marginRight="4px"
                >{`${model.name} - `}</Typography>
                <Typography
                  variant="body2"
                  color={colors.placeholderGray}
                  lineHeight="1.5"
                  fontSize="16px"
                >
                  {model.id}
                </Typography>
              </Box>
            ))}
          </Container>
        ) : null}
      </Box>
      <SaveChangesBlock
        handleSaveChanges={handleSaveChanges}
        submitLoading={submitLoading}
        shouldRender={hasUnsavedChanges && edit}
      />
    </StyledContainer>
  )
}

export default GeneralInformation
