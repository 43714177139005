import React, { useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import { Typography } from '@mui/material'
import { Box, SummaryItem } from 'library'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import Map from 'components/General/Map'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { StateCreationProps } from 'utils/State/creation'

const StateSummary = ({
  stateData,
  handleBack,
  handleSubmit,
  submitLoading,
}: StateCreationProps) => {
  const { stateInformation } = stateData
  const { text } = useTranslation(textFiles.STATE_CREATION)
  const {
    summary: { title, description, status, name, map },
  } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [, setApiLoaded] = useState<boolean>(false)
  const [address, setAddress] = useState<Address | undefined>(
    stateInformation.address
  )

  return (
    <Skeleton
      previousFunction={handleBack}
      title={title}
      description={description}
      submitLoading={submitLoading}
      nextFunction={handleSubmit}
      gridContainerStyles={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      containerStyles={{ height: '100%' }}
      formStyles={{
        height: '100%',
      }}
      bodyStyles={{
        height: '100%',
        overflowY: 'inherit',
      }}
    >
      <SummaryItem
        title={name}
        text={stateInformation.name}
        icon={<LocationCityIcon />}
      />

      <SummaryItem
        title={status}
        text={generalText.status[stateInformation.status]}
        icon={<CheckCircleIcon />}
      />

      <Box width="100%" height="353px" sx={{ marginBottom: '5rem' }}>
        <Typography variant="body1" marginBottom="12px">
          {map}
        </Typography>
        <Map
          disabled
          setIsLoaded={setApiLoaded}
          curboSpots={[]}
          address={address}
          setAddress={setAddress}
          circleCenter={
            address ? { lat: address.lat, lng: address.lng } : undefined
          }
        />
      </Box>
    </Skeleton>
  )
}

export default StateSummary
